/**
 * Context Unite template
 * description: all the context will be here in order to avoid over populating on the APP file
 */

import React from "react";

import AppStoreProvider from '@lib/Context';
import { PopupProvider } from '@lib/hooks/usePopup';
import {ManageDealProvider} from "@lib/Context/ManageDealContext/manage-deal.context";
import {StepSettingsProvider} from "@lib/Context/StepSettings/step-settings.context";
import {QuoteStepProvider} from "@lib/Context/QuoteStep/quote-step.context";

const ContextUnite = ({children}) => {
  return (
    <AppStoreProvider>
      <PopupProvider>
        <ManageDealProvider>
          <QuoteStepProvider>
            <StepSettingsProvider>
              {children}
            </StepSettingsProvider>
          </QuoteStepProvider>
        </ManageDealProvider>
      </PopupProvider>
    </AppStoreProvider>
  )
}

export default ContextUnite;
