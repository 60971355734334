import { useCallback, Fragment, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import DealName from '@atoms/TableViewColumns/DealName/deal-name.component';
import OriginCountry from '@atoms/TableViewColumns/Origin/origin-country.component';
import DealProducts from '@atoms/TableViewColumns/Product/deal-products.component';
import PricePerUnit from '@atoms/TableViewColumns/PricePerUnit/price-per-unit.component';
import DealIncoterms from '@atoms/TableViewColumns/Incoterms/deal-incoterms.component';
import DealVendorName from '@atoms/TableViewColumns/DealVendorName/deal-vendor-name.component';
import DealDeliveryDate from '@atoms/TableViewColumns/DealDeliveryDate/deal-delivery-date.component';
import DealTotalVolume from '@atoms/TableViewColumns/DealTotalVolume/deal-total-volume.component';
import DealTotalPrice from '@atoms/TableViewColumns/DealTotalPrice/deal-total-price.component';
import DefaultTableCell from '@atoms/TableViewColumns/DefaultTableCell/default-table-cell.component';
import DealCreationDate from '@atoms/TableViewColumns/DealCreationDate/deal-creation-date.component';
import RequestFinance from '@atoms/TableViewColumns/RequestFinance/request-financing.component';
import DealStatus from "@atoms/TableViewColumns/DealStatus/deal-status.component";
import DocumentsLink from '@atoms/TableViewColumns/DocumentsLink/documents-link';
import TableViewHead from '@atoms/TableViewHead/table-view-head.component';
import LoadingSpinner from '@molecules/LoadingSpinner/loading-spinner.component';
import HorizontalScrolling from '@organisms/NodeHorizontalSliding/horizontal-sliding.component';
import DeleteButton from '@molecules/DeleteButton/delete-button.component';
import { useUserRole } from '@lib/hooks/useUserRole';
import { useDealsContext } from '@lib/Context/Deals/deals.context';
import NoItems from 'Components/Shared/NoItems/NoItems.component';

import styles from './table-view.module.scss';

const TableView = ({ deals, loading, deleteDeal }) => {
  const { t } = useTranslation();
  const { isAdmin, isOrgAdmin } = useUserRole();
  const { columns, setFilterByColumns } = useDealsContext();
  const navigate = useNavigate();
  const ref = useRef(null);
  const refTable = useRef(null);

  const columnSortHandler = useCallback(
    (column, value) => {
      setFilterByColumns(column, value);
    },
    [setFilterByColumns]
  );

  const editHandler = useCallback(
    (deal) => {
      navigate(`/manage-deal/${deal?.Id}`, {
        state: {
          showArchivedDeals: deal?.Archived,
          stepId: deal.Step?.Current?.Id,
        },
      });
    },
    [navigate]
  );

  const renderColumnHead = useCallback(
    (columnName) => {
      switch (columnName) {
        case 'name':
          return (
            <TableViewHead
              columnName={'Deal Name'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'origin':
          return (
            <TableViewHead
              columnName={'Origin'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'product':
          return (
            <TableViewHead
              columnName={'Product'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'pricePerUnit':
          return (
            <TableViewHead
              columnName={'Price per unit'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'incoterms':
          return (
            <TableViewHead
              columnName={'Incoterms'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'vendorName':
          return (
            <TableViewHead
              columnName={'Vendor Name'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'deliveryDate':
          return (
            <TableViewHead
              columnName={'Delivery Date'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'totalVolume':
          return (
            <TableViewHead
              columnName={'Total Volume'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'totalPrice':
          return (
            <TableViewHead
              columnName={'Total Price'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'dealStatus':
          return (
            <TableViewHead
              columnName={'Deal Status'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'creationDate':
          return (
            <TableViewHead
              columnName={'Creation Date'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'requestFinance':
          return (
            <TableViewHead
              columnName={'Services'}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case 'documents':
          return <TableViewHead columnName={'Documents'} sorting={false} />;
        case 'priceType':
          return <TableViewHead columnName={'PriceType'} sorting={false} />;
        case 'contract':
          return <TableViewHead columnName={'Contract'} sorting={false} />;
      }
    },
    [columnSortHandler]
  );

  const renderColumnActions = useCallback(
    (deal) => {
      return (
        <div className={styles.actionsColumn} key={deal.Deal.Id}>
          <IconButton
            onClick={editHandler.bind(this, deal.Deal)}
            aria-label='delete'
            size='medium'>
            <EditIcon fontSize='inherit' />
          </IconButton>
          {(isAdmin || isOrgAdmin) ? (
            <DeleteButton
              buttonTitle={t('Delete')}
              confirmationTitle={t(
                'Are you sure you want to delete this deal?'
              )}
              agreementHandler={deleteDeal.bind(this, deal.Deal)}
            />
          ) : null}
        </div>
      );
    },
    [deleteDeal, editHandler, isAdmin, isOrgAdmin, t]
  );

  const assignColumnValue = (column, deal) => {
    const currentDeal = deal.Deal;

    switch (column) {
      case 'name':
        return (
          <DealName
            dealCode={currentDeal.Code}
            dealName={currentDeal.Name}
            isMailMuted={currentDeal.EmailsMuted}
            dealId={currentDeal.Id}
            userType={currentDeal.UserTypeInDeal}
            requiredSide={currentDeal.RequiresActionBy}
            isBlockChainVerified={currentDeal.BlockchainVerification}
            sorting={false}
          />
        );
      case 'origin':
        return <OriginCountry products={currentDeal.Products} />;
      case 'product':
        return <DealProducts products={currentDeal.Products} />;
      case 'pricePerUnit':
        return (
          <PricePerUnit
            products={currentDeal.Products}
            priceType={currentDeal?.PriceType}
          />
        );
      case 'incoterms':
        return <DealIncoterms incoterms={currentDeal.PriceTerms} />;
      case 'vendorName':
        return <DealVendorName vendorName={currentDeal.SellerUser} />;
      case 'deliveryDate':
        return <DealDeliveryDate deliveryDate={currentDeal.DeliveryDate} />;
      case 'totalVolume':
        return <DealTotalVolume totals={currentDeal.Totals} />;
      case 'totalPrice':
        return <DealTotalPrice totals={currentDeal.Totals} />;
      case 'dealStatus':
        return <DealStatus value={currentDeal?.Step?.Current?.Name} pending={currentDeal?.isPending} />;
      case 'creationDate':
        return <DealCreationDate creationDate={currentDeal.CreateDate} />;
      case 'documents':
        return <DocumentsLink dealId={currentDeal.Id} />;
      case 'priceType':
        return <DefaultTableCell value={currentDeal?.PriceType} />;
      case 'contract':
        return <DefaultTableCell value={currentDeal?.Contract} />;
      case 'requestFinance':
        return <RequestFinance financing={currentDeal?.RequestFinance} serviceFee={currentDeal?.ServiceFeeObject} insurance={currentDeal?.RequestInsurance} />;
    }
  };

  return (
    <div className={styles.tableWrap}>
      <HorizontalScrolling
        refParent={refTable}
        refComponent={ref}
        items={deals}
        loading={loading}
      />
      <TableContainer
        component={Paper}
        sx={{ minHeight: loading ? '100vh' : '' }}
        className={styles.tableContainer}
        ref={ref}>
        <Table size='medium' className={styles.table} ref={refTable}>
          <TableHead className={styles.tableHead}>
            <TableRow>
              {columns.map((column) => (
                <Fragment key={column}>{renderColumnHead(column)}</Fragment>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {!loading &&
              deals.map((deal) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={deal.Deal.Id}
                  className={deal.Deal.UserTypeInDeal === 'seller' ? styles.sellerBorder : (deal.Deal.UserTypeInDeal === 'buyer' ? styles.buyerBorder : '')}
                >
                  {columns.map((column, i) => (
                    <TableCell scope='row' key={i}>
                      {assignColumnValue(column, deal)}
                    </TableCell>
                  ))}
                  <TableCell scope='row'>{renderColumnActions(deal)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {loading && (
          <div className={styles.loaderWrap}>
            <LoadingSpinner />
          </div>
        )}
        {!loading && !deals.length && (
          <NoItems
            message={t(
              'There are no deals created yet. Please create a new deal.'
            )}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default TableView;
