/**
 * Navigation Dropdown component
 * @description: when a link item has dropdowns
 * @param dropdownName:
 */

import {useTranslation} from "react-i18next";
import React, {useState} from "react";

import ChevronDownIcon from "@assets/icons/ChevronDown";

import styles from "./nav-dropdown.module.scss";

const NavDropdown = ({dropdownName, innerLinks}) => {
  const { t } = useTranslation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div
        className={`${ isDropdownOpen ? styles.opened : styles.closed} ${styles.dropdownHeader}`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {t(dropdownName)}
        <span><ChevronDownIcon /></span>
      </div>

      { isDropdownOpen &&
        <div className={styles.navDropdown}>
          {innerLinks.map((menu, i) => (
            menu.link
              ? (
                <a
                  href={String(menu.link)}
                  title={t(menu.name)}
                  key={i}
                >{t(menu.name)}</a>
              )
              : (
                <div onClick={() => menu.callback()} className={styles.desc}>
                  {menu.leftIcon ? menu.leftIcon : null}{t(menu.name)}
                </div>
              )
          ))}
        </div>
      }
    </div>
  )
}

export default NavDropdown;