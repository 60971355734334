import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import AscDescFilter from '@atoms/Filters/asc-desc-filter.component';
import { TableCell } from '@mui/material';

import styles from './table-view-head.module.scss';

const TableViewHead = ({ columnName, sorting, onSorting }) => {
  const { t } = useTranslation();

  return (
    <TableCell className={styles.headerColumn} key={columnName}>
      {sorting ? (
        <AscDescFilter handleClick={onSorting}>
          <>{t(columnName)}</>
        </AscDescFilter>
      ) : (
        <>{t(columnName)}</>
      )}
    </TableCell>
  );
};

export default memo(TableViewHead);
