import MessagesList from '@organisms/Messages/MessagesList/messages-list.component';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './styles.css';

function Messages() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenWidth > 1160)
      navigate(params.id ? `/manage-deal/${params.id}` : '/');
  }, [screenWidth]);

  return (
    <div className='messages-screen'>
      <MessagesList />
    </div>
  );
}

export default Messages;
