import { useTranslation } from 'react-i18next';
import React from 'react';

import TooltipComponent from '@atoms/Tooltip/tooltip.component';
import Button from '@atoms/Button/button.component';

import styles from './product-buttons.module.scss';

const ProductButtons = ({ stepActions, handleSubmit, newHandleSubmit }) => {
  const { t } = useTranslation();
  
  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.saveButtons}>
        {stepActions?.map((action) => {
          return (
            <div className={styles.tooltipWrap} key={action.Name}>
              <TooltipComponent text={action.Text}>
                <Button
                  onClick={(event) => {
                    if(newHandleSubmit) {
                      newHandleSubmit(action.Type)
                    } else {
                      if (typeof action.onClick === 'function') {
                        action.onClick(event);
                        // handleSubmit(action.Type);
                      } else {
                        handleSubmit(action.Type)();
                      }
                    }
                  }}
                  size={action.size || 'xl'}
                  type={action.type}
                  disabled={action.Disable}
                  btnColor={
                    action.Type === 'rejected'
                      ? 'tertiary'
                      : action.Type === 'saveForceApprove'
                      ? 'senary'
                      : null
                  }>
                  {t(action.Name)}
                </Button>
              </TooltipComponent>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductButtons;
