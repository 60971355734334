import React, { useState, useEffect, useRef, memo } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import LoadingSpinner from '../../../Shared/LoadingSpinner';
import StyledDropzone from '../../../Shared/StyledDropzone';
import SelectInput from '../../../Shared/SelectInput';
import CloseIcon from '@assets/icons/Close';
import AttachmentIcon from '@assets/icons/Attachment';
import Popup from '@organisms/Popup/popup.component';
import messagesService from '@lib/services/messages-service';

import './styles.scss';

function SendMessage({ documents, channel, setMessages, messages }) {
  const params = useParams();
  const fileInput = useRef();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [message, setMessage] = useState('');
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [sendMessageOpened, setSendMessageOpened] = useState(false);
  const [alert, setAlert] = useState({});
  const [errors, setErrors] = useState({});
  const [chosenDocument, setChosenDocument] = useState('');
  const [charactersCount, setCharactersCount] = useState(0);
  const [sendLoading, setSendLoading] = useState(false);
  const { t } = useTranslation();

  const textareaMaxChars = 1000;

  const labels = {
    deal: t('Deal'),
    insurance: t('RequestInsurance'),
    finance: t('RequestFinance'),
  };

  const removeAttachedfFile = (index) => {
    const arr = [...attachedFiles];
    arr.splice(index, 1);
    setAttachedFiles(arr);
  };

  const writeMessage = (r) => {
    if (r.target.value.length <= textareaMaxChars) {
      setMessage(r.target.value);
      setCharactersCount(r.target.value.length);
    }
  };

  const sendMessage = () => {
    setSendLoading(true);
    const formData = new FormData();
    let attachments = [];
    attachedFiles.map((file) => formData.append('File[]', file));
    formData.append('DealId', params.id);
    formData.append('Message', message);
    formData.append('Channel', String(channel));

    //Append DocumentId from the dropdown or return empty string if nothing was chosen
    if (
      documents.length > 0 &&
      chosenDocument &&
      chosenDocument !== 'Attach Document'
    ) {
      const choseDocumentKey = Object.keys(chosenDocument);
      formData.append('DocumentId', choseDocumentKey[0]);
    } else {
      formData.append('DocumentId', '');
    }

    attachments.length > 0 && formData.append('GenerateDocument', attachments);

    messagesService
      .sendMessage(formData)
      .then((response) => {
        setSendMessageOpened(false);
        setMessage('');
        setAttachedFiles([]);
        setMessages([...response.data.items, ...messages]);
        //window.location.reload();
      })
      .catch((e) => {
        if (e.response.data.status === 500) {
          setErrors({});
          setAlert({
            state: 'error',
            message: t(`Server error! Please contact our support team.`),
          });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        } else if (e.response.data.status === 422) {
          setErrors(JSON.parse(e.response.data.message));
        } else {
          setErrors({});
          setAlert({ state: 'error', message: e.response.data.message });
          setTimeout(() => {
            setAlert({});
          }, 4000);
        }
      })
      .finally(() => {
        setSendLoading(false);
      });
  };

  useEffect(() => {
    if (message.length > 0) {
      setErrors({});
    }
  }, [message]);

  const onDrop = (files) => {
    setAttachedFiles([...attachedFiles, ...files]);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className='send-messages-container'>
      <Popup message={alert.message} state={alert.state} />
      {params.id && (
        <React.Fragment>
          <p>{t(`Send Message`)}</p>
          <textarea
            id='message-input'
            placeholder={`${t(`Enter message here`, {
              channel: labels[channel],
            })}...`}
            value={message}
            onChange={writeMessage}></textarea>
          <div className='char-count'>
            {`${charactersCount} ${t('out of {{count}} characters', {
              count: textareaMaxChars,
            })}`}
          </div>
          {errors && errors.Message && (
            <p className='error'>{errors.Message}</p>
          )}
          {!!documents.length && (
            <div className='message-input--container'>
              <SelectInput
                options={documents}
                onChangeOptionHandler={(selectedDocument) =>
                  setChosenDocument(selectedDocument)
                }
                placeholder={t('AttachDocument')}
                canClearValue={true}
              />
            </div>
          )}
          <div className='attach-files-container'>
            {screenWidth > 800 ? (
              <StyledDropzone onDropHandler={onDrop} />
            ) : (
              <>
                <button
                  className='attach-file-button'
                  onClick={() => {
                    fileInput.current.click();
                  }}>
                  {t('Attach File')}
                </button>
                <input
                  type='file'
                  name='file'
                  ref={fileInput}
                  style={{ display: 'none' }}
                  onChange={(e) => onDrop([e.target.files[0]])}
                />
              </>
            )}
          </div>
          {screenWidth > 800 && (
            <div className='attach-file-container'>
              {attachedFiles.length > 0 &&
                attachedFiles.map((file, index) => {
                  return (
                    <div className='name-container' key={index}>
                      <AttachmentIcon />
                      <Tooltip
                        enterTouchDelay={0}
                        title={
                          <Typography style={{ fontSize: 13 }}>
                            {file.name}
                          </Typography>
                        }>
                        <p className='attached-file-name'>{file.name}</p>
                      </Tooltip>
                      <div
                        style={{ height: 15, cursor: 'pointer' }}
                        onClick={() => removeAttachedfFile(index)}>
                        <CloseIcon />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {errors && errors.File && <p className='error'>{errors.File}</p>}
          <button className='send-message-button' onClick={sendMessage}>
            {sendLoading ? (
              <div className='loader'>
                <LoadingSpinner />
                <p>{t('Sending')}...</p>
              </div>
            ) : (
              <p>{t('Send Message')}</p>
            )}
          </button>
          {screenWidth <= 800 && (
            <div className='attach-file-container'>
              {attachedFiles.length > 0 &&
                attachedFiles.map((file, index) => {
                  return (
                    <div className='name-container' key={index}>
                      <AttachmentIcon />
                      <Tooltip
                        enterTouchDelay={0}
                        title={
                          <Typography style={{ fontSize: 13 }}>
                            {file.name}
                          </Typography>
                        }>
                        <p className='attached-file-name'>{file.name}</p>
                      </Tooltip>
                      <div
                        style={{ height: 15, cursor: 'pointer' }}
                        onClick={() => removeAttachedfFile(index)}>
                        <CloseIcon />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </React.Fragment>
      )}
    </section>
  );
}

export default memo(SendMessage);
