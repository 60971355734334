import React from "react";
import {createPortal} from "react-dom";

const Portal = ({children, overlay = "overlay"}) => {
  const setOverlay = () => {
    switch (overlay) {
      case "overlay": {
       return "overlay-root"
        break;
      }
      // under PC step stepper
      case "stepper": {
        return "under-stepper-root"
      }
    }
  }

  return (
    <>
      {createPortal(
        children,
        document.getElementById(setOverlay())
      )}
    </>
  )
}

export default Portal;
