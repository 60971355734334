/**
 * Product Summary component
 * @description: Show the summary of a specific Product.
 * @param productIndex: the index of the product.
 */

import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DifferentialTotal from '../ProductAccordion/DifferentialTotal/differential-total.component';
import OutrightTotal from '@organisms/Products/ProductAccordion/OutrightTotal/outright-total.component';

import styles from './product-summary.module.scss';

const ProductSummary = ({ productIndex, prefix = '' }) => {
  const { watch } = useFormContext();
  const { t } = useTranslation();

  const watchPriceType = watch(`${prefix}PriceType`);

  return (
    <div className={styles.summaryContainer}>
      <div className={styles.summaryTitle}>
        {watchPriceType === 'Differential' ? t('Summary') : t('Total')}
      </div>
      <div className={styles.summaryText}>
        {watchPriceType === 'Differential' ? (
          <div className={styles.summaryText}>
            <DifferentialTotal index={productIndex} prefix={prefix} />
          </div>
        ) : (
          <div className={styles.totalText}>
            <b>
              <OutrightTotal index={productIndex} prefix={prefix} />
            </b>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ProductSummary);
