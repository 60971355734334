import React from 'react';

import CreateDealComponent from '@organisms/CreateDeal/create-deal.component';
import { ManageDealProvider } from '@lib/Context/ManageDealContext/manage-deal.context';

import '@pages/CreateDealBuy/styles.scss';

function CreateDealSell({state}) {
  return (
    <ManageDealProvider>
      <div className='create-deal-page'>
        <section className='main-page'>
          <CreateDealComponent
            state={state}
          />
        </section>
      </div>
    </ManageDealProvider>
  );
}

export default CreateDealSell;
