import {Controller, useFormContext} from "react-hook-form";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import dealsService from "@services/deals-service";
import {useUserRole} from "@lib/hooks/useUserRole";
import variables from '@base/styles/variables.scss';
import Button from "@atoms/Button/button.component";
import MuiOutlinedInput from "@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component";
import MuiSelect from "@atoms/Fields/ReactFormFields/MuiSelect/mui-select.component";
import RadioInputs from "@atoms/Fields/RadioInputs/radio-inputs.component";
import InputLabel from "@atoms/Fields/InputLabel/input-label.components";
import SwitchComponent from "@molecules/Switch/switch.component";
import {
  CreatePageSingleDocument
} from "@organisms/Documents/SingleDocument/CreatePageSingleDocument/create-page-single-document.component";
import PageLoader from "@templates/PageLoader/page-loader.template";
import styles from "@organisms/CreateDeal/CreateSteps/01.StepOne/create-step-one.module.scss";

const CreateStepThree = (
  {
    setCreateStep,
    createDealFlow,
    setPayloadStep3,
    setParentQuoteDocument,
    parentQuoteDocument,
    setParentPceDocument,
    parentPceDocument,
    setPrevCreateStep
  }) => {
  const { t } = useTranslation();
  const { isAdmin, isTrader, isBuyer, isSeller, isOrgAdmin } = useUserRole();
  const {
    handleSubmit,
    watch,
    setValue
  } = useFormContext();

  const [pceDocument, setPceDocument] = useState(parentPceDocument)
  const [quoteDocument, setQuoteDocument] = useState(parentQuoteDocument)
  const [isDraft, setIsDraft] = useState(false)
  const [loader, setLoader] = useState(true)

  const watchQuotDocuments = watch('QuotationDocumentSwitch');
  const watchPCEDocuments = watch('PCEDocumentSwitch');
  const watchAlreadyDiscussedDetails = watch("AlreadyDiscussedDetails")

  useEffect(() => {
    setPayloadStep3([])
    const data = {
      ...watch(),
      "Action": 'stepThird',
      'Flow': createDealFlow
    }

    dealsService
      .createDeal(data)
      .then((res) => {
        setIsDraft(res.data?.SaveDraft)
        if(!watchAlreadyDiscussedDetails && !res.data?.SaveDraft) {
          setValue('AlreadyDiscussedDetails', "No")
          setValue('NextStep', "QuotationStep")
        }
        setLoader(false)
      })
      .catch((e) => {
        console.log('error: ',e)
      })
  }, [])

  useEffect(() => {
    setParentQuoteDocument(quoteDocument)
  },[quoteDocument])

  useEffect(() => {
    setParentPceDocument(pceDocument)
  },[pceDocument])

  const stepChoiceRadioOptions = [
    {
      "key": 'QuotationStep',
      'name': 'step',
      "value": 'QuotationStep',
      "label": t('QuotationStep'),
    },
    {
      "key": 'ProceedWithPCE',
      'name': 'step',
      "value": 'ProceedWithPCE',
      "label": t('ProceedWithPCE'),
    }
  ];

  const renderUserReferenceNumber = () => {
    return (
      <Controller
        name={'Reference'}
        render={({ field }) => (
          <MuiOutlinedInput
            {...field}
            label={'ReferenceNumberLabel'}
            required={false}
            placeholder={t('Type')}
          />
        )}
      />
    )
  }

  const renderAdminReferenceNumber = () => {
    return (
      <>
        <Controller
          name={'BuyerReference'}
          render={({ field }) => (
            <MuiOutlinedInput
              {...field}
              label={'BuyerReferenceNumberLabel'}
              required={false}
              placeholder={t('Type')}
            />
          )}
        />
        <Controller
          name={'SellerReference'}
          render={({ field }) => (
            <MuiOutlinedInput
              {...field}
              label={'SellerReferenceNumberLabel'}
              required={false}
              placeholder={t('Type')}
            />
          )}
        />
      </>
    )
  }

  const renderReferenceNumber = useCallback(() => {
    if (isAdmin || isOrgAdmin || isTrader) {
      return renderAdminReferenceNumber();
    } else {
      return renderUserReferenceNumber();
    }
  }, [isAdmin, isTrader, isOrgAdmin])

  const renderDocumentSection = () => {
    return(
      <div className={styles.documentIndicatorsWrap}>
        <InputLabel label={t('DocumentsIndicationTitle')} />
        <div>
          <div>
            <div className={styles.switchWrap}>
              <Controller
                name={'QuotationDocumentSwitch'}
                defaultValue={true}
                render={({ field }) => (
                  <SwitchComponent
                    values={[t('QuotationSwitchLabel'), '']}
                    onChange={(event) => {
                      field.onChange(event);
                    }}
                    checked={watchQuotDocuments || false}
                    offColor={variables.switcherOffColor}
                  />
                )}
              />
            </div>
            {!!watchQuotDocuments &&
              <CreatePageSingleDocument
                documentType={'quote'}
                onRefreshDocuments={setQuoteDocument}
                stepDocument={quoteDocument && quoteDocument[0]}
                deleteDocument={() => setQuoteDocument([])}
              />
            }
          </div>
          <div className={styles.switchWrap}>
            <Controller
              name={'PCEDocumentSwitch'}
              defaultValue={true}
              render={({ field }) => (
                <SwitchComponent
                  values={[t('PCESwitchLabel'), '']}
                  onChange={(event) => {
                    field.onChange(event);
                  }}
                  checked={watchPCEDocuments || false}
                  offColor={variables.switcherOffColor}
                />
              )}
            />
          </div>
          {!!watchPCEDocuments &&
            <CreatePageSingleDocument
              documentType={'contract'}
              onRefreshDocuments={setPceDocument}
              stepDocument={pceDocument[0]}
              deleteDocument={() => setPceDocument([])}
            />
          }
        </div>
      </div>
    )
  }

  const renderRadioButtons = useCallback(() => {
    return(
      <Controller
        name={'NextStep'}
        render={({ field }) => (
          <RadioInputs
            {...field}
            options={stepChoiceRadioOptions}
            label={'NextStepLabel'}
            view={'default'}
          />
        )}
      />
    )
  }, [])

  const renderDiscussedDetailsResponse = () => {
    if(watchAlreadyDiscussedDetails === "No") {
      return renderRadioButtons()
    }
    else if (watchAlreadyDiscussedDetails === "Yes") {
      return renderDocumentSection()
    }
  }

  const renderDiscussedDetailsLabel = useCallback(() => {
    if(isAdmin || isOrgAdmin || isTrader) {
      return "AlreadyDiscussedDetailsLabelAdmin";
    } else if(createDealFlow === "WantToBuy") {
      return "AlreadyDiscussedDetailsLabelSeller";
    } else if(createDealFlow === "WantToSell") {
      return "AlreadyDiscussedDetailsLabelBuyer";
    }
  }, [isAdmin, isTrader, isOrgAdmin, createDealFlow])

  const renderDiscussedDetails = useCallback(() => {
    return (
      <>
        <Controller
          name={'AlreadyDiscussedDetails'}
          render={({ field }) => (
            <MuiSelect
              {...field}
              onChange={(event) => {
                field.onChange(event);
              }}
              label={renderDiscussedDetailsLabel()}
              options={{"No": t("No"), 'Yes': t("Yes")}}
              placeholder={'Select'}
              defaultValue={t('No')}
            />
          )}
        />
      </>
    )
  }, [])

  const renderForm = () => {
    if (!isDraft) {
      return (
        <>
          {renderDiscussedDetails()}
          {renderDiscussedDetailsResponse()}
          {renderReferenceNumber()}
        </>
      )
    } else {
      return (
        <>{renderReferenceNumber()}</>
      )
    }
  }

  const handleNext = (formData) => {
    setCreateStep(4);
    setPrevCreateStep(3);
    setPayloadStep3({
      ...formData,
      "Action": 'stepFourth',
      "Flow": createDealFlow,
      "QuotationDocumentId": watchQuotDocuments ? quoteDocument[0]?.Files[0]?.Id || null : null,
      "PurchaseDocumentId": watchPCEDocuments ?  pceDocument[0]?.Files[0]?.Id || null : null,
    })

    // clear the parent documents if the switchers are off
    if(!watchQuotDocuments) {
      setParentQuoteDocument([])
    }
    if(!watchPCEDocuments) {
      setParentPceDocument([])
    }
  }

  return (
    <PageLoader isLoading={loader}>
      {renderForm()}
      <div className={styles.btnWrap}>
        <Button
          onClick={() => handleSubmit(handleNext)()}
          size={'large'}
        >
          {t('Next')}
        </Button>
      </div>
    </PageLoader>
  )
}

export default CreateStepThree;