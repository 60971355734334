import { memo, useState, createElement } from 'react';
import { styled } from '@mui/system';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider } from '@material-ui/core';

import styles from './menu-button.module.scss';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}));

const Component = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const clickHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeHandler = (onClick) => {
    typeof onClick === 'function' && onClick();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-haspopup='true'
        onClick={clickHandler}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id='three-dots-menu'
        MenuListProps={{
          'aria-labelledby': 'three-dots-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={closeHandler}>
        {menuItems.map((item, index) => (
          item.divider ? (
            <Divider sx={{ my: 0.5 }} key={`divider-${index}`} />
          ) : (
            <MenuItem
              key={item?.name}
              onClick={closeHandler.bind(this, item?.onClick)}
              disableRipple>
              {createElement(item?.icon, item?.iconProps)}
              <div style={{ marginLeft: 10 }}>{item.name}</div>
            </MenuItem>
          )
        ))}
      </StyledMenu>
    </>
  );
};

export const MenuButton = memo(Component);
