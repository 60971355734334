/**
 * InputFieldComponent Label component
 * @props label: the name of the label
 * @props required: if the label will have tee required sign
 * Usage: <InputLabel label={label} required={required} />
 */

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './input-label.module.scss';
import Tooltip from '@atoms/Tooltip/tooltip.component';

const InputLabel = ({ label, required }) => {
  const { t } = useTranslation();
  const labelRef = useRef();
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const compareLabel =
      labelRef.current?.scrollWidth > labelRef.current?.clientWidth;
    setIsOverflow(compareLabel);
  }, [labelRef]);

  return (
    <div>
      {label && (
        <div className={styles.labelWrap}>
          <Tooltip disable={!isOverflow} text={t(label)}>
            <p ref={labelRef} className={styles.label}>
              {t(label)}
              {!!required && (
                <span className={styles.requiredIndicator}>*</span>
              )}
            </p>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default InputLabel;
