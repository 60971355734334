import { httpClient } from "./httpClient";

const RESOURCE_PATH = "api-common";

const commonService = {
  getSettings: function () {
    return httpClient.get(`${RESOURCE_PATH}/settings`);
  },
  submitSettings: function (data) {
    return httpClient.post(`${RESOURCE_PATH}/settings`, data);
  },
  getOrganizations: function () {
    return httpClient.get(`${RESOURCE_PATH}/organizations`);
  },
  getCountries: function () {
    return httpClient.get(`${RESOURCE_PATH}/countries`);
  },
  getStatuses: function () {
    return httpClient.get(`${RESOURCE_PATH}/deal-statuses`);
  },
  generateLedgerReport: function (data) {
    return httpClient.post(`${RESOURCE_PATH}/operations-report`, data, {
      responseType: "blob",
    });
  },
};

export default commonService;
