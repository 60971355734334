/**
 * Calculator Form
 * @description: inside settings page
 * @param fields
 * @param isFormDirty: the parent can reset the dirty state. IF needed
 * @param refreshFields: on submit send to the parent in order to send the new updated fields. IF needed.
 * @param ref: coming from the parent in order the parent to trigger the submit button. IF needed.
 */

import {forwardRef, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Controller, useForm, FormProvider} from "react-hook-form";

import {usePopup} from "@lib/hooks/usePopup";
import commonService from "@services/common-service";
import FormInput from "@atoms/Fields/ReactFormFields/FormInput/form-input.component";
import FormSelectOptions from "@atoms/Fields/ReactFormFields/FormSelectOptions/form-select-options.component";
import Grid from "@atoms/Grid/DynamicGrid/grid.component";
import Button from "@atoms/Buttons/Button";

import styles from "../deal-settings.module.scss";

const CalculatorFeesForm = forwardRef(({fields, isFormDirty, refreshFields, submitRef}, ref) => {
  const { t } = useTranslation();
  const { showPopup } = usePopup();

  const requiredField = t('Field is required');

  const defaultValues = {
    feeServiceLevel1: fields?.feeServiceLevel1?.Value || "",
    feeServiceLevel1Type: fields?.feeServiceLevel1?.Type || "",
    feeServiceLevel2: fields?.feeServiceLevel2?.Value || "",
    feeServiceLevel2Type: fields?.feeServiceLevel2?.Type || "",
    landTransportation: fields?.landTransportation?.Value || "",
    landTransportationCurrency: fields?.landTransportation?.Currency || "",
    landTransportationType: fields?.landTransportation?.Type || "",
    landInsurance: fields?.landInsurance?.Value || "",
    landInsuranceType: fields?.landInsurance?.Type || "",
    seaTransportation: fields?.seaTransportation?.Value || "",
    seaTransportationCurrency: fields?.seaTransportation?.Currency || "",
    seaTransportationType: fields?.seaTransportation?.Type || "",
    seaInsurance: fields?.seaInsurance?.Value || "",
    seaInsuranceType: fields?.seaInsurance?.Type || "",
    insurance: fields?.insurance?.Value || "",
    insuranceType: fields?.insurance?.Type || "",
    finance: fields?.finance?.Value || "",
    financeType: fields?.finance?.Type || "",
  };

  const form = useForm({defaultValues});

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = form;

  const handleSubmitButton =  useCallback((data) => {
    commonService
      .submitSettings(data)
      .then(() => {
        showPopup({
          state: 'success',
          message: t('Saved successfully'),
          timer: 4000,
        });
      })
      .finally(() => {
        reset(data)
        refreshFields()
      })
  }, [])

  useEffect(() => {
    isFormDirty(isDirty)
  }, [isDirty])

  return (
    <>
      <FormProvider {...form}>
        <Grid columns={8}>
          <Controller
            control={control}
            name='feeServiceLevel1'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"number"}
                label={fields?.feeServiceLevel1?.Label}
                required={true}
                placeholder={'Enter'}
                error={errors['feeServiceLevel1']?.message}
              />
            }
          />
        </Grid>
        <Grid columns={4}>
          <Controller
            control={control}
            name='feeServiceLevel1Type'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                required={true}
                placeholder={'Enter'}
                readonly={true}
              />
            }
          />
        </Grid>

        <Grid columns={8}>
          <Controller
            control={control}
            name='feeServiceLevel2'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"number"}
                label={fields?.feeServiceLevel2?.Label}
                required={true}
                placeholder={'Enter'}
                error={errors['feeServiceLevel2']?.message}
              />
            }
          />
        </Grid>
        <Grid columns={4}>
          <Controller
            control={control}
            name='feeServiceLevel2Type'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                required={true}
                placeholder={'Enter'}
                readonly={true}
              />
            }
          />
        </Grid>

        <Grid columns={4}>
          <Controller
            control={control}
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            name='landTransportation'
            render={({ field }) =>
              <FormInput
                {...field}
                type={"number"}
                label={fields?.landTransportation?.Label}
                required={true}
                placeholder={'Enter'}
                error={errors['landTransportation']?.message}
              />
            }
          />
        </Grid>
        <Grid columns={4}>
          <Controller
            control={control}
            name='landTransportationCurrency'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormSelectOptions
                {...field}
                placeholder={"Select an Option"}
                defaultValue={fields?.landTransportation?.Currency}
                selectOptions={{'USD': "USD"}}
              />
            }
          />
        </Grid>
        <Grid columns={4}>
          <Controller
            control={control}
            name='landTransportationType'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                required={true}
                placeholder={'Enter'}
                readonly={true}
              />
            }
          />
        </Grid>

        <Grid columns={8}>
          <Controller
            control={control}
            name='landInsurance'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"number"}
                label={fields?.landInsurance?.Label}
                required={true}
                placeholder={'Enter'}
                error={errors['landInsurance']?.message}
              />
            }
          />
        </Grid>
        <Grid columns={4}>
          <Controller
            control={control}
            name='landInsuranceType'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                required={true}
                placeholder={'Enter'}
                readonly={true}
              />
            }
          />
        </Grid>

        <Grid columns={4}>
          <Controller
            control={control}
            name='seaTransportation'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"number"}
                label={fields?.seaTransportation?.Label}
                required={true}
                placeholder={'Enter'}
                error={errors['seaTransportation']?.message}
              />
            }
          />
        </Grid>
        <Grid columns={4}>
          <Controller
            control={control}
            name='seaTransportationCurrency'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormSelectOptions
                {...field}
                placeholder={"Select an Option"}
                defaultValue={fields?.seaTransportation?.Currency}
                selectOptions={{'USD': "USD"}}
              />
            }
          />
        </Grid>
        <Grid columns={4}>
          <Controller
            control={control}
            name='seaTransportationType'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                required={true}
                placeholder={'Enter'}
                readonly={true}
              />
            }
          />
        </Grid>

        <Grid columns={8}>
          <Controller
            control={control}
            name='seaInsurance'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"number"}
                label={fields?.seaInsurance?.Label}
                required={true}
                placeholder={'Enter'}
                error={errors['seaInsurance']?.message}
              />
            }
          />
        </Grid>
        <Grid columns={4}>
          <Controller
            control={control}
            name='seaInsuranceType'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                required={true}
                placeholder={'Enter'}
                readonly={true}
              />
            }
          />
        </Grid>

        <Grid columns={8}>
          <Controller
            control={control}
            name='insurance'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"number"}
                label={fields?.insurance?.Label}
                required={true}
                placeholder={'Enter'}
                error={errors['insurance']?.message}
              />
            }
          />
        </Grid>
        <Grid columns={4}>
          <Controller
            control={control}
            name='insuranceType'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                required={true}
                placeholder={'Enter'}
                readonly={true}
              />
            }
          />
        </Grid>

        <Grid columns={8}>
          <Controller
            control={control}
            name='finance'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"number"}
                label={fields?.finance?.Label}
                required={true}
                placeholder={'Enter'}
                error={errors['finance']?.message}
              />
            }
          />
        </Grid>
        <Grid columns={4}>
          <Controller
            control={control}
            name='financeType'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                required={true}
                placeholder={'Enter'}
                readonly={true}
              />
            }
          />
        </Grid>
      </FormProvider>
      <div className={styles.saveButtonWrap}>
        <Button
          type={"fill"}
          size={"large"}
          onClick={handleSubmit(handleSubmitButton)}
          disabled={!isDirty}
          ref={submitRef}
        >{t("Save")}</Button>
      </div>
    </>
  )
})

CalculatorFeesForm.displayName = "CalculatorFeesForm";

export default CalculatorFeesForm;
