import React from 'react';

const SvgComponent = (props) => (
  <svg
    width='800px'
    height='800px'
    {...props}
    viewBox='0 0 1024 1024'
    className='icon'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M182.52 146.2h585.14v402.28h73.15V73.06H109.38v877.71h402.36v-73.14H182.52z'
      fill={props?.fill || '#0F1F3C'}
    />
    <path
      d='M255.67 219.34h438.86v73.14H255.67zM255.67 365.63h365.71v73.14H255.67zM255.67 511.91H475.1v73.14H255.67zM731.17 584.97c-100.99 0-182.86 81.87-182.86 182.86s81.87 182.86 182.86 182.86c100.99 0 182.86-81.87 182.86-182.86s-81.87-182.86-182.86-182.86z m0 292.57c-60.5 0-109.71-49.22-109.71-109.71 0-60.5 49.22-109.71 109.71-109.71 60.5 0 109.71 49.22 109.71 109.71 0.01 60.49-49.21 109.71-109.71 109.71z'
      fill={props?.fill || '#0F1F3C'}
    />
    <path
      d='M777.43 686.4l-46.26 46.25-46.26-46.25-38.78 38.78 46.26 46.26-46.26 46.26 38.78 38.78 46.26-46.25 46.26 46.25 38.79-38.78-46.26-46.26 46.26-46.26z'
      fill={props?.fill || '#0F1F3C'}
    />
  </svg>
);
export default SvgComponent;
