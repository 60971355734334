import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip } from '@mui/material';
import GppBadIcon from '@mui/icons-material/GppBad';

import Portal from '@molecules/Portal/portal.component';
import PopupLayout from '@organisms/Popup/PopupLayout/popup-layout.component';

import styles from './failed-verification.module.scss';

const Component = ({ status, onClose }) => {
  const { t } = useTranslation();

  if (
    !status ||
    (status?.value !== 'NotVerified' && status?.value !== 'Failed')
  ) {
    return null;
  }

  return (
    <Portal overlay={'overlay'}>
      <PopupLayout closePopUp={onClose}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Box typography={'h5'} fontWeight={'bold'} className={styles.title}>
              <div>
                <Tooltip title={t('VerificationFailed')} placement='top-start'>
                  <GppBadIcon className={styles.verificationFailed} />
                </Tooltip>
              </div>
              {status?.message}
            </Box>
          </Grid>
          <Grid item md={12}>
            {status.value === 'Failed' ? (
              <div>{t('IssueWithBlockchainTryAgain')}</div>
            ) : (
              <div>{t('The following discrepancies were detected:')}</div>
            )}
          </Grid>
          {status?.value === 'NotVerified' && !status?.messageHashMatches && (
            <Grid item md={12}>
              <Trans
                i18nKey='ShaDoesNotMatch'
                values={{ type: t('Message') }}
              />
            </Grid>
          )}
          {status?.value === 'NotVerified' && !status?.documentHashMatches && (
            <Grid item md={12}>
              <Trans
                i18nKey='ShaDoesNotMatch'
                values={{ type: t('Document') }}
              />
            </Grid>
          )}
          {status?.value === 'NotVerified' && !status?.dealIdMatches && (
            <Grid item md={12}>
              <Trans i18nKey='DealIdDoesNotMatch' />
            </Grid>
          )}
          {status?.value === 'NotVerified' && !status?.messageIdMatches && (
            <Grid item md={12}>
              <Trans i18nKey='MessageIdDoesNotMatch' />
            </Grid>
          )}
        </Grid>
      </PopupLayout>
    </Portal>
  );
};

export const FailedVerified = memo(Component);
