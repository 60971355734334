import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import localizationService from '@services/localization-service';
import { useUserRole } from '@lib/hooks/useUserRole';
import { IDENTITY_URL } from '@lib/config/config';
import {
  activeDealsName,
  settingsName,
  createDealName,
  activeDealsLink,
  archivedDealsLink,
  createDealBuyLink,
  createDealSellLink,
  settingsLink,
} from '@base/globalVariables/page-links';
import FrFlag from '@atoms/Images/fr-flag.image';
import EnFlag from '@atoms/Images/en-flag.image';
import SpFlag from '@atoms/Images/sp-flag.image';
import FullScreenLayout from '@atoms/FullScreenLayout/full-screen-layout.component';
import NavDropdown from '@atoms/NavDropdown/nav-dropdown.componet';

import './header-mobile-hamburger.styles.scss';

function HamburgerMenu({ logout, userMessage }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { isAdmin, isOrgAdmin } = useUserRole();

  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));
  const [menuOpened, setMenuOpened] = useState(false);
  const [cookies, removeCookie] = useCookies();

  const changeLanguage = (language) => {
    localizationService.changeLanguage(language);
    setLanguage(language);
  };

  const updateUserBeforeEdit = useCallback(() => {
    removeCookie('id_token');
    removeCookie('access_token');

    window.location.href = IDENTITY_URL;
  }, []);

  const languageMenu = [
    {
      name: `English`,
      leftIcon: <EnFlag />,
      callback: () => changeLanguage('en'),
    },
    {
      name: `Français`,
      leftIcon: <FrFlag />,
      callback: () => changeLanguage('fr'),
    },
    {
      name: `Español`,
      leftIcon: <SpFlag />,
      callback: () => changeLanguage('es'),
    },
  ];

  useEffect(() => {
    if (!menuOpened) {
      document.body.style.overflow = 'unset';
    }
  }, [menuOpened]);

  const renderPageName = () => {
    const name = location.pathname.replaceAll('/', ' ').replaceAll('-', ' ');

    switch (name) {
      case 'create deal':
        return <>{createDealName}</>;
        break;
      case 'deal settings':
        return <>{settingsName}</>;
        break;
      case 'Reports':
        return <>{'Reports'}</>;
        break;
      case ' ':
        return <>{activeDealsName}</>;
        break;
      default:
        return <>{name}</>;
        break;
    }
  };

  return (
    <div className='hamburger-menu-container'>
      {menuOpened ? (
        <FullScreenLayout
          toggle={() => setMenuOpened(false)}
          isMenuOpened={menuOpened}>
          <div className='hamburger-menu'>
            <div className='top-line'>
              <p>
                {t('Menu')} | {renderPageName()}
              </p>
              <div
                className='close-button'
                onClick={() => {
                  setMenuOpened(false);
                }}>
                <CloseOutlinedIcon />
              </div>
            </div>
            <div
              className='navigation-list'
              onClick={() => setMenuOpened(!menuOpened)}>
              <Link className='navigation-el' to={activeDealsLink}>
                {t('ActiveDeals')}
              </Link>
              {/*<NavDropdown dropdownName={'Trade Dashboard'} innerLinks={tradeMenu} />*/}
              <Link className='navigation-el' to={archivedDealsLink}>
                {t('ArchivedDeals')}
              </Link>
              <Link className='navigation-el' to={createDealBuyLink}>
                {t('WantToBuy')}
              </Link>
              <Link className='navigation-el' to={createDealSellLink}>
                {t('WantToSell')}
              </Link>
              {(isAdmin || isOrgAdmin) && (
                <>
                  <Link className='navigation-el' to={settingsLink}>
                    {t('Settings')}
                  </Link>
                  <Link className='navigation-el' to={'/reports'}>
                    {t('Reports')}
                  </Link>
                </>
              )}
              <NavDropdown
                dropdownName={`Language: ${language.toUpperCase()}`}
                innerLinks={languageMenu}
              />
              <a className='navigation-el' onClick={logout}>
                {t('Sign out')}
              </a>
            </div>
            <div className='user-name'>
              <a
                onClick={() => {
                  updateUserBeforeEdit();
                }}
                title={t('My Account')}>
                <PersonOutlineOutlinedIcon />
                <p>{userMessage}</p>
              </a>
            </div>
          </div>
        </FullScreenLayout>
      ) : (
        <div
          className='menu-bars'
          onClick={() => {
            setMenuOpened(true);
          }}>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
        </div>
      )}
    </div>
  );
}

export default HamburgerMenu;
