import { memo, useEffect, useState } from 'react';

import { SingleDocument } from '../SingleDocument/single-document.component';

import styles from './step-documents.style.module.scss';

const StepDocuments = ({
  documents,
  canWrite,
  updateDocuments,
  activePage,
  ...props
}) => {
  const [currentDocuments, setCurrentDocuments] = useState(documents);

  useEffect(() => {
    setCurrentDocuments(documents);
  }, [documents]);

  return (
    <div className={styles.relatedDocuments}>
      <SingleDocument
        key={currentDocuments?.Name}
        stepDocument={currentDocuments}
        canWrite={canWrite}
        updateDocuments={updateDocuments}
        activePage={activePage}
        //onRefreshDocuments={setCurrentDocuments}
        {...props}
      />
    </div>
  );
};

export default memo(StepDocuments);
