import React from "react";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

import styles from "./notification-icon.module.scss";

const NotificationIcon = ({emailsMuted}) => {
  return (
    <>
      {emailsMuted &&
        <div className={styles.muteIcon}>
          <UnsubscribeIcon className={styles.mutedEmailIcon}/>
        </div>
      }
      {!emailsMuted &&
        <div className={styles.muteIcon}>
          <MarkEmailReadIcon className={styles.notMutedEmailIcon} />
        </div>
      }
    </>
  )
}

export default NotificationIcon;