import { useState } from 'react';
import { createSingletonHook } from './singletonHook';

const usePopupHook = () => {
  const [message, setMessage] = useState('');
  const [state, setState] = useState('');

  const hidePopup = (timer) => {
    setTimeout(() => {
      setMessage('');
      setState('');
    }, timer);
  };

  const showPopup = ({ message, state, timer }) => {
    setMessage(message);
    setState(state);

    hidePopup(timer);
  };

  return { showPopup, message, state };
};

const [useSingletonHook, SingletonHookProvider] =
  createSingletonHook(usePopupHook);

export const usePopup = useSingletonHook;
export const PopupProvider = SingletonHookProvider;
