import React from "react";
import styles from './empty-select.module.scss';

const EmptySelect = ({placeholder}) => {
  return (
    <input placeholder={placeholder} className={styles.emptySelect} disabled={true} />
  )
}

export default EmptySelect;
