import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import MessageIcon from '@assets/icons/Message';

import './styles.scss';

function OpenMessages({ channel, canWrite }) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div
      className='open-messages'
      onClick={() => {
        navigate(params.id ? `/messages/${params.id}` : '/messages', {
          state: {
            channel: channel,
            canWrite: canWrite,
          },
        });
      }}>
      <MessageIcon />
    </div>
  );
}

export default OpenMessages;
