import React, {useEffect, useState} from 'react';

import { popUpSubmitButtonsHelper } from '@lib/helpers/popUpSubmitButtons.helper';
import DealModificationMessages from '@molecules/DealModificationMessages/deal-modification-messages.component';
import Portal from '@molecules/Portal/portal.component';
import PopupLayout from '@organisms/Popup/PopupLayout/popup-layout.component';
import PageLoader from '@templates/PageLoader/page-loader.template';

const StepInfoModal = ({
  validateInfo,
  stepSettings,
  buttonType,
  step,
  saveLoader,
  handleModalClose,
  disableButton,
  handleOnConfirmClick,
  executionType
}) => {
  const [isLoading, setIsLoading] = useState(saveLoader || true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 550);
  }, []);

  return (
    <Portal overlay={'overlay'}>
      <PageLoader isLoading={isLoading}>
        {!isLoading && (
          <PopupLayout
            onConfirmClick={(action) => {
              handleOnConfirmClick(action);
            }}
            closePopUp={handleModalClose}
            disabled={disableButton}
            buttons={validateInfo && stepSettings &&
              popUpSubmitButtonsHelper(validateInfo, stepSettings, buttonType)
            }
          >
            {validateInfo.items && Object.keys(validateInfo.items).length > 1 && (
              <DealModificationMessages
                settings={stepSettings}
                existingMandatoryFile={stepSettings?.ExistingMandatoryFile}
                step={step.StepName}
                stepAbbreviation={step.StepAbbreviation}
                saveDraft={validateInfo?.items?.SaveDraft}
                validationFields={validateInfo?.items?.ValidationFields}
                userReceiver={validateInfo?.items?.UserReceiver}
                userParties={validateInfo.items?.UserParties || []}
                stepDocument={validateInfo?.items?.StepDocument}
                buttonType={buttonType}
                approvalNeeded={validateInfo?.items?.ApprovalNeeded}
                onBehalf={stepSettings?.UserOnBehalf}
                executionType={executionType}
              />
            )}
          </PopupLayout>
        )}
      </PageLoader>
    </Portal>
  );
};

export default StepInfoModal;
