/**
 * Stepper V3 component
 * @description: default MUI stepper
 * @param steps: info with the steps
 * @param selectStep: parent function that is triggered when you choose a step
 * @param selectedStepperStep: parent state for the step ID of the stepper
 * @param setSelectedStepperStep: parent setState for the step ID of the stepper
 * Custom stepper: https://mui.com/material-ui/react-stepper/
 */

import { useCallback, useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckIcon from "@mui/icons-material/Check";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import IconOnlyButton from "@atoms/Buttons/AddButton/add-button.component";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { useManageDealContext } from "@lib/Context/ManageDealContext/manage-deal.context";

import "./stepper-v3.styles.scss";
import { useUserRole } from "@lib/hooks/useUserRole";

const StepperV3 = ({
  steps,
  selectStep,
  selectedStepperStep,
  setSelectedStepperStep,
  disabledStepSelector,
  setOpenRequestServiceDialog,
  dealDate,
  canWrite,
  // channel,
  // setChannel,
  haveServices,
}) => {
  const { t } = useTranslation();
  const { deal } = useManageDealContext();
  const [localSteps, setLocalSteps] = useState([]);
  const { isFinancier, isInsurer } = useUserRole();
  const { setChannel } = useManageDealContext();

  const renderStepLabel = useCallback(
    (step, index) => {
      if (selectedStepperStep === index) {
        return t(step.Name);
      } else {
        return t(step.Label);
      }
    },
    [selectedStepperStep]
  );

  const handleStepperClick = (e, step, index) => {
    if (!disabledStepSelector) {
      setSelectedStepperStep(index); // Use step.Id instead of index
      selectStep(step);
      switch (step.Label) {
        case "TRFN":
          setChannel("finance");
          break;
        case "CRINS":
          setChannel("insurance");
          break;
        case "BYINS":
          setChannel("buyer_insurance");
          break;
        case "FXH":
          setChannel("fx_hedging");
          break;
        case "SF":
          setChannel("sea_freight");
          break;
        case "LT":
          setChannel("land_transport");
          break;
        case "LDSV":
          setChannel("supervision");
          break;
        case "QC":
          setChannel("quality_checks");
          break;
        default:
          setChannel("deal");
          break;
      }
    } else {
      e.preventDefault();
    }
  };

  const openRequestServices = () => {
    if (!isFinancier && !isInsurer && canWrite && haveServices) {
      setOpenRequestServiceDialog(true);
    }
  };

  return (
    <div className={"stepper-wrap--v3"}>
      <Stepper activeStep={selectedStepperStep} alternativeLabel>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (step.Id === deal.Step.Current.Id && step.Status === "active") {
            labelProps.icon = " ";
            stepProps.className = "active-step";
          } else {
            stepProps.className = `${step.Done ? "" : "default-label"}`;

            switch (step.Type) {
              case "with-icon":
                labelProps.optional = (
                  <Typography
                    variant="caption"
                    style={{ display: "flex", justifyContent: "left" }}
                  >
                    {dealDate}
                  </Typography>
                );

                labelProps.icon = <CheckIcon />;
                stepProps.className = "status--neutral";
                break;
              case "button":
                labelProps.icon = "";
                stepProps.className =
                  isFinancier || isInsurer || !canWrite || !haveServices
                    ? "status--disabled"
                    : "status--neutral";
                break;
              case "empty":
                labelProps.icon = <CheckIcon />;
                stepProps.className = "status--active";
                break;
            }
          }

          if (index === selectedStepperStep) {
            stepProps.className = `${stepProps.className} ${"selected-step"}`;
          }

          return (
            <Step
              {...stepProps}
              key={step.StepId || step.Id}
              completed={!!step.Done}
              onClick={(e) =>
                step.Type === "button"
                  ? openRequestServices()
                  : handleStepperClick(e, step, index)
              }
            >
              {step.Type === "button" ? (
                <>
                  {!isFinancier && !isInsurer && canWrite && haveServices && (
                    <p className={"label"}>{renderStepLabel(step, index)}</p>
                  )}
                  <StepLabel {...labelProps}></StepLabel>
                </>
              ) : (
                <StepLabel {...labelProps}>
                  {renderStepLabel(step, index)}
                </StepLabel>
              )}
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default StepperV3;
