/**
 * Documents Title component
 * @description:
 * @param type = attach | null: used to show a different text inside the subTitle area.
 */

import React, { useState, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import DocumentHistoryPopup from '@organisms/Documents/DocumentFile/DocumentsHistoryPopup/document-history-popup.component';

import styles from './documents-title.module.scss';

const DocumentsTitle = ({ document, step, file, type }) => {
  const { t } = useTranslation();

  const [uploadDocsDialog, setUploadDocsDialog] = useState(false);

  const mainTitle = useCallback(() => {
    if (document) {
      return <>{t(document?.Name)}</>;
    } else {
      switch (step.StepAbbreviation) {
        case 'PAY':
          return <>{t('Instructions')}</>;
        case 'PROOF':
          return <>{t('Payment Confirmation')}</>;
      }
    }
  }, [step, document]);

  const renderSubtext = useCallback(() => {
    if (type === 'attach') {
      return <p>{t('PleaseAttachDocument')}</p>;
    } else if (document) {
      if (step.StepAbbreviation === 'PROOF') {
        return <p>{t('PleaseGenerateDocument')}</p>;
      } else {
        return <p>{t('PleaseGenerateOrAttachDocument')}</p>;
      }
    }
  }, [document, type]);

  return (
    <div className={styles.titleInnerWrap}>
      <div className={styles.titleIconWrap}>
        {document && document?.Files?.length > 0 ? (
          <ContentPasteSearchIcon
            className={styles.list}
            onClick={() => setUploadDocsDialog(true)}
          />
        ) : (
          <FileCopyIcon className={styles.fileIcon} />
        )}
        {uploadDocsDialog && (
          <DocumentHistoryPopup
            title={t('ManageDocuments')}
            isEdit={true}
            setUploadDocsDialog={setUploadDocsDialog}
            step={step}
            file={file}
            stepDocument={document}
            onRefreshDocuments={() => setUploadDocsDialog(false)}
          />
        )}
      </div>
      <div className={styles.titleWrap}>
        <h3>{mainTitle()}</h3>
        {renderSubtext()}
      </div>
    </div>
  );
};

export default memo(DocumentsTitle);
