import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const IdentitySignout = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  // Block direct access, only execute when it's an iframe (re-authorize the user)
  useEffect(() => {
    const isIframe = window !== window.top;

    if (isIframe) {
      removeCookie('id_token');
      removeCookie('access_token');
      removeCookie('nonce');
      removeCookie('user');
      localizationService.clearDictionary();
      localStorage.removeItem('userInfo');
      sessionStorage.removeItem('activePage');
    } else {
      window.location.replace('/');
    }
  }, []);
  
  return <React.Fragment></React.Fragment>;
}

export default IdentitySignout;