import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@lib/helpers/format-number.helper';
import { convertCurrencyHelper } from '@lib/helpers/convert-currency.helper';
import styles from '@organisms/Products/DealTotal/deal-total.module.scss';
import { formatPrice, formatVolume } from '@lib/helpers/helpers';
import {useManageDealContext} from "@lib/Context/ManageDealContext/manage-deal.context";

const OutrightDealTotal = ({ prefix = '' }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { deal } = useManageDealContext();

  const watchContainers = watch(`${prefix}NumContainers`);
  const watchServiceFee = watch(`${prefix}ServiceFee`) || deal?.ServiceFee;
  const watchProducts = watch(`Products`);
  const watchPriceUnit = watch(`${prefix}UnitPriceCurrencyId`);
  const watchNetWeightUnit = watch(`${prefix}NetWeightUnitId`);

  const weight = watchProducts.reduce(
    (acc, curr) => acc + Number(curr?.NetWeight || 0),
    0
  );
  const totalProductsPrice = watchProducts.reduce(
    (acc, curr) =>
      acc + Math.abs(Number(curr?.UnitPrice || 0)).toFixed(4) * Number(curr?.NetWeight || 1),
    0
  );
  const coffeePrice = useMemo(() => {
    if (!totalProductsPrice) {
      return null;
    }
    const currency = convertCurrencyHelper(watchPriceUnit);
    const sign = totalProductsPrice < 0 ? '-' : '';

    return formatPrice(Number(totalProductsPrice), currency, sign);
  }, [totalProductsPrice, watchPriceUnit]);

  const totalWeight = useMemo(() => {
    if (!weight || !watchNetWeightUnit) {
      return null;
    }

    return `${formatVolume(Number(weight), watchNetWeightUnit)}`;
  }, [watchNetWeightUnit, weight]);

  const totalPrice = useMemo(() => {
    if (!totalProductsPrice || !watchServiceFee || !watchContainers) {
      return null;
    }
    const currency = convertCurrencyHelper(watchPriceUnit);
    const total =
      Number(Math.abs(Number(totalProductsPrice)).toFixed(4)) + Number(watchServiceFee) * Number(watchContainers);
    const sign = total < 0 ? '-' : '';

    return formatPrice(Math.abs(Number(total)), currency, sign);
  }, [totalProductsPrice, watchServiceFee, watchContainers, watchPriceUnit]);

  const platformFee = useMemo(() => {
    if (!watchContainers || !watchPriceUnit || !watchServiceFee) {
      return '--';
    }
    return `${convertCurrencyHelper(watchPriceUnit)}${formatNumber(
      watchContainers * watchServiceFee
    )}`;
  }, [t, watchContainers, watchPriceUnit, watchServiceFee]);

  return (
    <>
      <div className={styles.line}>
        <div>{t('Coffee Price')}:</div>
        <div>
          {totalWeight && coffeePrice ? `${totalWeight}, ${coffeePrice}` : '--'}
        </div>
      </div>
      <div className={styles.line}>
        <div>{t('Platform Fee')}:</div>
        <div>{platformFee}</div>
      </div>
      <div className={styles.line}>
        <div>
          <b>{t('Total')}:</b>
        </div>
        <div>
          <b>
            {totalWeight && totalPrice ? `${totalWeight}, ${totalPrice}` : '--'}
          </b>
        </div>
      </div>
    </>
  );
};

export default OutrightDealTotal;
