import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import {useUserRole} from "@lib/hooks/useUserRole";

import './stepper-v2.styles.scss';

const StepperV2 = ({activeStep, state}) => {
  const { t } = useTranslation();
  const { isAdmin, isTrader, isOrgAdmin } = useUserRole();
  const [stepsLabel, setStepsLabel] = useState([])

  useEffect(() => {
    if(state === "WantToSell") {
      setStepsLabel([
        `${isAdmin || isOrgAdmin || isTrader ? 'BuyerSellerDetails' : 'CreateStep1Buyer'}`, 'CreateStep2', 'CreateStep3', 'CreateStep4'
      ])
    } else if(state === "WantToBuy") {
      setStepsLabel([
        `${isAdmin || isOrgAdmin || isTrader ? 'BuyerSellerDetails' : 'CreateStep1Seller'}`, 'CreateStep2', 'CreateStep3', 'CreateStep4'
      ])
    }
  }, [state])

  return (
    <div className={'create__stepper-wrap'}>
      {stepsLabel &&
        <Stepper activeStep={activeStep}>
          {stepsLabel.map((label) => {
            const labelProps = {};

            return (
              <Step key={label}>
                <StepLabel {...labelProps}>{t(label)}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      }
    </div>
  )
}

export default StepperV2;