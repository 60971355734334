import React from "react";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

import styles from "./attention-icon.module.scss";

const AttentionIcon = () => {
    return (
        <>
            <div className={styles.muteIcon}>
                <NotificationImportantIcon className={styles.attentionIcon}/>
            </div>
        </>
    )
}

export default AttentionIcon;