import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { formatNumber } from '@lib/helpers/format-number.helper';
import { convertCurrencyHelper } from '@lib/helpers/convert-currency.helper';
import styles from '@organisms/Products/DealTotal/deal-total.module.scss';
import {useManageDealContext} from "@lib/Context/ManageDealContext/manage-deal.context";

const DifferentialDealTotal = ({ prefix = '' }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { deal } = useManageDealContext();

  const watchContainers = watch(`${prefix}NumContainers`);
  const watchServiceFee = watch(`${prefix}ServiceFee`) || deal?.ServiceFee;
  // const watchPriceType = watch(`${prefix}PriceType`);
  // const watchProducts = watch('Products');
  const watchPriceUnit = watch(`${prefix}UnitPriceCurrencyId`);
  // const watchContract = watch(`${prefix}Contract`);
  // const watchNetWeightUnit = watch(`${prefix}NetWeightUnitId`);

  const platformFee = useMemo(() => {
    if (!watchContainers || !watchPriceUnit || !watchServiceFee) {
      return '--';
    }
    return `${convertCurrencyHelper(
      watchPriceUnit
    )}${formatNumber(watchContainers * watchServiceFee)}`;
  }, [t, watchContainers, watchPriceUnit, watchServiceFee]);

  // const calculateTotalDifferential = useCallback(() => {
  //   const totalDifferential = watchProducts.reduce(
  //     (acc, curr) => acc + Number(curr?.UnitPrice || 1),
  //     0
  //   );
  //
  //   return totalDifferential;
  // }, [watchProducts]);

  // const calculateTotalFutureContracts = useCallback(() => {
  //   const futureContracts = watchProducts.reduce(
  //     (acc, curr) => acc + Number(curr?.FutureContracts || 0),
  //     0
  //   );
  //
  //   return futureContracts || '--';
  // }, [watchProducts]);

  // const renderDifferentialTotalPrice = () => {
  //   return (
  //     <>
  //       <b>
  //         {watchContract} +{convertCurrencyHelper(watchPriceUnit)}
  //         {formatNumber(calculateTotalDifferential())}/{watchNetWeightUnit}
  //       </b>
  //     </>
  //   );
  // };

  return (
    <>
      <div className={styles.line}>
        <div>{t('Platform Fee')}:</div>
        <div>{platformFee}</div>
      </div>
      {/* <div className={styles.line}>
        <div>
          <b>{t('Price')}:</b>
        </div>
        <div>
          <b>{renderDifferentialTotalPrice()}</b>
        </div>
      </div> */}
    </>
  );
};

export default DifferentialDealTotal;
