import React from "react";
import GetAppIcon from '@mui/icons-material/GetApp';

import styles from "./export-icon.module.scss";

const ExportIcon = () => {
    return (
        <>
            <div className={styles.muteIcon}>
                <GetAppIcon className={styles.exportIcon}/>
            </div>
        </>
    )
}

export default ExportIcon;