import { useMemo } from 'react';
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";

import { useWindowSize } from '@lib/hooks/useWindowSize';
import {useQuoteStepContext} from "@lib/Context/QuoteStep/quote-step.context";
import Button from "@atoms/Button/button.component";
import HighChartsWrapper from '@organisms/HighChart/high-chart.component';

import styles from './chart-price-breakdown.module.scss';
import './legend.styles.scss';

const PriceBreakDown = ({index}) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { setShowPriceBreakDown } = useQuoteStepContext();

  const watchCalculator = watch(`Products[${index}].Calculator`);
  const watchNetWeight = watch(`Products[${index}].NetWeight`);
  const watchUnitPrice = watch(`Products[${index}].UnitPrice`);

  const isMdScreenOrAbove = useMemo(() => width >= 760, [width]);

  const cues = useMemo(
    () => ({
      farmgate: {
        label: t('Farmgate'),
        color: '#F33D3C',
        value: Math.floor(Math.random() * (50 - 2) + 2),
      },
      logistics: {
        label: t('Logistics'),
        color: '#F7B500',
        value: (watchCalculator.LandTransportation.Value + watchCalculator.SeaTransportation.Value)/watchNetWeight,
      },
      insurance: {
        label: t('Insurance'),
        color: '#33C5FF',
        value: (watchCalculator.LandInsurance.Value + watchCalculator.SeaInsurance.Value)/watchNetWeight,
      },
      platformFee: {
        label: t('Platform Fee'),
        color: '#0091FE',
        value: Math.floor(Math.random() * (50 - 2) + 2),
      },
      Financing: {
        label: t('Financing'),
        color: '#6ED300',
        value: Math.floor(Math.random() * (50 - 2) + 2),
      },
    }),[]);

  const chartOptions = useMemo(
    () => ({
      chart: {
        width: 580,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: isMdScreenOrAbove ? '227px' : 'auto'
      },
      title: {
        text: undefined,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: 'Price breakdown',
          data: Object.entries(cues).reduce(
            (acc, [key, value]) => [
              ...acc,
              {
                name: value.label,
                y: value.value,
                sliced: false,
                selected: false,
                color: value.color,
              },
            ],[]),
        },
      ],
      credits: {
        enabled: false
      },
      legend: {
        useHTML: true,
        className: styles.priceBreakDownLegend,
        align: isMdScreenOrAbove ? 'left' : 'center',
        verticalAlign: isMdScreenOrAbove ? 'middle' : 'bottom',
        layout: 'vertical',
        margin: 0,
        itemMarginTop: 5,
        symbolHeight: 16,
        symbolWidth: 24,
        symbolRadius: 0,
        symbolPadding: 14,
        itemStyle: {
          borderColor: 'black',
          borderWidth: 2,
        },
        labelFormatter: function () {
          return `
            <div className="highcharts-legend-item">
              <span class="highcharts-legend-key">${this.name}: </span>
              <span class="highcharts-legend-value">$${this.y}/MT</span>
            </div>`;
        },
      },
    }),
    [cues, isMdScreenOrAbove]
  );

  return (
    <div className={styles.priceBreakDownContainer}>
      <HighChartsWrapper options={chartOptions} loading={false} />
      <Button type={'link'} onClick={() => setShowPriceBreakDown(false)}>{t('Close')}</Button>
    </div>
  );
};

export default PriceBreakDown;
