import { memo } from 'react';

const OriginCountry = ({ products }) => {
  return (
    <div>
      {products?.map((product, i) => (
        <div key={i}>{product.Origin}</div>
      ))}
    </div>
  );
};

export default memo(OriginCountry);
