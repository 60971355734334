import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Grid, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import variables from '@base/styles/variables.scss';
import InputLabel from '@atoms/Fields/InputLabel/input-label.components';
import { isValueIncludedInArray, required } from '@lib/helpers/validation';
import Switch from 'react-switch';
import '../edit-deal.styles.scss';
import '../edit-deal.module.scss';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import styles from './support-agents.module.scss';
import { MuiAutocomplete } from '@atoms/Fields/ReactFormFields/MuiAutocomplete/mui-autocomplete.component';
import { useUserRole } from '@lib/hooks/useUserRole';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';

const Component = ({ supportAgentsList, permissions }) => {
  const { t } = useTranslation();
  const { isAdmin } = useUserRole();
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();

  const {
    setValue,
    setError,
    clearErrors,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const {
    fields: supportAgents,
    append: appendSupportAgent,
    remove: removeSupportAgent,
  } = useFieldArray({
    control,
    name: 'SupportAgents',
  });

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <InputLabel label={'Support Agents'} />
        <IconButton
          onClick={() =>
            appendSupportAgent({
              User: '',
              Write: 1,
              ViewDeal: true,
            })
          }>
          <AddCircleIcon fontSize='large' className={styles.green} />
        </IconButton>
      </Box>
      <Grid container spacing={1}>
        {supportAgents.map((agent, index) => {
          return (
            <Grid
              item
              sx={12}
              md={12}
              key={agent.id}
              className={styles.gridItem}>
              <div className={styles.inputAgent}>
                <Controller
                  name={`SupportAgents[${index}].User`}
                  //defaultValue={[]}
                  rules={{
                    ...required,
                    ...isValueIncludedInArray(
                      [
                        // //getValues('SellerEmail') || buyerEmail,
                        // //getValues('BuyerEmail'),
                        // ...getValues('SupportAgents').map(
                        //   (agent) => agent?.key
                        // ),
                        // ...getValues('SupportAgents')
                        //   .filter((_, agentIndex) => agentIndex !== index)
                        //   .map((agent) => agent?.key),
                      ],
                      t('AlreadyAddedEmail')
                    ),
                  }}
                  render={({ field }) => (
                    <>
                      <MuiAutocomplete
                        disableClearable
                        freeSolo={false}
                        onChange={(value) => {
                          clearErrors(`SupportAgents[${index}].User`);
                          const agents = getValues('SupportAgents').filter(
                            (_, agentIndex) => agentIndex !== index
                          );
                          const foundAgent = agents.find(
                            (agent) => agent.User === value
                          );
                          if (!!foundAgent) {
                            setError(`SupportAgents[${index}].User`, {
                              type: 'custom',
                              message: t('AlreadyAddedEmail'),
                            });
                          } else {
                            setValue(`SupportAgents[${index}].User`, value);
                          }
                        }}
                        required
                        label={t('User')}
                        value={field.value || agent.User}
                        options={supportAgentsList}
                        placeholder={'Select'}
                        error={
                          errors?.SupportAgents &&
                          errors.SupportAgents[index]?.User
                        }
                      />
                    </>
                  )}
                />
              </div>
              <div className={styles.permissionInput}>
                <Controller
                  name={`SupportAgents[${index}].Write`}
                  defaultValue={[]}
                  render={({ field }) => (
                    <div className={styles.selectInput}>
                      <MuiAutocomplete
                        disableClearable
                        freeSolo={false}
                        onChange={(value) => {
                          setValue(
                            `SupportAgents[${index}].Write`,
                            Number(value)
                          );
                        }}
                        label={t('Permissions')}
                        value={field.value}
                        options={permissions}
                        placeholder={'Select'}
                      />
                    </div>
                  )}
                />
              </div>
              <div>
                <p className={styles.label}>{t('DealAccess')}</p>
                <div className={'mute-mail--wrap'}>
                  <div className={'mute__switcher'}>
                    <Switch
                      onChange={(value) => {
                        setValue(`SupportAgents[${index}].ViewDeal`, value);
                      }}
                      checked={watch(`SupportAgents[${index}].ViewDeal`)}
                      onColor={variables.primaryBtnBgLight}
                      onHandleColor={variables.primaryBtnBgLight}
                      offColor='#999999'
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={48}
                      handleDiameter={30}
                      className={'switcher'}
                      name='DealAccess'
                      required
                    />
                  </div>
                </div>
              </div>

              <div className={styles.deleteAgent}>
                <IconButton onClick={() => removeSupportAgent(index)}>
                  <DeleteOutlineOutlinedIcon className={styles.red} />
                </IconButton>
              </div>
              {isAdmin && (
                <Link
                  to={'/'}
                  title={t('Impersonate')}
                  style={{ alignSelf: 'center' }}>
                  <IconButton
                    style={{ padding: 8, alignSelf: 'center', color: 'black' }}
                    onClick={() => {
                      const setCookies = new Promise((resolve) => {
                        setCookie(
                          'impersonateEmail',
                          supportAgentsList[
                            watch(`SupportAgents[${index}].User`)
                          ].Email,
                          {
                            encode: (v) => v,
                          }
                        );
                        resolve(cookies['impersonateEmail']);
                      });
                      setCookies.then(() => {
                        navigate('/');
                      });
                    }}>
                    <PersonOutlineOutlinedIcon />
                  </IconButton>
                </Link>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export const SupportAgents = memo(Component);
