import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { Skeleton } from "@mui/material";
import { Box } from "@material-ui/core";
import { NestedDropdown } from "mui-nested-menu";

import {
  AUTH_LOGOUT_ROOT,
  AUTH_ROOT,
  almacena_domain,
  IDENTITY_URL,
} from "@config/config";
import { useAppStore } from "@lib/Context";
import { setActivePageAction } from "@lib/Context/actions";
import { useUserRole } from "@lib/hooks/useUserRole";
import almacenaLogo from "@assets/Almacena-Logo.png";
import proCafeLogo from "@assets/images/header-procafe.png";
import localizationService from "@lib/services/localization-service";
import { smallDesktopMediaQuery } from "@base/globalVariables/global-variables";
import { proCafeWL } from "@base/globalVariables/page-links";
import ContactSupport from "@atoms/ContactSupport/contact-support.component";
import FrFlag from "@atoms/Images/fr-flag.image";
import EnFlag from "@atoms/Images/en-flag.image";
import SpFlag from "@atoms/Images/sp-flag.image";
import NotificationMessages from "@organisms/NotificationMessages/notification-messages.component";
import HeaderSearch from "@templates/Header/HeaderSearch/header-search.component";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import HamburgerMenu from "./HamburgerMenu/header-mobile-hamburger.component";
import "./header.styles.scss";
import styles from "./header.module.scss";

import { useNavigation } from "@lib/hooks/useNavigation";

function Header() {
  const { t } = useTranslation();
  const { dispatch, state: appState } = useAppStore();
  const [cookies, setCookie, removeCookie] = useCookies();
  const { isAdmin, isOrgAdmin, isFinancier, isInsurer } = useUserRole();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));
  const [renderedLogo, setRenderedLogo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { redirect } = useNavigation();

  const userName = useMemo(
    () => appState?.userInfo?.name || "",
    [appState?.userInfo?.name]
  );

  const whitelabel = useMemo(
    () => appState?.userInfo?.whiteLabel || "",
    [appState?.userInfo?.whiteLabel]
  );

  const changeLanguage = (language) => {
    localizationService.changeLanguage(language);
    setLanguage(language);
  };

  const handleActivePageLink = (link) => {
    redirect(link);
  };

  const logout = useCallback(() => {
    removeCookie("id_token");
    removeCookie("access_token");
    removeCookie("nonce");
    removeCookie("user");
    removeCookie("impersonateEmail");
    localizationService.clearDictionary();
    localStorage.removeItem("userInfo");
    sessionStorage.removeItem("activePage");
    window.location.replace(`${AUTH_LOGOUT_ROOT}${cookies["id_token"]}`);
  }, []);

  const stopImpersonate = () => {
    removeCookie("impersonateEmail", { path: "/" });
    window.location.href =
      window.location.href + "?" + "reloadTime=" + Date.now().toString();
  };

  const updateUserBeforeEdit = useCallback(() => {
    removeCookie("id_token");
    removeCookie("access_token");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const userMenuItems = useMemo(
    () => ({
      label: "",
      items: [
        {
          label: `${userName} ${t("Profile")}`,
          leftIcon: <PersonOutlineOutlinedIcon />,
          callback: () => (
            updateUserBeforeEdit(), (window.location.href = IDENTITY_URL || "")
          ),
        },
        {
          label: `${t("Language")}: ${
            language ? language.toUpperCase() : "EN"
          }`,
          leftIcon: <LanguageOutlinedIcon />,
          items: [
            {
              label: "English",
              leftIcon: <EnFlag />,
              callback: () => changeLanguage("en"),
            },
            {
              label: "Français",
              leftIcon: <FrFlag />,
              callback: () => changeLanguage("fr"),
            },
            {
              label: "Español",
              leftIcon: <SpFlag />,
              callback: () => changeLanguage("es"),
            },
          ],
        },
        {
          leftIcon: <ExitToAppOutlinedIcon />,
          label: t("Sign out"),
          callback: () => logout(),
        },
      ],
    }),
    [userName, t]
  );

  const impersonateMenu = useMemo(
    () => ({
      label: "",
      items: [
        ...userMenuItems.items,
        {
          leftIcon: <ExitToAppOutlinedIcon />,
          label: t("StopImpersonate"),
          callback: () => stopImpersonate(),
        },
      ],
    }),
    [userName, t]
  );

  const navigationLinks = useMemo(
    () => [
      {
        link: "/",
        text: t("ActiveDeals"),
      },
      {
        link: "/archived-deals",
        text: t("ArchivedDeals"),
      },
    ],
    []
  );

  useEffect(() => {
    if (isFinancier === false && isInsurer === false) {
      navigationLinks.push(
        {
          link: "/create-deal-buy",
          text: t("WantToBuy"),
        },
        {
          link: "/create-deal-sell",
          text: t("WantToSell"),
        }
      );
    }
  }, [isFinancier, isInsurer]);

  const renderLogo = () => {
    switch (appState?.userInfo?.whiteLabel) {
      case "procafe":
        return (
          <a className={styles.logoWrapper} href={proCafeWL}>
            <img className={styles.logo} src={proCafeLogo} alt="logo" />
          </a>
        );
      default:
        return (
          <a
            className={styles.logoWrapper}
            href={almacena_domain + "?force-login=true"}
          >
            <img className={styles.logo} src={almacenaLogo} alt="logo" />
          </a>
        );
    }
  };

  useEffect(() => {
    const handlePopstate = () => {
      const oldActivePage = window.location.pathname;
      dispatch(setActivePageAction(oldActivePage));
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (whitelabel) {
      const logo = renderLogo();

      if (logo) {
        setRenderedLogo(logo);

        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      }
    }
  }, [whitelabel]);

  return (
    <div>
      <header>
        <div className={styles.container}>
          {screenWidth < smallDesktopMediaQuery && (
            <>
              {isLoading ? (
                <div className={styles.siteLogo}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <Skeleton variant="circular" width="40px" height="30px" />
                    <Box
                      sx={{
                        display: "flex",
                        width: "110px",
                        flexFlow: "row wrap",
                        gap: "8px",
                      }}
                    >
                      <Skeleton
                        variant="rounded"
                        width="110px"
                        height="10px"
                        style={{ borderRadius: 4 }}
                      />
                      <Skeleton
                        variant="rounded"
                        width="110px"
                        height="10px"
                        style={{ borderRadius: 4 }}
                      />
                    </Box>
                  </Box>
                </div>
              ) : (
                <div className={styles.siteLogo}>{renderedLogo}</div>
              )}
              <HamburgerMenu
                logout={logout}
                userMessage={`${userName} ${t("Profile")}`}
              />
            </>
          )}
          {screenWidth >= smallDesktopMediaQuery && (
            <>
              <section className={styles.menuWrapper}>
                {/*Change logos here*/}
                {isLoading ? (
                  <div className={styles.siteLogo}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Skeleton variant="circular" width="40px" height="30px" />
                      <Box
                        sx={{
                          display: "flex",
                          width: "110px",
                          flexFlow: "row wrap",
                          gap: "8px",
                        }}
                      >
                        <Skeleton
                          variant="rounded"
                          width="110px"
                          height="10px"
                          style={{ borderRadius: 4 }}
                        />
                        <Skeleton
                          variant="rounded"
                          width="110px"
                          height="10px"
                          style={{ borderRadius: 4 }}
                        />
                      </Box>
                    </Box>
                  </div>
                ) : (
                  <div className={styles.siteLogo}>{renderedLogo}</div>
                )}
                <ul className={styles.navigationList}>
                  {navigationLinks.map((link, index) => {
                    return (
                      <li
                        key={index}
                        className={`${
                          appState?.activePage === link.link
                            ? `${styles.active}`
                            : ""
                        }`}
                        onClick={() => {
                          handleActivePageLink(link.link);
                        }}
                      >
                        <Link
                          to={link.link}
                          state={link.state}
                          title={t(link.text)}
                        >
                          {t(link.text)}
                        </Link>
                      </li>
                    );
                  })}
                  {isAdmin && (
                    <>
                      <li
                        className={`${
                          appState?.activePage === "/deal-settings"
                            ? `${styles.active}`
                            : ""
                        }`}
                        onClick={() => {
                          handleActivePageLink("/deal-settings");
                        }}
                      >
                        <Link to={"/deal-settings"} title={t("Settings")}>
                          {t("Settings")}
                        </Link>
                      </li>
                      <li
                        className={`${
                          appState?.activePage === "/reports"
                            ? `${styles.active}`
                            : ""
                        }`}
                        onClick={() => {
                          handleActivePageLink("/reports");
                        }}
                      >
                        <Link to={"/reports"} title={t("Reports")}>
                          {t("Reports")}
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </section>
              <section className={styles.userContainer}>
                <div className={styles.searchWrap}>
                  <HeaderSearch />
                </div>
                {screenWidth >= smallDesktopMediaQuery && (
                  <div
                    className={styles.signInContainer}
                    onClick={() =>
                      !userName && window.location.replace(AUTH_ROOT)
                    }
                  >
                    {cookies.impersonateEmail != undefined ? (
                      <SupervisorAccountIcon
                        color="#d40014"
                        style={{ fill: "#d40014" }}
                      />
                    ) : (
                      <PersonOutlineOutlinedIcon />
                    )}
                    {userName ? (
                      <div className={"header__nester-dropdown--wrap"}>
                        <NestedDropdown
                          menuItemsData={
                            cookies.impersonateEmail != undefined
                              ? impersonateMenu
                              : userMenuItems
                          }
                          ButtonProps={{ variant: "outlined" }}
                        />
                      </div>
                    ) : (
                      <p className={styles.signInText}>
                        {t("Sign In")} / {t("Sign Up")}
                      </p>
                    )}
                  </div>
                )}
                {!location.pathname.includes("manage-deal") && (
                  <div className={"sub-header__envelope-wrap"}>
                    <NotificationMessages />
                  </div>
                )}
                {!(isAdmin || isOrgAdmin) && (
                  <ContactSupport
                    contact={appState?.defaultContactSupport}
                    title={t("Contact Support")}
                  />
                )}
              </section>
            </>
          )}
        </div>
      </header>
    </div>
  );
}

export default Header;
