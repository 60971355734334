import { memo, useCallback } from 'react';

import { convertCurrencyHelper } from '@lib/helpers/convert-currency.helper';
import { formatNumber } from '@lib/helpers/format-number.helper';

const PricePerUnit = ({ products, priceType }) => {
  const renderPrice = useCallback(
    ({ Price, PriceCurrency, VolumeUnit }, index) => {
      const sign = Number(Price) > 0 ? '+' : Number(Price) < 0 ? '-' : '';
      const price = Price
        ? `${convertCurrencyHelper(PriceCurrency)}${Math.abs(
            Number(Price)
          ).toFixed(4)}/${VolumeUnit || ''}`
        : '--';

      return Price ? (
        <div key={index}>
          {priceType === 'Differential' ? sign : ''}
          {price}
        </div>
      ) : (
        '--'
      );
    },
    [priceType]
  );

  return <div>{products?.map(renderPrice)}</div>;
};

export default memo(PricePerUnit);
