import React from 'react';

import styles from './Loader.module.scss';
import { useAppStore } from '../../../lib/Context';

const Loader = () => {
  const { state } = useAppStore();

  return (
    <>
      {state.isLoading && (
        <div className={styles.loaderContainer}>
          <div className={styles.loaderWrap}>
            <div className={styles.loader}></div>
          </div>
        </div>)
      }
    </>
  )
}

export default Loader
