/**
 * Asc Desc Filter component
 * description: Shows the state of the filter: Asc or Desc
 * Usage: <AscDescFilterIcons />
 */

import { useRef, useState } from 'react';

import styles from './asc-desc-filter.module.scss';

const AscDescFilter = ({ handleClick, children }) => {
  const [filterType, setFilterType] = useState('none');
  const filter = useRef(filterType);

  const filterToggle = () => {
    if (filter.current === '' || filter.current === 'none') {
      filter.current = 'asc';
    } else if (filter.current === 'asc') {
      filter.current = 'desc';
    } else if (filter.current === 'desc') {
      filter.current = 'none';
    }

    handleClick(filter.current);
    setFilterType(filter.current);
  };

  return (
    <div className={styles.container} onClick={filterToggle}>
      {children}
      <div
        className={`
      ${styles.filterIconsWrap} 
      ${
        filterType === 'asc'
          ? styles.ascWrap
          : filterType === 'desc'
          ? styles.descWrap
          : ''
      }
      `}>
        <div className={`${styles.asc} ${styles.filterIcons}`}>
          <div className={styles.arrowUp}></div>
        </div>
        <div className={`${styles.desc} ${styles.filterIcons}`}>
          <div className={styles.arrowDown}></div>
        </div>
      </div>
    </div>
  );
};

export default AscDescFilter;
