import React, { createContext, useContext, useMemo, useState } from 'react';

export const useRfqStepContext = () => {
  const context = useContext(RfqStepContext);
  if (!context) {
    throw new Error('DealsContext must be used within a useRfqStepProvider');
  }
  return context;
};

const RfqStepContext = createContext();

export const RfqStepProvider = ({ children }) => {
  const [contracts, setContracts] = useState({});
  const [incoterms, setIncoterms] = useState({});
  const [minContainers, setMinContainers] = useState(0);
  const store = useMemo(
    () => ({
      incoterms,
      contracts,
      minContainers,
      setContracts,
      setIncoterms,
      setMinContainers,
    }),
    [contracts, incoterms, minContainers]
  );

  return (
    <RfqStepContext.Provider value={store}>{children}</RfqStepContext.Provider>
  );
};
