/**
 * Quote Step Component
 * description: Form used inside Create Deal and Quick Step Inside Manage Deal
 * @props buttons: show or hide the default quickStep buttons
 * @props onBack: if we have default buttons activated decide what the back button will do from the parent component
 * @props submit: if we have default buttons activated decide what the submit button will do from the parent component
 * @props loader: the parent component will tell us if we need to show the loader instead of the buttons for 1s
 * @props errors: bring the input errors
 * @props quoteFields: the main fields which are outside the accordions
 * @props quoteProduct: the products fields which are inside the accordions
 * @props setFields: set the products fields needed to the api on submit
 * @props setMainFields: set the main fields needed to the api on submit
 * @props step: on which step are you at the moment : 'create' | any
 * @props setChildProducts: send the current products to the parent in order to use them later again, because we change them inside the component
 * @props childProducts: use the products sent before.
 */

import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AddchartIcon from '@mui/icons-material/Addchart';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';

import dealsService from '@services/deals-service';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import { usePopup } from '@lib/hooks/usePopup';
import { stepStatusCompleted } from '@lib/helpers/StepHelper/step-status.helper';
import { stepStatusActive } from '@base/globalVariables/global-variables';
import IconOnlyButton from '@atoms/Buttons/AddButton/add-button.component';
import ConfirmationPopup from '@organisms/Popup/ConfirmationPopup/confirmation-popup.component';
import DocumentsTitleSection from '@organisms/Documents/DocumentsTitleSection/documents-title-section.component';
import PCStepForm from '@organisms/Steps/PCStep/PCStepForm/pc-step-form.component';
import TermsAndConditionsMessage from '@organisms/TermsAndConditions/terms-and-conditions-message.component';
import AdditionalDocuments from '@organisms/Documents/AdditionalDocuments/additional-documents.component';
import DocumentsSection from '@organisms/Documents/DocumentsSection/documents-section.component';
import SampleDeleteBtn from '@organisms/SampleDeleteBtn/sample-delete-btn.component';

import styles from './pc-step-samples.module.scss';
import { useUserRole } from '@lib/hooks/useUserRole';

const PCStepSamples = ({
  samples,
  archivedDeal,
  setUpdateAPR,
  onSubmit,
  step,
  setExecutionTypePayload,
  loadStep,
  executionTypePayload,
  canWrite,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const { showPopup } = usePopup();
  const { deal, setSelectedStep } = useManageDealContext();

  const [currentPC, setCurrentPC] = useState(0); // for the accordion open logic
  const [newSample, setNewSample] = useState(false); // to know when newSample is being created
  const { isFinancier, isInsurer } = useUserRole();

  const createNewSample = () => {
    dealsService
      .createNewPCSample(params.id, {
        Id: [samples[currentPC].Step.Id],
      })
      .then(() => {
        setNewSample(false);
        setUpdateAPR(true);
        showPopup({
          state: 'success',
          message: t('New Price Confirmation added!'),
          timer: 4000,
        });
      })
      .catch((e) => {
        showPopup({
          state: 'error',
          message: t(e.response.data),
          timer: 4000,
        });
      });
  };

  const changeCurrentSample = (index) => {
    setCurrentPC(index);
    setSelectedStep(samples[index].Step);
  };

  useEffect(() => {
    if (!samples?.length) {
      return;
    }
    const sampleIndex = samples.findIndex(
      (sample) => sample.Step.State === 'active'
    );

    const index = sampleIndex !== -1 ? sampleIndex : 0;
    changeCurrentSample(index);
  }, [samples, deal]);

  const renderStep = () => {
    if (stepStatusCompleted(samples[currentPC]?.Step?.Status)) {
      return (
        <DocumentsSection
          showAllDocuments={false}
          loadStep={loadStep}
          onSubmit={onSubmit}
          documents={samples[currentPC]?.Documents}
          currentStep={samples[currentPC]?.Step}
          stepActions={samples[currentPC]?.Buttons}
          stepSettings={samples[currentPC].Settings}
          currentInnerStep={currentPC}
        />
      );
    } else {
      if (executionTypePayload === 'Attach') {
        return (
          <DocumentsSection
            showAllDocuments={false}
            loadStep={loadStep}
            onSubmit={onSubmit}
            documents={samples[currentPC].Documents}
            currentStep={samples[currentPC].Step}
            stepActions={samples[currentPC]?.Buttons}
            stepSettings={samples[currentPC].Settings}
            currentInnerStep={currentPC}
          />
        );
      } else {
        return (
          <>
            {samples?.length > 0 && (
              <PCStepForm sample={samples[currentPC]} onSubmit={onSubmit} />
            )}
            <TermsAndConditionsMessage />
            <AdditionalDocuments currentStep={step} />
          </>
        );
      }
    }
  };

  return (
    <>
      {newSample && (
        <ConfirmationPopup
          message={t('AreYouSureYouWantCreatePC')}
          cancelationHandler={() => {
            setNewSample(false);
          }}
          agreementHandler={createNewSample}
        />
      )}
      {samples?.length > 0 && (
        <div className={styles.stepsPagination}>
          {samples?.map((item, i) => (
            <div
              className={`${styles.button} ${
                styles[item?.Step?.Status] || ''
              } ${currentPC === i ? styles.current : ''}`}
              key={i}
              onClick={changeCurrentSample.bind(this, i)}>
              <AddchartIcon />
              {item?.Step?.Status === stepStatusActive &&
                !item?.Step?.AlreadySubmitted &&
                samples.length > 1 &&
                i > 0 &&
                canWrite &&
                !isFinancier &&
                !isInsurer && (
                  <SampleDeleteBtn
                    stepId={item.Step?.DealId}
                    sampleId={item.Step?.Id}
                    setUpdateAPR={setUpdateAPR}
                  />
                )}
            </div>
          ))}
          {!archivedDeal && canWrite && !isFinancier && !isInsurer && (
            <IconOnlyButton
              handleClick={() => setNewSample(true)}
              tooltip={t('AreYouSureYouWantCreatePC')}>
              <ControlPointOutlinedIcon />
            </IconOnlyButton>
          )}
        </div>
      )}
      <DocumentsTitleSection
        step={samples[currentPC]?.Step}
        stepSettings={samples[currentPC].Settings}
        setExecutionTypePayload={setExecutionTypePayload}
        currentInnerStep={currentPC}
      />

      {renderStep()}
    </>
  );
};

export default memo(PCStepSamples);
