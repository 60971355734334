import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './request-financing.module.scss';

const RequestFinance = ({ financing, serviceFee, insurance }) => {
  const { t } = useTranslation();

  const financingLabel = financing ? t('Financing: Almacena') : t('Financing: Own');
  const insuranceLabel = insurance ? t('Insurance Label') : "";

  return (
    <div className={styles.financingWrap}>
      <div className={styles.column}>
        {insurance && <p className={styles.label}>{insuranceLabel}</p>}
        <p className={styles.label}>{financingLabel}</p>
        <p className={styles.hint}>{serviceFee.Name}</p>
      </div>
    </div>
  );
};

export default memo(RequestFinance);
