const ChevronDownIcon = () => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.1936 2.02313C12.7939 2.70564 10.3222 3.05129 7.84639 3.05129C7.84639 3.05129 7.84607 3.05129 7.84575 3.05129C3.6598 3.05095 0.878348 2.0478 0.851148 2.038C0.591626 1.94171 0.301385 2.03259 0.132744 2.26336C-0.0352568 2.49447 -0.0448572 2.81309 0.110024 3.05467L7.47006 14.5425C7.5891 14.7283 7.78751 14.8395 7.99999 14.8395C8.21247 14.8395 8.41087 14.7283 8.52991 14.5425L15.8899 3.05467C16.04 2.82086 16.0362 2.51305 15.881 2.28364C15.7261 2.05354 15.4512 1.95083 15.1936 2.02313Z'
        fill='#333333'
      />
    </svg>
  );
};

export default ChevronDownIcon;
