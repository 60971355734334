/**
 * Description: returns the user back to the previous page he has been.
 * Usage: <BackButton />
 */

import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from './Button';

const BackButton = forwardRef(({ type, size, onClick, backRef, btnText }, ref) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Button
      onClick={() => {
        navigate(-1);
        typeof onClick === 'function' && onClick();
      }}
      type={type}
      size={size}
      ref={ref}>
      {btnText || t('Back')}
    </Button>
  );
});

BackButton.displayName = 'BackButton';

export default BackButton;
