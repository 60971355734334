/**
 * Form Input
 * description: working with React Hook Form Input
 */

import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import InputLabel from '@atoms/Fields/InputLabel/input-label.components';
import FieldError from '@atoms/Fields/ReactFormFields/FieldError/field-error.component';

import styles from './form-input.module.scss';
import { useEffect } from 'react';

const FormInput = forwardRef((props, ref) => {
  const {
    type,
    label,
    placeholder,
    readonly,
    required,
    errors,
    disabled,
    value,
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <InputLabel label={label ? label : ''} required={required} />
      <input
        {...rest}
        className={`
          ${styles.field} 
          ${!!errors ? styles.error : ''}
          ${!label ? styles.noLabel : styles.hasLabel}
        `}
        type={type}
        disabled={readonly || disabled}
        placeholder={placeholder && t(placeholder)}
        onWheel={(e) => e.target.blur()}
        value={value || rest.value || ''}
        ref={ref}
        onFocus={(e) => {
          e.target.select();
        }}
      />
      {errors && <FieldError error={errors} />}
    </div>
  );
});

FormInput.displayName = 'FormInput';

export default FormInput;
