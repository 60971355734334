import React from 'react';

const SvgComponent = (props) => (
  <svg
    width='800px'
    height='800px'
    {...props}
    viewBox='0 0 1024 1024'
    className='icon'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M182.52 146.2h585.14v256h73.15V73.06H109.38v877.71h256v-73.14H182.52z'
      fill={props?.fill || '#0F1F3C'}
    />
    <path
      d='M255.67 219.34h438.86v73.14H255.67zM255.67 365.63h365.71v73.14H255.67zM255.67 511.91H475.1v73.14H255.67zM775.22 458.24L439.04 794.42l-0.52 154.64 155.68 0.52L930.38 613.4 775.22 458.24z m51.72 155.16l-25.43 25.43-51.73-51.72 25.44-25.44 51.72 51.73z m-77.14 77.15L620.58 819.77l-51.72-51.72 129.22-129.22 51.72 51.72zM511.91 876.16l0.17-51.34 5.06-5.06 51.72 51.72-4.85 4.85-52.1-0.17z'
      fill={props?.fill || '#0F1F3C'}
    />
  </svg>
);
export default SvgComponent;
