import { useCallback, useEffect } from "react";
import { useAppStore } from "../../../lib/Context";
import { showLoaderAction } from "../../../lib/Context/actions";
import { httpClient } from "../../../lib/services/httpClient";

const timeoutIds = [];

const NetworkInterceptor = () => {
    const { dispatch } = useAppStore();
    const handleError = useCallback((error) => {
        if (timeoutIds.length) {
            timeoutIds.forEach(id => clearTimeout(id));
            timeoutIds.length = 0;
        }
        dispatch(showLoaderAction({ isLoading: false }));
        return Promise.reject(error);
    }, [dispatch]);

    useEffect(() => {
        const interceptor = httpClient.interceptors.request.use(
            (request) => {
                const timeout = setTimeout(() => {
                    dispatch(showLoaderAction({ isLoading: true }))
                }, 550);
                timeoutIds.push(timeout);

                return request;
            }, handleError);

        return () => {
            httpClient.interceptors.request.eject(interceptor);
        };
    }, [])

    useEffect(() => {
        const interceptor = httpClient.interceptors.response.use(
            (response) => {
                if (timeoutIds.length) {
                    timeoutIds.forEach(id => clearTimeout(id));
                    timeoutIds.length = 0;
                }
                dispatch(showLoaderAction({ isLoading: false }));

                return response;
            },
            handleError)

        return () => {
            httpClient.interceptors.response.eject(interceptor);
        }
    }, [])

    return <></>

}

export default NetworkInterceptor;