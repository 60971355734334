import enIcon from "@assets/en_US.png";
import frIcon from "@assets/fr_FR.png";
import deIcon from "@assets/de_DE.png";
import itIcon from "@assets/it_IT.png";
import esIcon from "@assets/es_ES.png";
import almacenaLogo from "@assets/Almacena-Logo-old.png";
import envelope from "@assets/icons/envelope-no-dot.svg";
import close from "@assets/icons/Close.svg";

export const almacenaLogoMain = almacenaLogo;
export const enFla = enIcon;
export const frFlag = frIcon;
export const deFlag = deIcon;
export const itFlag = itIcon;
export const esFlag = esIcon;
export const envelopeIcon = envelope;
export const closeIcon = close;
