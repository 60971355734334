import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { IconButton, Tooltip } from '@mui/material';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import GppBadIcon from '@mui/icons-material/GppBad';
import VisibilityIcon from '@mui/icons-material/Visibility';
import parse from 'html-react-parser';

import { POLYGON_SCAN_NETWORK } from '@lib/config/config';
import { useUserRole } from '@lib/hooks/useUserRole';
import DeleteButton from '@molecules/DeleteButton/delete-button.component';

import styles from './message-subject.module.scss';
import { MenuDropdown } from '@organisms/MenuDropdown/menu-dropdown.component';

const BlockchainIcon = ({ status, transactionHash }) => {
  const { t } = useTranslation();

  switch (status) {
    case 'Verified':
      return (
        <Tooltip title={t('BlockchainRegistered')} placement='top'>
          <GppGoodIcon className={styles.verified} />
        </Tooltip>
      );
    case 'VerificationFailed':
      return (
        <Tooltip title={t('BlockchainRegistrationFailed')} placement='top'>
          <GppBadIcon className={styles.verificationFailed} />
        </Tooltip>
      );
    case 'VerificationPending':
      return (
        <Tooltip
          title={t('BlockchainRegistrationPendingVerified')}
          placement='top'>
          <SafetyCheckIcon className={styles.verificationPending} />
        </Tooltip>
      );
    case 'VerificationDisabled':
      return (
        <Tooltip title={t('VerificationDisabled')} placement='top'>
          <GppBadIcon className={styles.verificationDisabled} />
        </Tooltip>
      );
    default:
      return null;
  }
};

function Component({
  message,
  parser,
  archived,
  onOpenOriginalMessage,
  onRestoreMessage,
  onDeleteMessage,
}) {
  const { t } = useTranslation();
  const { isAdmin, isOrgAdmin } = useUserRole();

  if (!parser) {
    return (
      <div className={styles.subjectContainer}>
        <div className={styles.subject}>{message?.OriginalSubject}</div>
      </div>
    );
  }
  return (
    <>
      {!!message.Deleted && (
        <div className={styles.deletedMessageHeader}>
          {t('Deleted Message')}
        </div>
      )}
      <div className={styles.subjectContainer}>
        <div className={styles.subject}>{parse(String(message.Subject))}</div>
        <div className={styles.actions}>
          {!message?.TransactionHash ? (
            <BlockchainIcon
              status={message.BlockchainStatus}
              transactionHash={message?.TransactionHash}
            />
          ) : (
            <MenuDropdown
              options={[
                {
                  name: t('View transaction in blockchain'),
                  onClick: () =>
                    window.open(
                      `${POLYGON_SCAN_NETWORK}${message?.TransactionHash}`,
                      '_blank'
                    ),
                },
                {
                  name: t('View Raw Data'),
                  onClick: onOpenOriginalMessage,
                },
              ]}>
              <BlockchainIcon
                status={message.BlockchainStatus}
                transactionHash={message?.TransactionHash}
              />
            </MenuDropdown>
          )}

          {/* {message.BlockchainStatus === 'Verified' && (
            <Tooltip title={t('ViewOriginalMessage')} placement='top'>
              <IconButton onClick={onOpenOriginalMessage}>
                <VisibilityIcon className={styles.viewOriginalMsg} />
              </IconButton>
            </Tooltip>
          )} */}
          {/* {isAdmin && !message.Deleted && !archived && (
            <DeleteButton
              confirmationTitle={t(
                'Are you sure you want to delete this message?'
              )}
              agreementHandler={onDeleteMessage}
              buttonTitle={t('Delete message')}
            />
          )}
          {!!message.Deleted && isAdmin && (
            <IconButton onClick={onRestoreMessage}>
              <RestoreFromTrashIcon />
            </IconButton>
          )} */}
        </div>
      </div>
    </>
  );
}

export const MessageSubject = memo(Component);
