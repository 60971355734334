import { forwardRef } from 'react';
import { FormHelperText, InputAdornment, OutlinedInput } from '@mui/material';

import InputLabel from '@atoms/Fields/InputLabel/input-label.components';

import './mui-outlined-input.styles.scss';

const MuiOutlinedInput = forwardRef(
  (
    {
      label,
      required,
      end,
      start,
      defaultValue,
      name,
      helperText,
      textAlign,
      fontWeight,
      arrows,
      error,
      ...props
    },
    ref
  ) => {
    return (
      <div className={'outlined-input-component'}>
        <InputLabel label={label ? label : ''} required={required} />
        <OutlinedInput
          {...props}
          error={!!error}
          className={`${textAlign} ${fontWeight} ${arrows ? 'arrows' : ''}`}
          inputRef={ref}
          id='outlined-adornment-weight'
          startAdornment={
            <InputAdornment position='start'>{start}</InputAdornment>
          }
          endAdornment={<InputAdornment position='end'>{end}</InputAdornment>}
          aria-describedby='outlined-weight-helper-text'
          inputProps={{
            'aria-label': 'weight',
          }}
          value={props.value === null ||  props.value === undefined ? '' : props.value}
        />
        {!!error && (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        )}
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </div>
    );
  }
);

MuiOutlinedInput.displayName = 'MuiOutlinedInput';

export default MuiOutlinedInput;
