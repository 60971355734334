/**
 * Form Select Options
 * @description: used together with React Hook Forms
 * @param
 *
 */

import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
} from 'react';

import { useFormContext } from 'react-hook-form';
import ChevronDownIcon from '@assets/icons/ChevronDown';
import EmptySelect from '@atoms/EmptySelect/empty-select.component';
import FormInput from '@atoms/Fields/ReactFormFields/FormInput/form-input.component';
import ClearButton from '@molecules/ButtonsVariation/ClearButton/clear-button.component';
import SearchSelector from '@organisms/SearchSelector/search-selector.component';

import styles from './form-select-options.module.scss';

const FormSelectOptions = forwardRef((props, ref) => {
  const {
    selectOptions,
    required,
    placeholder,
    errors,
    selectValue,
    canClearValue = true,
    icon,
    onSelectedOption,
    label,
    defaultValue,
    readOnly,
    noItems = 'Currently there are no options available.',
    ...rest
  } = props;
  const { setValue, getValues } = useFormContext();
  const wrapperRef = useRef(null);

  const formProps = rest;

  const [showDropdown, setShowDropdown] = useState(false);
  const [highlightedEl, setHighlightedEl] = useState(-1);
  const [options, setOptions] = useState(selectOptions);
  const [filteredOptions, setFilteredOptions] = useState(selectOptions); // needed for the select search
  const [inputValue, setInputValue] = useState(defaultValue || '');

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const assignDefaultOption = () => {
    let arr = [];
    for (const [key, value] of Object.entries(selectOptions)) {
      arr.push({
        id: key,
        name: value.Name || value,
        code: value.SKU || value.Hint || null,
      });

      if (key == defaultValue) {
        setInputValue(value.Name || selectOptions[key]);

        setValue(formProps.name, key);
      }
    }
    return arr;
  };

  //init
  useEffect(() => {
    if (selectOptions) {
      setOptions(selectOptions);
      setFilteredOptions(assignDefaultOption());
    }
  }, [selectOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDropdown &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showDropdown]);

  // on Form Change
  useEffect(() => {
    if (formProps.value && formProps.value !== '') {
      setInputValue(formProps.value);
    }
  }, [formProps.value]);

  const handleKeyChange = useCallback(
    (e) => {
      e.stopPropagation();
      switch (e.key) {
        case 'ArrowDown': {
          if (!showDropdown) setShowDropdown(true);
          if (highlightedEl >= filteredOptions.length - 1) {
            setHighlightedEl(0);
          } else {
            setHighlightedEl(highlightedEl + 1);
          }
          break;
        }
        case 'ArrowUp': {
          if (highlightedEl <= 0) {
            setHighlightedEl(filteredOptions.length - 1);
          } else {
            setHighlightedEl(highlightedEl - 1);
          }
          break;
        }
        case 'Enter': {
          setShowDropdown(false);
          setValue(formProps.name, filteredOptions[highlightedEl].key || '');
          setValue(rest.name, filteredOptions[highlightedEl].value || '');
          break;
        }
        case 'Escape': {
          setShowDropdown(false);
          setHighlightedEl(-1);
          break;
        }
      }
    },
    [filteredOptions, highlightedEl]
  );

  const search = (value) => {
    let arr = [];
    options.map((el) => {
      if (el.value.toLowerCase().includes(value.toLowerCase())) arr.push(el);
    });

    setFilteredOptions(arr);
  };

  // on dropdown Select
  const selectOption = useCallback((event, option, value) => {
    event && event.stopPropagation();
    setShowDropdown(false);
    setValue(formProps.name, option, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setInputValue(value);
  }, []);

  if (options && !readOnly) {
    if (Object.keys(options).length > 1) {
      return (
        <div className={styles.selectorContainer} ref={wrapperRef}>
          <div
            className={`
              ${styles.select} 
              ${getValues(rest.name) === selectValue ? styles.defaultValue : ''}
            `}
            onClick={() => setShowDropdown(!showDropdown)}
            onKeyDown={handleKeyChange}
            tabIndex={0}>
            <FormInput
              {...rest}
              label={label}
              type={'text'}
              readonly={readOnly}
              placeholder={placeholder}
              required={required}
              disabled={true}
              errors={errors}
              value={
                selectOptions[inputValue]?.Name ||
                selectOptions[inputValue] ||
                inputValue
              }
            />
            <div className={styles.actions}>
              <div style={{ transform: showDropdown ? 'rotate(180deg)' : '' }}>
                <ChevronDownIcon />
              </div>
            </div>
          </div>
          {showDropdown && (
            <div
              className={styles.dropdown}
              onMouseLeave={() => setHighlightedEl(-1)}>
              {options?.length > 9 && (
                <div className={styles.searchWrap}>
                  <SearchSelector
                    onChange={search}
                    isDropdownOpen={showDropdown}
                  />
                </div>
              )}
              {filteredOptions.map((item, index) => (
                <Fragment key={item.id}>
                  {icon && <img src={icon} alt={'select-icon'} />}
                  <div
                    className={`
                      ${item.code ? styles.inventoryOption : ''}
                      ${styles.option} 
                      ${highlightedEl === index ? styles.highlight : ''} 
                    `}
                    onClick={(e) => selectOption(e, item.id, item.name)}
                    onMouseEnter={setHighlightedEl.bind(this, index)}>
                    <p className={styles.name}>{item.name}</p>
                    {item.code && <p className={styles.code}>{item.code}</p>}
                  </div>
                </Fragment>
              ))}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <FormInput
          {...rest}
          label={label}
          type={'text'}
          readonly={true}
          placeholder={noItems}
          disabled={true}
          required={required}
          value={inputValue}
          errors={errors}
        />
      );
    }
  } else {
    return (
      <FormInput
        {...rest}
        label={label}
        type={'text'}
        readonly={true}
        placeholder={noItems}
        disabled={true}
        required={required}
        value={options[inputValue]?.Name || options[inputValue] || inputValue}
        errors={errors}
      />
    );
  }
});

FormSelectOptions.displayName = 'FormSelectOptions';

export default FormSelectOptions;
