import { ActionTypes } from './types';

export const showLoaderAction = (payload) => ({
  type: ActionTypes.ShowLoader,
  payload: payload,
});
export const setUserInfoAction = (payload) => ({
  type: ActionTypes.SetUserInfo,
  payload: payload,
});
export const setActivePageAction = (payload) => ({
  type: ActionTypes.SetActivePage,
  payload: payload,
});
export const setDefaultContactSupport = (payload) => ({
  type: ActionTypes.SetDefaultContactSupport,
  payload: payload,
});
