import React, { useState, useEffect, useCallback } from 'react';
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import StepsList from '../../DealSteps/StepsList';
import StepInputs from '../../DealSteps/StepInputs';
import dealsService from '@lib/services/deals-service';
import documentsService from '@lib/services/documents-service';
import { useWindowSize } from '@lib/hooks/useWindowSize';
import {
  ManageDealProvider,
  useManageDealContext,
} from '@lib/Context/ManageDealContext/manage-deal.context';
import { useAppStore } from '@lib/Context';
import { setUserInfoAction } from '@lib/Context/actions';
import { usePopup } from '@lib/hooks/usePopup';
import { setDefaultContactSupport } from '@lib/Context/actions';
import Button from '@atoms/Button/button.component';
import LoadingSpinner from '@molecules/LoadingSpinner/loading-spinner.component';
import StepperV1 from '@molecules/Steppers/StepperV1/stepper-v1.component';
import MessagesList from '@organisms/Messages/MessagesList/messages-list.component';
import ServicesManagement from '@organisms/ServicesManagement/services-management.component';
import DealInnerCard from '@organisms/DealInnerCard/deal-inner-card.component';
import Popup from '@organisms/Popup/popup.component';
import OpenMessages from '@organisms/Messages/OpenMessages/open-messages.component';
import DocumentsSection from '@organisms/Documents/DocumentsSection/documents-section.component';

import './manage-deal.styles.scss';

const ManageDealComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const {
    setDeal: setDealContext,
    setSelectedStep: setSelectedStepContext,
    setDocuments: setDealDocumentsContext,
    setServicesFees: setServicesFeesContext,
    canWrite,
    setCanWrite,
    channel,
    setChannel,
    allDealDocuments,
    setAllDealDocuments,
  } = useManageDealContext();
  const { showPopup } = usePopup();
  const { dispatch } = useAppStore();

  const [deal, setDeal] = useState({});
  const [steps, setSteps] = useState({});
  const [stepsArray, setStepsArray] = useState([]);
  const [stepInputs, setStepInputs] = useState([]);
  const [stepActions, setStepActions] = useState([]);
  const [selectedStep, setSelectedStep] = useState({});
  const [stepSettings, setStepSettings] = useState({});
  const [documents, setDocuments] = useState([]);
  const [alert, setAlert] = useState({});
  const [refreshMessages, setRefreshMessages] = useState(1);
  //const [allDealDocuments, setAllDealDocuments] = useState([]);
  const [products, setProducts] = useState();
  const [samples, setSamples] = useState([]);
  const [updateAPR, setUpdateAPR] = useState(false);
  const [initStep, setInitStep] = useState();
  const [loading, setLoading] = useState(false);
  const [pcInfo, setPcInfo] = useState([]);
  const [selectedStepperStep, setSelectedStepperStep] = useState(0);
  const [aprStep, setAprStep] = useState({});
  const [showServicesManagement, setShowServicesManagement] = useState(false);
  const [servicesFees, setServicesFees] = useState([]);
  const [activePage, setActivePage] = useState('steps');
  const [activeService, setActiveService] = useState('Almacena');
  const [hideStepper, setHideStepper] = useState(false);
  //const [channel, setChannel] = useState('deal');

  // //Get the URL, and if there is a param named step with a valid stepId, than redirect the user
  // useEffect(() => {
  //   const stepId = searchParams.get('step') || initStep?.stepId;
  //   stepId && selectStep(stepId);
  // }, []);

  useEffect(() => {
    if (updateAPR) {
      selectStep(selectedStep);
      setUpdateAPR(false);
    }
  }, [updateAPR]);

  useEffect(() => {
    if (activePage !== 'orders') {
      setChannel('deal');
    }
  }, [activePage]);

  // Renders the step that's currently in process by the backend
  const renderOngoingStep = () => {
    if (!params?.id) {
      return;
    }

    setLoading(true);
    setHideStepper(false);

    dealsService
      .getDealSteps(params.id)
      .then(async (r) => {
        await selectStep(r.data.items[0].Deal?.Step?.Current?.Id);
        setSelectedStepperStep(r.data.items[0].Deal?.Step?.Current?.Id);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!params?.id) {
      return;
    }
    setLoading(true);
    setHideStepper(false);

    dealsService
      .getDealSteps(params.id)
      .then(async (r) => {
        const steps = Object.values(r.data.items[0].Steps).reduce(
          (acc, curr) => {
            return [...acc, ...curr];
          },
          []
        );

        // assign the APR step.
        const apr = r.data.items[0].Steps['Pre-Execution'].filter(
          (tempStep) => {
            if (tempStep.Abbreviation === 'APR') {
              return tempStep;
            }
          }
        );

        dispatch(setUserInfoAction(r.data.user));
        setAprStep(apr);
        setSteps(r.data.items[0].Steps);
        setStepsArray(steps);
        setDealDocumentsContext(r.data.items[0].Documents);
        setDealContext(r.data.items[0].Deal);
        setSelectedStepperStep(r.data.items[0].Deal?.Step?.Current?.Id);
        setDeal(r.data.items[0].Deal);
        setCanWrite(r.data.items[0].Deal?.CanWriteInDeal);

        const stepId = searchParams.get('step');
        if (stepId) {
          await selectStep(stepId);
        } else if (state && state?.stepId) {
          await selectStep(state?.stepId);
        } else {
          await selectStep(r.data.items[0].Deal?.Step?.Current?.Id);
        }

        if (state && state.dealEdited) {
          navigate({ state: null });
          showPopup({
            state: 'success',
            message: t('Deal {{id}} is updated successfully!', {
              id: r.data.items[0].Deal.Title,
            }),
            timer: 4000,
          });
        }

        if (state && state.showDocuments) {
          await getAllDealDocumentsHandler(r.data.items[0].Deal.Id);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const renderServicesFee = (dealId) => {
    setLoading(true);

    dealsService
      .getServicesData(dealId)
      .then((r) => {
        setServicesFeesContext(r.data.items);
        setServicesFees(r.data.items);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectStep = (step) => {
    setAllDealDocuments([]);
    setShowServicesManagement(false);
    setLoading(true);
    setHideStepper(false);

    const pageParam = searchParams.get('paymentReturn');

    return dealsService
      .getStepDetails(params.id, step?.Id || step, pageParam)
      .then((r) => {
        dispatch(setDefaultContactSupport(r.data.DefaultContactSupport));
        setSelectedStepContext(r.data.items[0].Step);
        setSelectedStep(r.data.items[0].Step);
        setDocuments(r.data.items[0].Documents);

        if (r.data.items[0].PriceConfirmations) {
          const pcDocuments = r.data.items[0].PriceConfirmations.reduce(
            (acc, curr) => {
              return [...acc, curr.Documents[0]];
            },
            []
          );

          const pcSettings = r.data.items[0].PriceConfirmations.reduce(
            (acc, curr) => {
              return [...acc, curr.Settings];
            },
            []
          );

          setDealDocumentsContext(pcDocuments);
          setStepSettings(pcSettings);
        } else {
          setDealDocumentsContext(
            r.data.items[0].Documents ||
              (r.data.items[0].Samples && r.data.items[0].Samples[0].Documents)
          );
          setStepSettings(
            r.data.items[0].Settings ||
              (r.data.items[0].Samples && r.data.items[0].Samples[0].Settings)
          );
        }

        setStepInputs(r.data.items[0].Fields);
        setProducts(r.data.items[0].Products);
        setSamples(r.data.items[0].Samples);
        setStepActions(r.data.items[0]?.Buttons || []);
        setPcInfo(r.data.items[0].PriceConfirmations);
      })
      .finally(() => {
        // remove the paymentReturn parameter from the url after sending it to the endpoint
        if (pageParam) {
          searchParams.delete('paymentReturn');
          setSearchParams(searchParams);
        }

        setLoading(false);
      });
  };

  const updateStepList = (currentStep) => {
    dealsService.getDealSteps(params.id).then((r) => {
      const steps = Object.values(r.data.items[0].Steps).reduce((acc, curr) => {
        return [...acc, ...curr];
      }, []);

      setSteps(r.data.items[0].Steps);
      setStepsArray(steps);
      setRefreshMessages(refreshMessages + 1);
      if (currentStep) {
        setInitStep(currentStep);
      } else {
        setInitStep({ stepId: r.data.items[0].Deal?.Step?.Current?.Id }); // get the current step of the deal
      }
    });
  };

  const getAllDealDocumentsHandler = (dealId) => {
    setHideStepper(false);

    return documentsService.getAllDealDocuments(dealId).then(({ data }) => {
      setSelectedStep({});
      setAllDealDocuments(data.items.Steps);
      setShowServicesManagement(false);
    });
  };

  const backToOverview = () => {
    if (state) {
      state.stepId = null;
    }
    setShowServicesManagement(false);
    setSelectedStep({});
    setAllDealDocuments([]);
    setHideStepper(false);
  };

  const selectAprStep = () => {
    selectStep(aprStep[0].Id);
    setHideStepper(true);
  };

  const selectDefaultStep = (Id) => {
    setSelectedStepperStep(Id);
    selectStep(Id);
    setActivePage('steps');
  };

  const renderSelectedStep = () => {
    return (
      <>
        <StepInputs
          inputs={stepInputs}
          step={selectedStep}
          archivedDeal={!!deal.Archived}
          stepActions={stepActions}
          stepSettings={
            Array.isArray(stepSettings) ? stepSettings[0] : stepSettings
          }
          loadStep={selectStep}
          reloadStepList={(currentStep) => {
            updateStepList(currentStep);
          }}
          products={products}
          samples={samples}
          pcInfo={pcInfo}
          setUpdateAPR={setUpdateAPR}
          reloadMessages={() => {
            setRefreshMessages(refreshMessages + 1);
          }}
          documents={documents}
          canWrite={deal.CanWriteInDeal}
        />
      </>
    );
  };

  const renderServicesManagement = () => {
    renderServicesFee(deal.Id);
    setShowServicesManagement(true);
  };

  const handleServicesFeeChange = (value) => {
    renderServicesFee(deal.Id);
    setServicesFees((prevFees) => ({ ...prevFees, Value: value }));
  };

  const renderDocumentSection = useCallback(() => {
    return (
      <DocumentsSection
        showAllDocuments={true}
        documents={allDealDocuments}
        canWrite={deal.CanWriteInDeal}
        getAllDealDocumentsHandler={getAllDealDocumentsHandler}
      />
    );
  }, [allDealDocuments]);

  return (
    <div className='manage-deal-screen'>
      <div className='main-page-container'>
        <Popup message={alert.message} state={alert.state} />
        {loading ? (
          <div className='manage-deal-screen loader'>
            <div style={{ minHeight: '50vh' }}>
              <LoadingSpinner />
            </div>
          </div>
        ) : (
          <div className='deal-information'>
            <DealInnerCard
              deal={deal}
              handleManagementClick={selectAprStep}
              handleOngoingStepClick={renderOngoingStep}
              getAllDealDocuments={getAllDealDocumentsHandler.bind(
                this,
                deal.Id
              )}
              backToOverview={backToOverview}
              setActivePage={setActivePage}
              activePage={activePage}
              handleServicesOrders={(items) => {
                setServicesFeesContext(items);
                setServicesFees(items);
                setShowServicesManagement(true);
                setActivePage('orders');
              }}
              setLoading={setLoading}
            />
            {!selectedStep.Id &&
              !allDealDocuments.length &&
              !showServicesManagement &&
              (!state || (state && !state.stepId)) && (
                <StepsList
                  dealId={deal.Id}
                  archivedDeal={!!deal.Archived}
                  currentStep={selectedStep}
                  steps={steps}
                  selectStepHandler={selectDefaultStep}
                  reloadStepList={() => {
                    updateStepList();
                  }}
                  reloadMessages={() => {
                    setRefreshMessages(refreshMessages + 1);
                  }}
                />
              )}

            {showServicesManagement ? (
              <div className='step-details-container'>
                <div className={'step-title__container'}>
                  <ServicesManagement
                    serviceFields={servicesFees}
                    dealId={deal.Id}
                    dealDate={deal.CreateDate}
                    onChange={handleServicesFeeChange}
                    onBack={() => setShowServicesManagement(false)}
                    setActiveService={setActiveService}
                    //canWrite={deal.CanWriteInDeal}
                    //channel={channel}
                    //setChannel={setChannel}
                  />
                </div>
              </div>
            ) : (
              (!!selectedStep.Id || !!allDealDocuments.length) && (
                <div className='step-details-container'>
                  {!hideStepper && (
                    <div className={'step-title__container'}>
                      <StepperV1
                        steps={stepsArray}
                        selectStep={selectStep}
                        selectedStepperStep={selectedStepperStep}
                        setSelectedStepperStep={setSelectedStepperStep}
                      />
                    </div>
                  )}

                  {selectedStep.Id &&
                    !allDealDocuments.length &&
                    renderSelectedStep()}
                  {allDealDocuments.length > 0 && renderDocumentSection()}
                </div>
              )
            )}
          </div>
        )}

        {width > 1160 ? (
          <MessagesList
            refresh={refreshMessages}
            archived={deal.Archived === 0 ? false : true}
            stepId={selectedStep.Id}
            //canWrite={deal.CanWriteInDeal}
            activePage={activePage}
            activeService={activeService}
            //channel={channel}
            //setChannel={setChannel}
          />
        ) : (
          <OpenMessages canWrite={deal.CanWriteInDeal} channel={channel} />
        )}
      </div>
    </div>
  );
};

export default ManageDealComponent;
