import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import Button from '@atoms/Button/button.component';
import PopupLayout from '@organisms/Popup/PopupLayout/popup-layout.component';
import CheckboxesChooseArea from '@molecules/CheckboxesChooseArea/checkboxes-choose-area.component';
import { useDealsContext } from '@lib/Context/Deals/deals.context';

const ManageColumns = () => {
  const { columns, setColumns } = useDealsContext();
  const { t } = useTranslation();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const checkboxes = [
    {
      name: 'origin',
      label: t('Origin'),
    },
    {
      name: 'product',
      label: t('Product'),
    },
    {
      name: 'pricePerUnit',
      label: t('Price per unit'),
    },
    {
      name: 'incoterms',
      label: t('Incoterms'),
    },
    {
      name: 'dealStatus',
      label: t('Deal Status'),
    },
    {
      name: 'priceType',
      label: t('PriceType'),
    },
    {
      name: 'contract',
      label: t('Contract'),
    },
    {
      name: 'vendorName',
      label: t('Vendor Name'),
    },
    {
      name: 'deliveryDate',
      label: t('Delivery Date'),
    },
    {
      name: 'totalVolume',
      label: t('Total Volume'),
    },
    {
      name: 'totalPrice',
      label: t('Total Price'),
    },
    {
      name: 'creationDate',
      label: t('Creation Date'),
    },
    {
      name: 'documents',
      label: t('Documents'),
    },
    {
      name: 'requestFinance',
      label: t('Services'),
    }
  ];

  // Get the Filtered Columns from the LocalStore.
  useEffect(() => {
    if (localStorage.getItem('list-filters')) {
      const storedFilters = localStorage.getItem('list-filters').split(',');
      if (storedFilters.length > 0) {
        setColumns(storedFilters);
      }
    }
  }, []);

  return (
    <div>
      <Button
        type={'link'}
        onClick={() => {
          setIsPopupOpen(!isPopupOpen);
        }}>
        {t('Manage Columns')}
      </Button>
      {isPopupOpen && (
        <PopupLayout
          closePopUp={setIsPopupOpen}
          popupTitle={t('Manage Columns')}>
          <CheckboxesChooseArea
            items={checkboxes}
            setResult={setColumns}
            result={columns}
          />
        </PopupLayout>
      )}
    </div>
  );
};

export default ManageColumns;
