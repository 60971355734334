import React, { useState, memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import { useTranslation } from 'react-i18next';

import DealInfo from './DealInfo/deal-info.component';
import DealProducts from './DealProducts/deal-products.component';
import DealProgress from './DealProgress/deal-progress.component';
import dealsService from '@services/deals-service';
import { formatPrice, formatVolume } from '@lib/helpers/helpers';
import { useAppStore } from '@lib/Context';
import { useUserRole } from '@lib/hooks/useUserRole';
import { truncateSentence } from '@lib/helpers/truncate-sentence.helper';
import ArrowDownIcon from '@assets/icons/ArrowDownIcon';
import Button from '@atoms/Buttons/Button';
import TooltipComponent from '@atoms/Tooltip/tooltip.component';
import DeleteButton from '@molecules/DeleteButton/delete-button.component';
import NotificationIcon from '@molecules/NotificationIcon/notification-icon.component';

import styles from './deal-card-v2.module.scss';
import ImportIcon from "@molecules/ImportIcon/import-icon.component";
import ExportIcon from "@molecules/ExportIcon/export-icon.component";
import AttentionIcon from "@molecules/AttentionIcon/attention-icon.component";
import GppGoodIcon from '@mui/icons-material/GppGood';

const DealCardV2 = ({ deal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAdmin, isTrader, isOrgAdmin } = useUserRole();
  const [stepId, setStepId] = useState();
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [disableTooltip, setDisableTooltip] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [animate, setAnimate] = useState(false);

  const truncateWidth = 55;

  const showMoreProducts = (event) => {
    event.stopPropagation();
    setAnimate(true);
    setToggle(!toggle);
  };

  const dealInfo = useMemo(() => {
    const { Price, PriceCurrency, Volume, VolumeUnit } = deal.Totals;
    const price =
      Price && PriceCurrency ? formatPrice(Price, PriceCurrency) : '';
    const volume =
      Volume && VolumeUnit
        ? price
          ? `| ${formatVolume(Volume, VolumeUnit)}`
          : `${formatVolume(Volume, VolumeUnit)}`
        : '';
    const seller = deal.Seller
      ? volume
        ? `| ${deal.Seller}`
        : deal.Seller
      : '';
    const sellerUser = deal.SellerUser
      ? seller
        ? `| ${deal.SellerUser}`
        : deal.SellerUser
      : '';

    return `${price} ${volume} ${seller} ${sellerUser}`;
  }, [
    deal.Price,
    deal.PriceCurrency,
    deal.Seller,
    deal.Volume,
    deal.VolumeUnit,
  ]);

  const navigateToDeal = (event) => {
    navigate(`/manage-deal/${deal?.Id}`, {
      state: { showArchivedDeals: deal?.Archived, stepId },
    });
  };

  //On Init get the stepId
  useEffect(() => {
    if (deal?.Step?.Current) {
      setStepId(deal.Step?.Current?.Id);
    }
  }, [deal?.Step?.Current]);

  useEffect(() => {
    if (!deal.DraftStatus) {
      if (deal.DraftStatus === null) {
        setDisableTooltip(true);
        return;
      }
      setTooltipMessage(t('Syncing for identifier from the platform'));
    } else {
      setTooltipMessage(t('Set Buyer and Seller to exit Draft status'));
    }
  }, [deal.DraftStatus]);

  const deleteDeal = () => {
    dealsService.deleteDeal(deal.Id).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className={`${styles.container} ${deal.UserTypeInDeal === 'seller' ? styles.sellerBorder : (deal.UserTypeInDeal === 'buyer' ? styles.buyerBorder : '')}`}>
      <div className={styles.dealHeading}>
        <div className={styles.headingTop}>
          <div className={styles.iconsWrapper}>
            <NotificationIcon emailsMuted={deal.EmailsMuted} />
            {deal.UserTypeInDeal === 'buyer' &&
            <TooltipComponent text={t("You are a buyer")} disable={false}>
              <ImportIcon />
            </TooltipComponent>
            }
            {deal.UserTypeInDeal === 'seller' &&
            <TooltipComponent text={t("You are a seller")} disable={false}>
              <ExportIcon />
            </TooltipComponent>
            }
          </div>
          <div className={styles.codeDate}>
            <TooltipComponent text={tooltipMessage} disable={disableTooltip}>
              <p className={styles.dealCode}>
                {deal.Code || ''} | {deal.CreateDate || '--'}
              </p>
            </TooltipComponent>
            {(isAdmin || isOrgAdmin) ? (
              <DeleteButton
                style={{ padding: 0 }}
                buttonTitle={t('Delete')}
                confirmationTitle={t(
                  'Are you sure you want to delete this deal?'
                )}
                agreementHandler={() => deleteDeal()}
              />
            ) : null}
          </div>
        </div>
        <div className={styles.dealInfoContainer}>
          <div className={styles.dealTitleContainer}>
            <p className={`${styles.dealTitle} ${styles.lineClamp}`}>
              {deal?.Title}
            </p>
            {deal.BlockchainVerification && <GppGoodIcon className={styles.verifiedIcon}/>}
            {deal.RequiresActionBy[deal.UserTypeInDeal] &&
            <TooltipComponent text={t("Action Needed")} disable={false}>
              <AttentionIcon />
            </TooltipComponent>
            }
          </div>
          <div className={styles.dealMoreInfo}>
            <TooltipComponent
              text={dealInfo}
              disable={dealInfo.length <= truncateWidth}>
              <p className={styles.info}>
                {truncateSentence(dealInfo, truncateWidth)}
              </p>
            </TooltipComponent>

            {!!deal.Products && (
              <div
                className={`${styles.arrowIcon} ${
                  toggle ? styles.transform : ''
                }`}
                onClick={showMoreProducts}>
                <ArrowDownIcon height={12} width={12} />
              </div>
            )}
          </div>
        </div>
      </div>

      <DealProgress dealStep={deal.Step} navigateToDeal={navigateToDeal} />

      {toggle ? (
        <DealProducts products={deal.Products} />
      ) : (
        <DealInfo deal={deal} animate={animate} />
      )}

      <div className={styles.actions}>
        <Button type='empty' size='medium' onClick={navigateToDeal}>
          {deal?.Archived ? t('View Deal') : t('Deal Details')}
        </Button>
      </div>
    </div>
  );
};

export default memo(DealCardV2);
