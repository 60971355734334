import React, {
  useState,
  useCallback,
  memo,
  useEffect,
  Fragment,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { updateButtons } from 'Components/DealSteps/StepInputs';
import { useStepSettingsContext } from '@lib/Context/StepSettings/step-settings.context';
import dealsService from '@lib/services/deals-service';
import Button from '@atoms/Button/button.component';
import Grid from '@atoms/Grid/DynamicGrid/grid.component';
import TooltipComponent from '@atoms/Tooltip/tooltip.component';
import FormInputFields from '@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component';

import styles from './apr-step-form.module.scss';

const APRStepForm = ({ sample, onSubmit }) => {
  const { t } = useTranslation();
  const { setStepSettingsContext } = useStepSettingsContext();
  const params = useParams();

  const [sampleButtons, setSampleButtons] = useState([]);
  const [inventoryLotsOptions, setInventoryLotsOptions] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);

  const defaultFieldsValue = useMemo(() => {
    const fields = sample?.Fields.reduce((acc, curr) => {
      acc[curr.Key] = curr.Value || '';
      return acc;
    }, {});

    return fields;
  }, [sample]);

  const form = useForm({
    defaultValues: {
      ...defaultFieldsValue,
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = form;

  const watchAllFields = watch();

  //reset form on sample change
  useEffect(() => {
    reset(defaultFieldsValue);
  }, [defaultFieldsValue]);

  useEffect(() => {
    const newButtons = isDirty ? updateButtons(sampleButtons) : sampleButtons;

    setSampleButtons(newButtons);
  }, [isDirty]);

  useEffect(() => {
    if (sample?.Fields) {
      const required = sample.Fields.filter(field => field.Required).map(field => field.Key);
      setRequiredFields(required);
    }
  }, [sample]);

  useEffect(() => {
    if (sample && sample?.Buttons) {
      setSampleButtons(sample?.Buttons);
    } else {
      setSampleButtons([]);
    }

    setStepSettingsContext(sample.Settings);
  }, [sample]);

  const onSubmitHandler = useCallback(
    (buttonType, currentStepId, formData, settings) => {
      onSubmit({
        ...formData,
        ButtonType: buttonType,
        currentStepId,
        settings: settings,
      });
      reset(formData);
    },
    [onSubmit, reset]
  );

  const fieldsGrid = (key) => {
    switch (key) {
      default:
        return 12;
    }
  };

  const onChangeProductHandler = useCallback(
    (value) => {
      if (!value) {
        return;
      }
      dealsService
        .getInventoryLotsByProductId({
          dealId: params.id,
          productId: value,
        })
        .then(({ data }) => {
          setInventoryLotsOptions(JSON.parse(data));
        });
    },
    [params.id]
  );

  const renderField = useCallback((item) => {
    return (
        <Controller
          name={item.Key}
          rules={{
            required: {
              value: !!item.Required,
              message: t('Field is required'),
            },
          }}
          defaultValue={item.Value}
          render={({ field }) => {
            if (field.name === 'SampleInventoryLotId') {
              item.Options = inventoryLotsOptions || item.Options;
            }
            return (
              <Grid columns={fieldsGrid(item.Key)}>
                <FormInputFields
                  info={{ ...field }}
                  fields={item}
                  required={item.Required}
                  onChange={(event) => {
                    if (field.name == 'ProductId') {
                      onChangeProductHandler(event.target.value);
                    }
                    field.onChange(event);
                  }}
                  error={errors[item.Key]}
                  disabled={!!item.Readonly || !!sample.Step.Done}
                />
              </Grid>
            );
          }}
        />
      );
    },
    [inventoryLotsOptions, onChangeProductHandler, sample]
  );

  const buttonsColor = (buttonType) => {
    switch (buttonType) {
      case 'saveDraft':
        return `quaternary`;
      case 'rejected':
        return `tertiary`;
      case 'saveForceComplete':
      case 'saveForceApprove':
        return `senary`;
      default:
        return 'primary';
    }
  };

  const renderButton = (button) => {
    const allRequiredFieldsFilled = requiredFields.every((field) => watchAllFields[field] && !errors[field]);

    return (
      <TooltipComponent key={button.Name} text={t(button.Text)}>
        <Button
          onClick={handleSubmit((formData) => {
            onSubmitHandler(
              button.Type,
              sample?.Step?.Id,
              formData,
              sample?.Settings
            );
          })}
          size={'xl'}
          btnColor={buttonsColor(button.Type)}
          disabled={
            !allRequiredFieldsFilled ||
            Object.keys(errors).length > 0
              ? true
              : button.Disable
              ? true
              : false
          }>
          {t(button.Name)}
        </Button>
      </TooltipComponent>
    );
  };

  return (
    <FormProvider {...form}>
      {sample?.Fields.map((formField, i) => (
        <Fragment key={i}>{renderField(formField)}</Fragment>
      ))}
      <div>
        <div className={styles.saveButtons}>
          {!!sampleButtons?.length && sampleButtons.map(renderButton)}
        </div>
      </div>
    </FormProvider>
  );
};

export default memo(APRStepForm);
