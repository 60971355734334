import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';

import AttachmentIcon from '@assets/icons/Attachment';
import documentsService from '@lib/services/documents-service';

import styles from './message-files.module.scss';

const MessageFiles = ({ files, message }) => {
  const { t } = useTranslation();

  const showFile = (file) => {
    if (file.FilePath.split('.').pop() === 'pdf')
      window.open(`/download/${file.FilePath}`);
    else {
      documentsService.getDocument(file.FilePath).then((r) => {
        const blobFile = new Blob([r.data]);
        const fileURL = URL.createObjectURL(blobFile);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', file.FileName);
        link.click();
      });
    }
  };
  return (
    <div className={styles.filesContainer}>
      {files.map((file, index) => {
        return (
          <div
            key={index}
            className={styles.file}
            onClick={() => {
              showFile(file);
            }}>
            <div className={styles.fileName}>
              <AttachmentIcon />
              {file.FileName?.length > 25 ? (
                <Tooltip
                  enterTouchDelay={0}
                  title={
                    <Typography style={{ fontSize: 13 }}>
                      {file.FileName}
                    </Typography>
                  }>
                  <p className={styles.fileNameText}>{file.FileName}</p>
                </Tooltip>
              ) : (
                <p className={styles.fileNameText}>{file.FileName}</p>
              )}
            </div>
            <div className={styles.fileInformation}>
              {!!file.Date && (
                <>
                  {t('Uploaded')} {file.Date}
                </>
              )}
              {!!message.Sender && (
                <>
                  {t('By')} {message.Sender}
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MessageFiles;
