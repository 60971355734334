import {useCallback, useEffect, useRef} from "react";

const PageButtonInteraction = ({children, setKeyPressed}) => {
  const elementRef = useRef();

  useEffect(() => {
    elementRef.current.focus();
  }, []);

  const handleKeyChange = useCallback(
    (e) => {
      e.stopPropagation();
      setKeyPressed(e.key)
    }, []
  );

  return (
    <div onKeyDown={handleKeyChange} tabIndex={0} ref={elementRef} id={"page-interaction"}>
      <div>
        {children}
      </div>
    </div>
  )
}

export default PageButtonInteraction;
