/**
 * Page Template
 * description: used to define the styles for each page.
 * @props children
 */

import styles from "./page-container.module.scss";

const PageContainer = ({children}) => {
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        {children}
      </div>
    </div>
  )
}

export default PageContainer;
