import {stepStatusApproved, stepStatusPendingApproval} from "@base/globalVariables/global-variables";

export function stepStatusCompleted(stepStatus) {
  if(stepStatus === stepStatusPendingApproval ||
    stepStatus === stepStatusApproved) {
    return true;
  } else {
    return false
  }
}
