import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import NotificationIcon from '@molecules/NotificationIcon/notification-icon.component';
import Button from '@atoms/Button/button.component';
import { useAppStore } from '@lib/Context';
import '@css-variables';

const Component = ({ onSubmit }) => {
  const { t } = useTranslation();

  const { state: appState } = useAppStore();

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Box typography={'h5'} sx={{ fontWeight: 'bold' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <NotificationIcon emailsMuted={false} />
              {t('You have 0 remaining DocuSign document signatures')}
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Trans
          i18nKey='NoRemainingDocuSignDocumentSignatures'
          values={{ contact: appState?.userInfo?.defaultContactSupport?.Email }}>
          <span>
            If you need to increase DocuSign document signatures, please contact
            Almacena Administrator over
          </span>{' '}
          <a
            style={{ color: $warm-blue, textDecoration: 'none' }}
            href={`mailto:${appState?.userInfo?.defaultContactSupport?.Email}`}>
            <strong>{appState?.userInfo?.defaultContactSupport?.Email}</strong>
          </a>
        </Trans>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          size={'large'}
          onClick={onSubmit}
          btnColor={'tertiary'}>
          {t('Close')}
        </Button>
      </Grid>
    </Grid>
  );
};

export const RemainingSignatures = memo(Component);
