import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import dealsService from '@lib/services/deals-service';
import ConfirmationPopup from '@organisms/Popup/ConfirmationPopup/confirmation-popup.component';

import { useNavigation } from '@lib/hooks/useNavigation';

import styles from './complete-cancel-deal.module.scss';

const CompleteCancelDeal = ({dealName, dealId, dealPopup, setCompleteOrCancelDeal}) => {
  const { t } = useTranslation();
  const { redirect } = useNavigation();

  const [showDealPopup, setShowDealPopup] = useState(false);
  const [confirmationLoader, setConfirmationLoader] = useState(false);

  useEffect(() => {
    if (!!dealPopup) {
      setShowDealPopup(true);
    }
  }, [dealPopup])
  

  const completeDeal = () => {
    setConfirmationLoader(true);
    dealsService
      .completeDeal(dealId, 2)
      .then(() => redirect('/'))
  };

  const cancelDeal = () => {
    setConfirmationLoader(true);
    dealsService
      .completeDeal(dealId, 3)
      .then((r) => redirect('/'))
  };

  return (
    <>
      {showDealPopup && (
        dealPopup === 'complete' ? (
          <ConfirmationPopup
            message={
              <Trans i18nKey='MarkAsCompleted' values={{ name: dealName }} />
            }
            loader={confirmationLoader}
            cancelationHandler={() => {
              setShowDealPopup(false);
              setCompleteOrCancelDeal(false);
            }}
            agreementHandler={completeDeal}
          />
        ) : dealPopup === 'cancel' ? (
          <ConfirmationPopup
            message={
              <Trans i18nKey='MarkAsCancelled' values={{ name: dealName }} />
            }
            loader={confirmationLoader}
            cancelationHandler={() => {
              setShowDealPopup(false);
              setCompleteOrCancelDeal(false);
            }}
            agreementHandler={cancelDeal}
          />
        ) : null
      )}

    </>
  )
}

export default CompleteCancelDeal;
