import { formatCountryName } from '@lib/helpers/helpers';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './deal-info.module.scss';

const DealInfo = ({ deal, animate }) => {
  const { t } = useTranslation();

  const buyer = useMemo(() => {
    const buyerUser = deal.BuyerUser ? deal.BuyerUser : '';
    const buyerCompany = deal.Buyer
      ? buyerUser
        ? `(${deal.Buyer})`
        : deal.Buyer
      : '';
    return `${buyerUser} ${buyerCompany}`;
  }, [deal.Buyer, deal.BuyerCompany]);

  const incoterms = useMemo(() => {
    return deal.PriceTerms;
  }, [deal.PriceTerms]);

  const lot = useMemo(() => {
    const lotCode = deal.LotCode ? deal.LotCode : '';
    const lotCreateDate = deal.LotCreateDate ? `| ${deal.LotCreateDate}` : '';
    return `${lotCode} ${lotCreateDate}`;
  }, []);

  return (
    <div className={`${styles.details}  ${animate ? styles.visible : ''}`}>
      <div className={styles.detailsItem}>
        <span>{t('Lot')}:</span>
        <p> {deal.LotId || deal.LotDate ? lot : ' -- '}</p>
      </div>
      <div className={styles.detailsItem}>
        <span>{t('Origin')}:</span>
        <p>
          {deal.LotOriginCountry
            ? formatCountryName(deal.LotOriginCountry)
            : ' -- '}
        </p>
      </div>
      <br />
      <div className={styles.detailsItem}>
        <span>{t('Buyer')}:</span>
        <p>{deal.Buyer || deal.BuyerCompany ? buyer : ' -- '}</p>
      </div>

      <div className={styles.detailsItem}>
        <span>{t('Incoterms')}:</span>
        <p>{deal.PriceTerms ? incoterms : ' -- '}</p>
      </div>
      <div className={styles.detailsItem}>
        <span>{t('Expire')}:</span>
        <p> {deal.ExpireDate ? deal.ExpireDate : ' -- '}</p>
      </div>
    </div>
  );
};

export default memo(DealInfo);
