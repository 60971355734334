import {memo, useCallback} from 'react';
import {useTranslation} from "react-i18next";

const DealStatus = ({ value, pending }) => {
  const { t } = useTranslation();

  const renderMessage = useCallback(() => {
    if(pending) {
      return <>{t('PendingVerification')}</>
    } else {
      return <>{value || ''}</>
    }
  }, [pending, value])

  return <div>{renderMessage()}</div>;
};

export default memo(DealStatus);
