import { Cookies } from 'react-cookie';
import { httpClient } from './httpClient';
import i18next from 'i18next';
import en from '../i18n/en.json';
const cookies = new Cookies();

const localizationService = {
  getLocalization: function (language) {
    const keys = Object.keys(en);
    return httpClient
      .post(
        `api-common/localization`,
        { keys, cultures: [language] },
        {
          headers: {
            'Accept-Language': language || 'en',
          },
        }
      )
      .then((response) => {
        const serverLanguage = response.data?.resources?.[language];
        localStorage.setItem(language, JSON.stringify(serverLanguage));
        // localStorage.setItem('language', language);

        return serverLanguage;
      });
  },
  changeLanguage: function (language) {
    i18next.changeLanguage(language).then(() => {
      cookies.set('i18next', language, { path: '/'});
      localStorage.setItem('i18nextLng', language);
      const savedLanguage = localStorage.getItem(language);

      // Getting localization is needed for a backend to know
      // the last selected language so that it translates messages dynamically
      // and sends emails in the proper language.
      // There is a check if has stored language in order to avoid double requesting the endpoint because
      // once has called when i18next is initializing
      if (savedLanguage) {
        return this.getLocalization(language).then(() =>
          window.location.reload()
        );
      }

      // Reloading the window is needed for translated the system messages
      return window.location.reload();
    });
  },
  clearDictionary: function () {
    localStorage.removeItem('en');
    localStorage.removeItem('fr');
    localStorage.removeItem('es');
  },
  refreshDictionary: function () {
    const language = cookies.get('i18next');
    this.clearDictionary();
    this.getLocalization(language);
  },
};

export default localizationService;
