/**
 * Info component
 * description: showing a simple information instead of an input
 * @props: item: can be object with Value inside or just Value
 */

import styles from './info.module.scss';

const Info = ({ item }) => {
  const localValue = item.Value || item;

  return (
    <div className={styles.container}>
      {localValue.split('\n').map((text, i) => (
        <p key={i}>{text}</p>
      ))}
    </div>
  )
}

export default Info;
