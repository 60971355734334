/**
 * Add Lot Popup component
 * @param setOpenLotPopup: the view state of the popup which is controlled by the parent
 * @param productIndex: the index of the current product.
 */

import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Controller, useForm, FormProvider} from "react-hook-form";

import {usePopup} from "@lib/hooks/usePopup";
import {useManageDealContext} from "@lib/Context/ManageDealContext/manage-deal.context";
import dealsService from "@services/deals-service";
import Heading from "@atoms/Headings/heading.component";
import Grid from "@atoms/Grid/DynamicGrid/grid.component";
import FormInputFields from "@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component";
import Portal from "@molecules/Portal/portal.component";
import NotificationIcon from "@molecules/NotificationIcon/notification-icon.component";
import PopupLayout from "@organisms/Popup/PopupLayout/popup-layout.component";
import styles from "@organisms/Products/ProductAccordion/product-accordion.module.scss";
import PageLoader from "@templates/PageLoader/page-loader.template";


const AddLotPopup = ({setOpenLotPopup, productId, productIndex, lotId, icoNumberOptions}) => {
  const { t } = useTranslation();
  const { deal } = useManageDealContext();
  const { showPopup } = usePopup();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm()
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const handleOnConfirmClick = (formData) => {
    dealsService
    .addProductToInventory(deal.Id, productId, formData.icoNumber, lotId)
    .then(() => {
      showPopup({
        state: 'success',
        message: t('LotAddedSuccessfully'),
        timer: 4000,
      });
      setOpenLotPopup(false)
    })
  }

  return (
    <Portal overlay={'overlay'}>
      <PageLoader isLoading={isLoading}>
        {!isLoading && (
          <PopupLayout
            onConfirmClick={
              handleSubmit((formData) => {
                handleOnConfirmClick(formData)
              })
            }
            closePopUp={() => setOpenLotPopup(false)}
            // disabled={disableButton}
            buttons={[
              {
                buttonColor: 'default',
                buttonType: 'fill',
                buttonName: 'Add',
                action: 'addLot',
              }
            ]}
          >
            <Heading type={'h3'}>
              <NotificationIcon emailsMuted={deal.EmailsMuted} />
              <Trans i18nKey='AddingLotPopupTitle' values={{ lot: icoNumberOptions?.isCustomProduct ? icoNumberOptions.Name : deal.Title }} /> 
            </Heading>
            <div className={styles.textWrap}>
              <Trans
                i18nKey='AddingLotPopupDescription'
                values={
                  { volume: deal?.Products[productIndex]?.Volume,
                    volumeUnit: deal?.Products[productIndex]?.VolumeUnit,
                    product: deal?.Products[productIndex]?.Name
                  }
                }
              />
            </div>
            {!!icoNumberOptions.isCustomProduct && (
              <div className={styles.textWrap}>{t('ApplyIcoNumber')}</div>
            )}
            
            <div>
              <FormProvider {...form}>
                <Controller
                  name={'icoNumber'}
                  defaultValue={icoNumberOptions.isCustomProduct === 0 ? icoNumberOptions.Name : ""}
                  render={({ field }) => {
                    return (
                      <Grid columns={12}>
                        <FormInputFields
                          info={{ ...field }}
                          fields={{
                            Type: 'text',
                            Label: t('IcoNumberLabel'),
                            Placeholder: icoNumberOptions.isCustomProduct === 0 ? icoNumberOptions.Name : "",
                          }}
                          onChange={field.onChange}
                          disabled={!icoNumberOptions.isCustomProduct}
                        />
                      </Grid>
                    );
                  }}
                />
              </FormProvider>
            </div>
            <div>
              <Trans i18nKey='LotInventoryMailNotification' />
            </div>
          </PopupLayout>
        )}
      </PageLoader>
    </Portal>
  )
}

export default AddLotPopup;