/**
  * Heading components
  * @props: type: type of the header.
  * @props: children: the text of the heading.
  * @props: position: the positioning of the heading.
  * Usage <Heading type="h1">Text heading</Heading>
*/

import {memo} from "react";

import styles from './heading.module.scss';

function Heading(props) {
  return (
    <props.type className={styles.title}>{props.children}</props.type>
  )
}

export default memo(Heading);
