import React from "react";
import PublishIcon from '@mui/icons-material/Publish';

import styles from "./import-icon.module.scss";

const ImportIcon = () => {
    return (
        <>
        <div className={styles.muteIcon}>
            <PublishIcon className={styles.importIcon}/>
        </div>
        </>
    )
}

export default ImportIcon;