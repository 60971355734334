import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import FormInput from '@atoms/Fields/ReactFormFields/FormInput/form-input.component';
import Heading from '@atoms/Headings/heading.component';

import styles from './edit-title.module.scss';

const EditTitle = ({ title, headingType, setTitle, setErrors }) => {
  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState(false);

  const defaultValues = {
    editTitle: title,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues });

  const requiredField = t('Field cannot be empty');

  useEffect(() => {
    if (isEditMode) {
      setErrors(true);
    } else {
      setErrors(false);
    }
  }, [isEditMode]);

  const changeTitle = (data) => {
    if (!errors['editTitle']) {
      setIsEditMode(false);
      setTitle(data['editTitle']);
    }
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
    switch (e.key) {
      case 'Enter': {
        handleSubmit(changeTitle)();
        break;
      }
    }
  };

  const editMode = () => {
    setIsEditMode(true);
  };

  return (
    <div>
      {isEditMode ? (
        <div className={styles.inputWrap} onKeyDown={handleKeyDown}>
          <Controller
            control={control}
            name='editTitle'
            rules={{
              required: {
                value: true,
                message: requiredField,
              },
            }}
            render={({ field }) => (
              <FormInput
                {...field}
                type={'text'}
                required={false}
                placeholder={t('Enter')}
                error={errors['editTitle']?.message}
                autoFocus
              />
            )}
          />
          <div className={styles.icon} onClick={handleSubmit(changeTitle)}>
            <CheckCircleOutlineIcon />
          </div>
        </div>
      ) : (
        <div className={styles.title}>
          <div className={styles.header}>
            <Heading type={headingType}>{getValues('editTitle')}</Heading>
          </div>
          <div className={styles.icon} onClick={editMode}>
            <BorderColorOutlinedIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditTitle;
