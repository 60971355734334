const MessageIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="black"  xmlns="http://www.w3.org/2000/svg">
            <path d="M0.997333 14.3974C0.733256 14.396 0.480394 14.2904 0.29366 14.1037C0.106927 13.917 0.00140097 13.6641 0 13.4V5.19736C0.00140792 4.65039 0.219317 4.12622 0.606087 3.73945C0.992857 3.35268 1.51703 3.13477 2.064 3.13336H11.7973C12.3452 3.13336 12.8708 3.35065 13.2587 3.73757C13.6466 4.12449 13.8653 4.64946 13.8667 5.19736V10.664C13.8667 11.2119 13.6494 11.7375 13.2625 12.1254C12.8755 12.5133 12.3506 12.7319 11.8027 12.7334H3.68533C3.59314 12.7341 3.50354 12.764 3.42933 12.8187L1.6 14.2C1.42557 14.3289 1.21422 14.3981 0.997333 14.3974V14.3974ZM2.064 4.20003C1.79992 4.20143 1.54706 4.30695 1.36033 4.49369C1.17359 4.68042 1.06807 4.93328 1.06667 5.19736V13.2667L2.78933 11.9654C3.04853 11.7728 3.36247 11.6682 3.68533 11.6667H11.7973C12.0633 11.6667 12.3183 11.5611 12.5063 11.373C12.6944 11.185 12.8 10.9299 12.8 10.664V5.19736C12.7986 4.93328 12.6931 4.68042 12.5063 4.49369C12.3196 4.30695 12.0667 4.20143 11.8027 4.20003H2.064Z"/>
            <path d="M9.03467 8.51474C9.32922 8.51474 9.568 8.27596 9.568 7.9814C9.568 7.68684 9.32922 7.44806 9.03467 7.44806C8.74011 7.44806 8.50133 7.68684 8.50133 7.9814C8.50133 8.27596 8.74011 8.51474 9.03467 8.51474Z"/>
            <path d="M6.96533 8.51474C7.25989 8.51474 7.49867 8.27596 7.49867 7.9814C7.49867 7.68684 7.25989 7.44806 6.96533 7.44806C6.67078 7.44806 6.432 7.68684 6.432 7.9814C6.432 8.27596 6.67078 8.51474 6.96533 8.51474Z"/>
            <path d="M4.832 8.51474C5.12655 8.51474 5.36533 8.27596 5.36533 7.9814C5.36533 7.68684 5.12655 7.44806 4.832 7.44806C4.53745 7.44806 4.29867 7.68684 4.29867 7.9814C4.29867 8.27596 4.53745 8.51474 4.832 8.51474Z" />
            <path d="M15.4667 8.99999C15.3252 8.99999 15.1896 8.9438 15.0895 8.84378C14.9895 8.74376 14.9333 8.60811 14.9333 8.46666V3.064C14.9319 2.79992 14.8264 2.54706 14.6397 2.36033C14.4529 2.17359 14.2001 2.06807 13.936 2.06667H3.2C3.05855 2.06667 2.9229 2.01048 2.82288 1.91046C2.72286 1.81044 2.66667 1.67478 2.66667 1.53333C2.66667 1.39188 2.72286 1.25623 2.82288 1.15621C2.9229 1.05619 3.05855 1 3.2 1H13.936C14.483 1.00141 15.0071 1.21932 15.3939 1.60609C15.7807 1.99286 15.9986 2.51702 16 3.064V8.46666C16 8.60811 15.9438 8.74376 15.8438 8.84378C15.7438 8.9438 15.6081 8.99999 15.4667 8.99999Z" />
        </svg>

    )
}

export default MessageIcon;