import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip } from '@mui/material';
import GppGoodIcon from '@mui/icons-material/GppGood';

import Portal from '@molecules/Portal/portal.component';
import PopupLayout from '@organisms/Popup/PopupLayout/popup-layout.component';

import styles from './success-verification.module.scss';

const Component = ({ status, onClose }) => {
  const { t } = useTranslation();
  
  if (!status || status?.value !== 'Verified') {
    return null;
  }

  return (
    <Portal overlay={'overlay'}>
      <PopupLayout closePopUp={onClose}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Box typography={'h5'} fontWeight={'bold'} className={styles.title}>
              <div>
                <Tooltip
                  title={t('BlockchainRegistered')}
                  placement='top-start'>
                  <GppGoodIcon className={styles.verified} />
                </Tooltip>
              </div>
              {status?.message}
            </Box>
          </Grid>
        </Grid>
      </PopupLayout>
    </Portal>
  );
};

export const SuccessVerified = memo(Component);
