import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

export const useStepSettingsContext = () => {
  const context = useContext(StepSettingsContext);
  if (!context) {
    throw new Error('useStepSettingsContext must be used within a StepSettingsProvider');
  }
  return context;
};

const StepSettingsContext = createContext();

export const StepSettingsProvider = ({ children }) => {
  const [stepSettingsContext, setStepSettingsContext] = useState({});
  const store = useMemo(
    () => ({
      stepSettingsContext,
      setStepSettingsContext,
    }),
    [stepSettingsContext]
  );

  return (
    <StepSettingsContext.Provider value={store}>
      {children}
    </StepSettingsContext.Provider>
  );
};
