import { memo, useMemo } from 'react';

const DealTotalPrice = ({ totals }) => {
  const price = useMemo(() => {
    return totals?.Price ? `${totals.PriceCurrency}${totals.Price}` : '--';
  }, [totals.Price, totals.PriceCurrency]);

  return <div>{price}</div>;
};

export default memo(DealTotalPrice);
