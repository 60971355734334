/**
 * Convert currency Helper
 * description: Converts the letters to the appropriate symbols
 * @props currency
 * Usage: convertCurrencyHelper(currency)
 */

export function convertCurrencyHelper(currency) {
  switch (currency) {
    case "USD":
      return '$';
      break;
    case "EUR":
      return '€';
      break;
    default:
      return currency;
  }
}
