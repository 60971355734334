import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dealsService from '../../lib/services/deals-service';
import './styles.css';

function DealInvitation() {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    dealsService.verifyInvitation(params.hashString).then((resp) => {
      if (resp.data) navigate(`/manage-deal/${resp.data}`);
    });
  }, []);

  return (
    <div className='deal-invitation'>
      <div className='content'>
        <h1>You do not have access to the deal!</h1>
        <h3>
          Please register a user with the same email address from the
          invitation.
        </h3>
      </div>
    </div>
  );
}

export default DealInvitation;
