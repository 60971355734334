import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

function ConfirmationPopup({
  message,
  loader,
  cancelationHandler,
  cancelButtonText = 'NO',
  agreementHandler,
  agreeButtonText = 'YES'
}) {
  const wrapperRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !loader &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        cancelationHandler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className='confirmation-screen'>
      <div ref={wrapperRef} className='confirmation-container'>
        <div className='text'>{message}</div>
        <div className='buttons'>
          <button className='cancel button' onClick={() => cancelationHandler()}>
            {t(cancelButtonText)}
          </button>
          <button className='confirm button' onClick={() => agreementHandler()}>
            {t(agreeButtonText)}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPopup;
