import React from 'react';

const SvgComponent = (props) => (
  <svg
    width='800px'
    height='800px'
    {...props}
    viewBox='0 0 1024 1024'
    className='icon'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M182.99 146.2h585.14v402.29h73.14V73.06H109.84v877.71H512v-73.14H182.99z'
      fill={props?.fill || '#0F1F3C'}
    />
    <path
      d='M256.13 219.34h438.86v73.14H256.13zM256.13 365.63h365.71v73.14H256.13zM256.13 511.91h219.43v73.14H256.13zM731.55 585.06c-100.99 0-182.86 81.87-182.86 182.86s81.87 182.86 182.86 182.86c100.99 0 182.86-81.87 182.86-182.86s-81.86-182.86-182.86-182.86z m0 292.57c-60.5 0-109.71-49.22-109.71-109.71 0-60.5 49.22-109.71 109.71-109.71 60.5 0 109.71 49.22 109.71 109.71 0.01 60.49-49.21 109.71-109.71 109.71z'
      fill={props?.fill || '#0F1F3C'}
    />
    <path
      d='M758.99 692.08h-54.86v87.27l69.39 68.76 38.61-38.96-53.14-52.66z'
      fill={props?.fill || '#0F1F3C'}
    />
  </svg>
);
export default SvgComponent;
