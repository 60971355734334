/**
 * Additional Documents component
 * @description: the additional documents in a step showed in a 'Generate mode' and in the 'Attach mode'
 * @param
 */

import React, { useEffect, useMemo } from 'react';

import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import StepOtherDocuments from '@organisms/Documents/StepOtherDocuments/step-other-documents.component';

const AdditionalDocuments = ({ currentStep, canWrite }) => {
  const { documents: dealDocumentsContext, selectedStep: selectedStepContext } =
    useManageDealContext();

  const otherDocuments = useMemo(() => {
    const tempDocument = dealDocumentsContext?.filter((document) => {
      return (
        document.Name !==
        (selectedStepContext?.StepDocument || currentStep?.StepMainDocument)
      );
    });

    return tempDocument;
  }, [dealDocumentsContext, selectedStepContext]);

  return (
    <>
      {otherDocuments?.length > 0 && (
        <StepOtherDocuments
          documents={otherDocuments}
          step={currentStep}
          canWrite={canWrite}
        />
      )}
    </>
  );
};

export default AdditionalDocuments;
