import { memo, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import MuiOutlinedInput from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import Grid from 'Components/Shared/Grid/grid.component';
import { convertCurrencyHelper } from '@lib/helpers/convert-currency.helper';
import FormSelectOptions from '@atoms/Fields/ReactFormFields/FormSelectOptions/form-select-options.component';
import Button from '@atoms/Buttons/Button';
import { formatPrice } from '@lib/helpers/helpers';
import { useQuoteStepContext } from '@lib/Context/QuoteStep/quote-step.context';

const Calculator = ({ index, product, onConfirm, onCancel }) => {
  const {
    getValues,
    reset,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useFormContext();
  const { NetWeightUnitId, UnitPriceCurrencyId, Products } = getValues();
  const watchProductPrice = watch(`Products[${index}].Calculator.Price.Value`);
  const watchProductVolume = watch(`Products[${index}].NetWeight`);
  const { t } = useTranslation();
  const { incoterms, contracts } = useQuoteStepContext();

  const incotermsOptions = useMemo(() => {
    const asArray = Object.entries(incoterms).slice(0, 2);

    return Object.fromEntries(asArray);
  }, [incoterms]);

  const priceLabel = useMemo(() => {
    const unitPrice = UnitPriceCurrencyId ? `(${UnitPriceCurrencyId})` : '';
    return `${t('Price')} ${unitPrice}`;
  }, [UnitPriceCurrencyId, t]);

  const fieldType = useCallback(
    (field) => {
      const map = {
        fixedPrice: convertCurrencyHelper(UnitPriceCurrencyId),
        percentage: '%',
      };
      const type = Products[index].Calculator[field]?.Type;

      return type && map[type] ? map[type] : '';
    },
    [Products, UnitPriceCurrencyId, index]
  );

  const cancelHandler = useCallback(() => {
    reset((formValues) => {
      const products = [...formValues.Products];
      products[index].Calculator = {
        ...products[index].Calculator,
        ...product.Calculator,
      };
      return {
        ...formValues,
        Products: [...products],
      };
    });
    onCancel();
  }, [index, onCancel, product.Calculator, reset]);

  const confirmHandler = useCallback(() => {
    const {
      Products,
      PriceType,
      Contract,
      Incoterms: dealIncotermValue,
    } = getValues();
    const { NetWeight } = Products[index];
    const {
      Incoterms: productIncotermValue,
      IncotermPrice,
      LandTransportation,
      LandInsurance,
      SeaTransportation,
      SeaInsurance,
      Insurance,
    } = Products[index].Calculator;

    if (!IncotermPrice.Value || !NetWeight) {
      setError(`Products[${index}].Calculator.IncotermPrice`, {
        type: 'error',
        message: 'No value',
      });

      return;
    }

    const containersCount = Math.ceil(
      Number(NetWeight) / arabicaContainerCapacity
    );
    const insurance = Number(Insurance.Value) / 100;
    const landInsurance = Number(LandInsurance.Value) / 100;
    const seaInsurance = Number(SeaInsurance.Value) / 100;
    const basePriceValue = Number(IncotermPrice.Value);

    const FOT = productIncotermValue.Value === 'FOT' ? basePriceValue : 0;

    const FOB =
      productIncotermValue.Value === 'FOB'
        ? basePriceValue
        : FOT +
          (Number(LandTransportation.Value) * containersCount) /
            Number(NetWeight) +
          FOT * insurance * landInsurance;
    const CFR =
      productIncotermValue.Value === 'CFR'
        ? basePriceValue
        : Number(FOB) +
          (Number(SeaTransportation.Value) * containersCount) /
            Number(NetWeight);
    const CIF =
      productIncotermValue.Value === 'CIF'
        ? basePriceValue
        : CFR + FOB * insurance * seaInsurance;

    // const formulaFOT = basePriceValue;
    // const formulaFOB =
    //   formulaFOT +
    //   (Number(LandTransportation.Value) * containersCount) / Number(NetWeight) +
    //   formulaFOT * insurance * landInsurance;
    // const formulaCFR =
    //   Number(formulaFOB) +
    //   (Number(SeaTransportation.Value) * containersCount) / Number(NetWeight);
    // const formulaCIF = formulaCFR + formulaFOB * insurance * seaInsurance;

    const mapIncotermValue = {
      FOT,
      FOB,
      CFR,
      CIF,
    };

    const calculateIncoterm = (incoterm) => {
      const productIncotermsAsArray = Object.entries(incotermsOptions);
      const foundProductIncotermIndex = productIncotermsAsArray.findIndex(
        ([_, value]) => value === productIncotermValue.Value
      );
      const foundIndex = productIncotermsAsArray.findIndex(
        ([_, value]) => value === incoterm
      );
      if (foundIndex > -1 && foundProductIncotermIndex > foundIndex) {
        //Here can add logic if selected product incoterm is greater than deal incoterm
        return 0;
      } else {
        return productIncotermValue.Value === incoterm
          ? basePriceValue
          : mapIncotermValue[incoterm];
      }
    };

    const calculateIncotermMap = {
      FOT: calculateIncoterm.bind(this, 'FOT'),
      FOB: calculateIncoterm.bind(this, 'FOB'),
      CFR: calculateIncoterm.bind(this, 'CFR'),
      CIF: calculateIncoterm.bind(this, 'CIF'),
    };

    const contractPrice = Number(contracts[Contract]?.Value) || 0;
    const calculatedIncotermPrice =
      typeof calculateIncotermMap[dealIncotermValue] === 'function'
        ? calculateIncotermMap[dealIncotermValue]()
        : calculateIncotermMap[dealIncotermValue];
    const calculatedPrice =
      PriceType === 'Differential'
        ? calculatedIncotermPrice - contractPrice
        : calculatedIncotermPrice;

    const incotermsAsArray = Object.entries(incoterms);
    const foundIncotermIndex = incotermsAsArray.findIndex(
      ([_, value]) => value === dealIncotermValue
    );
    const availableIncoterms =
      foundIncotermIndex !== -1
        ? incotermsAsArray.slice(0, foundIncotermIndex + 1)
        : incotermsAsArray;
    const setOfCalculatedIncoterms = availableIncoterms.reduce(
      (acc, [_, value]) => {
        acc[value] = calculateIncotermMap[value]();
        return acc;
      },
      {}
    );

    console.log(setOfCalculatedIncoterms);

    const calculatedIncoterms = JSON.stringify(setOfCalculatedIncoterms);
    const unitPrice =
      `${
        calculatedPrice > 0
          ? '+' + formatPrice(calculatedPrice)
          : formatPrice(calculatedPrice)
      }` || '';

    setValue(`Products[${index}].Containers`, containersCount);
    setValue(
      `Products[${index}].Calculator.IncotermsCalculated`,
      calculatedIncoterms
    );
    setValue(`Products[${index}].UnitPrice`, unitPrice);
    onConfirm();
  }, [
    getValues,
    index,
    contracts,
    incoterms,
    setValue,
    onConfirm,
    incotermsOptions,
  ]);

  return (
    <>
      <Grid columns={4}>
        <Grid columns={5}>
          <Controller
            name={`Products[${index}].Calculator.${'Incoterms'}.Value`}
            defaultValue={{ Value: 'FOB' }}
            render={({ field }) => (
              <FormSelectOptions
                {...field}
                label={'Incoterms'}
                required={false}
                selectOptions={incotermsOptions}
                placeholder={'Select'}
                readOnly={false}
              />
            )}
          />
        </Grid>
        <Grid columns={7}>
          <Controller
            name={`Products[${index}].Calculator.${'IncotermPrice'}.Value`}
            defaultValue={''}
            render={({ field }) => (
              <MuiOutlinedInput
                {...field}
                type='number'
                textAlign='right'
                label={priceLabel}
                end={NetWeightUnitId ? `/${NetWeightUnitId}` : ''}
              />
            )}
          />
        </Grid>
        <Grid columns={6}>
          <Button type='link' onClick={confirmHandler}>
            {t('Confirm')}
          </Button>
        </Grid>
        <Grid columns={6}>
          <Button type='link' onClick={cancelHandler}>
            {t('Cancel')}
          </Button>
        </Grid>
      </Grid>
      <Grid columns={8}>
        <div>
          <Grid columns={4}>
            <Controller
              name={`Products[${index}].Calculator.${'LandTransportation'}.Value`}
              defaultValue={''}
              render={({ field }) => (
                <MuiOutlinedInput
                  {...field}
                  type='number'
                  textAlign='right'
                  label={'Transport'}
                  start={<LocalShippingIcon />}
                  end={fieldType('LandTransportation')}
                />
              )}
            />
          </Grid>
          <Grid columns={4}>
            <Controller
              name={`Products[${index}].Calculator.${'LandInsurance'}.Value`}
              defaultValue={''}
              render={({ field }) => (
                <MuiOutlinedInput
                  {...field}
                  type='number'
                  textAlign='right'
                  label={'Insurance'}
                  start={<LocalShippingIcon />}
                  end={fieldType('LandInsurance')}
                />
              )}
            />
          </Grid>
          <Grid columns={4}>
            <Controller
              name={`Products[${index}].Calculator.${'Finance'}.Value`}
              defaultValue={''}
              render={({ field }) => (
                <MuiOutlinedInput
                  {...field}
                  type='number'
                  textAlign='right'
                  label={'Finance'}
                  start='Yearly'
                  end={fieldType('Finance')}
                />
              )}
            />
          </Grid>
        </div>
        <div>
          <Grid columns={4}>
            <Controller
              name={`Products[${index}].Calculator.${'SeaTransportation'}.Value`}
              defaultValue={''}
              render={({ field }) => (
                <MuiOutlinedInput
                  {...field}
                  type='number'
                  textAlign='right'
                  start={<DirectionsBoatIcon />}
                  end={fieldType('SeaTransportation')}
                />
              )}
            />
          </Grid>
          <Grid columns={4}>
            <Controller
              name={`Products[${index}].Calculator.${'SeaInsurance'}.Value`}
              defaultValue={''}
              render={({ field }) => (
                <MuiOutlinedInput
                  {...field}
                  type='number'
                  textAlign='right'
                  start={<DirectionsBoatIcon />}
                  end={fieldType('SeaInsurance')}
                />
              )}
            />
          </Grid>
          <Grid columns={4}>
            <Controller
              name={`Products[${index}].Calculator.${'Months'}.Value`}
              defaultValue={''}
              render={({ field }) => (
                <MuiOutlinedInput
                  {...field}
                  type='number'
                  textAlign='right'
                  start={'Months'}
                />
              )}
            />
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default memo(Calculator);
