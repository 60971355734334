export const required = { required: 'RequiredField' };

export const numeric = {
  pattern: {
    value: /^\d/,
    message: 'ValidationErrorOnlyNumbersAllowed',
  },
};

export const email = {
  pattern: {
    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: 'InvalidEmail',
  },
};

export const isValueIncludedInArray = (array, message) => ({
  validate: (value) => {
    return array.includes(value) ? message : null;
  },
});
