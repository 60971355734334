import { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {FormHelperText} from "@mui/material";

import InputLabel from '@atoms/Fields/InputLabel/input-label.components';

import styles from './mui-select.module.scss';
import './mui-select.styles.scss';

const ContractItem = ({ value, currency, volumeUnit }) => {
  return (
    <div className={styles.container}>
      <p className={styles.key}>{value?.Name || value}</p>
      <p className={styles.value}>
        {currency}
        {value?.Value}/{volumeUnit}
      </p>
    </div>
  );
};

const DefaultItem = ({ value }) => {
  return (
    <div>
      <div className={styles.name}>{value?.Name || value}</div>
      {value?.Hint && <div className={styles.hint}>{value?.Hint}</div>}
    </div>
  );
};

const MuiSelect = forwardRef(
  (
    { label, required, options, placeholder, noItems, customItem, error, ...props },
    ref
  ) => {
    const { t } = useTranslation();

    const fieldOptions = options
      ? Object.entries(options).map(([key, value]) => ({
          props: value,
          value: value?.Key || key,
        }))
      : [];

    const disabled = useMemo(
      () => !fieldOptions.length || props.disabled,
      [fieldOptions.length, props.disabled]
    );

    const renderValue = useCallback(
      (selected) => {
        if (!fieldOptions.length) {
          return noItems;
        }
        if (!selected || selected?.length === 0) {
          return <p className={styles.placeholder}>{t(placeholder)}</p>;
        }

        // We have a problem with sorting when the options are associative array.
        // The solution to this issue is to turn the option's keys into an index and add 'Key' property to the option's value.
        // Because of that is the code below.
        const value = !!fieldOptions[0].props?.Key
          ? fieldOptions.find((option) => option.props.Key == selected)?.props
          : options[selected];

        return t(value?.Name || value || '');

        // return customItem ? (
        //   <ContractItem {...customItem} value={value} />
        // ) : (
        //   t(value?.Name || value || '')
        // );
      },
      [customItem, fieldOptions.length, noItems, options, placeholder]
    );

    const renderMenuItem = useCallback(
      (option) => (
        <MenuItem key={option.value} value={option.value} sx={{ padding: 1.5 }}>
          <DefaultItem value={option.props} />

          {/* {customItem ? (
            <ContractItem {...customItem} value={option.props} />
          ) : (
            <DefaultItem value={option.props} />
          )} */}
        </MenuItem>
      ),
      []
    );

    return (
      // <ThemeProvider theme={theme}>
      <div className={'mui-form-wrap'}>
        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <InputLabel label={label ? label : ''} required={required} />
          <Select
            {...props}
            disabled={disabled}
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 255,
                  width: 185,
                },
              },
            }}
            renderValue={renderValue}
            value={props.value || ''}
            error={!!error}
          >
            {fieldOptions.map(renderMenuItem)}
          </Select>
        </FormControl>
        {!!error && (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        )}
      </div>
      // </ThemeProvider>
    );
  }
);

MuiSelect.displayName = 'MuiSelect';

export default MuiSelect;

// {fieldOptions.length <= 5
//   ?  <>
//     <InputLabel label={label ? label : ''} required={required} />
//     <Select
//       {...props}
//       disabled={disabled}
//       displayEmpty
//       renderValue={renderValue}
//     >
//       {fieldOptions.map(renderMenuItem)}
//     </Select>
//   </>
//   : <MuiAutocomplete
//     {...props}
//     label={label}
//     onChange={props.onChange}
//     required={required}
//     options={options}
//     disabled={disabled}
//   />
// }
