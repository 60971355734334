import { useState, useEffect, useRef, useCallback } from 'react';
import Highcharts from 'highcharts'

const useHighCharts = () => {
  const chartComponentRef = useRef(null);
  const [highChartInstance, setHighChartInstance] = useState(null);

  const showLoading = useCallback(() => {
    if (highChartInstance && chartComponentRef.current) {
      chartComponentRef.current.chart.showLoading();
    }
  }, [highChartInstance]);

  const hideLoading = useCallback(() => {
    if (highChartInstance && chartComponentRef.current) {
      chartComponentRef.current.chart.hideLoading();
    }
  }, [highChartInstance]);

  useEffect(() => {
    if (chartComponentRef.current) {
      setHighChartInstance(Highcharts);
    }
  }, [chartComponentRef.current]);

  return { highChartInstance, chartComponentRef, showLoading, hideLoading };
};

export default useHighCharts;
