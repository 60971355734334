export const ARABICA_CONT_CAPACITY = 21000;
export const ROBUSTA_CONT_CAPACITY = 21000;

export function containerCalculator({ weight: weight, species: species }) {
  const arabica = 'Arabica';
  const robusta = 'Robusta';

  let containers = 0;

  if (species === arabica) {
    containers = Math.ceil(weight / ARABICA_CONT_CAPACITY);
  } else if (species === robusta) {
    containers = Math.ceil(weight / ROBUSTA_CONT_CAPACITY);
  }

  return containers;
}
