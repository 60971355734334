import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Cookies } from 'react-cookie';
import { es, fr } from 'date-fns/locale';

import './date.styles.scss';

const cookies = new Cookies();

const Date = ({
  item,
  isStepActive,
  fields,
  errorHandler,
  onChange,
  setView,
  field,
}) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setValue(item.Value);
  }, [item]);

  const changeDateHandler = (date) => {
    if (typeof field?.onChange === 'function') {
      field.onChange(date);
      onChange && onChange(date);
      const toStringDate = date.toDateString();
      setValue(toStringDate);
      return;
    }
    if (date === null) {
      fields[item.Key] = '';
      errorHandler();
    } else if (
      date === 'Invalid Date' ||
      date.getFullYear().toString().length < 4
    ) {
      fields[item.Key] = item.Value;
      errorHandler('Invalid Date');
    } else {
      const toStringDate = date.toDateString();

      fields[item.Key] = toStringDate;
      setValue(toStringDate);
      errorHandler();
    }
    onChange && onChange(date);
  };

  const datePlaceholder = () => {
    switch (item.Key) {
      case 'ExpirationDate':
        return `${t('Choose expiration date')}...`;
      case 'DeliveryDate':
        return `${t('Choose delivery date')}...`;
      default:
        return `${t('Choose date')}...`;
    }
  };

  // assign calendar language
  const assignLanguage = () => {
    const language = cookies.get('i18next'); // get the current language

    switch (language) {
      case 'fr': {
        return fr;
      }
      case 'es': {
        return es;
      }
    }
  };

  return (
    <div onKeyDown={(e) => e.stopPropagation()} className={'old-date--wrap'}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={assignLanguage()}>
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          views={
            setView === 'month' ? ['year', 'month'] : ['year', 'month', 'day']
          }
          inputFormat={setView === 'month' ? 'MMMM yyyy' : 'dd.MM.yyyy'}
          mask='__.__.____'
          value={value}
          onChange={(newValue) => {
            changeDateHandler(newValue);
          }}
          renderInput={({ inputRef, inputProps, InputProps }) =>
            !item.Readonly && !isStepActive ? (
              <Box
                sx={{ display: 'flex', alignItems: 'center' }}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => setOpen(true)}>
                <input
                  ref={inputRef}
                  {...inputProps}
                  placeholder={datePlaceholder()}
                  disabled={true}
                  className={'date-field'}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                {InputProps?.endAdornment}
              </Box>
            ) : (
              <input
                ref={inputRef}
                {...inputProps}
                placeholder={item.Value || ''}
                disabled={true}
                onKeyDown={(e) => e.stopPropagation()}
              />
            )
          }
        />
      </LocalizationProvider>
    </div>
  );
};

export default memo(Date);
