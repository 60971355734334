import { formatNumber } from "./format-number.helper";

export function formatPrice(value, symbol, sign) {
  let amount = 0;

  if (value < 100 && value > 0) {
    amount = value.toFixed(4);
  } else {
    amount = Number(value).toFixed(4);
    // Add trailing zero.
    //amount = addTrailingZero(amount, 3);
  }

  if (Number(amount) === 0) {
    return "--";
  }

  return `${sign || ""}${symbol || ""}${amount}`;
}

export function formatVolume(value, unit) {
  // To be more precise there is one exception when the unit is MT. In that case, the value should be rounded to the second digit
  if (unit === "MT") {
    const amount = value % 1 !== 0 ? value.toFixed(2) : value;
    return formatNumber(amount, ",") + (unit || "");
  }

  const amount =
    value < 100 && value > 0
      ? value.toFixed(2)
      : formatNumber(Math.round(value), ",");

  if (Number(amount) === 0) {
    return "--";
  }

  return formatNumber(amount, ",") + (unit || "");
}

export function addTrailingZero(amount, maxDecimal) {
  if (String(amount).includes(".")) {
    //split the number by '.'
    const splitAmount = String(amount).split(".");
    //format the first part of the number
    const beforeDecimal = formatNumber(splitAmount[0]);
    const afterDecimal = splitAmount[1];
    for (let i = 0; i < afterDecimal.length; i++) {
      // if last number
      if (i === afterDecimal.length - 1) {
        // if last number is not 0
        if (afterDecimal[i] != "0" && i < maxDecimal) {
          return beforeDecimal + "." + afterDecimal + "0";
        } else {
          return beforeDecimal + "." + afterDecimal;
        }
      }
    }
  } else {
    // If the number is not decimal '.'
    return formatNumber(amount);
  }
}

export function formatCountryName(name) {
  if (!name && typeof name !== "string") {
    return name;
  }

  const words = name.split(" ");
  if (words.length === 1) {
    return name;
  } else {
    return words
      .map((word) => {
        return word.length > 3 ? word[0].toUpperCase() : "";
      })
      .filter((word) => word)
      .join("");
  }
}

export function serviceFeeConvertor() {
  const serviceLevel = (value) => {
    const mapLevel = {
      500: 1,
      1000: 2,
      3000: 3,
    };

    return mapLevel[value] || "";
  };

  const feePerVolumeUnit = (value, unit) => {
    const map = {
      kg: value / 1000,
      lbs: value / 2204.62,
      MT: value,
    };
    return map[unit] || value;
  };

  return {
    serviceLevel,
    feePerVolumeUnit,
  };
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
