import React, { useEffect } from "react";
import fetchCommonData from "@services/authentication-services";
import { IDENTITY_URL } from "@config/config";
import { setUserInfoAction } from "@lib/Context/actions";
import { useAppStore } from "@lib/Context";

function FetchUserDetails({ setIsPendingUser }) {
  const { dispatch, state: appStore } = useAppStore();
  useEffect(() => {
    fetchCommonData.getUserDetails().then((r) => {
      dispatch(setUserInfoAction(r.data.data));
      if (!r.data?.data?.userOrganizationId) {
        window.location.replace(IDENTITY_URL);
      }
      if (r.data.data.isPendingUser) {
        setIsPendingUser(true);
      }
    });
  }, []);
  return <React.Fragment></React.Fragment>;
}

export default FetchUserDetails;
