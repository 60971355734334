/**
 * Truncate Sentence/word
 * description: Truncate a sentence to the given symbol count and add "..." at the end.
 * @props text : text to be truncated
 * @props length: length of the truncated text.
 */

export function truncateSentence(text, length) {
  if(text?.length > length) {
    return <>{text}...</>
  } else {
    return text
  }
}
