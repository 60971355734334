import { httpClient } from './httpClient';

const RESOURCE_PATH = 'api-deal';

const dealsService = {
  getDeals: function (filter, restParams) {
    const params = {
      PerPage: 10,
      ...restParams,
    };
    const responseType = params.Export ? 'arraybuffer' : undefined;
    return httpClient.get(
      `${RESOURCE_PATH}?${
        filter ? `Keywords=${filter}&` : ''
      }`,
      { params, responseType }
    );
  },

  getDealSteps: function (dealId) {
    return httpClient.get(`${RESOURCE_PATH}/${dealId}/steps`);
  },

  getServicesData: function (dealId) {
    return httpClient.get(`${RESOURCE_PATH}/${dealId}/getServices`);
  },

  getStepDetails: function (dealId, stepId, param) {
    return httpClient.get(
      `${RESOURCE_PATH}/${dealId}/stepDetails/${stepId}${
        param ? `?paymentReturn=${param}` : ''
      }`
    );
  },

  getCreateDeal: function () {
    return httpClient.get(`${RESOURCE_PATH}/createDeal`);
  },

  getUpdateDeal: function (dealId) {
    return httpClient.get(`${RESOURCE_PATH}/${dealId}/updateDeal`);
  },

  updateStep: function (dealId, stepId, data) {
    return httpClient.put(
      `${RESOURCE_PATH}/${dealId}/updateStep/${stepId}`,
      data
    );
  },

  validateUpdateStep: function (dealId, stepId, data) {
    return httpClient.put(
      `${RESOURCE_PATH}/${dealId}/validateUpdateStep/${stepId}`,
      data
    );
  },

  updateServicesData: function (dealId, data) {
    return httpClient.put(`${RESOURCE_PATH}/${dealId}/updateServices`, data);
  },

  updateDeal: function (dealId, data) {
    return httpClient.put(`${RESOURCE_PATH}/${dealId}/updateDeal`, data);
  },

  createNewSample: function (dealId, data) {
    return httpClient.post(`${RESOURCE_PATH}/${dealId}/createNewSample`, data);
  },

  createNewPCSample: function (dealId, data) {
    return httpClient.post(`${RESOURCE_PATH}/${dealId}/createNewPriceConfirmation`, data);
  },

  completeDeal: function (dealId, status) {
    return httpClient.put(`${RESOURCE_PATH}/${dealId}/completeDeal`, {
      Status: status,
    });
  },

  addProductToInventory: function (dealId, productId, icoNumber, lotId) {
    return httpClient.post(`${RESOURCE_PATH}/${dealId}/addToProductInventory/${productId}`, {
      icoNumber: icoNumber,
      lotId: lotId,
    });
  },

  deleteDeal: function (dealId) {
    return httpClient.delete(`${RESOURCE_PATH}/${dealId}`);
  },

  userOrganization: function (data) {
    return httpClient.put(`${RESOURCE_PATH}/userOrganization`, data);
  },

  createDeal: function (data) {
    return httpClient.post(`${RESOURCE_PATH}/createDeal`, data);
  },

  deleteStep: function (dealId, stepId) {
    return httpClient.delete(`${RESOURCE_PATH}/${dealId}/deleteStep/${stepId}`);
  },

  sync: function (dealId) {
    return httpClient.put(`${RESOURCE_PATH}/${dealId}/syncInventory`, {});
  },

  verifyInvitation: function (hash) {
    return httpClient.get(`${RESOURCE_PATH}/verifyInvitation/${hash}`);
  },

  getInventoryLotsByProductId: function ({ dealId, productId }) {
    return httpClient.get(
      `${RESOURCE_PATH}/${dealId}/productLots/${productId}`
    );
  },

  getContracts: function (dealId, productId, productSku, productType) {
    const payload = {
      'DealId': dealId,
      'ProductId': productId,
      'ProductSku': productSku,
      'PriceType': productType
    };

    return httpClient.put(
      `${RESOURCE_PATH}/productContracts`,
      payload
    );
  },
};

export default dealsService;
