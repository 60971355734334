import { memo, useState, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormField from '@atoms/Fields/Field/form-field.component';
import TooltipComponent from '@atoms/Tooltip/tooltip.component';
import { fieldMap, updateButtons } from 'Components/DealSteps/StepInputs';
import Button from '@atoms/Button/button.component';
import Grid from '@atoms/Grid/DynamicGrid/grid.component';
import FormInputFields from '@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';

const DefaultStep = ({
  inputs,
  buttons,
  onSubmit,
  isStepActive,
  disableButton,
}) => {
  const { t } = useTranslation();
  const { selectedStep } = useManageDealContext();
  const [stepButtons, setStepButtons] = useState([]);

  const defaultValues = inputs.reduce((acc, curr) => {
    acc[curr.Key] = curr.Value;
    return acc;
  }, {});

  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    const newButtons = isDirty ? updateButtons(buttons) : buttons;
    setStepButtons(newButtons);
  }, [isDirty]);

  useEffect(() => {
    setStepButtons(buttons || []);
  }, [buttons]);

  const buttonsColor = (buttonType) => {
    switch (buttonType) {
      case 'saveDraft':
        return `quaternary`;
      case 'rejected':
        return `tertiary`;
      case 'saveForceApprove':
        return `senary`;
      default:
        return 'primary';
    }
  };

  const renderButtons = () => {
    return (
      <div className='buttons'>
        <div className='save-buttons'>
          {stepButtons.map((button) => {
            return (
              <div className={'save-buttons__tooltip-wrap'} key={button.Name}>
                <TooltipComponent text={button.Text}>
                  <Button
                    onClick={handleSubmit((formData) => {
                      onSubmit({ ...formData, ButtonType: button.Type});
                      reset(formData);
                    })}
                    size={'xxl'}
                    btnColor={buttonsColor(button.Type)}
                    disabled={disableButton || button.Disable ? true : false}>
                    {t(button.Name)}
                  </Button>
                </TooltipComponent>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <FormProvider {...form}>
      {inputs?.map((item) => {
        return (
          <Controller
            key={item.Key}
            name={item.Key}
            render={({ field }) => {
              return (
                <Grid columns={item.GridNumber}>
                  <FormInputFields
                    info={{ ...field }}
                    fields={item}
                    onChange={field.onChange}
                    disabled={!!item.Readonly || !!selectedStep?.Done}
                  />
                </Grid>
              );
            }}
          />
        );
      })}
      {renderButtons()}
    </FormProvider>
  );
};

export default memo(DefaultStep);
