/**
 * Pop up Submit Buttons Component
 * @description: assigning the buttons to the Step buttons
 */

export function popUpSubmitButtonsHelper(
  validateInfo,
  stepSettings,
  buttonAction
) {
  let arr = [];

  const saveAsDraftButton = {
    buttonColor: 'quaternary',
    buttonType: 'fill',
    buttonName: 'Save as Draft',
    action: 'saveDraft',
  };

  const sendButton = {
    buttonColor: 'default',
    buttonType: 'fill',
    buttonName: 'Send',
    action: 'saveApprove',
  };

  const sendWithCustomVersion = {
    buttonColor: 'default',
    buttonType: 'fill',
    buttonName: 'Send with system version',
    action: 'saveApproveSystemDoc',
  };

  const sendWithUserTemplate = {
    buttonColor: 'secondary-grey',
    buttonType: 'fill',
    buttonName: 'Send with user template',
    action: 'saveApproveUploadedDoc',
  };

  const confirmButton = {
    buttonColor: 'tertiary',
    buttonType: 'fill',
    buttonName: 'Confirm',
    action: 'saveApprove',
  };

  const newSystemVersion = {
    buttonColor: 'tertiary',
    buttonType: 'fill',
    buttonName: 'New System Version',
    action: 'saveApproveSystemDoc',
  };

  const keepUserTemplate = {
    buttonColor: 'secondary-grey',
    buttonType: 'fill',
    buttonName: 'Keep User Template',
    action: 'saveApproveUploadedDoc',
  };

  const saveForceApprove = {
    buttonColor: 'senary',
    buttonType: 'fill',
    buttonName: 'Confirm Force Approval',
    action: 'saveForceApprove',
  };

  const approvedDefault = {
    buttonColor: 'default',
    buttonType: 'fill',
    buttonName: 'Approve',
    action: 'approved',
  };

  const approved = {
    buttonColor: 'tertiary',
    buttonType: 'fill',
    buttonName: 'Approve',
    action: 'approved',
  };

  const rejectedDefault = {
    buttonColor: 'default',
    buttonType: 'fill',
    buttonName: 'Decline',
    action: 'rejected',
    disabled: true,
  };

  const rejected = {
    buttonColor: 'tertiary',
    buttonType: 'fill',
    buttonName: 'Decline',
    action: 'rejected',
    disabled: true,
  };

  const reopen = {
    buttonColor: 'default',
    buttonType: 'fill',
    buttonName: 'Reopen',
    action: 'change',
  };

  const reopenConfirm = {
    buttonColor: 'tertiary',
    buttonType: 'fill',
    buttonName: 'Confirm',
    action: 'change',
  };

  const request = {
    buttonColor: 'default',
    buttonType: 'fill',
    buttonName: 'Request',
    action: 'requestSample',
  };

  if (validateInfo?.items?.SaveDraft) {
    arr.push(saveAsDraftButton);
  } else if (!validateInfo?.items?.SaveDraft) {
    if (buttonAction === 'saveForceApprove') {
      arr.push(saveForceApprove);
    } else if (buttonAction === 'requestSample') {
      arr.push(request);
    }  else if (buttonAction === 'approved') {
      //!SaveDraft & approved & !EmailsMuted
      if (!stepSettings?.EmailsMuted) {
        arr.push(approvedDefault);
      } else {
        //!SaveDraft & approved & EmailsMuted
        arr.push(approved);
      }
    } else if (buttonAction === 'rejected') {
      //!SaveDraft & rejected & !EmailsMuted
      if (!stepSettings?.EmailsMuted) {
        arr.push(rejectedDefault);
      } else {
        //!SaveDraft & rejected & EmailsMuted
        arr.push(rejected);
      }
    } else if (buttonAction === 'change') {
      //!SaveDraft & rejected & !EmailsMuted
      if (!stepSettings?.EmailsMuted) {
        arr.push(reopen);
      } else {
        //!SaveDraft & rejected & EmailsMuted
        arr.push(reopenConfirm);
      }
    } else if (buttonAction === 'requestChange') {
      //!SaveDraft & requestChange & !EmailsMuted
      if (!stepSettings?.EmailsMuted) {
        arr.push(sendButton);
      } else {
        //!SaveDraft & requestChange & EmailsMuted
        arr.push(confirmButton);
      }
    }
    //!SaveDraft & !EmailsMuted
    else if (!stepSettings?.EmailsMuted) {
      //!SaveDraft & !EmailsMuted & !UploadedDocument
      if (!validateInfo?.items?.UploadedDocument) {
        arr.push(sendButton);
      } else {
        arr.push(sendWithCustomVersion);
        arr.push(sendWithUserTemplate);
      }
    } else {
      //!SaveDraft & EmailsMuted & !UploadedDocument
      if (!validateInfo?.items?.UploadedDocument) {
        arr.push(confirmButton);
      } else {
        //!SaveDraft & EmailsMuted & UploadedDocument
        arr.push(newSystemVersion);
        arr.push(keepUserTemplate);
      }
    }
  }

  return arr;
}
