import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';

import { useNavigation } from '@lib/hooks/useNavigation';
import { useUserRole } from '@lib/hooks/useUserRole';
import BackButton from '@atoms/Buttons/back-button.component';
import Button from '@atoms/Button/button.component';
import CreateStepOne from '@organisms/CreateDeal/CreateSteps/01.StepOne/create-step-one.component';
import StepperV2 from '@molecules/Steppers/StepperV2/stepper-v2.component';
import CreateStepTwo from '@organisms/CreateDeal/CreateSteps/02.StepTwo/create-step-two.component';
import CreateStepThree from '@organisms/CreateDeal/CreateSteps/03.StepThree/create-step-three.component';
import CreateStepFour from '@organisms/CreateDeal/CreateSteps/04.StepFour/create-step-four.component';

import './create-deal.styles.scss';
import styles from './create-deal.module.scss';

const CreateDealComponent = ({ state, blockchainVerificationValue }) => {
  const { t } = useTranslation();
  const cancelRef = useRef();
  const { redirect } = useNavigation();
  const { isAdmin, isTrader, isOrgAdmin } = useUserRole();

  const [createStep, setCreateStep] = useState(1);
  const [prevCreateStep, setPrevCreateStep] = useState(0);
  const [payloadStep1, setPayloadStep1] = useState({});
  const [payloadStep3, setPayloadStep3] = useState({});
  const [quoteDocument, setQuoteDocument] = useState([]);
  const [pceDocument, setPceDocument] = useState([]);
  const [blockchainVerification, setBlockchainVerification] = useState(
    !!blockchainVerificationValue
  );

  // When the step is changed scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [createStep]);

  useEffect(() => {
    setBlockchainVerification(blockchainVerificationValue);
  }, [blockchainVerificationValue]);

  const form = useForm();
  const {
    formState: { dirtyFields },
  } = form;

  const renderMainSubheader = useCallback(() => {
    if (createStep === 1) {
      if (isAdmin || isOrgAdmin || isTrader) {
        return t('CreateSubHeaderAdmin');
      } else if (state === 'WantToSell') {
        return t('CreateSubHeaderSeller');
      } else if (state === 'WantToBuy') {
        return t('CreateSubHeaderBuyer');
      }
    } else if (createStep === 2) {
      return t('DealDetailsTitle');
    } else if (createStep === 3) {
      return t('CreateStep3Title');
    } else if (createStep === 4) {
      return (
        <>
          <div>
            {t('This is a summary of the Deal you are about to create.')}
          </div>
          <div>{t('Please, verify and confirm.')}</div>
        </>
      );
    }
  }, [isAdmin, isOrgAdmin, isTrader, state, createStep]);

  const renderStepTwo = useCallback(() => {
    return (
      <>
        {createStep === 2 && Object.keys(payloadStep1).length > 0 && (
          <CreateStepTwo
            stepActions={[
              {
                Type: '_Next',
                Name: 'Next',
                type: 'fill',
                size: 'large',
              },
            ]}
            createDealFlow={state}
            setCreateStep={setCreateStep}
            prevCreateStep={prevCreateStep}
            payload={payloadStep1}
          />
        )}
      </>
    );
  }, [createStep, payloadStep1]);

  const renderStepFour = useCallback(() => {
    return (
      <>
        {Object.keys(payloadStep3).length > 0 && (
          <CreateStepFour
            createDealFlow={state}
            stepFormValues={payloadStep3}
          />
        )}
      </>
    );
  }, [payloadStep3]);

  return (
    <div className={styles.createContainer}>
      <div className={styles.mainHeader}>
        <div>
          <h3>{t('Create New Deal')}</h3>
          <div className={styles.subHeader}>{renderMainSubheader()}</div>
        </div>
        {createStep === 1 ? (
          <Button
            size={'large'}
            type={'link'}
            onClick={() => {
              redirect('/');
            }}>
            {t('Cancel')}
          </Button>
        ) : (
          <Button
            size={'large'}
            type={'link'}
            onClick={() => {
              setCreateStep(Number(createStep) - 1);
              setPrevCreateStep(createStep);
            }}>
            {t('Back')}
          </Button>
        )}
      </div>
      <div className={styles.stepperWrap}>
        <StepperV2 activeStep={createStep - 1} state={state} />
      </div>
      <FormProvider {...form}>
        {createStep === 1 && (
          <CreateStepOne
            setCreateStep={setCreateStep}
            setPrevCreateStep={setPrevCreateStep}
            setPayloadStep1={setPayloadStep1}
            createDealFlow={state}
          />
        )}
        {renderStepTwo()}
        {createStep === 3 && (
          <CreateStepThree
            setCreateStep={setCreateStep}
            setPrevCreateStep={setPrevCreateStep}
            createDealFlow={state}
            setPayloadStep3={setPayloadStep3}
            setParentQuoteDocument={setQuoteDocument}
            parentQuoteDocument={quoteDocument}
            parentPceDocument={pceDocument}
            setParentPceDocument={setPceDocument}
          />
        )}
        {createStep === 4 && renderStepFour()}
      </FormProvider>
    </div>
  );
};

export default CreateDealComponent;
