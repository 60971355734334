import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid } from '@mui/material';

import HeadingComponent from '@atoms/Headings/heading.component';
import NotificationIcon from '@molecules/NotificationIcon/notification-icon.component';
import Button from '@atoms/Button/button.component';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import MuiOutlinedInput from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import { useUserRole } from '@lib/hooks/useUserRole';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import { useAppStore } from '@lib/Context';
import { usePopup } from '@lib/hooks/usePopup';
import LoadingSpinner from '@molecules/LoadingSpinner/loading-spinner.component';
import { useDebounce } from '@lib/hooks/useDebounce';
import dealsService from '@lib/services/deals-service';
import { isJsonString } from '@lib/helpers/helpers';

const UserNamesComponent = ({ userType, email, loading }) => {
  const { t } = useTranslation();
  const {
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = useFormContext();

  return (
    <>
      <Grid item xs={6} md={3}>
        <Controller
          name={`${userType}FirstName`}
          render={({ field }) => (
            <MuiOutlinedInput
              {...field}
              label={t('First Name')}
              disabled={
                !!getValues(`${userType}FirstName`) &&
                !dirtyFields[`${userType}FirstName`]
              }
              required={true}
              placeholder={t('Enter')}
              textAlign={'left'}
              error={errors[`${userType}FirstName`]}
              end={loading ? <LoadingSpinner /> : ''}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Controller
          name={`${userType}LastName`}
          render={({ field }) => (
            <MuiOutlinedInput
              {...field}
              label={t('Last Name')}
              disabled={
                !!getValues(`${userType}LastName`) &&
                !dirtyFields[`${userType}LastName`]
              }
              required={true}
              placeholder={t('Enter')}
              textAlign={'left'}
              error={errors[`${userType}LastName`]}
              end={loading ? <LoadingSpinner /> : ''}
            />
          )}
        />
      </Grid>
    </>
  );
};

const Component = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { isSeller, isBuyer, isAdmin, isOrgAdmin } = useUserRole();
  const { deal } = useManageDealContext();
  const { state: appState } = useAppStore();
  const { showPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const { debounce } = useDebounce();

  const form = useForm({
    defaultValues: {
      BuyerEmail: deal?.BuyerEmail || '',
      BuyerFirstName: '',
      BuyerLastName: '',
      SellerEmail: deal?.SellerEmail || '',
      SellerFirstName: '',
      SellerLastName: '',
    },
    shouldUnregister: false,
  });

  const {
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = form;

  const onSubmitHandler = useCallback(
    async (formData) => {
      setIsLoading(true);
      try {
        await onSubmit(formData);
      } catch (error) {
        if (!error?.response?.data.message) {
          showPopup({
            state: 'error',
            message: t('Something went wrong'),
            timer: 4000,
          });
        } else {
          const message = isJsonString(error?.response?.data.message)
            ? t('Invalid E-mail')
            : t(error?.response?.data.message);
          showPopup({
            state: 'error',
            message,
            timer: 4000,
          });

          Object.entries(JSON.parse(error.response.data.message)).forEach(
            ([key, value]) =>
              setError(key, { type: 'custom', message: t(value[0]) })
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [onSubmit, setError, showPopup, t]
  );

  const fetchUserNamesByEmail = useCallback(({ email, userType }) => {
    return dealsService
      .userOrganization({
        UserEmail: email,
        DealId: deal.Id,
      })
      .then(({ data }) => {
        reset((formValues) => ({
          ...formValues,
          [`${userType}FirstName`]: data.FirstName || '',
          [`${userType}LastName`]: data.LastName || '',
        }));
      })
      .finally(() => setIsLoadingEmail(false));
  }, []);

  const onChangeEmail = useCallback(
    (event, userType) => {
      setIsLoadingEmail(true);
      return debounce(
        () =>
          fetchUserNamesByEmail({
            email: event?.target?.value.trim(),
            userType,
          }),
        500
      )();
    },
    [fetchUserNamesByEmail, debounce]
  );

  useEffect(() => {
    fetchUserNamesByEmail({
      email: deal?.SellerEmail.trim(),
      userType: 'Seller',
    });
    fetchUserNamesByEmail({
      email: deal?.BuyerEmail.trim(),
      userType: 'Buyer',
    });

    return () => reset();
  }, []);

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box typography={'h5'} sx={{ fontWeight: 'bold' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <NotificationIcon emailsMuted={false} />
                {t('SendPurchaseContractDocumentForSignaturesTitle')}
              </Box>
            </Box>
          </Box>
        </Grid>
        {(isAdmin || isOrgAdmin) && (
          <Grid item xs={12}>
            {t('SendPurchaseContractDocumentForSignaturesDesc')}
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            name={`BuyerEmail`}
            render={({ field }) => (
              <MuiOutlinedInput
                {...field}
                onChange={(event) => {
                  onChangeEmail(event, 'Buyer');
                  field.onChange(event);
                }}
                label={deal?.Buyer || t('Buyer')}
                disabled={(!isAdmin && !isOrgAdmin)}
                required={false}
                placeholder={t('Enter')}
                textAlign={'left'}
                error={errors['BuyerEmail']}
              />
            )}
          />
        </Grid>
        {(isAdmin || isOrgAdmin) && (
          <UserNamesComponent
            userType={'Buyer'}
            email={deal?.BuyerEmail.trim()}
            loading={isLoadingEmail}
          />
        )}
        <Grid item xs={12} md={6}>
          <Controller
            name={`SellerEmail`}
            render={({ field }) => (
              <MuiOutlinedInput
                {...field}
                onChange={(event) => {
                  onChangeEmail(event, 'Seller');
                  field.onChange(event);
                }}
                label={deal?.Seller || t('Seller')}
                disabled={(!isAdmin && !isOrgAdmin)}
                required={false}
                placeholder={t('Enter')}
                textAlign={'left'}
                error={errors['SellerEmail']}
              />
            )}
          />
        </Grid>
        {(isAdmin || isOrgAdmin) && (
          <UserNamesComponent
            userType={'Seller'}
            email={deal?.SellerEmail.trim()}
            loading={isLoadingEmail}
          />
        )}
        <Grid item xs={12}>
          {t('EmailsWithDocuSignLinksForSignature')}
        </Grid>
        <Grid item xs={12}>
          {t('OnceSuccessfullySigned')}
        </Grid>
        <Grid item xs={12}>
          <Trans
            i18nKey='SupportDefaultEmail'
            values={{ contact: appState?.userInfo?.defaultContactSupport?.Email }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            size={'large'}
            onClick={handleSubmit(onSubmitHandler)}
            btnColor={'green'}
            loading={isLoading}
            disabled={isLoading}>
            {t('Send for signing')}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export const PurchaseContractDocuSign = memo(Component);
