import React, {useCallback} from "react";
import {Trans, useTranslation} from "react-i18next";

import {useAppStore} from "@lib/Context";
import {useUserRole} from "@lib/hooks/useUserRole";
import Sandglass from "@assets/images/sandglass.png";
import Heading from "@atoms/Headings/heading.component";
import Button from "@atoms/Button/button.component";

import styles from './waiting-step-view.module.scss';

const WaitingStepView = (
  {
    declined,
    stepSettings,
    document,
    pendingUsers,
    setShowForm
  }) => {
  const { state } = useAppStore();
  const { isAdmin, isOrgAdmin, isSeller, isBuyer, isTrader } = useUserRole();
  const { t } = useTranslation();

  const renderHeader = useCallback(() => {
    if(Object.keys(pendingUsers).length > 0) {
      return t('DealParticipantsAreBeingVerified')
    } else {
      return (
        <>
          {declined
            ? <Trans
              i18nKey='SellerRevisingStepDecline'
              values={{ company: stepSettings?.UserReceiver?.Organization, document: t(document)}}
            />
            : <Trans
              i18nKey='SellerPreparingQuote'
              values={{ company: stepSettings?.UserReceiver?.Organization, document: t(document)}}
            />
          }
        </>
      )
    }
  }, [pendingUsers, declined, stepSettings])

  const renderContent = () => {
    if(Object.keys(pendingUsers).length > 0) {
      if(isAdmin || isOrgAdmin || isTrader) {
        return (
          <>
            <div className={styles.subHeader}>
              {t('UsersIntroducedToTheSystem')}:
              {Object.entries(pendingUsers).map(([key, value], index) => {
                if(key === 'buyer') {
                  return (
                    <div key={index}>
                      {t('Buyer')}: {value}
                    </div>
                  )
                } else {
                  return (
                    <div key={index}>
                      {t('Seller')}: {value}
                    </div>
                  )
                }
              })}
            </div>
          </>
        )
      } else if(isSeller || isBuyer) {
        return (
          <div className={styles.subHeader}>
            <Trans i18nKey='DealEnabledForProcessing' values={{ email: state?.userInfo?.email }} />
          </div>
        )
      }
      return <></>
    } else {
      return (
        <>
          <div className={styles.subHeader}>
            <Trans i18nKey='StandBy' values={{ days: 3 }} />
          </div>
          <div className={styles.subHeader}>
            <Trans
              i18nKey='NotifiedWhenQuoteIsSubmitted'
              values={{ email: state?.userInfo?.email }}
            />
          </div>
        </>
      )
    }
  }

  return (
    <div className={styles.container}>
      <Heading type={'h2'}>
        {renderHeader()}
      </Heading>
      {renderContent()}
      <div className={styles.imageWrap}>
        <img src={Sandglass} title={'sandglass'} />
      </div>
      {(isAdmin || isOrgAdmin || isTrader) &&
        <Button type={'empty'} onClick={() => setShowForm()}>{t('ViewDealDetails')}</Button>
      }
    </div>
  )
}

export default WaitingStepView;