import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./NoPermissions.module.scss";

function NoPermissions() {
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      {/*<SubHeader*/}
      {/*  searchInput={state && state.filter}*/}
      {/*  option={state && state.filterOption}*/}
      {/*/>*/}
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <img
              src={require("@assets/images/403.jpg")}
              width="100%"
              alt="no image"
            />
          </div>
          <div className={styles.message}>
            <div className={styles.title}>
              {t("You're not permitted to see this.")}
            </div>
            <div className={styles.text}>
              {t("Please contact contact@almacenaplatform.com.")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoPermissions;
