import { createElement, createContext, useContext } from 'react';

export const createSingletonHook = (useHook) => {
  const Context = createContext(undefined);

  const SingletonHookProvider = ({ children, ...props }) => {
    const value = useHook(props);
    return createElement(Context.Provider, { value }, children);
  };

  const useSingletonHook = () => {
    const value = useContext(Context);
    if (typeof value === 'undefined') {
      throw new Error(
        'Component must be a wrapped in a singleton hook Provider'
      );
    }
    return value;
  };

  return [useSingletonHook, SingletonHookProvider];
};
