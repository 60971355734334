import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageContainer from '@templates/PageContainer/page-container.template';

import styles from './AccessDenied.module.scss';

function AccessDenied() {
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <PageContainer>
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <img
              src={require('@assets/images/403.jpg')}
              width='100%'
              alt='no image'
            />
          </div>
          <div className={styles.message}>
            <div className={styles.title}>
              {t("You're not permitted to see this.")}
            </div>
            <div className={styles.text}>
              {t("The page you're trying to access has restricted access.")}
            </div>
            <div className={styles.text}>
              {t('If you feel this is a mistake, contact your admin.')}
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default AccessDenied;
