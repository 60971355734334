/**
 * Sample Delete Button component
 * @description: used inside PC and APR step.
 * @param setUpdateAPR: send to the parent when you delete the sample in order to refresh the view
 * @param stepId:
 * @param sampleId:
 */

import {useTranslation} from "react-i18next";
import React from "react";
import {Tooltip, Typography} from "@mui/material";

import dealsService from "@services/deals-service";
import CloseIcon from '@mui/icons-material/Close';

import styles from './sample-delete.btn.module.scss';

const SampleDeleteBtn = ({stepId, sampleId, setUpdateAPR}) => {
  const { t } = useTranslation();

  const deleteSample = (e) => {
    e.stopPropagation();
    dealsService
      .deleteStep(stepId, sampleId)
      .then(() => {
        setUpdateAPR(true)
      })
      .catch((e) => {
        console.log(e.response.data)
      });
  }

  return (
    <Tooltip
      title={
        <Typography style={{ fontSize: 13 }}>
          {t('RemoveSample')}
        </Typography>
      }
      placement="top"
    >
      <div className={styles.container} onClick={deleteSample}>
        <CloseIcon className={styles.btn}></CloseIcon>
      </div>
    </Tooltip>
  )
}

export default SampleDeleteBtn;