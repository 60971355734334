import React from 'react';

import styles from './loading-spinner.module.scss';

const LoadingSpinner = () => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loaderWrap}>
        <div className={styles.loader}></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
