import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

import styles from './copy-button.module.scss';
import { memo } from 'react';
import { useState } from 'react';
import { useCopyToClipboard } from '@lib/hooks/useCopyToClipboard';
const Component = ({ value }) => {
  const [copied, setCopied] = useState(false);
  const [copiedValue, copy] = useCopyToClipboard();

  return (
    <IconButton
      className={styles.copyButton}
      onClick={() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
        copy(value);
      }}>
      {copied ? <LibraryAddCheckIcon /> : <ContentCopyIcon />}
    </IconButton>
  );
};

export const CopyButton = memo(Component);
