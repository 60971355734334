import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Popup from '@organisms/Popup/popup.component';
import MessagesList from '@organisms/Messages/MessagesList/messages-list.component';
import OpenMessages from '@organisms/Messages/OpenMessages/open-messages.component';
import EditDealComponent from '@organisms/EditDeal/edit-deal.component';
import dealsService from '@lib/services/deals-service';
import handleErrorsHelper from '@molecules/HandleErrors/handle-errors.helper';

import './styles.scss';
import LoadingSpinner from '@molecules/LoadingSpinner/loading-spinner.component';
import { Box } from '@mui/material';

function EditDeal() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const params = useParams();
  const [deal, setDeal] = useState({});
  const [steps, setSteps] = useState([]);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({});
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);
  const [traders, setTraders] = useState([]);
  const [traderId, setTraderId] = useState();
  const [buyerAgents, setBuyerAgents] = useState();
  const [sellerAgents, setSellerAgents] = useState();
  const [roleInDeal, setRoleInDeal] = useState();
  const [mutedEmail, setMutedEmail] = useState();
  const [showContactInfo, setShowContactInfo] = useState();
  const [seller, setSeller] = useState('');
  const [sellerUser, setSellerUser] = useState('');
  const [sellerEmail, setSellerEmail] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [buyer, setBuyer] = useState('');
  const [buyerUser, setBuyerUser] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [buyerId, setBuyerId] = useState('');
  const [feeProducts, setFeeProducts] = useState();
  const [blockchainVerificationValue, setBlockchainVerificationValue] =
    useState(0);
  const [permissionsList, setPermissionsList] = useState({});
  const [financeAgents, setFinanceAgants] = useState([]);
  const [financierId, setFinancierId] = useState();
  const [insuranceAgents, setInsuranceAgants] = useState([]);
  const [insurerId, setInsurerId] = useState();
  const { t } = useTranslation();

  const editDeal = (data, refs) => {
    setLoader(true);

    if (refs.length) data.References = refs;
    else if (typeof refs === 'object' && !Array.isArray(refs)) {
      //if buyer, he has different Reference field
      data['ReferenceType'] = Object.keys(refs)[0];
      data['ReferenceValue'] = refs[Object.keys(refs)[0]];
    } else {
      //if admin
      data.References = {};
    }

    dealsService
      .updateDeal(params.id, data)
      .then((r) => {
        navigate(`/manage-deal/${params.id}`, { state: { dealEdited: true } });
      })
      .catch((e) => {
        handleErrorsHelper(e.response.data, setErrors, setAlert);
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    setLoader(true);
    dealsService
      .getUpdateDeal(params.id)
      .then((r) => {
        setUser(r.data.user);
        setDeal(r.data.items.Deal);
        setTraders([r.data.items.Traders]);
        setTraderId(r.data.items.TraderId);
        setMutedEmail(r.data.items.MuteEmails);
        setShowContactInfo(r.data.items.ShowContactInfo);
        setSeller(r.data.items.Seller);
        setSellerUser(r.data.items.SellerUser);
        setSellerEmail(r.data.items.SellerEmail);
        setSellerId(r.data.items.SellerId);
        setBuyer(r.data.items.Buyer);
        setBuyerUser(r.data.items.BuyerUser);
        setBuyerEmail(r.data.items.BuyerEmail);
        setBuyerId(r.data.items.BuyerId);
        setFeeProducts(r.data.items.FeeProducts);
        setBuyerAgents(r.data?.items?.BuyerAgents || []);
        setSellerAgents(r.data?.items?.SellerAgents || []);
        setRoleInDeal(r.data?.items?.RoleInDeal);
        setBlockchainVerificationValue(r.data?.items?.BlockchainVerification);
        setPermissionsList(r.data?.items?.Permissions);
        setFinanceAgants([r.data?.items?.Financiers]);
        setFinancierId(r.data?.items?.FinancierId);
        setInsuranceAgants([r.data?.items?.Insurers]);
        setInsurerId(r.data?.items?.InsurerId);
        const stepsArr = [];
        Object.keys(r.data.items.Steps).map((key1) => {
          Object.keys(r.data.items.Steps[key1]).map((key2) =>
            stepsArr.push(r.data.items.Steps[key1][key2])
          );
        });
        setSteps(stepsArr);
      })
      .finally(() => setLoader(false));

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (loader) {
    return <Box height={'80vh'}>{/* <LoadingSpinner /> */}</Box>;
  }
  return (
    <div className='edit-deal'>
      <Popup message={alert.message} state={alert.state} />
      <section className='main-page'>
        <EditDealComponent
          errors={errors}
          user={user}
          traderId={traderId}
          traders={traders}
          steps={steps}
          onSubmitHandler={editDeal}
          mutedEmailProp={mutedEmail}
          showContactInfoProp={showContactInfo}
          loader={loader}
          deal={deal}
          sellerInfo={{ name: sellerUser, organization: seller }}
          sellerEmailInfo={sellerEmail}
          sellerId={sellerId}
          buyerInfo={{ name: buyerUser, organization: buyer }}
          buyerEmailInfo={buyerEmail}
          buyerId={buyerId}
          feeProducts={feeProducts}
          buyerAgents={buyerAgents}
          sellerAgents={sellerAgents}
          roleInDeal={roleInDeal}
          blockchainVerificationValue={blockchainVerificationValue}
          permissionsList={permissionsList}
          financeAgents={financeAgents}
          financierId={financierId}
          insuranceAgents={insuranceAgents}
          insurerId={insurerId}
        />
      </section>
    </div>
  );
}

export default EditDeal;
