/**
 * Accordion Component
 * @props title : the title of the accordion
 * @props children
 * @props defaultExpand: if the accordion will be open by default
 * @props type: type of the accordion
 * @props subTitle: If we need subtitle with a description inside.
 * @props description: description under the subtitle.
 * @props opened<number>: needed in order to open a specific accordion and close the other
 * @props index<number>: needed in order to mark the index of the current accordion for the close/open functionality
 * @props titleButton: button inside the title of the accordion next to the arrow
 * @props onClick: triggers and send to the parent when you toggle the accordion
 *
 * @desc Toggle Logic: the parent needs to have a state that keeps the index(1 | 2 | 3) of the current opened accordion
 * And send it to this component using the pros "opened".
 * inside the index props keep the index of the current accordion (1 | 2 | 3)
 * On accordion click: this component will trigger the onClick props,
 * and will send to the parent the state(false, true) of the current accordion
 * There the parent will update the current opened accordion: onClick={(value) => value && setOpenedAccordion(index)}
 * If the index and the opened values are equal(2===2) then the accordion is being opened and all the others are being closed.
 *
 * Usage: <SingleAccordionComponent
 * title={'Farm Details'}
 * opened={openAccordion}
 * index={i}
 * >{children}</SingleAccordionComponent>
 */

import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Tooltip } from '@mui/material';

import ChevronDownIcon from '@assets/icons/ChevronDown';

import styles from './single-accordion.module.scss';

const SingleAccordion = ({
  title,
  type = 'full',
  children,
  defaultExpand,
  description,
  subTitle,
  opened,
  index,
  titleButton,
  onClick,
  closeAccordion,
}) => {
  const { t } = useTranslation();

  const [openAccordion, isOpenAccordion] = useState(
    defaultExpand ? defaultExpand : false
  );

  // Open a specific accordion and close All others
  useEffect(() => {
    if (index && typeof opened == 'number') {
      if (opened === index) {
        isOpenAccordion(true);
      } else {
        isOpenAccordion(false);
      }
    }
  }, [index, opened]);

  useEffect(() => {
    if (typeof opened == 'boolean') {
      isOpenAccordion(opened);
    }
  }, []);

  const closeBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    titleButton && titleButton();
  };

  // Avoid repeating code
  const accordionTitleHtml = (titleClass) => {
    return (
      <>
        <div
          className={`
            ${titleClass === 'close' ? styles.opened : styles.closed} 
            ${styles.accordionHeader}
          `}>
          {titleButton && (
            <div className={styles.headerButton} onClick={closeBtn}>
              <Tooltip title={t('Remove')}>
                <CloseOutlinedIcon />
              </Tooltip>
            </div>
          )}
          <ChevronDownIcon />
        </div>
      </>
    );
  };

  // if we have closeAccordion send as prop:
  // let the parent decide when to close the accordion
  const toggleAccordion = () => {
    if (closeAccordion === undefined || closeAccordion === true) {
      isOpenAccordion(!openAccordion);
      onClick && onClick(!openAccordion);
    } else {
      onClick && onClick(!openAccordion, true);
    }
  };

  return (
    <div
      className={`${
        type === 'compact' ? styles.compactContainer : styles.container
      }`}>
      <div
        className={styles.title}
        onClick={() => {
          toggleAccordion();
        }}
      >
        <div className={styles.titleText}>{title}</div>
        {openAccordion ? accordionTitleHtml('close') : accordionTitleHtml()}
      </div>
      {openAccordion && (
        <div className={styles.content}>
          {subTitle && <h4>{subTitle}</h4>}
          {description && (
            <div className={styles.description}>{description}</div>
          )}
          {children}
        </div>
      )}
    </div>
  );
};

export default SingleAccordion;
