/**
 * Switch component
 * @description: toggle with text
 */

import React, { forwardRef } from 'react';
import Switch from 'react-switch';
import {useTranslation} from "react-i18next";

import variables from '@base/styles/variables.scss';
import InputLabel from '@atoms/Fields/InputLabel/input-label.components';

import './switch.styles.scss';
import styles from './switch.module.scss';

const SwitchComponent = forwardRef(
  ({
     values,
     onChange,
     label,
     required,
     uncheckedHandleIcon,
     checkedHandleIcon,
     height,
     width,
     handleDiameter,
     offColor,
     onColor,
     ...props
   }, ref) => {
    const { t } = useTranslation();

    return (
      <div className={styles.hideMessages}>
        <div className={styles.labelWrap}>
          <InputLabel label={label ? label : ''} required={required} />
        </div>
        {!!values[0] && (
          <p>{t(values[0])}</p>
        )}
        <Switch
          {...props}
          ref={ref}
          onChange={(value) => {
            onChange(value);
          }}
          onColor={props.disabled || onColor ? onColor || variables.disabled : variables.primaryBtnBgLight}
          offColor={props.disabled || offColor ? offColor || variables.disabled : variables.primaryBtnBgLight}
          uncheckedIcon={false}
          checkedIcon={false}
          uncheckedHandleIcon={uncheckedHandleIcon || undefined}
          checkedHandleIcon={checkedHandleIcon || undefined}
          height={height || 20}
          width={width || 48}
          handleDiameter={handleDiameter || 13}
        />
        {!!values[1] && (
          <p>{t(values[1])}</p>
        )}
      </div>
    );
  }
);

SwitchComponent.displayName = "SwitchComponent";

export default SwitchComponent;
