import { Box } from '@mui/material';
import { memo, useEffect, useRef, useState } from 'react';

import Tooltip from '@atoms/Tooltip/tooltip.component';

import styles from './ellipsis-text.module.scss';
const Component = ({ text, className }) => {
  const ref = useRef();
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const compareLabel = ref.current?.scrollWidth > ref.current?.clientWidth;
    setIsOverflow(compareLabel);
  }, [ref]);

  return (
    <Box className={styles.container}>
      <Tooltip disable={!isOverflow} text={text}>
        <Box ref={ref} className={`${styles.text} ${className}`}>
          {text}
        </Box>
      </Tooltip>
    </Box>
  );
};

export const EllipsisText = memo(Component);
