import Portal from "@molecules/Portal/portal.component";
import PopupLayout from "@organisms/Popup/PopupLayout/popup-layout.component";
import {UploadDocument} from "@organisms/Dialogs/UploadDocument/upload-document.component";

const DocumentHistoryPopup = ({step, file, stepDocument, onRefreshDocuments, setUploadDocsDialog, isEdit, title}) => {
  return (
    <Portal overlay={'overlay'}>
      <PopupLayout closePopUp={setUploadDocsDialog}>
        <UploadDocument
          title={title}
          isEdit={isEdit}
          onClose={() => setUploadDocsDialog(false)}
          step={step}
          documentId={file?.DocumentId}
          isLocked={stepDocument?.IsLocked}
          documentTypeId={stepDocument?.Id}
          dealStepId={stepDocument?.DealStepId}
          onRefreshDocuments={onRefreshDocuments}
        />
      </PopupLayout>
    </Portal>
  )
}

export default DocumentHistoryPopup;
