import { useEffect } from 'react';
import HighchartsReact, {} from 'highcharts-react-official';

import useHighCharts from './useHighCharts.context';

/**
 * A wrapper component for rendering Highcharts charts with options and loading state.
 *
 * @component
 * @param {import("highcharts-react-official/dist/highcharts-react")} options - The options for the Highcharts chart.
 * @param {boolean} loading - Whether to display the loading state for the chart.
 * @returns {JSX.Element} - The rendered Highcharts chart.
 *
 * @example
 * <HighChartsWrapper
 *   options={{
 *     chart: {
 *       type: 'bar'
 *     },
 *     title: {
 *       text: 'My Chart'
 *     },
 *     series: [{
 *       data: [1, 2, 3]
 *     }]
 *   }}
 *   loading={false}
 * />
 */

const HighChartsWrapper = ({ options, loading }) => {
  const { highChartInstance, chartComponentRef, showLoading, hideLoading } =
    useHighCharts();

  useEffect(() => {
    if (loading) {
      showLoading();
    } else hideLoading();
  }, [hideLoading, loading, showLoading]);

  return (
    <>
      <HighchartsReact
        ref={chartComponentRef}
        constructorType={'chart'}
        highcharts={highChartInstance}
        options={options}
      />
    </>
  );
}

export default HighChartsWrapper;
