/**
 *Header Search component
 * @description: Search inside the header component
 * @param
 */

import React, {
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {useTranslation} from "react-i18next";
import {
  Controller,
  useForm,
  FormProvider
} from "react-hook-form";

import { useNavigation } from "@lib/hooks/useNavigation";
import SearchIcon from "@assets/icons/Search";
import FormInputFields from "@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component";
import PageButtonInteraction from "@templates/PageButtonInteraction/page-button-interaction.template";

import styles from "./header-search.module.scss";
import './header-search.styles.scss';

const HeaderSearch = () => {
  const { t } = useTranslation();
  const { redirect } = useNavigation();
  const submitRef = useRef();

  const [contentType, setContentType] = useState('activeDeals');
  const [keyPressed, setKeyPressed] = useState();

  const form = useForm();
  const {
    getValues,
  } = form;

  useEffect(() => {
    switch (keyPressed) {
      case 'Enter': {
        submitRef.current.click();
        break;
      }
      default:
        break;
    }
  }, [keyPressed]);

  const headerSearchInputs = useMemo(() => [
    {
      "Key": "SearchInput",
      "Label": null,
      "Type": "text",
      "Readonly": 0,
      "Value": null,
      "Required": 0,
      "Placeholder": `${t("What are you looking for")}...`,
      "Class": "headerSearchInput"
    }
  ], [t])

  const search = () => {
    redirect('/', {
      state: {
        filter: getValues('SearchInput'),
        contentType,
      },
    });
  };

  return (
    <section className={`${styles.searchContainer} header__search-container`}>
      <div className={styles.searchInput}>
        <PageButtonInteraction setKeyPressed={(value) => setKeyPressed(value)}>
          <FormProvider {...form}>
            {headerSearchInputs.map((searchField) => {
              return(
                <Fragment key={searchField.Key}>
                  <Controller
                    name={searchField.Key}
                    className={styles[searchField.Class]}
                    render={({ field }) => (
                      <FormInputFields
                        info={{ ...field }}
                        fields={searchField}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                </Fragment>
              )
            })}
          </FormProvider>
        </PageButtonInteraction>
      </div>
      <div
        className={styles.searchIcon}
        onClick={search}
        ref={submitRef}
      >
        <SearchIcon />
      </div>
    </section>
  )
}

export default HeaderSearch;