import { useNavigate } from 'react-router-dom';
import { useAppStore } from '@lib/Context';
import { setActivePageAction } from '@lib/Context/actions';

export const useNavigation = () => {
  const navigate = useNavigate();
  const { dispatch } = useAppStore();

  const redirect = (link, state) => {
    dispatch(setActivePageAction(link));
    sessionStorage.setItem('activePage', link);

    navigate(link, state);

    //return window.history.pushState({ oldActivePage: window.location.pathname }, "", link);
  };

  return {
    redirect
  };
};