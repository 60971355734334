import { forwardRef, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, FormHelperText, TextField } from '@mui/material';

import InputLabel from '@atoms/Fields/InputLabel/input-label.components';

import './mui-autocomplete.styles.scss';
import styles from './mui-autocomplete.module.scss';

const Component = forwardRef(
  (
    {
      label,
      helperText,
      error,
      required,
      options,
      freeSolo,
      type = 'text',
      noOptionsText = 'No Options',
      addCustomOption = false,
      fieldOnChange,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    const fieldOptions = options
      ? Object.entries(options).map(([key, value]) => ({
          props: value,
          label: value?.Name || value,
          value: value?.Key || key,
        }))
      : [];
    const defaultValue =
      fieldOptions.find((option) => {
        return option.value == props.value;
      }) || props.value;

    const [value, setValue] = useState(defaultValue || '');

    const renderOption = useCallback((props, option, state) => {
      const { props: selected, value } = option;

      return (
        <div
          {...props}
          style={{ padding: '15px', display: 'block', width: '100%' }}>
          <div className={styles.name}>{selected?.Name || selected}</div>
          {selected?.Hint && (
            <div className={styles.hint}>{selected?.Hint}</div>
          )}
        </div>
      );
    }, []);

    const renderAddOption = useCallback(() => {
      return (
        <div className={styles.addOptionWrap}>
          <div>
            <h5>{value}</h5>
            <div>{t('AddProductHint')}</div>
          </div>
        </div>
      );
    }, [value]);

    const handleInputChange = (e) => {
      if (fieldOnChange) {
        setValue(e.target.value);
        props.onChange(e);
        fieldOnChange(e);
      } else {
        props.onChange(e);
      }
    };

    return (
      <div className={'autocomplete-component'}>
        <InputLabel label={label ? label : ''} required={required} />

        <Autocomplete
          {...props}
          freeSolo={freeSolo}
          options={fieldOptions}
          autoHighlight
          //tell what is showing
          getOptionLabel={(option) => {
            if (option?.label) {
              return isNaN(option.label)
                ? option?.label
                : String(Number(option.label));
            } else {
              return String(option);
            }
          }}
          value={value}
          isOptionEqualToValue={(option, value) =>
            option?.value == value?.value
          }
          onChange={(event, data) => {
            props.onChange(data?.value);
            setValue(data?.label);
            return data;
          }}
          renderOption={renderOption}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={handleInputChange}
              // type={type}
              inputRef={ref}
              variant='outlined'
              fullWidth
              error={!!error}
            />
          )}
          ListboxProps={{
            style: {
              maxHeight: 255,
              width: '100%',
            },
          }}
          noOptionsText={addCustomOption ? renderAddOption() : noOptionsText}
        />
        {!!error && (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        )}
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </div>
    );
  }
);

Component.displayName = 'MuiAutocomplete';

export const MuiAutocomplete = memo(Component);
