/**
 * Outright Total
 * @description:Product total when the Price Type is Outright
 * @param index: the index of the current product
 */

import { memo, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { convertCurrencyHelper } from '@lib/helpers/convert-currency.helper';
import { formatPrice } from '@lib/helpers/helpers';
import { weightConverter } from '@lib/helpers/WeightConverter/weight-converter.helper';
// import {useQuoteStepContext} from "@lib/Context/QuoteStep/quote-step.context";
import Button from '@atoms/Button/button.component';

import styles from './differential-total.module.scss';

const DifferentialTotal = ({ index, prefix = '' }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  // const { showPriceBreakDown, setShowPriceBreakDown } = useQuoteStepContext();

  const watchNetWeightUnit = watch(`${prefix}NetWeightUnitId`);
  const watchProductNetWeight = watch(`Products.${index}.NetWeight`);
  const watchProductUnitPrice = watch(`Products.${index}.UnitPrice`);
  const watchSpecies = watch(`${prefix}Species`);
  const watchContract = watch(`${prefix}Contract`);
  const watchUnitPriceCurrency = watch(`${prefix}UnitPriceCurrencyId`);
  // Calculate and render the Contracts
  const futuresContracts = useMemo(() => {
    const arabica = 'Arabica';
    const robusta = 'Robusta';
    let contracts = 0;

    if (!watchProductNetWeight) {
      return 0;
    }
    // Converter the weight to kg.
    const convertedWeight = weightConverter({
      weight: Number(watchProductNetWeight),
      weightUnit: watchNetWeightUnit,
    });
    // Calculate the weight in kg in to containers.
    // const containers = containerCalculator({
    //   weight: convertedWeight,
    //   species: watchSpecies,
    // });

    // Calculate the Contracts.
    if (watchSpecies === arabica) {
      contracts = convertedWeight / 17009.8;
    } else if (watchSpecies === robusta) {
      contracts = convertedWeight / 10000;
    }

    return Math.ceil(contracts);
  }, [watchNetWeightUnit, watchProductNetWeight, watchSpecies]);

  const differentialPrice = useMemo(() => {
    const contract = watchContract;
    const currency = convertCurrencyHelper(watchUnitPriceCurrency);
    const netWeight = watchSpecies === 'Robusta' ? 'MT' : 'lbs';
    const sign =
      Number(watchProductUnitPrice) > 0
        ? '+'
        : Number(watchProductUnitPrice) < 0
        ? '-'
        : '';
    const price = watchProductUnitPrice
      ? formatPrice(Math.abs(Number(watchProductUnitPrice)), currency, sign)
      : '--';

    return contract && watchProductUnitPrice ? (
      <b>
        {contract} {price}/{netWeight}
      </b>
    ) : (
      '--'
    );
  }, [
    watchContract,
    watchProductUnitPrice,
    watchSpecies,
    watchUnitPriceCurrency,
  ]);

  useEffect(() => {
    setValue(`Products.${index}.FutureContracts`, futuresContracts);
  }, [futuresContracts, index, setValue]);

  return (
    <>
      <div>
        {t('Price')}: {differentialPrice}
      </div>
      {/*<div className={styles.priceBreakdown}>*/}
      {/*  <Button type={'link'} onClick={() => setShowPriceBreakDown(!showPriceBreakDown)}>{t('Price breakdown')}</Button>*/}
      {/*</div>*/}
    </>
  );
};

export default memo(DifferentialTotal);
