function dec2hex(dec) {
  return dec.toString(16).padStart(2, '0');
}

function generateRandomString(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

export const nonce = generateRandomString(24);
export const AUTH_ROOT = `${process.env.REACT_APP_AUTH_ROOT}${nonce}`;
export const AUTH_LOGOUT_ROOT = process.env.REACT_APP_AUTH_LOGOUT_ROOT;
export const INVENTORY_MANAGEMENT_URL =
  process.env.REACT_APP_INVENTORY_MANAGEMENT_URL;
export const MY_PROFILE_URL = process.env.REACT_APP_MY_PROFILE_URL;
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const almacena_domain = process.env.REACT_APP_ALMACENA_DOMAIN;
export const ORIGIN_URL = process.env.REACT_APP_ORIGIN_URL;
export const POLYGON_SCAN_NETWORK = process.env.REACT_APP_POLYGON_SCAN_NETWORK;
export const ESCC_Terms_and_Conditions =
  'https://www.ecf-coffee.org/wp-content/uploads/2021/04/European-Standard-Contract-for-Coffee-ESCC-2018-edition-as-amended_Feb-21.pdf';
export const IDENTITY_URL = process.env.REACT_APP_IDENTITY_URL;
