/**
 * Quote Step Component
 * description: Form used inside Create Deal
 */

import React, {
  memo,
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import dealsService from '@services/deals-service';
import { useQuoteStepContext } from '@lib/Context/QuoteStep/quote-step.context';
import { deepEqual } from '@lib/helpers/compare-objects.helper';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import PageLoader from '@templates/PageLoader/page-loader.template';
import Button from '@atoms/Button/button.component';
import Grid from '@atoms/Grid/DynamicGrid/grid.component';
import MuiOutlinedInputComponent from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import FormInputFields from '@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component';
import ProductButtons from '@molecules/ButtonsVariation/ProductButtons/product-buttons.component';
import SwitchComponent from '@molecules/Switch/switch.component';
import ProductsListComponent from '@organisms/Products/ProductsList/products-list.component';
import DealTotal from '@organisms/Products/DealTotal/deal-total.component';

import variables from '@base/styles/variables.scss';
import styles from '@organisms/Steps/QuoteStep/quote-step.module.scss';
import '@organisms/Steps/QuoteStep/quote-step.styles.scss';
import { useUserRole } from '@lib/hooks/useUserRole';
import { MuiAutocomplete } from '@atoms/Fields/ReactFormFields/MuiAutocomplete/mui-autocomplete.component';
import CheckmarkIcon from '@assets/icons/Checkmark';
import './create-step-two.styles.scss';

const CreateStepTwo = ({
  stepActions,
  createDealFlow,
  setCreateStep,
  payload,
  prevCreateStep,
}) => {
  const { t } = useTranslation();
  const { setContracts, setIncoterms, minContainers } = useQuoteStepContext();
  const { selectedStep } = useManageDealContext();
  const initialRender = useRef(true);
  const { isAdmin, isTrader, isBuyer, isSeller } = useUserRole();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();

  const [quoteMainFields, setQuoteMainFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [stepProduct, setStepProduct] = useState({});
  const [stepFields, setStepFields] = useState([]);
  const [services, setServices] = useState([]);
  const [requestSideOptions, setRequestSideOptions] = useState({});

  const speciesArabica = 'Arabica';
  const speciesRobusta = 'Robusta';

  const watchPriceType = watch('PriceType');
  const watchSpecies = watch('Species');
  const watchProducts = watch('Products');
  const watchNumContainers = watch('NumContainers');
  const watchFinance = watch('RequestFinance');
  const watchFinanceFor = watch('RequestFinanceFor');
  const watchFinanceIsPrivate = watch('RequestFinanceIsPrivate');
  const watchInsurance = watch('RequestInsurance');
  const watchInsuranceFor = watch('RequestInsuranceFor');
  const watchInsuranceIsPrivate = watch('RequestInsuranceIsPrivate');
  const watchBuyerInsurance = watch('BuyerInsurance');
  const watchBuyerInsuranceFor = watch('BuyerInsuranceFor');
  const watchBuyerInsuranceIsPrivate = watch('BuyerInsuranceIsPrivate');
  const watchFxHedging = watch('FxHedging');
  const watchFxHedgingFor = watch('FxHedgingFor');
  const watchFxHedgingIsPrivate = watch('FxHedgingIsPrivate');
  const watchSeaFreight = watch('SeaFreight');
  const watchSeaFreightFor = watch('SeaFreightFor');
  const watchSeaFreightIsPrivate = watch('SeaFreightIsPrivate');
  const watchLandTransport = watch('LandTransport');
  const watchLandTransportFor = watch('LandTransportFor');
  const watchLandTransportIsPrivate = watch('LandTransportIsPrivate');
  const watchLoadingSupervision = watch('LoadingSupervision');
  const watchLoadingSupervisionFor = watch('LoadingSupervisionFor');
  const watchLoadingSupervisionIsPrivate = watch('LoadingSupervisionIsPrivate');
  const watchQualityChecks = watch('QualityChecks');
  const watchQualityChecksFor = watch('QualityChecksFor');
  const watchQualityChecksIsPrivate = watch('QualityChecksIsPrivate');

  useEffect(() => {
    getStepInfo(payload);
  }, []);

  // Decided if we need to reset the Products form values or not.
  useEffect(() => {
    const stepProductOptions =
      stepProduct && stepProduct?.Template?.Fields[0]?.Options;
    const formProductOptions =
      watchProducts && watchProducts[0]?.Fields[0]?.Options;

    if (stepProductOptions && formProductOptions) {
      if (!deepEqual(stepProductOptions, formProductOptions)) {
        setValue('Products', []);
      }
    } else if (stepProductOptions && !formProductOptions) {
      setValue('Products', []);
    } else if (
      prevCreateStep === 1 &&
      !stepProductOptions &&
      Object.keys(stepProduct).length > 0
    ) {
      setValue('Products', []);
    }
  }, [stepProduct, prevCreateStep]);

  const getStepInfo = useCallback((data) => {
    if (Object.keys(data).length > 0) {
      dealsService
        .createDeal(data)
        .then((res) => {
          setStepProduct(res.data.items[0].Products);
          setStepFields(res.data.items[0].Fields);
          setServices(res.data.items[0].DealServices);
          setRequestSideOptions(res.data.items[0].RequestSide);

          if (watchFinance === undefined) {
            setValue('RequestFinance', false);
          }
          if (watchFinanceFor === undefined) {
            setValue('RequestFinanceFor', null);
          }
          if (watchFinanceIsPrivate === undefined) {
            setValue(
              'RequestFinanceIsPrivate',
              res.data.items[0].DealServices[0].IsPrivate
            );
          }
          if (watchInsurance === undefined) {
            setValue('RequestInsurance', false);
          }
          if (watchInsuranceFor === undefined) {
            setValue('RequestInsuranceFor', null);
          }
          if (watchInsuranceIsPrivate === undefined) {
            setValue(
              'RequestInsuranceIsPrivate',
              res.data.items[0].DealServices[1].IsPrivate
            );
          }
          if (watchBuyerInsurance === undefined) {
            setValue('BuyerInsurance', false);
          }
          if (watchBuyerInsuranceFor === undefined) {
            setValue('BuyerInsuranceFor', null);
          }
          if (watchBuyerInsuranceIsPrivate === undefined) {
            setValue(
              'BuyerInsuranceIsPrivate',
              res.data.items[0].DealServices[2].IsPrivate
            );
          }
          if (watchFxHedging === undefined) {
            setValue('FxHedging', false);
          }
          if (watchFxHedgingFor === undefined) {
            setValue('FxHedgingFor', null);
          }
          if (watchFxHedgingIsPrivate === undefined) {
            setValue(
              'FxHedgingIsPrivate',
              res.data.items[0].DealServices[3].IsPrivate
            );
          }
          if (watchSeaFreight === undefined) {
            setValue('SeaFreight', false);
          }
          if (watchSeaFreightFor === undefined) {
            setValue('SeaFreightFor', null);
          }
          if (watchSeaFreightIsPrivate === undefined) {
            setValue(
              'SeaFreightIsPrivate',
              res.data.items[0].DealServices[4].IsPrivate
            );
          }
          if (watchLandTransport === undefined) {
            setValue('LandTransport', false);
          }
          if (watchLandTransportFor === undefined) {
            setValue('LandTransportFor', null);
          }
          if (watchLandTransportIsPrivate === undefined) {
            setValue(
              'LandTransportIsPrivate',
              res.data.items[0].DealServices[5].IsPrivate
            );
          }
          if (watchLoadingSupervision === undefined) {
            setValue('LoadingSupervision', false);
          }
          if (watchLoadingSupervisionFor === undefined) {
            setValue('LoadingSupervisionFor', null);
          }
          if (watchLoadingSupervisionIsPrivate === undefined) {
            setValue(
              'LoadingSupervisionIsPrivate',
              res.data.items[0].DealServices[6].IsPrivate
            );
          }
          if (watchQualityChecks === undefined) {
            setValue('QualityChecks', false);
          }
          if (watchQualityChecksFor === undefined) {
            setValue('QualityChecksFor', null);
          }
          if (watchQualityChecksIsPrivate === undefined) {
            setValue(
              'QualityChecksIsPrivate',
              res.data.items[0].DealServices[7].IsPrivate
            );
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log('error: ', e);
        });
    }
  }, []);

  const productTemplate = useMemo(
    () => ({
      Calculator: stepProduct?.Template?.Calculator,
      Fields: stepProduct?.Template?.Fields,
      Label: null,
      OriginPrice: null,
      ProductId: null,
      ProductName: '',
      Species: '',
    }),
    [stepProduct]
  );

  const dealServicesFields = useMemo(
    () => ({
      RequestFinance: watchFinance,
      RequestFinanceFor: watchFinanceFor,
      RequestFinanceIsPrivate:
        !isTrader && !isAdmin
          ? watchFinance
            ? true
            : watchFinanceIsPrivate
          : watchFinanceIsPrivate,
      RequestInsurance: watchInsurance,
      RequestInsuranceFor: watchInsuranceFor,
      RequestInsuranceIsPrivate:
        !isTrader && !isAdmin
          ? watchInsurance
            ? true
            : watchInsuranceIsPrivate
          : watchInsuranceIsPrivate,
      BuyerInsurance: watchBuyerInsurance,
      BuyerInsuranceFor: watchBuyerInsuranceFor,
      BuyerInsuranceIsPrivate:
        !isTrader && !isAdmin
          ? watchBuyerInsurance
            ? true
            : watchBuyerInsuranceIsPrivate
          : watchBuyerInsuranceIsPrivate,
      FxHedging: watchFxHedging,
      FxHedgingFor: watchFxHedgingFor,
      FxHedgingIsPrivate:
        !isTrader && !isAdmin
          ? watchFxHedging
            ? true
            : watchFxHedgingIsPrivate
          : watchFxHedgingIsPrivate,
      SeaFreight: watchSeaFreight,
      SeaFreightFor: watchSeaFreightFor,
      SeaFreightIsPrivate:
        !isTrader && !isAdmin
          ? watchSeaFreight
            ? true
            : watchSeaFreightIsPrivate
          : watchSeaFreightIsPrivate,
      LandTransport: watchLandTransport,
      LandTransportFor: watchLandTransportFor,
      LandTransportIsPrivate:
        !isTrader && !isAdmin
          ? watchLandTransport
            ? true
            : watchLandTransportIsPrivate
          : watchLandTransportIsPrivate,
      LoadingSupervision: watchLoadingSupervision,
      LoadingSupervisionFor: watchLoadingSupervisionFor,
      LoadingSupervisionIsPrivate:
        !isTrader && !isAdmin
          ? watchLoadingSupervision
            ? true
            : watchLoadingSupervisionIsPrivate
          : watchLoadingSupervisionIsPrivate,
      QualityChecks: watchQualityChecks,
      QualityChecksFor: watchQualityChecksFor,
      QualityChecksIsPrivate:
        !isTrader && !isAdmin
          ? watchQualityChecks
            ? true
            : watchQualityChecksIsPrivate
          : watchQualityChecksIsPrivate,
    }),
    [
      watchFinance,
      watchFinanceFor,
      watchFinanceIsPrivate,
      watchInsurance,
      watchInsuranceFor,
      watchInsuranceIsPrivate,
      watchBuyerInsurance,
      watchBuyerInsuranceFor,
      watchBuyerInsuranceIsPrivate,
      watchFxHedging,
      watchFxHedgingFor,
      watchFxHedgingIsPrivate,
      watchSeaFreight,
      watchSeaFreightFor,
      watchSeaFreightIsPrivate,
      watchLandTransport,
      watchLandTransportFor,
      watchLandTransportIsPrivate,
      watchLoadingSupervision,
      watchLoadingSupervisionFor,
      watchLoadingSupervisionIsPrivate,
      watchQualityChecks,
      watchQualityChecksFor,
      watchQualityChecksIsPrivate,
    ]
  );

  useEffect(() => {
    if (stepProduct.Template?.Fields && watchProducts.length === 0) {
      append(productTemplate);
    }

    // Assign the default values to the Form
    if (stepFields.length > 0) {
      stepFields.forEach((item) => {
        if (item.Key === 'NumContainers') {
          if (!watchNumContainers) {
            setValue(item.Key, item.Value);
          }
        } else if (item.Value) {
          if (!getValues(item.Key)) {
            setValue(item.Key, item.Value);
          }
        }
      });
    }
  }, [stepFields]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Products',
  });

  const { replace } = useFieldArray({
    control,
    name: 'DealServices',
  });

  const onSubmitHandler = useCallback(() => {
    let haveChanges = false;
    services.map((service) => {
      if (service.IsPrivate !== watch(`${service.Key}IsPrivate`)) {
        haveChanges = true;
      }
    });
    if ((isAdmin || isTrader) && haveChanges) {
      if (confirm("Please confirm 'Private' fields change")) {
        //setServiceChanges(false);
        replace(dealServicesFields);
        setCreateStep(3);
      }
    } else {
      replace(dealServicesFields);
      setCreateStep(3);
    }
  }, [dealServicesFields]);

  const addProducts = useCallback(() => {
    append(productTemplate);
  }, [append, productTemplate]);

  const containersValidation = useCallback(
    (value) => {
      // if (!!value && Number(value) < Number(minContainers)) {
      //   return t(
      //     `Min containers count must be greater or equal to {{containers}}`,
      //     { containers: minContainers }
      //   );
      // }
      if (!!value && Number(value) < 1) {
        return t(
          `Min containers count must be greater or equal to {{containers}}`,
          { containers: 1 }
        );
      } else if (!Number.isInteger(Number(event.target?.value))) {
        return t(`WholeNumberValidation`);
      }
    },
    [minContainers, t]
  );

  const futureContractsValidation = useCallback((value) => {
    if (Number(value) <= 0) {
      return t('Value can not be a 0 or a negative number');
    } else if (!Number.isInteger(Number(value))) {
      return t(`WholeNumberValidation`);
    }
  }, []);

  const onChangeNumContainersHandler = useCallback(
    (onChange, event) => {
      // if (
      //   !!event.target?.value &&
      //   Number(event.target?.value) < Number(minContainers)
      // ) {
      //   setError('NumContainers', {
      //     type: 'error',
      //     message: `Min containers count must be greater or equal to ${minContainers}`,
      //   });

      //   onChange(event.target?.value);
      // }
      if (!!event.target?.value && Number(event.target?.value) < 1) {
        setError('NumContainers', {
          type: 'error',
          message: `Min containers count must be greater or equal to 1`,
        });

        onChange(event.target?.value);
      } else if (!Number.isInteger(Number(event.target?.value))) {
        setError('NumContainers', {
          type: 'error',
          message: `WholeNumberValidation`,
        });

        onChange(event.target?.value);
      } else {
        clearErrors('NumContainers');
        onChange(event.target?.value);
      }
    },
    [clearErrors, minContainers, setError]
  );

  const onChangeFutureContractsHandler = useCallback(
    (onChange, event) => {
      if (!!event.target?.value && Number(event.target?.value) <= 0) {
        setError('FutureContracts', {
          type: 'error',
          message: `Value can not be a 0 or a negative number`,
        });

        onChange(event.target?.value);
      } else if (!Number.isInteger(Number(event.target?.value))) {
        setError('FutureContracts', {
          type: 'error',
          message: `WholeNumberValidation`,
        });

        onChange(event.target?.value);
      } else {
        clearErrors('FutureContracts');
        onChange(event.target?.value);
      }
    },
    [clearErrors, setError]
  );

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const [species] = getValues(['Species']);
      if (!species) {
        return;
      }

      if (watchSpecies === speciesArabica) {
        // Arabica
        setValue(`NetWeightUnitId`, 'kg');
      } else if (watchSpecies === speciesRobusta) {
        // Robusta
        setValue(`NetWeightUnitId`, 'MT');
      }
    }
  }, [watchSpecies]);

  useEffect(() => {
    const contractsField = stepFields.find((field) => field.Key === 'Contract');
    const incotermsField = stepFields.find(
      (field) => field.Key === 'Incoterms'
    );
    setContracts(contractsField?.Options);
    setIncoterms(incotermsField?.Options);
  }, [stepFields, setContracts, setIncoterms]);

  useEffect(() => {
    if (Object.keys(stepFields)?.length > 0) {
      setQuoteMainFields(JSON.parse(JSON.stringify(stepFields)));
    }
  }, [stepFields]);

  const assignInputGrid = (key) => {
    switch (key) {
      case 'NumContainers':
        return 3;
      case 'ServiceFee':
        return 5;
      default:
        return 4;
    }
  };

  const renderFutureContracts = useCallback(
    (item) => {
      if (watchPriceType === 'Differential') {
        return (
          <Controller
            name={item.Key}
            rules={{
              required: {
                value: !!item.Required,
                message: t('Field is required'),
              },
              validate: futureContractsValidation,
            }}
            render={({ field }) => (
              <Grid columns={assignInputGrid(item.Key)}>
                <MuiOutlinedInputComponent
                  {...field}
                  onChange={(value) =>
                    onChangeFutureContractsHandler(field.onChange, value)
                  }
                  error={errors[item.Key]}
                  type={item.Type}
                  label={item.Label}
                  required={!!item.Required}
                  disabled={!!item.Readonly || !!selectedStep?.Done}
                  placeholder={t('Type')}
                />
              </Grid>
            )}
          />
        );
      } else {
        return null;
      }
    },
    [
      watchPriceType,
      errors,
      onChangeFutureContractsHandler,
      futureContractsValidation,
    ]
  );

  // if FE needs to assign which field is required
  const customFieldValidation = useCallback(
    (item) => {
      switch (item.Key) {
        case 'Contract':
          if (watchPriceType === 'Differential') {
            return true;
          } else {
            return false;
          }
        default:
          return !!item.Required;
      }
    },
    [watchSpecies, watchPriceType]
  );

  const requestServicesValidation = useCallback(
    (item) => {
      if (isAdmin || isTrader) {
        switch (item.Key) {
          case 'RequestFinance':
            return watchFinance === 'undefined' ? false : watchFinance;
          case 'RequestInsurance':
            return watchInsurance === 'undefined' ? false : watchInsurance;
          case 'BuyerInsurance':
            return watchBuyerInsurance === 'undefined'
              ? false
              : watchBuyerInsurance;
          case 'FxHedging':
            return watchFxHedging === 'undefined' ? false : watchFxHedging;
          case 'SeaFreight':
            return watchSeaFreight === 'undefined' ? false : watchSeaFreight;
          case 'LandTransport':
            return watchLandTransport === 'undefined'
              ? false
              : watchLandTransport;
          case 'LoadingSupervision':
            return watchLoadingSupervision === 'undefined'
              ? false
              : watchLoadingSupervision;
          case 'QualityChecks':
            return watchQualityChecks === 'undefined'
              ? false
              : watchQualityChecks;
        }
      } else return false;
    },
    [
      watchBuyerInsurance,
      watchFinance,
      watchFxHedging,
      watchInsurance,
      watchLandTransport,
      watchLoadingSupervision,
      watchQualityChecks,
      watchSeaFreight,
    ]
  );

  const renderMainFields = useCallback(() => {
    return (
      <>
        {quoteMainFields?.map((item, i) => {
          if (item.Key === 'UnitPriceCurrencyId') {
            return null;
          } else if (item.Key === 'NumContainers') {
            return (
              <Controller
                key={i}
                name={item.Key}
                rules={{
                  required: {
                    value: !!item.Required,
                    message: t('Field is required'),
                  },
                  validate: containersValidation,
                }}
                render={({ field }) => (
                  <Grid columns={assignInputGrid(item.Key)}>
                    <MuiOutlinedInputComponent
                      {...field}
                      onChange={onChangeNumContainersHandler.bind(
                        this,
                        field.onChange
                      )}
                      error={errors[item.Key]}
                      type={item.Type}
                      label={item.Label}
                      required={!!item.Required}
                      disabled={!!selectedStep?.Done}
                      placeholder={t('Type')}
                      defaultValue={item.Value}
                    />
                  </Grid>
                )}
              />
            );
          } else if (item.Key === 'FutureContracts') {
            return <Fragment key={i}>{renderFutureContracts(item)}</Fragment>;
          }

          return (
            <Fragment key={i}>
              {item.Type === 'placeholder' ? (
                <ProductsListComponent
                  products={fields}
                  onDelete={(index) => remove(index)}
                  properties={stepProduct.Properties}
                  validation={true}
                  errors={errors.Products}
                />
              ) : (
                <Controller
                  name={item.Key}
                  rules={{
                    required: {
                      value: customFieldValidation(item),
                      message: t('Field is required'),
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <Grid columns={assignInputGrid(item.Key)}>
                        <FormInputFields
                          info={{ ...field }}
                          fields={item}
                          volumeUnit={getValues('NetWeightUnitId')}
                          onChange={(value) => {
                            if (field.name === 'PriceType') {
                              const products = getValues('Products');
                              products.forEach((_, index) =>
                                setValue(`Products[${index}].UnitPrice`, '')
                              );
                              setValue('Contract', '');
                            }
                            field.onChange(value);
                          }}
                          required={customFieldValidation(item)}
                          disabled={!!item.Readonly || !!selectedStep?.Done}
                          error={errors[item.Key]}
                          minDate={false}
                        />
                      </Grid>
                    );
                  }}
                />
              )}
            </Fragment>
          );
        })}
        <p className={styles.servicesHeading}>{t('RequestServices')}</p>
        {services.map((item, i) => {
          return (
            <Fragment key={i}>
              <Grid columns={isTrader || isAdmin ? 12 : 6}>
                <div className={styles.requestServicesWrap}>
                  <Controller
                    name={item.Key}
                    defaultValue={false}
                    render={({ field }) => {
                      return (
                        <div className={styles.financingSwitcher}>
                          <SwitchComponent
                            values={['', t(item.Label)]}
                            onChange={(event) => {
                              field.onChange(event);
                            }}
                            checked={field.value}
                            onColor={variables.primaryBtnBgLight}
                            onHandleColor={variables.primaryBtnBgLight}
                            offColor='#999999'
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={48}
                            handleDiameter={30}
                            className={'financing-switcher'}
                          />
                        </div>
                      );
                    }}
                  />
                  <Controller
                    name={`${item.Key}For`}
                    defaultValue={null}
                    rules={{
                      required: {
                        value: requestServicesValidation(item),
                        message: t('Field is required'),
                      },
                      //validate: futureContractsValidation,
                    }}
                    control={control}
                    render={({ field }) => {
                      return (
                        <>
                          {(isTrader || isAdmin) && (
                            <div className={styles.selectInput}>
                              <MuiAutocomplete
                                disableClearable
                                freeSolo={false}
                                onChange={(value) => {
                                  clearErrors(`${item.Key}For`);
                                  setValue(`${item.Key}For`, value);
                                }}
                                value={field.value}
                                options={requestSideOptions}
                                placeholder='Select'
                                error={errors[`${item.Key}For`]}
                                //required={true}
                                disabled={!watch(`${item.Key}`)}
                              />
                            </div>
                          )}
                        </>
                      );
                    }}
                  />
                  <Controller
                    name={`${item.Key}IsPrivate`}
                    defaultValue={null}
                    render={({ field }) => {
                      return (
                        <>
                          {(isTrader || isAdmin) && (
                            <label
                              key={item.StepId}
                              className={`create-step`}
                              onClick={(e) => {
                                setValue(
                                  `${item.Key}IsPrivate`,
                                  !watch(`${item.Key}IsPrivate`)
                                );
                              }}>
                              <span
                                className={`checkmark ${
                                  watch(`${item.Key}IsPrivate`) ? 'checked' : ''
                                } `}>
                                {watch(`${item.Key}IsPrivate`) && (
                                  <div className='check'>
                                    <CheckmarkIcon />
                                  </div>
                                )}
                              </span>
                              <p>{t('Private')}</p>
                            </label>
                          )}
                        </>
                      );
                    }}
                  />
                </div>
              </Grid>
            </Fragment>
          );
        })}
      </>
    );
  }, [
    quoteMainFields,
    fields,
    control,
    remove,
    getValues,
    minContainers,
    errors,
    watchPriceType,
    services,
    requestSideOptions,
    requestServicesValidation,
  ]);

  return (
    <>
      {Object.keys(quoteMainFields).length > 0 && (
        <PageLoader isLoading={loading}>
          <div className={styles.mainForm}>{renderMainFields()}</div>
          <DealTotal />
          <div className={styles.footer}>
            {stepProduct?.Properties?.ReadOnly === 0 &&
              stepProduct?.Properties?.NewButton === 1 && (
                <div className={styles.newProductsWrap}>
                  <Button type={'empty'} size={'large'} onClick={addProducts}>
                    {t('New Product')}
                  </Button>
                </div>
              )}
          </div>
          {!!stepActions && (
            <ProductButtons
              handleSubmit={(actionType) =>
                handleSubmit(onSubmitHandler.bind(this, actionType))
              }
              stepActions={stepActions}
            />
          )}
        </PageLoader>
      )}
    </>
  );
};

export default memo(CreateStepTwo);
