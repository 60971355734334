/**
 * Deal Settings Page
 * @link /deal-settings
 */

import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MessagesList from '@organisms/Messages/MessagesList/messages-list.component';
import OpenMessages from '@organisms/Messages/OpenMessages/open-messages.component';
import commonService from '@services/common-service';
import { useWindowSize } from '@lib/hooks/useWindowSize';
import { useCallbackPrompt } from '@lib/hooks/InteruptPageChanging/useCallbackPrompt';
import { tabletMediaQuery } from '@base/globalVariables/global-variables';
import BackButton from '@atoms/Buttons/back-button.component';
import SingleAccordion from '@organisms/Accordion/SingleAccordion/single-accordion.component';
import PopupLayout from '@organisms/Popup/PopupLayout/popup-layout.component';
import PageLoader from '@templates/PageLoader/page-loader.template';
import PageContainer from '@templates/PageContainer/page-container.template';
import CalculatorFeesForm from '@pages/DealSettings/CalculatorFeesForm/calculator-fees-form.component';
import ContactsForm from '@pages/DealSettings/ContactsForm/contacts-form.component';
import SettingsGeneralForm from '@pages/DealSettings/GeneralForm/settings-general-form.component';

import './styles.scss';
import styles from './deal-settings.module.scss';
import {useUserRole} from "@lib/hooks/useUserRole";

const DealSettings = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  let submitRef = useRef({});
  const { isOrgAdmin } = useUserRole();

  const [loading, setLoading] = useState(true); // page loader
  const [generalSettings, setGeneralSettings] = useState([]); // array with the General Settings fields
  const [calculatorFields, setCalculatorFields] = useState([]); // array with the Calculator Settings fields
  const [contactFields, setContactFields] = useState([]); // array with the Contact Settings fields
  const [openedAccordion, setOpenedAccordion] = useState(1); //Index of the opened accordion
  const [accordionIndex, setAccordionIndex] = useState(0);
  const [showDialog, setShowDialog] = useState(false); // popup for the form changes
  const [dirtyForm, setDirtyForm] = useState(null); // the name of the current dirty form

  const [showPrompt, confirmNavigation] = useCallbackPrompt(dirtyForm); // page blocker

  const generalFormName = 'general';
  const calculatorFormName = 'calculator fees';
  const contactsFormName = 'contacts';

  // on Init
  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = useCallback(() => {
    commonService
      .getSettings()
      .then(({ data }) => {
        setGeneralSettings(data.General);
        setCalculatorFields(data.Calculator);
        setContactFields(data.Contact);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // if the form is dirty trigger the popup
  const checkFormState = useCallback(
    (isAccordionClosed, index, triggerPopup) => {
      if (triggerPopup) {
        setShowDialog(triggerPopup);
        setAccordionIndex(index);
      } else {
        setOpenedAccordion(index);
      }
    },
    []
  );

  // On Ignore button clicked inside the popup
  const ignoreChanges = useCallback(() => {
    setShowDialog(false);
    setDirtyForm(null);
    setOpenedAccordion(accordionIndex === openedAccordion ? 0 : accordionIndex);
    confirmNavigation(); // send to the redirect blocker
  }, []);

  // Refresh the forms, on update
  const handleRefresh = useCallback(() => {
    getSettings(); // refresh the values
  }, []);

  const handleConfirm = useCallback((index, currentIndex) => {
    submitRef.current[currentIndex].click();
    if (index === currentIndex) {
      setOpenedAccordion(0);
    } else {
      setOpenedAccordion(index);
    }
    setShowDialog(false);
    setDirtyForm(null);
    confirmNavigation(); // send to the redirect blocker
  }, []);

  // When the form gets dirty
  // Add to State the name of the form, so we can use it for:
  // the redirect blocker
  // and for the popup
  const handleIsFormDirty = useCallback((isDirty, formName) => {
    if (isDirty) {
      setDirtyForm(formName);
    } else {
      setDirtyForm(null);
    }
  }, []);

  const handlePopUpConform = (action) => {
    if (action === 'ignore') {
      return ignoreChanges();
    } else {
      handleConfirm(accordionIndex, openedAccordion);
    }
  };

  return (
    <PageLoader isLoading={loading}>
      <PageContainer>
        <div className={styles.container}>
          {showDialog && (
            <PopupLayout
              buttons={[
                {
                  buttonColor: 'tertiary',
                  buttonType: 'fill',
                  buttonTextSize: 'small',
                  buttonName: 'Ignore',
                  action: 'ignore',
                },
                {
                  buttonType: 'fill',
                  buttonTextSize: 'small',
                  buttonName: 'Confirm',
                  action: 'confirm',
                },
              ]}
              closePopUp={() => {
                setShowDialog(false);
              }}
              cancelButtonText={'Ignore'}
              onCancelClick={ignoreChanges}
              confirmButtonText={'Save'}
              onConfirmClick={(action) => {
                handlePopUpConform(action);
              }}
              popupTitle={`${t(
                'You have changes in section'
              )} <b>${dirtyForm}</b>`}
              buttonView={'flexEnd'}>
              {t('Would you like to save the changes?')}
            </PopupLayout>
          )}
          <section className={styles.settingsSection}>
            <div className={styles.headerWrap}>
              <h2>{t('DealSettings')}</h2>
              <BackButton
                size={'medium'}
                onClick={() => {
                  dirtyForm && setShowDialog(true);
                }}
              />
            </div>

            <SingleAccordion
              title={t('General')}
              opened={openedAccordion}
              index={1}
              onClick={(isAccordionClosed, triggerPopup) =>
                checkFormState(isAccordionClosed, 1, triggerPopup)
              }
              closeAccordion={dirtyForm ? false : true}>
              <SettingsGeneralForm
                fields={generalSettings}
                isFormDirty={(isDirty) =>
                  handleIsFormDirty(isDirty, generalFormName)
                }
                submitRef={(ref) => (submitRef.current[1] = ref)}
                refreshFields={handleRefresh}
              />
            </SingleAccordion>

            <SingleAccordion
              title={t('Calculator Fees')}
              opened={openedAccordion}
              index={2}
              onClick={(isAccordionClosed, triggerPopup) =>
                checkFormState(isAccordionClosed, 2, triggerPopup)
              }
              closeAccordion={dirtyForm ? false : true}>
              <CalculatorFeesForm
                fields={calculatorFields}
                isFormDirty={(isDirty) =>
                  handleIsFormDirty(isDirty, calculatorFormName)
                }
                submitRef={(ref) => (submitRef.current[2] = ref)}
                refreshFields={handleRefresh}
              />
            </SingleAccordion>

            {!isOrgAdmin &&
              <SingleAccordion
                title={t('Contacts')}
                opened={openedAccordion}
                index={3}
                onClick={(isAccordionClosed, triggerPopup) =>
                  checkFormState(isAccordionClosed, 3, triggerPopup)
                }
                closeAccordion={dirtyForm ? false : true}>
                <ContactsForm
                  fields={contactFields}
                  isFormDirty={(isDirty) =>
                    handleIsFormDirty(isDirty, contactsFormName)
                  }
                  submitRef={(ref) => (submitRef.current[3] = ref)}
                  refreshFields={handleRefresh}
                />
              </SingleAccordion>
            }
          </section>

          {width > tabletMediaQuery ? (
            <MessagesList archived={false} />
          ) : (
            <OpenMessages />
          )}
        </div>
      </PageContainer>
    </PageLoader>
  );
};

export default DealSettings;
