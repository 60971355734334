import React, { forwardRef, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import MuiSelect from '../MuiSelect/mui-select.component';
import MuiTextarea from '../MuiTextarea/mui-textarea.component';
import { MuiAutocomplete } from '../MuiAutocomplete/mui-autocomplete.component';
import { useStepSettingsContext } from '@lib/Context/StepSettings/step-settings.context';
import FormDate from '@atoms/Fields/ReactFormFields/FormDate/form-date.component';
import MuiOutlinedInput from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import Info from '@atoms/Fields/Info/info.component';
import InputLabel from '@atoms/Fields/InputLabel/input-label.components';
import Link from '@atoms/Fields/Link/Link';

const FormInputFields = forwardRef((props, ref) => {
  const {
    fields,
    info,
    isLoading,
    volumeUnit,
    currentArrayIndex,
    prefix = '',
    disabled,
    label,
    minDate,
    minDateValue,
    error,
    required,
    clearable,
  } = props;
  const { watch, getValues } = useFormContext();
  const { selectedStep } = useManageDealContext();
  const { stepSettingsContext } = useStepSettingsContext();
  const { t } = useTranslation();

  const chooseField = useCallback(() => {
    switch (fields.Type) {
      case 'dropdownAutocompleteInput': {
        const optionsKeysAsArray = Object.keys(fields.Options);
        if (optionsKeysAsArray.length <= 1) {
          return (
            <MuiOutlinedInput
              {...info}
              onChange={props.onChange}
              label={label}
              required={fields.Required}
              type={'number'}
              disabled={disabled || !!fields.Readonly}
              error={error}
            />
          );
        }
        return (
          <MuiAutocomplete
            {...info}
            freeSolo={true}
            type={'number'}
            onChange={props.onChange}
            label={label || fields.Label}
            required={fields.Required}
            defaultValue={fields.Value}
            options={fields.Options}
            placeholder={'Select'}
            disabled={disabled || !!fields.Readonly}
            error={error}
          />
        );
      }
      case 'dropdown': {
        if (!!fields.Searchable || !!fields.CreateProduct) {
          return (
            <MuiAutocomplete
              {...info}
              disableClearable={clearable ? !clearable : true}
              freeSolo={false}
              onChange={props.onChange}
              label={label || fields.Label}
              required={fields.Required}
              defaultValue={fields.Value}
              options={fields.Options}
              placeholder={'Select'}
              disabled={disabled || !!fields.Readonly}
              error={error}
            />
          );
        } else {
          return (
            <MuiSelect
              {...info}
              onChange={props.onChange}
              label={label || fields.Label}
              required={fields.Required}
              defaultValue={fields.Value}
              options={fields.Options}
              placeholder={'Select'}
              disabled={disabled || !!fields.Readonly}
              error={error}
            />
          );
        }
      }
      case 'contract': {
        let options = {};
        let label = '';

        if (prefix !== 'PC') {
          const [priceType, species] = getValues([
            `${prefix}PriceType`,
            `${prefix}Species`,
          ]);
          if (
            !priceType ||
            !species ||
            (watch(`${prefix}PriceType`) === 'Outright' &&
              watch(`${prefix}Species`))
          ) {
            return null;
          }

          label = `${t(fields.Label)} (${
            volumeUnit || stepSettingsContext?.NetWeightUnitId === 'lbs'
              ? 'NYCC'
              : 'ICEU'
          })`;

          const optionsAsArray = Object.entries(fields?.Options).filter(
            ([_, value]) => value.Species === species
          );
          options = Object.fromEntries(optionsAsArray);
        } else {
          label = `${t(fields.Label)} (${
            volumeUnit || stepSettingsContext?.NetWeightUnitId === 'lbs'
              ? 'NYCC'
              : 'ICEU'
          })`;
          const optionsAsArray = Object.entries(fields?.Options || []).filter(
            ([_, value]) => value
          );
          options = Object.fromEntries(optionsAsArray);
        }

        return (
          <MuiSelect
            {...info}
            options={options}
            label={label}
            required={required}
            placeholder={t('Select')}
            defaultValue={fields.Value}
            disabled={disabled || !!fields.Readonly}
            customItem={{
              currency: '$',
              volumeUnit: volumeUnit || stepSettingsContext?.NetWeightUnitId,
            }}
            error={error}
          />
        );
      }
      case 'date':
      case 'month':
      case 'dateUntil':
        return (
          <FormDate
            {...info}
            dateKey={fields.Key}
            label={fields.Label}
            required={fields.Required}
            readOnly={disabled || fields.Readonly}
            isStepActive={fields.isStepActive}
            defaultValue={fields.Value}
            view={fields.Type}
            minDate={minDate}
            minDateValue={minDateValue}
            error={error}
          />
        );
      case 'text':
      case 'number':
        return (
          <MuiOutlinedInput
            {...info}
            type={fields.Type}
            label={fields.Label}
            required={!!fields.Required}
            disabled={disabled || !!fields.Readonly}
            placeholder={fields.Placeholder || t('Type')}
            defaultValue={fields.Value}
            error={error}
          />
        );
      case 'textarea':
        return (
          <MuiTextarea
            {...info}
            type={fields.Type}
            label={fields.Label}
            required={!!fields.Required}
            disabled={disabled || !!fields.Readonly}
            error={error}
          />
        );
      case 'info': {
        return fields?.Value?.length > 0 ? (
          <>
            <InputLabel label={label || fields?.Label || ''} />
            <Info item={fields} />
          </>
        ) : null;
      }
      case 'link': {
        return fields?.Value?.length > 0 ? (
          <>
            <InputLabel label={label || fields?.Label || ''} />
            <Link item={fields} />
          </>
        ) : (
          ''
        );
      }
      default:
        return <></>;
    }
  }, [
    fields.Type,
    fields.Label,
    fields.Required,
    fields.Value,
    fields.Options,
    fields.Readonly,
    fields.Key,
    fields.isStepActive,
    info,
    watch,
    prefix,
    isLoading,
    volumeUnit,
    currentArrayIndex,
    selectedStep?.Done,
  ]);

  return chooseField();
});

FormInputFields.displayName = 'FormInputFields';

export default FormInputFields;
