import styles from "./not-found.module.scss";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import PageContainer from "@templates/PageContainer/page-container.template";
import Button from "../../01.atoms/Buttons/Button";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageContainer>
      <div className={styles.container}>
        <div className={styles.infoWrap}>
          <div className={styles.title}>404</div>
          <div className={styles.subTitle}>{t("The page you are looking for doesn't exist.")}</div>
          <div>
            <Button onClick={() =>  navigate("/")}>{t("Back to trade")}</Button>
          </div>
        </div>
        <div className={styles.imgWrap}>
          <img
            src={require('@assets/images/no-items-trasperant.png')}
            width='100%'
            alt='no image'
          />
        </div>
      </div>
    </PageContainer>
  )
}

export default NotFound;