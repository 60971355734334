import React, { useRef, useState, useEffect, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';

import ChevronDownIcon from '@assets/icons/ChevronDown';
import EmptySelect from '@atoms/EmptySelect/empty-select.component';
import Button from '@atoms/Buttons/Button';
import SearchSelector from '@organisms/SearchSelector/search-selector.component';

import './styles.scss';

function SelectInput({
  options,
  errors,
  isSearchable,
  onChangeOptionHandler,
  chosen,
  canClearValue,
  placeholder,
  field,
}) {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [highlightedEl, setHighlightedEl] = useState(-1);
  const [selection, setSelection] = useState(chosen ? chosen : '');
  const [selectOpen, setSelectOpen] = useState(false);
  const wrapperRef = useRef(null);
  const optionsListRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    !selectOpen && setFilteredOptions(options);
  }, [selectOpen]);

  const search = (value) => {
    let arr = [];
    options.map((el) => {
      if (el.toLowerCase().includes(value.toLowerCase())) arr.push(el);
    });

    setFilteredOptions(arr);
  };

  const select = (e) => {
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        if (!selectOpen) setSelectOpen(true);
        if (highlightedEl >= filteredOptions.length - 1) {
          setHighlightedEl(0);
          optionsListRef.current.scrollTo(0, 0);
        } else {
          setHighlightedEl(highlightedEl + 1);
          if (highlightedEl + 1 > 2) optionsListRef.current.scrollBy(0, 40);
        }
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        if (highlightedEl <= 0) {
          optionsListRef.current.scrollTo(
            0,
            optionsListRef.current.scrollHeight
          );
          setHighlightedEl(filteredOptions.length - 1);
        } else {
          setHighlightedEl(highlightedEl - 1);
          if (highlightedEl - 1 < filteredOptions.length - 2)
            optionsListRef.current.scrollBy(0, -40);
        }
        break;
      }
    }
  };

  const changeSelect = (item) => {
    if (typeof item === 'object') {
      const itemValues = Object.values(item);

      setSelection(itemValues[0] || itemValues);
    } else {
      setSelection(item);
    }

    optionsListRef.current.scrollTo(0, 0);
    setSelectOpen(false);
    if (onChangeOptionHandler) onChangeOptionHandler(item);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        optionsListRef.current.scrollTo(0, 0);
        setSelectOpen(false);
        setHighlightedEl(-1);
      }
    };

    if (selectOpen) document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [selectOpen]);

  const clearSelection = useCallback(
    (event) => {
      event.stopPropagation();
      setSelection('');
      onChangeOptionHandler('');
      setSelectOpen(false);
    },
    [onChangeOptionHandler]
  );

  useEffect(() => {
    setSelection(chosen ? chosen : '');
  }, [chosen]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  if (options.length > 0) {
    return (
      <React.Fragment>
        <div
          className={`select-container ${errors ? 'error' : ''}`}
          ref={wrapperRef}
          onKeyDown={select}
          tabIndex='0'>
          {isSearchable ? (
            <div
              className='choice'
              onClick={(e) => {
                selectOpen && optionsListRef.current.scrollTo(0, 0);
                setSelectOpen(!selectOpen);
              }}>
              <input
                {...field}
                id='selected-input'
                value={selection}
                placeholder={placeholder}
                readOnly={true}
              />
              {canClearValue && !!selection && (
                <Button
                  style={{ marginRight: 5, fontSize: 12 }}
                  type='link'
                  size='none'
                  onClick={clearSelection}>
                  {t('Clear')}
                </Button>
              )}
              <div
                className='arrow-container'
                style={{ transform: selectOpen ? 'rotate(180deg)' : '' }}>
                {filteredOptions &&
                  ((isSearchable && filteredOptions.length > 0) ||
                    (!isSearchable && filteredOptions.length > 1)) && (
                    <ChevronDownIcon />
                  )}
              </div>
            </div>
          ) : (
            <div
              className='choice'
              onClick={(e) => {
                selectOpen && optionsListRef.current.scrollTo(0, 0);
                setSelectOpen(!selectOpen);
              }}>
              <div className='text-container'>
                {selection && selection.length > 60 ? (
                  <Tooltip
                    enterTouchDelay={0}
                    title={
                      <Typography style={{ fontSize: 13 }}>
                        {selection}
                      </Typography>
                    }>
                    <span id='selected'>{selection}</span>
                  </Tooltip>
                ) : (
                  <span id='selected'>{selection || placeholder}</span>
                )}
              </div>

              {canClearValue && !!selection && (
                <Button
                  style={{ marginRight: 5, fontSize: 12 }}
                  type='link'
                  size='none'
                  onClick={clearSelection}>
                  {t('Clear')}
                </Button>
              )}

              <div
                className='arrow-container'
                style={{ transform: selectOpen ? 'rotate(180deg)' : '' }}>
                {options && options.length && <ChevronDownIcon />}
              </div>
            </div>
          )}
          {filteredOptions && (isSearchable || !isSearchable) && (
            <div
              ref={optionsListRef}
              className={`options-list ${selectOpen && 'opened'}`}
              onMouseLeave={() => {
                setHighlightedEl(-1);
              }}>
              {options.length > 9 && (
                <SearchSelector
                  onChange={(e) => search(e)}
                  selectOpen={selectOpen}
                />
              )}

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectOpen(false);
                }}>
                {filteredOptions?.map((item, index) => {
                  if (item) {
                    const itemArray = Object.values(item); //This line is needed in order to output the item

                    return (
                      <div
                        key={index}
                        className={`option  ${
                          highlightedEl === index ? 'highlight' : ''
                        }`}
                        onClick={() => {
                          changeSelect(item);
                        }}
                        onMouseEnter={() => {
                          setHighlightedEl(index);
                        }}>
                        <span className='text-container'>
                          {typeof item === 'object' ? itemArray[0] : item}
                        </span>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}
        </div>
        {errors && <p className='error-container'>{errors[0]}</p>}
      </React.Fragment>
    );
  } else {
    return (
      <EmptySelect placeholder={'Currently there are no options available.'} />
    );
  }
}

export default memo(SelectInput);
