import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import "@lib/config/i18n";

import Messages from "./Pages/Messages";
import ShowFile from "./Pages/ShowFile";
import DealInvitation from "./Pages/DealInvitation";
import IdentityCallback from "./Pages/IdentityCallback";
import ScrollToTop from "./Components/Shared/ScrollToTop";
import Loader from "./Components/Shared/Loader/Loader";
import NetworkInterceptor from "./Components/Shared/NetworkInterceptor/NetworkInterceptor";
import { IDENTITY_URL } from "@config/config";
import fetchCommonData from "@services/authentication-services";
import { userAuthorize } from "@lib/services/httpClient";
import {
  activeDealsLink,
  archivedDealsLink,
  createDealBuyLink,
  createDealSellLink,
  settingsLink,
} from "@base/globalVariables/page-links";
import Popup from "@organisms/Popup/popup.component";
import Header from "@templates/Header/header.template";
import Footer from "@templates/Footer/footer.template";
import ContextUnite from "@templates/ContextUnite/context-unite.template";
import NotFound from "@pages/NotFound/not-found.page";
import AccessDenied from "@pages/AccessDenied/AccessDenied";
import Deals from "@pages/Deals/deals.page";
import DealSettings from "@pages/DealSettings/deal-settings.page";
import ManageDeal from "@pages/ManageDeal/manage-deal.page";
import CreateDealBuy from "@pages/CreateDealBuy/create-deal-buy.page";
import CreateDealSell from "@pages/CreateDealSell/create-deal-sell.page";
import EditDeal from "@pages/EditDeal/edit-deal.page";
import IdentitySignout from "@pages/IdentitySignout/identity-signout";

import "./App.scss";
import FetchUserDetails from "Pages/FetchUserDetails/FetchUserDetails";
import NoPermissions from "@pages/NoPermissions/NoPermissions";
import { ReportsPage } from "@pages/Reports/reports.page";

function App() {
  const [cookies] = useCookies();
  const [isPendingUser, setIsPendingUser] = useState(false);

  useEffect(() => {
    //authorization
    if (
      !cookies["id_token"] &&
      window.location.pathname !== "/identityCallback"
    ) {
      userAuthorize();
    }
  }, []);

  return cookies["id_token"] ? (
    <div className="App">
      <ContextUnite>
        <Router>
          {window.location.pathname.includes("/download") ? null : <Header />}
          <FetchUserDetails setIsPendingUser={setIsPendingUser} />
          <ScrollToTop />
          {!isPendingUser ? (
            <Routes>
              <Route
                exact
                path={activeDealsLink}
                element={<Deals archived={false} />}
              />
              <Route exact path="/manage-deal/:id" element={<ManageDeal />} />
              <Route
                exact
                path={createDealBuyLink}
                element={<CreateDealBuy state={"WantToBuy"} />}
              />
              <Route
                exact
                path={createDealSellLink}
                element={<CreateDealSell state={"WantToSell"} />}
              />
              <Route exact path="/edit-deal/:id" element={<EditDeal />} />
              <Route exact path="/messages" element={<Messages />} />
              <Route exact path="/messages/:id" element={<Messages />} />
              <Route exact path="/download/:file" element={<ShowFile />} />
              <Route
                exact
                path={archivedDealsLink}
                element={<Deals archived={true} />}
              />
              <Route exact path={settingsLink} element={<DealSettings />} />
              <Route exact path={"/reports"} element={<ReportsPage />} />
              <Route
                exact
                path="/deal-invitation/:hashString"
                element={<DealInvitation />}
              />
              <Route
                exact
                path="/identityCallback"
                element={<IdentityCallback />}
              />
              <Route exact path="/error/403" element={<AccessDenied />} />
              <Route exact path="/error/404" element={<NotFound />} />
              <Route
                exact
                path="/identity-signout"
                element={<IdentitySignout />}
              />
            </Routes>
          ) : (
            <Routes>
              <Route exact path="/" element={<NoPermissions />} />
              <Route
                exact
                path="/identity-signout"
                element={<IdentitySignout />}
              />
              <Route
                exact
                path="/identity-signout"
                element={<IdentitySignout />}
              />
              <Route exact path="*" element={<NoPermissions />} />
            </Routes>
          )}
        </Router>
        <NetworkInterceptor />
        <Loader />
        <Popup />
      </ContextUnite>
    </div>
  ) : (
    <Router>
      <Routes>
        <Route exact path="/identityCallback" element={<IdentityCallback />} />
        <Route exact path="/identity-signout" element={<IdentitySignout />} />
      </Routes>
    </Router>
  );
}

export default App;
