import React, { useEffect, useState, memo, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { useNavigation } from "@lib/hooks/useNavigation";

import PrintContent from "../../../Shared/PrintContent/PrintContent";
import SendMessageComponent from "../SendMessage/send-message.component";
import { useWindowSize } from "@lib/hooks/useWindowSize";
import messagesService from "@lib/services/messages-service";
import variables from "@base/styles/variables.scss";
import Message from "../Message/message";
import { useLocation } from "react-router-dom";

import "./styles.scss";
import { useManageDealContext } from "@lib/Context/ManageDealContext/manage-deal.context";

function MessagesList({
  refresh,
  archived,
  stepId,
  isNotification,
  onBack,
  activePage,
  activeService,
}) {
  const params = useParams();
  const location = useLocation();
  const { redirect } = useNavigation();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [buttonText, setButtonText] = useState("Load More");
  const [messages, setMessages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(0);
  const [hideSystemMessages, setHideSystemMessages] = useState(
    JSON.parse(localStorage.getItem("hide-system-messages")) || false
  );
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [viewAllChannels, setViewAllChannels] = useState(
    JSON.parse(localStorage.getItem("show-all-channels")) || false
  );
  const { canWrite, channel, setChannel } = useManageDealContext();

  const labels = {
    deal: t("Deal"),
    insurance: t("RequestInsurance"),
    finance: t("RequestFinance"),
    buyer_insurance: t("BuyerInsurance"),
    fx_hedging: t("FxHedging"),
    sea_freight: t("SeaFreight"),
    land_transport: t("LandTransport"),
    supervision: t("LoadingSupervision"),
    quality_checks: t("QualityChecks"),
  };

  const showMessages = () => {
    let p;
    if (buttonText === "Show Less") {
      p = 0;
      setPage(0);
      setButtonText("Load More");
    } else {
      p = page + 1;
      setPage(page + 1);
    }

    getMessages(
      p,
      hideSystemMessages ? "1" : "0",
      channel || location.state?.channel
    );
  };

  const getMessages = (currentPage, hideSystem, channel) => {
    if (params.id) {
      messagesService
        .getDealsMessages(
          params.id,
          currentPage,
          hideSystem,
          stepId,
          channel || location.state?.channel,
          setChannel === undefined ? false : viewAllChannels
        )
        .then((r) => {
          if (r.data._meta.pageCount === currentPage + 1) {
            setButtonText("Show Less");
          }
          currentPage !== 0
            ? setMessages([...messages, ...r.data.items])
            : setMessages(r.data.items);
          setUser(r.data.user);
          if ("GeneratedDocuments" in r.data) {
            setDocuments([...r.data.GeneratedDocuments]);
          } // get the Generated Documents needed for the SendMessage dropdown
          setIsLoading(false);
        });
    } else {
      messagesService.getAllMessages(currentPage, hideSystem).then((r) => {
        if (r.data._meta.pageCount === currentPage + 1) {
          setButtonText("Show Less");
        }
        currentPage !== 0
          ? setMessages([...messages, ...r.data.items])
          : setMessages(r.data.items);
        setUser(r.data.user);
        setIsLoading(false);
      });
    }
  };

  const hideMessages = (value) => {
    localStorage.setItem("hide-system-messages", value);
    setHideSystemMessages(value);
    setButtonText("Load More");
    setPage(0);
    getMessages(0, value ? "1" : "0", channel || location.state?.channel);
  };

  const getAllMessagesMessages = (value) => {
    localStorage.setItem("show-all-channels", value);
    setViewAllChannels(value);
    setButtonText("Load More");
    setPage(0);
    // getMessages(0, value ? '1' : '0', channel);
  };

  useEffect(() => {
    getMessages(
      0,
      hideSystemMessages ? "1" : "0",
      channel || location.state?.channel
    );
  }, [refresh, activePage, activeService, viewAllChannels, channel]);

  const componentRef = useRef(null);
  const contentToPrint = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  return (
    <>
      {!isLoading && (
        <section
          className={`messages-container ${
            isNotification ? "notification-container" : ""
          }`}
        >
          <div className="head">
            {width < 1160 ? (
              <div className={"mobile__message--back-btn"}>
                <span
                  onClick={() => {
                    redirect("/");
                    typeof onBack === "function" && onBack();
                  }}
                >
                  <ArrowBackIosIcon />
                  <span className={"back-text-wrap"}>{t("Back")}</span>
                </span>
              </div>
            ) : (
              ""
            )}
            <div className={"message-head--inner-wrap"}>
              <div>
                <div className={"message-header"}>
                  <h3>{t("Messages")}</h3>
                  <PrintContent content={contentToPrint} />
                </div>
                {!isNotification && canWrite && (
                  <p className={"channel"}>{`${
                    labels[channel || location.state?.channel]
                  } ${t("Channel")}`}</p>
                )}
              </div>
              <div className="hide-messages">
                <div>
                  <p>{t("Hide system messages")}</p>
                  <div className={"message-header__buttons-wrap"}>
                    <div>
                      <Switch
                        onChange={hideMessages}
                        checked={hideSystemMessages}
                        onColor={variables.primaryBtnBgLight}
                        onHandleColor="#FFF"
                        offColor="#bdb9b9"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={48}
                        handleDiameter={13}
                      />
                    </div>
                  </div>
                </div>
                {!isNotification && (
                  <div>
                    <p>{t("ViewAllMessages")}</p>
                    <div className={"message-header__buttons-wrap"}>
                      <div>
                        <Switch
                          onChange={getAllMessagesMessages}
                          checked={viewAllChannels}
                          onColor={variables.primaryBtnBgLight}
                          onHandleColor="#FFF"
                          offColor="#bdb9b9"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={48}
                          handleDiameter={13}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!archived && (canWrite || location.state?.canWrite) && (
            <SendMessageComponent
              documents={documents}
              channel={channel || location.state?.channel}
              setMessages={setMessages}
              messages={messages}
            />
          )}
          <div ref={componentRef} className="messages-list">
            {messages.length > 0 ? (
              messages.map((item, index) => {
                return (
                  <Message
                    archived={archived}
                    message={item.Messages}
                    files={item.Files}
                    userRole={user}
                    key={index}
                    refresh={() => {
                      getMessages(page, hideSystemMessages ? "1" : "0");
                    }}
                    viewAllChannels={isNotification ? false : viewAllChannels}
                    setChannel={setChannel}
                  />
                );
              })
            ) : (
              <div className="messages-list">
                <div className={"missing-messages"}>
                  {t("Currently there are no messages to be displayed")}
                </div>
              </div>
            )}
          </div>
          {messages.length >= 5 && (
            <button className="show-more" onClick={showMessages}>
              {t(buttonText)}
            </button>
          )}
        </section>
      )}
    </>
  );
}

export default memo(MessagesList);
