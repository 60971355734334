import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export const useDealsContext = () => {
  const context = useContext(DealsContext);
  if (!context) {
    throw new Error(
      'DealsContext must be used within a useActiveDealsProvider'
    );
  }
  return context;
};

const DealsContext = createContext();

export const ActiveDealsProvider = ({ children }) => {
  const [filterPage, setFilterPage] = useState({
    currentPage: 0,
    pageCount: null,
    perPage: 10,
    totalCount: null,
  });
  const [filterColumns, setFilterColumns] = useState({});
  const [columns, setColumns] = useState([
    'name',
    'origin',
    'product',
    'priceType',
    'pricePerUnit',
    'incoterms',
    'dealStatus',
  ]);
  const [filterColumnsArray, setFilterColumnsArray] = useState([]);

  const setFilterByColumns = useCallback(
    (columnName, value) => {
      if (value !== 'none') {
        if (columnName in filterColumns) {
          setFilterColumns((current) => ({ ...current, [columnName]: value }));
          setFilterColumnsArray((current) => [
            ...current.filter((col) => !col.includes(columnName)),
            `${columnName}:${value}`,
          ]);
        } else {
          setFilterColumnsArray((current) => [
            ...current,
            `${columnName}:${value}`,
          ]);
          setFilterColumns((current) => ({
            ...current,
            [columnName]: value,
          }));
        }
      } else {
        if (columnName in filterColumns) {
          setFilterColumnsArray((current) => [
            ...current.filter((col) => !col.includes(columnName)),
          ]);
          const filter = Object.entries(filterColumns)
            .filter(([key, _]) => key !== columnName)
            .reduce((acc, [key, value]) => {
              acc[key] = value;
              return acc;
            }, {});
          setFilterColumns(filter);
        }
      }
    },
    [filterColumns, setFilterColumns]
  );

  // on Manage Columns change reset the asc/desc filters
  const cleanFilters = useCallback(() => {
    setFilterColumnsArray([]);
    setFilterColumns({});
  }, [setColumns]);

  const store = useMemo(
    () => ({
      filterPage,
      columns,
      filterColumns,
      filterColumnsArray,
      setColumns,
      setFilterPage,
      setFilterByColumns,
      cleanFilters,
    }),
    [filterPage, columns, setFilterByColumns, filterColumns, filterColumnsArray]
  );

  return (
    <DealsContext.Provider value={store}>{children}</DealsContext.Provider>
  );
};
