export function weightConverter({ weight: weight, weightUnit: weightUnit }) {
  const unitLbs = 'lbs';
  const unitMT = 'MT';
  let weightInKg = 0;

  if (weightUnit === unitLbs) {
    weightInKg = weight / 2.20462;
  } else if (weightUnit === unitMT) {
    weightInKg = weight * 1000;
  } else {
    weightInKg = weight;
  }

  return weightInKg;
}
