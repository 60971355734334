import { t } from 'i18next';

const handleErrorsHelper = (data, setErrors, setAlert) => {
  switch (data.status) {
    case 500:
      return (
        setErrors && setErrors(),
        setAlert({
          state: 'error',
          message: t(`Server error! Please contact our support team.`),
        }),
        setTimeout(() => {
          setAlert({});
        }, 4000)
      );

    case 422:
      return (
        setErrors && setErrors(JSON.parse(data.message)),
        setAlert({
          state: "error",
          message: t(`There is a validation error.`)
        }),
        setTimeout(() => {
          setAlert({});
        }, 4000)
      );

    default:
      return (
        setErrors && setErrors({}),
        setAlert({ state: 'error', message: data.message }),
        setTimeout(() => {
          setAlert({});
        }, 4000)
      );
  }
};

export default handleErrorsHelper;
