import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function IdentityCallback() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();

  const hashParams = window.location.hash
    .replace('#', '')
    .split('&')
    .reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      if (!acc[key]) {
        acc[key] = value;
      }
      return acc;
    }, {});

  const id_token = window.location.hash.split('&')[0].replace('#id_token=', '');
  const access_token = window.location.hash
    .split('&')[1]
    .replace('access_token=', '');

  const setCookies = new Promise((resolve) => {
    setCookie('id_token', id_token, { maxAge: 3600 });
    setCookie('access_token', access_token, { maxAge: 3600 });
    setCookie(
      'i18next',
      hashParams.lng || localStorage.getItem('i18nextLng') || 'en',
      { maxAge: 3600 }
    );
    resolve(cookies['id_token']);
  });

  useEffect(() => {
    setCookies.then(() => {
      navigate(cookies['requestedURL'] || '/');
      removeCookie('requestedURL');
    });
  }, [id_token]);

  return <React.Fragment></React.Fragment>;
}

export default IdentityCallback;
