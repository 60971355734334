/**
 * General Form
 * @description: inside settings page
 * @param fields
 * @param isFormDirty: the parent can reset the dirty state. IF needed
 * @param refreshFields: on submit send to the parent in order to send the new updated fields. IF needed.
 * @param ref: coming from the parent in order the parent to trigger the submit button. IF needed.
 */

import {forwardRef, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";

import {usePopup} from "@lib/hooks/usePopup";
import commonService from "@services/common-service";
import FormInput from "@atoms/Fields/ReactFormFields/FormInput/form-input.component";
import Button from "@atoms/Buttons/Button";
import styles from "@pages/DealSettings/deal-settings.module.scss";

const SettingsGeneralForm = forwardRef(({fields, isFormDirty, refreshFields, submitRef}, ref) => {
  const { t } = useTranslation();
  const { showPopup } = usePopup();

  const regexOnlyNumbers = /^\d+$/;
  const errorOnlyNumbers = t("Only whole numbers are allowed.");

  const defaultValues = {
    defaultLanguage: fields?.defaultLanguage?.Value || "",
    perPageResults: fields?.perPageResults?.Value || "",
    dealLifetimeInDays: fields?.dealLifetimeInDays?.Value || ""
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm({defaultValues});

  const handleSubmitButton = useCallback((data) => {
    commonService
      .submitSettings(data)
      .then(() => {
        showPopup({
          state: 'success',
          message: t('Saved successfully'),
          timer: 4000,
        });
      })
      .finally(() => {
        reset(data)
        refreshFields()
      })
  }, [])

  useEffect(() => {
    isFormDirty(isDirty)
  }, [isDirty])

  return (
    <>
      <form>
        <Controller
          control={control}
          name='defaultLanguage'
          render={({ field }) =>
            <FormInput
              {...field}
              type={"text"}
              label={fields?.defaultLanguage?.Label}
              required={false}
              placeholder={'Choose a language'}
              error={errors.defaultLanguage?.message}
              maxLength={2}
            />
          }
        />

        <Controller
          name='perPageResults'
          control={control}
          rules={{
            pattern: {
              value: regexOnlyNumbers,
              message: errorOnlyNumbers
            }
          }}
          render={({ field }) =>
            <FormInput
              {...field}
              type={"number"}
              label={fields?.perPageResults?.Label}
              required={false}
              placeholder={'Enter a number'}
              error={errors.perPageResults?.message}
            />
          }
        />

        <Controller
          control={control}
          name='dealLifetimeInDays'
          rules={{
            pattern: {
              value: regexOnlyNumbers,
              message: errorOnlyNumbers
            }
          }}
          render={({ field }) =>
            <FormInput
              {...field}
              type={"number"}
              label={fields?.dealLifetimeInDays?.Label}
              required={false}
              placeholder={'Enter a number'}
              error={errors.dealLifetimeInDays?.message}
            />
          }
        />

      </form>
      <div className={styles.saveButtonWrap} >
        <Button
          type={"fill"}
          size={"large"}
          onClick={handleSubmit(handleSubmitButton)}
          disabled={!isDirty}
          ref={submitRef}
        >{t("Save")}</Button>
      </div>
    </>
  )
})

SettingsGeneralForm.displayName = "SettingsGeneralForm";

export default SettingsGeneralForm;
