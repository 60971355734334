import React, { createContext, useReducer, useMemo, useContext } from 'react';
import { ActiveDealsProvider } from './Deals/deals.context';
import {
  appStoreInitialState,
  appStoreReducer,
  asyncActionMap,
} from './reducer';

export const useAppStore = () => {
  const context = useContext(AppStoreContext);
  if (!context) {
    throw new Error(
      'useAppStoreContext must be used within a AppStoreProvider'
    );
  }
  return context;
};

const AppStoreContext = createContext({});

export const AppStoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appStoreReducer, appStoreInitialState);
  const store = useMemo(
    () => ({
      state,
      dispatch: (action) => {
        const asyncActionHandler = asyncActionMap[action.type];
        if (asyncActionHandler) {
          asyncActionHandler(action.payload).then(dispatch);
        }
        dispatch(action);
      },
    }),
    [state, dispatch]
  );

  return (
    <AppStoreContext.Provider value={store}>
      <ActiveDealsProvider>{children}</ActiveDealsProvider>
    </AppStoreContext.Provider>
  );
};

export default AppStoreProvider;
