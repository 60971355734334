/**
 * Name: Button Component
 * Description: button component used inside the platform.
 * Usage example: <Button onClick={submit} type={empty} size={small} />
 * @param size?: the size of the button. medium | large | xl | xxl
 * @param type?: the type of the button.
 * @param onClick: after button click send to the parent.
 * @param icon?: if the button has icon
 * @param btnTextColor?: if you need to change the text color of the button
 * @param btnTextSize: if you need to change the size of the text.
 * @param btnColor: secondary-grey | tertiary
 */
import { CircularProgress } from '@mui/material';
import { forwardRef } from 'react';

import './button.styles.scss';

const Button = forwardRef((props, ref) => {
  const {
    children,
    size = 'medium',
    type = 'fill',
    btnTextSize = 'normal',
    icon,
    btnTextColor,
    href,
    disabled,
    btnColor,
    fullWidth,
    loading,
    ...restProps
  } = props;
  const typeClass = type.replace(' ', '-').toLowerCase(); //the correct format
  const buttonProps = restProps;

  return (
    <button
      {...buttonProps}
      disabled={disabled}
      className={`
      button-size--${size} 
      button-type--${typeClass} 
      text-size--${btnTextSize} 
        ${fullWidth ? 'button-fullwidth' : ''}
        ${icon ? 'icon-button' : ''} 
        ${btnTextColor ? 'text-color--' + btnTextColor : ''}
        ${btnColor ? 'btn-color--' + btnColor : ''}
      `}
      ref={ref}>
      {icon && <img src={icon} alt='button icon' className='btn-icon' />}

      {loading ? (
        <CircularProgress size={18} sx={{ color: '#fff' }} />
      ) : (
        children
      )}
    </button>
  );
});

Button.displayName = 'Button';

export default Button;
