import { almacena_domain, ESCC_Terms_and_Conditions } from '@lib/config/config';
import { memo } from 'react';
import { Trans } from 'react-i18next';

import styles from './terms-and-conditions-message.module.scss';

const TermsAndConditionsMessage = () => {
  return (
    <div className={styles.message}>
      <Trans i18nKey='TermsAndConditionsMessage'>
        <b>Almacena</b>{' '}
        <a href={`${almacena_domain}/terms-and-conditions`}>
          Terms and Conditions
        </a>{' '}
        and <b>ESCC</b>{' '}
        <a href={`${ESCC_Terms_and_Conditions}`}>Terms and Conditions</a> are
        applied.
      </Trans>
    </div>
  );
};

export default memo(TermsAndConditionsMessage);
