import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import parse from 'html-react-parser';

import styles from './message-content.module.scss';

const MessageContent = ({ message, parser }) => {
  if (!parser) {
    return (
      <div className={styles.originalMessage}>{message?.OriginalMessage}</div>
    );

    // return (
    //   <>
    //     {message.OriginalMessage.split('<br>').map((ms, index) => (
    //       <React.Fragment key={index}>
    //         <div>{ms}</div>
    //       </React.Fragment>
    //     ))}
    //   </>
    // );
  }
  return (
    <>
      {message.Message.split('\n').map((ms, index) => {
        if (ms === '\r') {
          return <br key={index} />;
        }
        return ms.length > 40 ? (
          <React.Fragment key={index}>
            <Tooltip
              key={index}
              enterTouchDelay={0}
              title={
                <Typography style={{ fontSize: 13 }}>
                  {parse(String(ms))}
                </Typography>
              }>
              <div className={styles.message}>{parse(String(ms))}</div>
            </Tooltip>
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            <div className={styles.message}>{parse(String(ms))}</div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default MessageContent;
