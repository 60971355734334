/**
 * Main Component for the notification Messages.
 */

import React, { useState, memo } from 'react';

import { envelopeIcon } from '@base/icons/icons.loader';
import LoadingSpinner from '@molecules/LoadingSpinner/loading-spinner.component';
import MessagesList from '@organisms/Messages/MessagesList/messages-list.component';

import styles from './notification-messages.module.scss';

function NotificationMessages() {
  const [isMessageListVisible, setIsMessageListVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);

  const handleButtonClick = () => {
    if (!openMessages) {
      setIsLoading(true);
      setOpenMessages(!openMessages);
      setTimeout(() => {
        setIsMessageListVisible(!isMessageListVisible);
        setIsLoading(false);
      }, 1000);
    } else {
      setOpenMessages(!openMessages);
      setIsMessageListVisible(!isMessageListVisible);
    }
  };

  return (
    <div className={styles.messageWrap}>
      <div className={styles.buttonWrap}>
        <img
          src={envelopeIcon}
          alt='messages'
          onClick={handleButtonClick}
          className={styles.messageIcon}
        />
        {isLoading && (
          <div className={styles.spinnerWrap}>
            <LoadingSpinner />
          </div>
        )}
      </div>
      {isMessageListVisible && (
        <MessagesList
          onBack={() => setIsMessageListVisible(false)}
          isNotification={true}
          archived={false}
        />
      )}
    </div>
  );
}

export default memo(NotificationMessages);
