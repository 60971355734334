import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import dayjs from 'dayjs';

import localizationService from '@services/localization-service';
import { useAppStore } from '@lib/Context';
import {
  setDefaultContactSupport,
  setUserInfoAction,
} from '@lib/Context/actions';
import { useDealsContext } from '@lib/Context/Deals/deals.context';
import { usePopup } from '@lib/hooks/usePopup';
import dealsService from '@lib/services/deals-service';
import Popup from '@organisms/Popup/popup.component';
import TableView from '@organisms/DealListView/TableView/table-view.component';
import CardView from '@organisms/DealListView/CardView/card-view.component';
import ManageColumns from '@organisms/Filters/ManageColumns/manage-columns.component';
import PaginationComponent from '@organisms/Pagination/pagination.component';

import variables from '@css-variables';
import styles from './deals.module.scss';
import { useWindowSize } from '@lib/hooks/useWindowSize';
import { tabletMediaQuery } from '@base/globalVariables/global-variables';

const Deals = ({ archived }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showPopup } = usePopup();
  const { width } = useWindowSize();
  const {
    columns,
    filterColumns,
    setFilterPage,
    filterPage,
    filterColumnsArray,
  } = useDealsContext();
  const { dispatch, state: appStore } = useAppStore();

  const [view, setView] = useState(
    localStorage.getItem('deals-view') === 'card' ? true : false
  ); // true === card view, false === table view
  const [deals, setDeals] = useState([]);
  const [buttonText, setButtonText] = useState('Load More');
  const [loading, setLoading] = useState(false);

  // change to Card view if the viewport is under 1024
  useEffect(() => {
    if (width < tabletMediaQuery) {
      setView(true);
    }
  }, [width]);

  const switchViewHandler = useCallback((value) => {
    setView(value);
    localStorage.setItem('deals-view', value ? 'card' : 'list');

    // reset the deals count when you switch from card to list.
    if (!value) {
      fetchActiveDeals({ page: 0, type: 'pagination' });
    }
  }, []);

  const showDeals = () => {
    if (buttonText === 'Show Less') {
      setButtonText('Load More');
    }
    const currentPage =
      filterPage.currentPage === filterPage.pageCount - 1
        ? 0
        : filterPage.currentPage + 1;
    fetchActiveDeals({ page: currentPage });
  };

  const moveNextPage = (page) => {
    fetchActiveDeals({ page: page - 1, type: 'pagination' });
  };

  const pageInfo = useMemo(() => {
    if (!filterPage.totalCount) {
      return '';
    }
    const range =
      filterPage.perPage * (filterPage.currentPage + 1) < filterPage.totalCount
        ? filterPage.perPage * (filterPage.currentPage + 1)
        : filterPage.totalCount;

    // handle the start number on the different views: list and card
    let start = filterPage.currentPage * 10 + 1;

    return `${start} - ${range} out of ${filterPage.totalCount} items`;
  }, [filterPage.currentPage, filterPage.perPage, filterPage.totalCount]);

  const fetchActiveDeals = useCallback(
    ({ page, type }) => {
      const filter = state ? state.filter || null : null;

      setLoading(true);

      const params = {
        Page: page || 0,
        Order: filterColumnsArray,
        Archived: archived ? 1 : undefined,
      };

      dealsService
        .getDeals(filter, params)
        .then(({ data }) => {
          localStorage.setItem('userInfo', JSON.stringify(data.user));
          dispatch(setUserInfoAction(data.user));
          dispatch(setDefaultContactSupport(data.DefaultContactSupport));
          setFilterPage({
            ...data._meta,
            currentPage: data._meta.currentPage - 1,
          });

          if (type && type === 'pagination') {
            setDeals(data.items);
          } else {
            if (page === 0) {
              data.items.length === data._meta.totalCount
                ? setButtonText()
                : setButtonText('Load More');

              setDeals(data.items);
            } else {
              if ([...deals, ...data.items].length < data._meta.totalCount) {
                setButtonText('Load More');
              } else if (
                [...deals, ...data.items].length === data._meta.totalCount
              ) {
                setButtonText('Show Less');
              }
              setDeals([...deals, ...data.items]);
            }
          }
        })
        .finally(() => setLoading(false));
    },
    [state, archived, filterColumnsArray, setFilterPage, deals]
  );

  const deleteHandler = useCallback(
    (deal) => {
      dealsService.deleteDeal(deal?.Id).then(() => {
        const message = t('{{name}} is deleted successfully', {
          name: deal.Name,
        });
        showPopup({
          state: 'success',
          message,
          timer: 4000,
        });
        fetchActiveDeals({ page: 0 });
      });
    },
    [fetchActiveDeals]
  );

  const exportDealsHandler = useCallback(() => {
    const filter = state ? state.filter || null : null;
    const page = filterPage.currentPage;
    const date = dayjs(new Date()).format('DD_MM_YYYY_HH:mm');
    const fileName = `${
      archived ? 'Archived_Deals' : 'Active_Deals'
    }_${date}.xls`;

    const params = {
      Page: page || 0,
      Archived: archived ? 1 : undefined,
      Order: filterColumnsArray,
      Columns: columns,
      Export: 1,
    };

    dealsService
      .getDeals(filter, params)
      .then((response) => {
        const blobFile = new Blob([response.data]);
        const fileURL = URL.createObjectURL(blobFile);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', fileName);
        link.click();
      })
      .catch(console.error);
  }, [archived, columns, filterColumnsArray, filterPage.currentPage, state]);

  // The Deals should be refreshed in a several cases
  // Changed 'state': When the user use search field
  // Changed 'filterColumns': This is changed when the user clicks to sort a column
  // Changed 'archived': This is changed when the user goes to Archive/Active Deals pages
  useEffect(() => {
    fetchActiveDeals({ page: 0 });
  }, [state, filterColumns, archived]);

  useEffect(() => {
    if (state && state.dealCreated) {
      navigate({ state: null });
      showPopup({
        state: 'success',
        message: t('NewDealCreatedSuccessfully!'),
        timer: 4000,
      });
    }
  }, [state]);

  useEffect(() => {
    localizationService.refreshDictionary();
  }, []);

  return (
    <div className={styles.dealScreen}>
      <Popup message={alert.message} state={alert.state} />
      <div
        className={`${styles.container} ${
          !view ? styles.listViewContainer : ''
        }`}>
        <section className={styles.dealsContainer}>
          <div className={styles.dealsHeader} id={'scroll-anchor'}>
            <div className={styles.section}>
              {width > tabletMediaQuery && (
                <div className={styles.switchWrap}>
                  <Switch
                    className={styles.switchButton}
                    onChange={switchViewHandler}
                    checked={view}
                    onColor={'#4241DD'}
                    onHandleColor={variables.primaryBtnTextLight}
                    offColor={'#4241DD'}
                    uncheckedIcon={
                      <div className={styles.icon}>
                        <FormatListBulletedIcon fontSize='small' />
                      </div>
                    }
                    checkedIcon={
                      <div className={styles.icon}>
                        <GridViewIcon fontSize='small' />
                      </div>
                    }
                    height={20}
                    width={48}
                    handleDiameter={15}
                  />
                </div>
              )}
              <h2>{archived ? t('ArchivedDeals') : t('ActiveDeals')}</h2>
              {!view && !loading && <div>{pageInfo}</div>}
            </div>
            {!view && (
              <div className={styles.section}>
                {/* <div>
                  <IconButton onClick={exportDealsHandler}>
                    <FileDownloadIcon />
                  </IconButton>
                </div> */}
                <div>
                  <ManageColumns />
                </div>
              </div>
            )}
          </div>
          {/* true === card view, false === table view */}
          {view ? (
            <>
              <CardView deals={deals} loading={loading} />
              {buttonText && deals.length > 0 && (
                <button className={styles.showMore} onClick={showDeals}>
                  {t(buttonText)}
                </button>
              )}
            </>
          ) : (
            <>
              <TableView
                deals={deals}
                loading={loading}
                deleteDeal={deleteHandler}
              />
              {deals.length > 0 && (
                <div className={styles.paginationWrap}>
                  <PaginationComponent
                    count={filterPage.pageCount}
                    page={moveNextPage}
                  />
                </div>
              )}
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default Deals;
