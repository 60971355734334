/**
 * Description: button component used inside the platform.
 * Usage example: <Button onClick={submit} type={empty} size={small}>Text</Button>
 * @param size?: small | medium | large | none. Default value 'medium'.
 * @param type?: empty | fill | link. Default value 'fill'.
 * @param btnTheme?: primary | secondary. Default value 'primary'.
 * @param onClick.
 * @param disabled?.
 */

import {forwardRef} from "react";
import { createTheme, ThemeProvider } from '@mui/material';

import styleVariables from '@css-variables';
import StyledButton from './button.styles';

const primaryThemeBtn = createTheme({
  palette: {
    primary: {
      main: styleVariables.secondaryBtnBgDark,
      text: styleVariables.primaryBtnTextLight,
    },
  },
});

const secondaryThemeBtn = createTheme({
  palette: {
    primary: {
      main: styleVariables.tertiaryBtnBgGrey,
    },
  },
});

const tertiaryThemeBtn = createTheme({
  palette: {
    primary: {
      main: styleVariables.btnBgOrange,
    },
  },
});

const Button = forwardRef((props, ref) => {
  const {
    children,
    type = 'fill',
    size = 'medium',
    btnTheme = 'primary',
    btnTextSize = "normal",
    ...rest
  } = props;
  const themes = {
    primary: primaryThemeBtn,
    secondary: secondaryThemeBtn,
    tertiary: tertiaryThemeBtn,
  };

  const theme = themes[btnTheme] || primaryThemeBtn;

  return (
    <ThemeProvider theme={theme}>
      <StyledButton
        {...rest}
        variant='outlined'
        size={size}
        className={`
          btn-size--${size} btn-type--${type}
          text-size--${btnTextSize} 
        `}
        ref={ref}
      >{children}</StyledButton>
    </ThemeProvider>
  );
});

Button.displayName = "Button";

export default Button;
