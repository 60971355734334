/**
 * Deal Total
 * @description: shows the total information of a deal
 */

import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import DifferentialDealTotal from '@organisms/Products/DealTotal/DifferentialDealTotal/differential-deal-total.component';
import OutrightDealTotal from '@organisms/Products/DealTotal/OutrightDealTotal/outright-deal-total.component';

import styles from './deal-total.module.scss';

const DealTotal = ({ prefix = '', priceType }) => {
  const { watch } = useFormContext();

  const watchPriceType = priceType || watch(`${prefix}PriceType`);

  return (
    <div className={styles.container}>
      {watchPriceType === 'Outright' && (
        <OutrightDealTotal prefix={prefix} />
      )}
      {watchPriceType === 'Differential' && (
        <DifferentialDealTotal prefix={prefix} />
      )}
    </div>
  );
};

export default memo(DealTotal);
