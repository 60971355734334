import { httpClient } from './httpClient';

const RESOURCE_PATH = 'api-document';

const documentsService = {
  getDocument: function (filePath) {
    return httpClient.get(`${RESOURCE_PATH}/download/${filePath}`, {
      responseType: 'arraybuffer',
    });
  },

  getDocumentVersions: function (documentId, dealId) {
    return httpClient.get(
      `${RESOURCE_PATH}/${documentId}/documentVersions/${dealId}`
    );
  },

  uploadDocumentVersion: function (documentId, dealId, data) {
    return httpClient.post(
      `${RESOURCE_PATH}/${documentId}/documentVersions/${dealId}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },

  uploadDocument: function (data) {
    return httpClient.post(`${RESOURCE_PATH}/uploadDocument`, data);
  },

  uploadCreateDocument: function (data ) {
    return httpClient.post(`${RESOURCE_PATH}/uploadCreateDocument`, data)
  },

  signDocument: function (payload) {
    return httpClient.post(
      `${RESOURCE_PATH}/submitDocumentForSignature/${payload?.LatestDocumentVersionId}`,
      payload
    );
  },

  generateDocument: function (documentId, dealId) {
    return httpClient.get(
      `${RESOURCE_PATH}/${documentId}/generateDealPdfDocument/${dealId}`
    );
  },

  getAllDealDocuments: function (dealId) {
    return httpClient.get(`${RESOURCE_PATH}/dealDocuments/${dealId}`);
  },

  deleteDocument: function (documentId, dealId) {
    return httpClient.delete(
      `${RESOURCE_PATH}/${documentId}/deleteDocument/${dealId}`
    );
  },

  deleteDocumentVersion: function (dealId, documentId, versionId) {
    return httpClient.delete(
      `${RESOURCE_PATH}/${versionId}/deleteVersion/${documentId}/${dealId}`
    );
  },
};

export default documentsService;
