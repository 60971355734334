/**
 * Page Loader
 * description: show spinner until the page loads
 * @props isLoading
 * @props children
 */

import React from "react";

import LoadingSpinner from "@molecules/LoadingSpinner/loading-spinner.component";

const PageLoader = ({isLoading, children}) => {
  return (
    <>
      { isLoading ?
        <LoadingSpinner /> :
        children
      }
    </>
  )
}

export default PageLoader;
