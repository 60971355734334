import { useMemo } from 'react';
import { Fragment, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatCountryName,
  formatPrice,
  formatVolume,
} from '@lib/helpers/helpers';
import TooltipComponent from '../../../01.atoms/Tooltip/tooltip.component';

import styles from './deal-products.module.scss';

const DealProducts = ({ products }) => {
  const titleRef = useRef();
  const [isOverflowTitle, setIsOverflowTitle] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const compareTitle =
      titleRef.current?.scrollWidth > titleRef.current?.clientWidth;
    setIsOverflowTitle(compareTitle);
  }, [titleRef]);

  const renderProducts = (product, index) => {
    const {
      Name,
      Origin,
      Price,
      PriceCurrency,
      Species,
      Total,
      Volume,
      VolumeUnit,
    } = product;

    const productTitle = () => {
      const price =
        Price && PriceCurrency
          ? `| ${formatPrice(Price, PriceCurrency)}${VolumeUnit ? `/${VolumeUnit}` : '' }`
          : '';
      const volume =
        Volume && VolumeUnit
          ? price
            ? `| ${formatVolume(Volume, VolumeUnit)}`
            : `${formatVolume(Volume, VolumeUnit)}`
          : '';

      return `${Name} ${volume} ${price} `;
    };

    return (
      <Fragment key={index}>
        <TooltipComponent disable={!isOverflowTitle} text={product.Name}>
          <p ref={titleRef} className={styles.productName}>
            {productTitle()}
          </p>
        </TooltipComponent>

        <div className={styles.productDetails}>
          <p>
            <span>{t('Origin')}: </span>
            {formatCountryName(Origin) || '--'}
          </p>
          <p>
            <span>{t('Species')}: </span>
            {Species || '--'}
          </p>
          <p>
            <span>{t('Total')}: </span>
            {Total ? formatPrice(Total, PriceCurrency) : '--'}
          </p>
        </div>
      </Fragment>
    );
  };
  return (
    <div className={`${styles.container} ${styles.visible}`}>
      {!!products?.length && products.map(renderProducts)}
    </div>
  );
};

export default memo(DealProducts);
