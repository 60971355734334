import { Button as MaterialButton } from '@mui/material';
import styled from '@emotion/styled';

const StyledButton = styled(MaterialButton)(({ theme }) => ({
  borderColor: theme.palette.primary.main,

  '&.btn-type--fill': {
    border: 0,
    backgroundColor: 'var(--primary-btn-bg-light)',
    color: theme.palette.primary.text,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'var(--primary-btn-text-light)',
    },
  },

  '&:disabled': {
    backgroundColor: 'var(--primary-btn-bg-disabled)',
  },

  '&.btn-type--empty': {
    border: 0,
    backgroundColor: 'var(--primary-btn-bg-light)',
    color: theme.palette.primary.text,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'var(--primary-btn-text-light)',
    },

    '&:disabled': {
      border: 'var(--primary-btn-bg-disabled)',
      backgroundColor: 'var(--primary-btn-bg-disabled)',
      color: 'var(--primary-btn-text-light)',
    },
  },

  '&.btn-type--link': {
    border: 'none',
    background: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    cursor: 'pointer',

    '&:hover': {
      color: 'var(--primary-text-default)',
    },
  },

  '&.btn-size--none': {
    padding: 0,
  },

  '&.btn-size--medium': {
    padding: '5px 25px',
  },

  '&.btn-size--large': {
    padding: '15px 26px',
  },
}));

export default StyledButton;
