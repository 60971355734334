import React, {Fragment, useEffect, useState} from "react";
import { Controller, useForm, FormProvider } from "react-hook-form";
import {Trans, useTranslation} from "react-i18next";

import {useManageDealContext} from "@lib/Context/ManageDealContext/manage-deal.context";
import Grid from "@atoms/Grid/DynamicGrid/grid.component";
import FormInputFields from "@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component";
import Button from "@atoms/Button/button.component";
import TooltipComponent from "@atoms/Tooltip/tooltip.component";
import Heading from "@atoms/Headings/heading.component";
import Portal from "@molecules/Portal/portal.component";
import NotificationIcon from "@molecules/NotificationIcon/notification-icon.component";
import SingleAccordion from "@organisms/Accordion/SingleAccordion/single-accordion.component";
import PopupLayout from "@organisms/Popup/PopupLayout/popup-layout.component";
import PageLoader from "@templates/PageLoader/page-loader.template";
import dealsService from "@services/deals-service";
import AddLotPopup from "@organisms/Popup/AddLotPopup/add-lot-popup.component";
import { useUserRole } from "@lib/hooks/useUserRole";

import styles from "@organisms/Products/ProductAccordion/product-accordion.module.scss";

const PCEProductAccordion = (
  {
    index,
    productFields,
    productId,
    onDelete,
    properties,
    label
  }
) => {
  const { t } = useTranslation();
  const { isAdmin, isOrgAdmin, isBuyer } = useUserRole();

  const [openLotPopup, setOpenLotPopup] = useState(false);
  const [lotId, setLotId] = useState(null);
  const [icoNumberOptions, setIcoNumberOptions] = useState(0);

  const assignInputGrid = (key) => {
    switch (key) {
      default:
        return 12;
    }
  };

  useEffect(() => {
    setLotId(productFields[0]?.Value);
  }, [setLotId]);

  return (
    <div key={index} className={styles.container}>
      <SingleAccordion
        title={label}
        opened={true}
        titleButton={
          !!properties?.ReadOnly ? null : onDelete.bind(this, index)
        }
      >
        <div className={styles.accordionWrap}>
          {(isAdmin || isOrgAdmin || isBuyer) && (
            <div className={styles.addInventoryWrap}>
              <TooltipComponent text={!lotId ? t('PleaseSpecifyLot') : null } disable={!!lotId}>
                <Button
                  style={{ marginRight: 5, fontSize: 12, }}
                  type='link'
                  size='none'
                  disabled={!lotId}
                  onClick={() => {
                    setOpenLotPopup(true)
                  }}
                >
                  {t('AddToMyInventory')}
                </Button>
              </TooltipComponent>
            </div>
          )}

          {openLotPopup &&
            <AddLotPopup setOpenLotPopup={setOpenLotPopup} productId={productId} productIndex={index} lotId={lotId} icoNumberOptions={icoNumberOptions} />
          }
          {productFields.map((item, i) => {
            return (
              <Fragment key={i}>
                <Controller
                  name={`Products[${index}].${item.Key}`}
                  render={({ field }) => {
                    return (
                      <Grid columns={assignInputGrid(item.Key)}>
                        <FormInputFields
                          info={{ ...field }}
                          fields={item}
                          onChange={(value) => {
                            field.onChange(value);
                            setIcoNumberOptions(item.Options[value.target.value]);
                            setLotId(value.target.value);
                          }}
                        />
                      </Grid>
                    );
                  }}
                />
              </Fragment>
              )
          })}
        </div>
      </SingleAccordion>
    </div>
  )
}

export default PCEProductAccordion;
