import React from 'react';

import { ManageDealProvider } from '@lib/Context/ManageDealContext/manage-deal.context';
import CreateDealComponent from '@organisms/CreateDeal/create-deal.component';

import './styles.scss';

function CreateDealBuy({state}) {
  return (
    <ManageDealProvider>
      <div className='create-deal-page'>
        <section className='main-page'>
          <CreateDealComponent
            state={state}
          />
        </section>
      </div>
    </ManageDealProvider>
  );
}

export default CreateDealBuy;
