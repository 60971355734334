import React, { useState } from 'react';
import Button from '@atoms/Buttons/Button';
import VerticalSeparation from '@atoms/SeparationLine/VerticalSeparation/vertical-separation.component';
import { useTranslation } from 'react-i18next';
import Heading from '@atoms/Headings/heading.component';

import styles from './reports.module.scss';
import { LedgerReport } from './LedgerReport/ledger-report';

export const ReportsPage = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState('ledger');

  return (
    <div className={styles.reportsPage}>
      <section className={styles.mainPage}>
        <div className={styles.container}>
          <Heading type={'h3'}>{t('Reports')}</Heading>
          <div className={styles.buttonsWrap}>
            <div className={styles.buttonInnerWrap}>
              <span
                className={`${activePage === 'ledger' ? styles.active : ''}`}>
                <Button
                  icon='assignment'
                  type='link'
                  size='small'
                  //className={`${styles.active}`}
                  onClick={() => {
                    // setIsConfirmationVisible(true);
                    setActivePage('ledger');
                  }}>
                  {t('Ledger')}
                </Button>
                <VerticalSeparation type={'dark'} />
              </span>
            </div>
          </div>

          <div className={styles.detailsContainer}>
            <LedgerReport />
          </div>
        </div>
      </section>
    </div>
  );
};
