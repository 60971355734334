/**
 * Delete Button component
 * description: Delete button with icon
 * @props agreementHandler: send to the parent when the user agrees at the confirmation pop up
 * @props confirmationTitle: translation of the confirmation title
 * @props buttonTitle: translation of the Delete button title
 */

import React, { useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from '@mui/material';

import ConfirmationPopup from '@organisms/Popup/ConfirmationPopup/confirmation-popup.component';

import styles from './delete-button.module.scss';

const DeleteButton = ({
  agreementHandler,
  confirmationTitle,
  buttonTitle,
  style,
}) => {
  const [confirmPop, setConfirmPop] = useState(false);

  const agree = () => {
    setConfirmPop(false);
    agreementHandler();
  };

  return (
    <div className={styles.container}>
      {confirmPop && (
        <ConfirmationPopup
          message={confirmationTitle}
          cancelationHandler={() => {
            setConfirmPop(false);
          }}
          agreementHandler={agree}
        />
      )}
      {/* <button
        className={styles.deleteButton}
        title={buttonTitle}
        onClick={() => setConfirmPop(true)}>
        <DeleteOutlineOutlinedIcon />
      </button> */}
      <IconButton
        title={buttonTitle}
        sx={style}
        onClick={() => setConfirmPop(true)}
        aria-label='delete'
        size='medium'
        color={'error'}>
        <DeleteOutlineOutlinedIcon fontSize='inherit' />
      </IconButton>
    </div>
  );
};

export default DeleteButton;
