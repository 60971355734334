/**
 * Radio Inputs component
 * @props inputs: array of object with all the radio inputs
 * @props onChange: on radio change send the value to the parent
 * @props label: if the radio group has a label
 * @props required: if the radio group is required
 * @props view: buttons design. default
 * Usage: <RadioInputs inputs={radioButtons} onChange={(value: any) => console.log('radio value changed', value)} label={'Net Weight Units'} required={true} />
 */

import React, {forwardRef} from "react";
import {FormHelperText} from "@mui/material";

import InputLabel from "@atoms/Fields/InputLabel/input-label.components";

import styles from "./radio-inputs.module.scss";

const RadioInputs = forwardRef((
  {
    required,
    options,
    label,
    errors,
    view = 'grid',
    ...rest
  }, ref) => {
  const formOptions = rest;

  return (
    <div className={`${styles.container} ${styles[view]}`} >
      <InputLabel label={label} required={required} />
      {options &&
        <>
          <div className={`
            ${styles.radioContainer}
            ${!!errors ? styles.error : ""}
          `}
          >
            {options.map((radio, i) => {
              return (
                <label key={i} className={styles.radioWrap}>
                  <input
                    {...formOptions}
                    type='radio'
                    name={radio.name}
                    value={radio.value}
                    checked={radio.value === formOptions.value || radio.key === formOptions.value}
                  />
                  {radio.label}
                </label>
              );
            })}
          </div>
          <div>
            {!!errors &&
              <FormHelperText error={!!errors}>{errors.message}</FormHelperText>
            }
          </div>
        </>
      }
    </div>
  );
});

RadioInputs.displayName = "RadioInputs";

export default RadioInputs;
