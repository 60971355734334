import React, {
  useEffect,
  useState,
  memo,
  useCallback,
  useReducer,
} from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Dropdown from "Components/Shared/Fields/Dropdown/Dropdown";
import Textarea from "Components/Shared/Fields/Textarea/Textarea";
import Input from "Components/Shared/Fields/Input/Input";
import Date from "Components/Shared/Fields/Date/Date";
import dealsService from "@lib/services/deals-service";
import { useManageDealContext } from "@lib/Context/ManageDealContext/manage-deal.context";
import { stepStatusCompleted } from "@lib/helpers/StepHelper/step-status.helper";
import { popUpSubmitButtonsHelper } from "@lib/helpers/popUpSubmitButtons.helper";
import Link from "@atoms/Fields/Link/Link";
import Info from "@atoms/Fields/Info/info.component";
import Portal from "@molecules/Portal/portal.component";
import handleErrorsHelper from "@molecules/HandleErrors/handle-errors.helper";
import DealModificationMessages from "@molecules/DealModificationMessages/deal-modification-messages.component";
import ProductsStep from "@organisms/ProductsStep/products-step.component";
import Popup from "@organisms/Popup/popup.component";
import PopupLayout from "@organisms/Popup/PopupLayout/popup-layout.component";
import DefaultStep from "@organisms/Steps/DefaultStep/default-step.component";
import StepInfoModal from "@organisms/Popup/StepInfoModal/step-info-modal.component";
import RfqStepComponent from "@organisms/Steps/RFQStep/rfq-step.component";
import QuoteStepComponent from "@organisms/Steps/QuoteStep/quote-step.component";
import PCEStepComponent from "@organisms/Steps/PCEStep/pce-step.component";
import PCStepSamples from "@organisms/Steps/PCStep/PCStepSamples/pc-step-samples.component";
import PageLoader from "@templates/PageLoader/page-loader.template";
import APRStepSamples from "@organisms/Steps/APRStep/APRStepSamples/apr-step-samples.component";
import DocumentsSection from "@organisms/Documents/DocumentsSection/documents-section.component";
import DocumentsTitleSection from "@organisms/Documents/DocumentsTitleSection/documents-title-section.component";
import AdditionalDocuments from "@organisms/Documents/AdditionalDocuments/additional-documents.component";
import TermsAndConditionsMessage from "@organisms/TermsAndConditions/terms-and-conditions-message.component";
import WaitingStepView from "@organisms/StepViews/WaitingStepView/waiting-step-view.component";

import "./styles.scss";

let fields = {};

export const fieldMap = {
  dropdown: (props) => <Dropdown {...props} canClearValue={true} />,
  textarea: (props) => <Textarea {...props} />,
  link: (props) => <Link {...props} />,
  text: (props) => <Input {...props} />,
  number: (props) => <Input {...props} />,
  info: (props) => (props?.item?.Value?.length > 0 ? <Info {...props} /> : ""),
  date: (props) => <Date {...props} />,
  month: (props) => <Date {...props} setView={"month"} />,
};

export const updateButtons = (buttons) => {
  if (!Array.isArray(buttons)) {
    return;
  }

  const copyButtons = [...buttons];

  const foundConfirmAction = copyButtons.find(
    (action) => action.Type === "saveConfirm"
  );
  const foundApproveAction = copyButtons.find(
    (action) => action.Type === "saveApprove"
  );

  if (foundApproveAction && foundConfirmAction) {
    const indexApprove = copyButtons.indexOf(foundApproveAction);
    copyButtons.splice(indexApprove, 1);

    const indexConfirm = copyButtons.indexOf(foundConfirmAction);
    copyButtons[indexConfirm] = { Name: "Save Draft", Type: "saveDraft" };
  } else if (foundApproveAction) {
    const index = copyButtons.indexOf(foundApproveAction);
    copyButtons[index] = { Name: "Save Draft", Type: "saveDraft" };
  } else if (foundConfirmAction) {
    const index = copyButtons.indexOf(foundConfirmAction);
    copyButtons[index] = { Name: "Save Draft", Type: "saveDraft" };
  }

  return copyButtons;
};

function StepInputs({
  inputs,
  step,
  archivedDeal,
  loadStep,
  reloadStepList,
  reloadMessages,
  stepActions,
  products,
  samples,
  setUpdateAPR,
  stepSettings,
  pcInfo,
  documents,
  canWrite,
}) {
  const params = useParams();
  const { t } = useTranslation();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { selectedStep: selectedStepContext } = useManageDealContext();

  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({});
  const [rejectMessage, setRejectMessage] = useState("");
  const [changeMessage, setChangeMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [saveLoader, setSaveLoader] = useState(true);
  const [isRejectPopLayoutVisible, setIsRejectPopLayoutVisible] =
    useState(false);
  const [isChangePopLayoutVisible, setIsChangePopLayoutVisible] =
    useState(false);
  const [quoteFields, setQuoteFields] = useState([]);
  const [quoteMainFields, setQuoteMainFields] = useState({});
  const [localFields, setLocalFields] = useState({});
  const [infoModal, setInfoModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [buttonType, setButtonType] = useState();
  const [validateInfo, setValidateInfo] = useState([]);
  const [innerStepSettings, setInnerStepSettings] = useState({});
  const [executionTypePayload, setExecutionTypePayload] = useState(
    step?.ExecutionType?.Type
  );
  const [showForm, setShowForm] = useState([]);

  //initial load for the inputs
  useEffect(() => {
    if (inputs?.length > 0) {
      fields = {};
      inputs.forEach((input, i) => {
        fields[input.Key] = input.Value;
      });
      setErrors({});

      setLocalFields(fields);
    }
  }, [inputs]);

  // initial load for the step
  // clear the fields
  useEffect(() => {
    fields = {};
    setLocalFields({});
  }, [products, step]);

  const save = (buttonType, currentStep, formData = {}) => {
    setDisableButton(true);
    setSaveLoader(true);

    //This is because of the Products case
    let data = [];
    if (
      step.StepAbbreviation === "QUOT" ||
      step.StepAbbreviation === "PCE" ||
      step.StepAbbreviation === "RFQ"
    ) {
      if (quoteFields?.length > 0) {
        const productsPayload = {
          Products: quoteFields,
        };

        Object.assign(quoteMainFields, productsPayload);
        data = quoteMainFields;
      }
    } else {
      data = localFields;
    }

    if (buttonType === "saveForceApprove") {
      const forcePayload = {
        ButtonType: buttonType,
        currentStepId: currentStep || step.Id,
      };

      data = forcePayload;
    }

    const payload =
      buttonType === "saveForceApprove" ||
      buttonType === "change" ||
      buttonType === "approved"
        ? { ButtonType: buttonType }
        : {
            ...data,
            ...formData,
            ButtonType: buttonType,
            ExecutionType: executionTypePayload,
          };

    dealsService
      .updateStep(params.id, currentStep || selectedStepContext.Id, payload)
      .then((r) => {
        if (r.data.PaymentRedirectUrl) {
          window.location.href = r.data.PaymentRedirectUrl;
        } else {
          products = r.data.items[0].Products;

          loadStep(step.Id).then(() => {
            // stay on the same page of the deal after clicking those buttons.
            if (
              buttonType !== "saveDraft" &&
              buttonType !== "change" &&
              buttonType !== "saveForceApprove" &&
              buttonType !== "approved" &&
              buttonType !== "saveApprove"
            ) {
              // go to the first unfinished step
              reloadStepList();
            } else {
              //Stay on the same page
              reloadStepList(currentStep || step.Id);
            }
          });

          if (buttonType === "approved") {
            setApproveModal(true);
          }
          reloadMessages && reloadMessages();
          setAlert({
            state: "success",
            message: t("Step was updated successfully!"),
          });
          setSaveLoader(false);

          setTimeout(() => {
            setAlert({});
          }, 4000);
          if (
            buttonType === "saveConfirm" ||
            buttonType === "approved" ||
            buttonType === "saveFinish" ||
            buttonType === "saveApprove" ||
            buttonType === "change"
          ) {
            window.scrollTo(0, 0);
          }
        }
      })
      .catch((e) => {
        setSaveLoader(false);
        handleErrorsHelper(e.response.data, setErrors, setAlert);
      })
      .finally(() => {
        setDisableButton(false);
        setFormData(null);
        setInfoModal(false); // hide the info Modal
        setApproveModal(false);
        setCurrentStep("");
      });
  };

  // const onChangeHandler = useCallback(() => {
  //   const foundConfirmAction = stepActions.find(
  //     (action) => action.Type === 'saveConfirm'
  //   );
  //   const foundApproveAction = stepActions.find(
  //     (action) => action.Type === 'saveApprove'
  //   );
  //
  //   if (foundApproveAction && foundConfirmAction) {
  //     const indexApprove = stepActions.indexOf(foundApproveAction);
  //     stepActions.splice(indexApprove, 1);
  //
  //     const indexConfirm = stepActions.indexOf(foundConfirmAction);
  //     stepActions[indexConfirm] = { Name: 'Save Draft', Type: 'saveDraft' };
  //
  //     forceUpdate();
  //   } else if (foundConfirmAction) {
  //     const index = stepActions.indexOf(foundConfirmAction);
  //     stepActions[index] = { Name: 'Save Draft', Type: 'saveDraft' };
  //     forceUpdate();
  //   } else if (foundApproveAction) {
  //     const index = stepActions.indexOf(foundApproveAction);
  //     stepActions[index] = { Name: 'Save Draft', Type: 'saveDraft' };
  //     forceUpdate();
  //   }
  // }, [stepActions]);

  const onSubmitHandler = ({ settings, ...res }) => {
    settings && setInnerStepSettings(settings);
    setFormData(res);
    setButtonType(res.ButtonType);
    setCurrentStep(res.currentStepId);
    if (res.ButtonType === "rejected") {
      setLocalFields(res);
      setIsRejectPopLayoutVisible(true);
    } else if (res.ButtonType === "requestChange") {
      setLocalFields(res);
      setIsChangePopLayoutVisible(true);
    } else if (
      res.ButtonType === "change" ||
      res.ButtonType === "saveForceApprove"
    ) {
      setLocalFields(res);
      setInfoModal(true);
      validate(res.ButtonType, res);
    } else if (res.ButtonType !== "change") {
      setLocalFields(res);
      setInfoModal(true);
      validate(res.ButtonType, res);
    } else {
      buttonTypeAction(res.ButtonType, res.currentStepId);
    }
  };

  // const productStepComponent = () => {
  //   return (
  //     <>
  //       {products && (
  //         <>
  //           <div className={'manage-deal--quote-step'}>
  //             <ProductsStep
  //               stepActions={stepActions}
  //               localErrors={errors}
  //               quoteFields={inputs}
  //               quoteProduct={products}
  //               setFields={setQuoteFields}
  //               setMainFields={setQuoteMainFields}
  //               buttons={false}
  //               step={step}
  //               onChange={onChangeHandler}
  //               onSubmit={onSubmitHandler}
  //               dealId={params.id}
  //               stepSettings={stepSettings}
  //               validateInfo={validateInfo}
  //               loadStep={loadStep}
  //               reloadStepList={reloadStepList}
  //               reloadMessages={reloadMessages}
  //             />
  //           </div>
  //         </>
  //       )}
  //     </>
  //   );
  // };

  const renderInputs = () => {
    switch (step.StepAbbreviation) {
      case "QUOT":
        return (
          <div className={"manage-deal--quote-step"}>
            <QuoteStepComponent
              stepActions={stepActions}
              onSubmit={onSubmitHandler}
              quoteFields={inputs}
              quoteProduct={products}
            />
          </div>
        );
      case "RFQ":
        return (
          <div className={"manage-deal--quote-step"}>
            <RfqStepComponent
              stepActions={stepActions}
              onSubmit={onSubmitHandler}
              quoteFields={inputs}
              quoteProduct={products}
            />
          </div>
        );
      case "PCE":
        return (
          <>
            <div className={"manage-deal--quote-step"}>
              <PCEStepComponent
                stepActions={stepActions}
                onSubmit={onSubmitHandler}
                quoteFields={inputs}
                quoteProduct={products}
              />
            </div>
          </>
        );
      case "APR":
        return (
          <div className={"manage-deal--step-pagination"}>
            <APRStepSamples
              samples={samples}
              archivedDeal={archivedDeal}
              setUpdateAPR={setUpdateAPR}
              onSubmit={onSubmitHandler}
              canWrite={canWrite}
            />
          </div>
        );
      case "PC":
        return (
          <div className={"manage-deal--step-pagination"}>
            <PCStepSamples
              samples={pcInfo}
              archivedDeal={archivedDeal}
              setUpdateAPR={setUpdateAPR}
              onSubmit={onSubmitHandler}
              step={step}
              executionTypePayload={executionTypePayload}
              setExecutionTypePayload={setExecutionTypePayload}
              loadStep={loadStep}
              canWrite={canWrite}
            />
          </div>
        );

      default:
        return (
          <DefaultStep
            inputs={inputs}
            buttons={stepActions}
            isStepActive={step.Done}
            disableButton={disableButton}
            onSubmit={onSubmitHandler}
          />
        );
    }
  };

  const sendMessage = () => {
    setSaveLoader(true);

    dealsService
      .updateStep(params.id, step.Id, {
        ButtonType: "requestChange",
        RequestMessage: changeMessage,
      })
      .then(() => {
        setIsChangePopLayoutVisible(false);
        window.location.reload();
      })
      .catch((e) => {
        handleErrorsHelper(e.response.data, setErrors, setAlert);
      })
      .finally(() => setSaveLoader(false));
  };

  const submitReject = (buttonType) => {
    setSaveLoader(true);

    dealsService
      .updateStep(params.id, currentStep || selectedStepContext.Id, {
        ButtonType: buttonType,
        RejectReason: rejectMessage,
      })
      .then(() => {
        loadStep(step.Id);
        reloadMessages && reloadMessages();
        setAlert({
          state: "success",
          message: t("Step was updated successfully!"),
        });
        setSaveLoader(false);
        setTimeout(() => {
          setAlert({});
        }, 4000);
        reloadStepList(step.Id);
      })
      .catch((e) => {
        setSaveLoader(false);
        handleErrorsHelper(e.response.data, setErrors, setAlert);
      })
      .finally(() => {
        setIsRejectPopLayoutVisible(false);
        setRejectMessage("");
      });
  };

  const validate = (buttonType, formFields) => {
    //This is because of the Products case
    let data = [];
    formFields = { ...formFields, ExecutionType: executionTypePayload };

    data = formFields;

    dealsService
      .validateUpdateStep(
        params.id,
        data.currentStepId || selectedStepContext.Id,
        {
          ...data,
          ButtonType: buttonType,
        }
      )
      .then((res) => {
        setValidateInfo(res.data);
      })
      .catch((e) => {
        handleErrorsHelper(e.response.data, setErrors, setAlert);
      })
      .finally(() => setSaveLoader(false));
  };

  // based on the type of the button on click do different things.
  const buttonTypeAction = (type, currentStep) => {
    setSaveLoader(true);
    setButtonType(type);
    switch (type) {
      case "rejected":
        setIsRejectPopLayoutVisible(true);
        setSaveLoader(false);
        break;
      case "requestChange":
        setIsChangePopLayoutVisible(true);
        setSaveLoader(false);
        break;
      case "saveNext":
      case "saveForceApprove":
      case "change":
        setInfoModal(true);
        validate(type);
        break;
      case "saveApprove":
        setInfoModal(true);
        break;
      case "approved":
        setApproveModal(true);
        setSaveLoader(false);
        break;
      default:
        save(type, currentStep);
    }
  };

  const renderStepFields = () => {
    if (stepStatusCompleted(selectedStepContext.Status)) {
      return (
        <DocumentsSection
          showAllDocuments={false}
          documents={documents}
          loadStep={loadStep}
          currentStep={step}
          stepActions={stepActions}
          onSubmit={onSubmitHandler}
          stepSettings={stepSettings}
          canWrite={canWrite}
        />
      );
    } else {
      if (executionTypePayload === "Attach") {
        return (
          <DocumentsSection
            showAllDocuments={false}
            documents={documents}
            loadStep={loadStep}
            currentStep={step}
            stepActions={stepActions}
            onSubmit={onSubmitHandler}
            stepSettings={stepSettings}
            canWrite={canWrite}
          />
        );
      } else {
        return (
          <>
            {renderInputs()}
            <TermsAndConditionsMessage />
            <AdditionalDocuments currentStep={step} canWrite={canWrite} />
          </>
        );
      }
    }
  };

  const handleShowingForms = () => {
    setShowForm([...showForm, step.DealId]);
  };

  const renderStep = () => {
    if (step?.HideContent?.Hide && !showForm.includes(step.DealId)) {
      return (
        <WaitingStepView
          declined={step?.HideContent?.Declined}
          stepSettings={stepSettings}
          document={step?.HideContent?.DocumentType}
          pendingUsers={step?.HideContent?.PendingUsers}
          setShowForm={handleShowingForms}
        />
      );
    } else {
      switch (step.StepAbbreviation) {
        case "APR":
        case "PC": {
          return renderInputs();
        }
        default: {
          return (
            <>
              <DocumentsTitleSection
                step={step}
                stepSettings={stepSettings}
                setExecutionTypePayload={setExecutionTypePayload}
              />
              {renderStepFields()}
            </>
          );
        }
      }
    }
  };

  return (
    <>
      <div className="step-inputs-form">
        <Popup message={alert.message} state={alert.state} />
        {renderStep()}
      </div>
      {infoModal && (
        <StepInfoModal
          step={step}
          saveLoader={saveLoader}
          stepSettings={stepSettings || innerStepSettings}
          buttonType={buttonType}
          disableButton={disableButton}
          validateInfo={validateInfo}
          handleOnConfirmClick={(action) =>
            save(action, currentStep, { ...formData })
          }
          handleModalClose={() => setInfoModal(false)}
          executionType={validateInfo?.items?.ExecutionType}
        />
      )}
      {approveModal && (
        <Portal overlay={"overlay"}>
          <PageLoader isLoading={saveLoader}>
            <PopupLayout
              onConfirmClick={(action) => {
                save(action, currentStep, { ...formData });
                setApproveModal(false);
              }}
              closePopUp={() => {
                setApproveModal(false);
              }}
              buttons={
                validateInfo &&
                stepSettings &&
                popUpSubmitButtonsHelper(validateInfo, stepSettings, buttonType)
              }
            >
              <DealModificationMessages
                settings={stepSettings || innerStepSettings}
                existingMandatoryFile={stepSettings?.ExistingMandatoryFile}
                step={step.StepName}
                stepAbbreviation={step.StepAbbreviation}
                userReceiver={stepSettings.UserReceiver}
                userParties={stepSettings?.UserParties || []}
                stepDocument={stepSettings.StepDocument}
                buttonType={buttonType}
                onBehalf={stepSettings.UserOnBehalf}
                executionType={validateInfo?.items?.ExecutionType}
              />
            </PopupLayout>
          </PageLoader>
        </Portal>
      )}
      {isRejectPopLayoutVisible && (
        <Portal overlay={"overlay"}>
          <PopupLayout
            placeholder={`${t("ReasonForRejection")}`}
            onConfirmClick={submitReject}
            onChange={(value) => setRejectMessage(value)}
            disabled={rejectMessage === "" ? true : false}
            closePopUp={() => {
              setIsRejectPopLayoutVisible(false);
              setRejectMessage("");
            }}
            buttons={popUpSubmitButtonsHelper(
              validateInfo,
              stepSettings,
              buttonType
            )}
          >
            <DealModificationMessages
              settings={stepSettings || innerStepSettings}
              existingMandatoryFile={stepSettings?.ExistingMandatoryFile}
              step={step.StepName}
              stepAbbreviation={step.StepAbbreviation}
              userReceiver={stepSettings?.UserReceiver}
              userParties={stepSettings?.UserParties || []}
              stepDocument={stepSettings?.StepDocument}
              buttonType={buttonType}
              onBehalf={stepSettings?.UserOnBehalf}
              executionType={validateInfo?.items?.ExecutionType}
            />
          </PopupLayout>
        </Portal>
      )}
      {isChangePopLayoutVisible && (
        <Portal overlay={"overlay"}>
          <PopupLayout
            placeholder={t("WhatChangesYouWantMake")}
            onConfirmClick={sendMessage}
            onChange={(value) => setChangeMessage(value)}
            disabled={changeMessage === "" ? true : false}
            closePopUp={() => setIsChangePopLayoutVisible(false)}
            buttons={
              validateInfo &&
              stepSettings &&
              popUpSubmitButtonsHelper(validateInfo, stepSettings, buttonType)
            }
          >
            <DealModificationMessages
              settings={stepSettings || innerStepSettings}
              existingMandatoryFile={stepSettings?.ExistingMandatoryFile}
              step={step.StepName}
              stepAbbreviation={step.StepAbbreviation}
              userReceiver={stepSettings?.UserReceiver}
              userParties={stepSettings?.UserParties || []}
              stepDocument={stepSettings?.StepDocument}
              buttonType={buttonType}
              onBehalf={stepSettings?.UserOnBehalf}
              executionType={validateInfo?.items?.ExecutionType}
            />
          </PopupLayout>
        </Portal>
      )}
    </>
  );
}

export default memo(StepInputs);
