/**
 * Grid component
 * description: dynamic grid component
 * @props: columns
 * @props: children
 * @props: nextInputColumns: first check if there is any
 * Usage: <Grid columns={item.GridNumber} nextInputColumns={} key={index}></Grid>
 */

import './grid.styles.scss';

const Grid = ({columns, children, offset, offsetPosition}) => {
  return (
    <div className={`dynamic-grid-section grid-columns--${columns} add-margin offset-${offsetPosition}-${offset}`}>
      {children}
    </div>
  )
}

export default Grid;
