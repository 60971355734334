import { httpClient } from "./httpClient";

const RESOURCE_PATH = "api-message";

const messagesService = {
  getAllMessages: function (currentPage, hideSystem) {
    return httpClient.get(
      `${RESOURCE_PATH}?Page=${currentPage}&PerPage=5&HideSystem=${hideSystem}`
    );
  },

  getDealsMessages: function (
    dealId,
    currentPage,
    hideSystem,
    stepId,
    channel,
    viewAll
  ) {
    return httpClient.get(
      `${RESOURCE_PATH}?DealId=${dealId}&Page=${currentPage}&PerPage=5&HideSystem=${
        hideSystem || 0
      }${stepId ? "&StepId=" + stepId : ""}&Channel=${channel}&ViewAll=${
        viewAll === true ? 1 : 0
      }`
    );
  },

  sendMessage: function (data) {
    return httpClient.post(`${RESOURCE_PATH}`, data);
  },

  deleteMessage: function (id, data) {
    return httpClient.delete(`${RESOURCE_PATH}/${id}`, { data });
  },

  verifyMessage: function (messageId) {
    return httpClient.get(`${RESOURCE_PATH}/verifyMessage/${messageId}`);
  },
};

export default messagesService;
