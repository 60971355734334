import React, { memo } from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import NotificationIcon from '@molecules/NotificationIcon/notification-icon.component';

import styles from './deal-name.module.scss';
import ImportIcon from "@molecules/ImportIcon/import-icon.component";
import ExportIcon from "@molecules/ExportIcon/export-icon.component";
import AttentionIcon from "@molecules/AttentionIcon/attention-icon.component";
import GppGoodIcon from '@mui/icons-material/GppGood';

const DealName = ({ isMailMuted, dealName, dealCode, dealId, userType, requiredSide, isBlockChainVerified }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Link to={`/manage-deal/${dealId}`} title={t("Manage Deal")}>
        <div className={styles.dealName}>
          <div className={styles.iconContainer}>
            <NotificationIcon emailsMuted={isMailMuted} />
            {userType === 'buyer' &&
            <Link to={`/manage-deal/${dealId}`} title={t("You are a buyer")}>
              <ImportIcon />
            </Link>
            }
            {userType === 'seller' &&
            <Link to={`/manage-deal/${dealId}`} title={t("You are a seller")}>
              <ExportIcon />
            </Link>
            }
          </div>
          <div className={styles.dealWrapper}>
            <div className={styles.name}>
              <div>{dealName}</div>
              {isBlockChainVerified && <GppGoodIcon className={styles.verifiedIcon} />}
              <Link to={`/manage-deal/${dealId}`} title={t("Action Needed")}>
                {requiredSide[userType] && <AttentionIcon />}
              </Link>
            </div>
            <div className={styles.dealId}>
              <div className={styles.wrapper}>{dealCode}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default memo(DealName);
