import { useForm } from "react-hook-form";
import {useEffect, useState} from "react";

import {useDealsContext} from "@lib/Context/Deals/deals.context";

import styles from './checkboxes-choose-area.module.scss';

const CheckboxesChooseArea = ({items, result, setResult}) => {
  const { register } = useForm();
  const { cleanFilters } = useDealsContext();

  const [sectionValue, setSectionValue] = useState(result)

  // on checkbox change
  useEffect(() => {
    setResult(sectionValue)
    //Save in local storage
    if(sectionValue) {
      localStorage.setItem('list-filters', `${sectionValue}`);
    }
  }, [sectionValue])

  const handleChange = (value) => {
    // remove from array
    if(sectionValue.includes(value)) {
      setSectionValue((sectionValue) =>
        sectionValue.filter(result => result !== value)
      )
      cleanFilters();
    } else {
      // push
      setSectionValue((sectionValue) => [...sectionValue, value])
    }
  }

  return (
    <div className={styles.container}>
      <form>
        {items.map((item) => (
          <label key={item.name}>
            <input
              {...register(item.name)}
              type="checkbox"
              value={item.name}
              name="chooseArea"
              checked={result.includes(item.name)}
              onChange={(e) => { handleChange(e.target.value)}}
            />{item.label}</label>
        ))}
      </form>
    </div>
  )
}

export default CheckboxesChooseArea;
