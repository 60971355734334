/**
 * Tooltip Component
 * description: Material UI that shows tooltip on every element you put inside it as {children}
 * @props text: the text of the tooltip
 * @props children: it can be everything includign another component
 * Usage: <TooltipComponent text='Lorem ipsum lorem'><Button /></TooltipComponent>
 */

import React from 'react';
import { Tooltip, Typography } from '@mui/material';

const TooltipComponent = ({ children, text, disable = false }) => {
  return (
    <Tooltip
      disableHoverListener={disable}
      disableTouchListener={disable}
      enterTouchDelay={0}
      title={
        text ? <Typography style={{ fontSize: 13 }}>{text}</Typography> : ''
      }>
      <div>{children}</div>
    </Tooltip>
  );
};

export default TooltipComponent;
