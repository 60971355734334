import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Grid, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import parse from 'html-react-parser';
import LoadingSpinner from '@molecules/LoadingSpinner/loading-spinner.component';
import MuiOutlinedInput from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import { useUserRole } from '@lib/hooks/useUserRole';
import InputLabel from '@atoms/Fields/InputLabel/input-label.components';
import { isValueIncludedInArray, required } from '@lib/helpers/validation';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import styles from './buyer-agents.module.scss';
import { MuiAutocomplete } from '@atoms/Fields/ReactFormFields/MuiAutocomplete/mui-autocomplete.component';
import { useCookies } from 'react-cookie';
import { useNavigate, Link } from 'react-router-dom';

const Component = ({ onValidateEmail, sellerEmail, permissions }) => {
  const { isAdmin, isOrgAdmin } = useUserRole();
  const [cookies, setCookie] = useCookies();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    setValue,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const {
    fields: buyerAgents,
    append: appendBuyerAgent,
    remove: removeBuyerAgent,
  } = useFieldArray({
    control,
    name: 'BuyerAgents',
  });

  const buyerHelperText = (buyer) => {
    if ((isAdmin || isOrgAdmin) && buyer.BuyerUser) {
      return buyer
        ? `<b>${buyer.BuyerUser}</b> ${t('from')} <b>${buyer.Buyer}</b>`
        : `<b>${buyer.BuyerUser}</b> ${t('from')} <b>N/A</b>`;
    } else {
      return '';
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <InputLabel label={'BuyerAgents'} />
        <IconButton
          onClick={() =>
            appendBuyerAgent({
              User: '',
              Write: 1,
              ViewDeal: 1,
            })
          }>
          <AddCircleIcon fontSize='large' className={styles.green} />
        </IconButton>
      </Box>
      <Grid container spacing={1}>
        {buyerAgents.map((buyer, index) => {
          return (
            <Grid
              item
              sx={12}
              md={12}
              key={buyer.id}
              className={styles.gridItem}>
              <div className={styles.inputAgent}>
                <Controller
                  name={`BuyerAgents[${index}].User`}
                  rules={{
                    ...required,
                    ...isValueIncludedInArray(
                      [
                        getValues('SellerEmail') || sellerEmail,
                        getValues('BuyerEmail'),
                        ...getValues('BuyerAgents')
                          .filter((_, agentIndex) => agentIndex !== index)
                          .map((agent) => agent?.User),
                        ...getValues('SellerAgents').map(
                          (agent) => agent?.User
                        ),
                      ],
                      t('AlreadyAddedEmail')
                    ),
                  }}
                  render={({ field }) => (
                    <>
                      <MuiOutlinedInput
                        {...field}
                        onChange={(event) => {
                          onValidateEmail({
                            value: event.target.value,
                            emailType: 'BuyerEmail',
                            index,
                          });
                          field.onChange(event);
                        }}
                        label={t('Email address')}
                        value={watch(`BuyerAgents[${index}].User`)}
                        required={true}
                        disabled={false}
                        textAlign={'left'}
                        placeholder={t('Type')}
                        error={
                          errors?.BuyerAgents && errors.BuyerAgents[index]?.User
                        }
                        helperText={
                          buyer && (
                            <div className={styles.helperText}>
                              {watch(
                                `BuyerAgents[${index}].OrganizationMessage`
                              )
                                ? parse(
                                    watch(
                                      `BuyerAgents[${index}].OrganizationMessage`
                                    )
                                  )
                                : parse(buyerHelperText(buyer))}
                            </div>
                          )
                        }
                        end={
                          watch(`BuyerAgents[${index}].loading`) ? (
                            <LoadingSpinner />
                          ) : (
                            ''
                          )
                        }
                      />
                    </>
                  )}
                />
              </div>
              <div className={styles.permissionInput}>
                <Controller
                  name={`BuyerAgents[${index}].Write`}
                  defaultValue={[]}
                  render={({ field }) => (
                    <div className={styles.selectInput}>
                      <MuiAutocomplete
                        disableClearable
                        freeSolo={false}
                        onChange={(value) => {
                          setValue(`BuyerAgents[${index}].Write`, value);
                        }}
                        label={t('Permissions')}
                        value={field.value}
                        options={permissions}
                        placeholder={'Select'}
                      />
                    </div>
                  )}
                />
              </div>
              <div className={styles.deleteAgent}>
                <IconButton onClick={() => removeBuyerAgent(index)}>
                  <DeleteOutlineOutlinedIcon className={styles.red} />
                </IconButton>
              </div>
              {isAdmin && (
                <Link
                  to={'/'}
                  title={t('Impersonate')}
                  style={{ alignSelf: 'center' }}>
                  <IconButton
                    style={{ padding: 8, alignSelf: 'center', color: 'black' }}
                    onClick={() => {
                      const setCookies = new Promise((resolve) => {
                        setCookie(
                          'impersonateEmail',
                          watch(`BuyerAgents[${index}].User`),
                          {
                            encode: (v) => v,
                          }
                        );
                        resolve(cookies['impersonateEmail']);
                      });

                      setCookies.then(() => {
                        navigate('/');
                      });
                    }}>
                    <PersonOutlineOutlinedIcon />
                  </IconButton>
                </Link>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export const BuyerAgents = memo(Component);
