import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

export const useQuoteStepContext = () => {
  const context = useContext(QuoteStepContext);
  if (!context) {
    throw new Error('DealsContext must be used within a useQuoteStepProvider');
  }
  return context;
};

const QuoteStepContext = createContext();

export const QuoteStepProvider = ({ children }) => {
  const [contracts, setContracts] = useState({});
  const [incoterms, setIncoterms] = useState({});
  const [minContainers, setMinContainers] = useState(0);
  const [showPriceBreakDown, setShowPriceBreakDown] = useState(false);
  const store = useMemo(
    () => ({
      incoterms,
      contracts,
      minContainers,
      showPriceBreakDown,
      setContracts,
      setIncoterms,
      setMinContainers,
      setShowPriceBreakDown
    }),
    [contracts, incoterms, minContainers, showPriceBreakDown]
  );

  return (
    <QuoteStepContext.Provider value={store}>
      {children}
    </QuoteStepContext.Provider>
  );
};
