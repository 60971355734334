import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip } from '@mui/material';
import GppGoodIcon from '@mui/icons-material/GppGood';

import Button from '@atoms/Button/button.component';
import InputLabel from '@atoms/Fields/InputLabel/input-label.components';
import { CopyButton } from '@molecules/CopyButton/copy-button.component';
import { EllipsisText } from '@molecules/EllipsisText/ellipsis-text.component';

import styles from './original-message.module.scss';

const Component = ({ message, children, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Box typography={'h5'} sx={{ fontWeight: 'bold' }}>
          <div>
            <Tooltip title={t('BlockchainRegistered')} placement='top-start'>
              <GppGoodIcon className={styles.verified} />
            </Tooltip>
          </div>
          {t('OriginalMessageDialogTitle')}
        </Box>
      </Grid>

      {children}

      <Grid item xs={12}>
        {message?.DocumentSha && (
          <Box className={styles.hashRow}>
            <EllipsisText
              text={`${t('DocumentSHA256')}: ${
                message?.DocumentSha
              }`}></EllipsisText>
            <CopyButton value={message?.DocumentSha} />
          </Box>
        )}
        {message?.MessageSha && (
          <Box className={styles.hashRow}>
            <EllipsisText
              text={`${t('MessageSHA256')}: ${
                message?.MessageSha
              }`}></EllipsisText>
            <CopyButton value={message?.MessageSha} />
          </Box>
        )}
        <InputLabel label={t('OriginalValue')} />
        <Box className={styles.originalValueContainer}>
          <Box className={styles.originalValue}>
            {message?.ValueSendForContract}
          </Box>
          <CopyButton value={message?.ValueSendForContract} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          size={'large'}
          onClick={() => {
            onSubmit(message?.Id);
          }}>
          {t('VerifyWithBlockchain')}
        </Button>
      </Grid>
    </Grid>
  );
};

export const OriginalMessage = memo(Component);
