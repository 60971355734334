/**
 * Icon Button component
 * @description: Button only with an icon
 * @param handleClick
 * @param tooltip: add the translated tooltip text.
 * @param children: add the Icon.
 * @example:
 * <IconOnlyButton handleClick={() => setNewSample(true)} tooltip={t('AreYouSureYouWantCreatePC')}>
 *  <ControlPointOutlinedIcon />
 * </IconOnlyButton>
 */

import React from "react";
import {Tooltip, Typography} from "@mui/material";

import styles from "./add-button.module.scss";

const IconOnlyButton = ({handleClick, tooltip, children}) => {
  return (
    <Tooltip
      title={
        <Typography style={{ fontSize: 13 }}>
          {tooltip}
        </Typography>
      }>
      <div
        className={`${styles.button} ${styles.add}`}
        onClick={() => handleClick()}
      >
        {children}
      </div>
    </Tooltip>
  )
}

export default IconOnlyButton;
