import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import localizationService from '@services/localization-service';
import en from '../i18n/en.json';
import fr from '../i18n/fr.json';
import es from '../i18n/es.json';

const __DEV__ = process.env.NODE_ENV === 'development' ? true : false;

const LanguageBackend = {
  init: function (services, backendOptions, i18nextOptions) {
    // __DEV__ &&
    //   console.log('init', { services, backendOptions, i18nextOptions });
  },
  read: async function (language, namespace, callback) {
    try {
      const savedLanguage = localStorage.getItem(language);
      if (!savedLanguage) {
        const serverLanguage = await localizationService.getLocalization(
          language
        );

        callback(null, serverLanguage);
      } else {
        callback(null, JSON.parse(savedLanguage));
      }
    } catch (e) {
      const savedLanguage = localStorage.getItem(language);

      if (savedLanguage) {
        callback(null, JSON.parse(savedLanguage));
      } else {
        switch (language) {
          case 'en':
            callback(null, en);
            break;
          case 'fr':
            callback(null, fr);
            break;
          case 'es':
            callback(null, es);
            break;
          default:
            callback(null, en);
        }
      }
    }
    // __DEV__ && console.log('read', { language, namespace, callback });
  },
  create: function (languages, namespace, key, fallbackValue) {
    // __DEV__ &&
    //   console.log('create', { languages, namespace, key, fallbackValue });
  },
  save: function (language, namespace, data) {
    // __DEV__ && console.log('save', { language, namespace, data });
  },
  type: 'backend',
};

// const language = localStorage.getItem('language');

i18next
  .use(LanguageDetector)
  .use(LanguageBackend)
  .use(initReactI18next)
  .init({
    // lng: language || 'en',
    load: 'currentOnly',
    fallbackLng: false,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span'],
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en', 'fr', 'es'],
  });
