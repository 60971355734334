import { usePopup } from '@lib/hooks/usePopup';

import './styles.scss';

function Popup({ message, state }) {
  const { message: messageHook, state: stateHook } = usePopup();

  return (
    <div
      className={`popup ${message || messageHook ? 'visible' : 'invisible'} ${
        state || stateHook
      }`}>
      {message || messageHook}
    </div>
  );
}

export default Popup;
