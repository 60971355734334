import { memo } from 'react';

const DealProducts = ({ products }) => {
  return (
    <div>
      {products?.map((product, i) => (
        <div key={i}>{product.Name}</div>
      ))}
    </div>
  );
};

export default memo(DealProducts);
