import React, {forwardRef, useCallback, useEffect, useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  createTheme,
  Paper,
  ThemeProvider,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import InputLabel from "@atoms/Fields/InputLabel/input-label.components";
import styles from './form-multi-select.module.scss';

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: '1px solid #efebeb',

          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #efebeb',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #efebeb',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #efebeb',
          },
          '.MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
            fill: '#000',
          },
        },
      },
    },
  },
});

const FormMultiSelect = forwardRef((props, ref) => {
  const {
    onChange,
    canClearValue,
    error,
    noOptionsText = 'No Options',
    placeholder = '',
    label,
    required,
    options,
    ...rest
  } = props;
  const { t } = useTranslation();

  const [selected, setSelected] = useState([]);

  const errorText = useMemo(() => {
    return !error ? '' : Array.isArray(error) ? error[0] : error;
  }, [error]);

  // Init
  useEffect(() => {
    if (options && rest.value) {
      const preselect = rest.value.map(
        (id) => options.find((option) => option.key === id) || id
      );
      setSelected(preselect);
      onChange(rest.name, preselect);
    } else {
      setSelected([]);
      onChange([]);
    }
  }, []);

  const handleChange = useCallback((event, value) => {
    setSelected(value);
    onChange(rest.name, value);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.wrap}>
        <FormControl sx={{ width: '100%', height: '100%' }}>
          <InputLabel
            label={label ? label : ''}
            required={required}
          />
          <Autocomplete
            {...rest}
            ref={ref}
            className={styles.test}
            disableClearable={!canClearValue}
            isOptionEqualToValue={(option, value) => {
              if (option?.key && value?.key) {
                return option.key == value.key;
              } else {
                return option == value;
              }
            }}
            getOptionLabel={(option) => option?.value || option}
            disableCloseOnSelect
            multiple
            id='multiselect-chips'
            options={options}
            value={selected}
            noOptionsText={t(noOptionsText)}
            onChange={handleChange}
            PaperComponent={(props) => <Paper elevation={8} {...props} />}
            ChipProps={{
              classes: {
                label: styles.input,
              },
            }}
            classes={{
              option: styles.input,
              endAdornment: styles.endAdornment,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error || false}
                helperText={errorText}
                placeholder={!selected?.length ? t(placeholder) : ''}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{
                  paddingLeft: selected ? '' : '2rem',
                }}>
                {selected && (
                  <div style={{ paddingRight: '0.8rem' }}>
                    <DoneIcon fontSize='small' />
                  </div>
                )}
                {option.value}
              </li>
            )}
            clearIcon={<div className={styles.clearButton}>{t('Clear')}</div>}
          />
        </FormControl>
      </div>
    </ThemeProvider>
  );
});

FormMultiSelect.displayName = "FormMultiSelect";

export default FormMultiSelect;
