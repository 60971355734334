
import { memo, useCallback } from 'react';
import ReactToPrint from 'react-to-print';
import PrintIcon from '../../../Assets/icons/Print';

const PrintContent = ({ content }) => {
    const triggerPrint = useCallback(() => {
        return (
            <div style={{ cursor: 'pointer' }}>
                <PrintIcon height={20} width={20} />
            </div>
        );
    }, []);
    return (
        <ReactToPrint
            content={content}
            removeAfterPrint
            trigger={triggerPrint}
        />
    )
}

export default memo(PrintContent);