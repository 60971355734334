import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import documentsService from '../../lib/services/documents-service';
import './styles.css';

function ShowFile() {
  const params = useParams();
  const [fileSrc, setFileSrc] = useState('');

  useEffect(() => {
    documentsService.getDocument(params.file).then((r) => {
      const file = new Blob([r.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setFileSrc(fileURL);
    });
  }, []);

  return (
    <div className='show-document'>
      <iframe src={fileSrc} />
    </div>
  );
}

export default ShowFile;
