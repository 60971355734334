/**
 * Vertical Separation Line
 * description: |
 * @props type: "light" | "dark"
 * Usage: <VerticalSeparation type="light" />
 */

import styles from "./vertical-separation.module.scss";

const VerticalSeparation = ({type}) => {
  return (
    <span className={`${styles.separationLine} ${type === "light" ? styles.lightColor : ""}`}> | </span>
  )
}

export default VerticalSeparation;
