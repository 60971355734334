import { ActionTypes } from './types';

export const appStoreInitialState = {
  isAuth: false,
  user: null,
  isLoading: false,
  activePage: sessionStorage.getItem('activePage') ? sessionStorage.getItem('activePage') : '/',
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,
  defaultContactSupport: null,
};

export const appStoreReducer = (state, action) => {
  return actionMap[action.type]
    ? actionMap[action.type](state, action.payload)
    : state;
};

const actionMap = {
  [ActionTypes.ShowLoader]: (state, { isLoading }) => ({
    ...state,
    isLoading,
  }),
  [ActionTypes.SetUserInfo]: (state, payload) => ({
    ...state,
    userInfo: payload,
  }),
  [ActionTypes.SetActivePage]: (state, payload) => ({
    ...state,
    activePage: payload,
  }),
  [ActionTypes.SetDefaultContactSupport]: (state, payload) => ({
    ...state,
    defaultContactSupport: payload,
  }),
};

export const asyncActionMap = {};
