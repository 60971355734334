import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './documents-link.module.scss';

const DocumentsLink = ({ dealId }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.link}>
      <Link
        to={`/manage-deal/${dealId}`}
        state={{ showDocuments: true }}
        title={t('Documents')}>
        {t('Documents')}
      </Link>
    </div>
  );
};

export default memo(DocumentsLink);
