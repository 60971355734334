/**
 * Search Selector component
 * description: Search inside a selector or a dropdown. Looking for the matching options.
 * @props onChange: on input search change send the value to the parent.
 * Usage: <SearchSelector onChange={(value) => search(value)} />
 */

import React, {useEffect, useState} from "react";

import Button from "@atoms/Buttons/Button";

import styles from './search-selector.module.scss';
import {useTranslation} from "react-i18next";

const SearchSelector = ({onChange, selectOpen}) => {
  const { t } = useTranslation();

  const [searchSelection, setSearchSelection] = useState('');

  useEffect(() => {
    !selectOpen &&
      setSearchSelection('');
  }, [selectOpen])

  const search = (e) => {
    e.stopPropagation();
    setSearchSelection(e.target.value);
    onChange(e.target.value);
  }

  return (
    <div className={styles.container}>
      <input
        value={searchSelection}
        placeholder={t("Search inside options...")}
        onChange={(e) => search(e)}
        id={styles['input']}
        onKeyDown={(e) => e.stopPropagation()}
      />

      {searchSelection.length > 0 &&
        <Button
          style={{ marginRight: 5, fontSize: 12, }}
          type='link'
          size='none'
          onClick={(e) => {setSearchSelection(''); onChange(e.target.value)}}
        >
          {t('Clear')}
        </Button>
      }
    </div>
  )
}

export default SearchSelector;
