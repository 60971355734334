import { Controller, useFormContext } from "react-hook-form";
import React, { useEffect, useState } from "react";
//import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import dealsService from "@services/deals-service";
import { useAppStore } from "@lib/Context";
import { useUserRole } from "@lib/hooks/useUserRole";
import { setDefaultContactSupport } from "@lib/Context/actions";
import variables from "@base/styles/variables.scss";
import MuiOutlinedInput from "@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component";
import Button from "@atoms/Button/button.component";
import SwitchComponent from "@molecules/Switch/switch.component";

import styles from "./create-step-one.module.scss";
import "./create-step-one.styles.scss";
import { userAuthorize } from "@lib/services/httpClient";

const CreateStepOne = ({
  setCreateStep,
  setPayloadStep1,
  createDealFlow,
  setPrevCreateStep,
}) => {
  const { t } = useTranslation();
  const { state } = useAppStore();
  const { dispatch } = useAppStore();
  const { isAdmin, isSeller, isBuyer, isTrader, isOrgAdmin } = useUserRole();
  //const createRelatedDealId = useLocation();

  const [whiteLabelSupportEmail, setWhiteLabelSupportEmail] = useState();

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const watchEmailMuted = watch("MuteEmails");
  const watchBlockchainVerification = watch("BlockchainVerification");
  const watchShowContactInfo = watch("ShowContactInfo");

  useEffect(() => {
    setPayloadStep1([]);
    if ((isAdmin || isOrgAdmin) && watchEmailMuted === undefined) {
      setValue("MuteEmails", true);
    }
    dealsService.getCreateDeal().then((r) => {
      dispatch(setDefaultContactSupport(r.data.DefaultContactSupport));
      setWhiteLabelSupportEmail(r.data?.items?.defaultContactSupport);
      if (watchBlockchainVerification === undefined) {
        setValue("BlockchainVerification", r.data.items.BlockchainVerification);
      }
      if (watchShowContactInfo === undefined) {
        setValue("ShowContactInfo", r.data.items.ShowContactInfo);
      }
    });
  }, []);

  const validateEmail = (value) => {
    // regex for valid email
    if (
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) &&
      (value !== state?.userInfo?.email || isOrgAdmin || isAdmin)
    ) {
      return true;
    } else {
      return t("EmailNotValid");
    }
  };

  const submit = (formData) => {
    setCreateStep(2);
    setPrevCreateStep(1);
    setPayloadStep1({
      ...formData,
      Action: "stepSecond",
      Flow: createDealFlow,
    });
  };

  const isWhiteLabel = () => {
    switch (state?.userInfo?.whiteLabel) {
      case "procafe":
        return whiteLabelSupportEmail;
      default:
        return false;
    }
  };

  useEffect(() => {
    const isBuyFlow = createDealFlow === "WantToBuy";
    const isSellFlow = createDealFlow === "WantToSell";
    const wlLabel = !!isWhiteLabel();

    if (((isOrgAdmin && isBuyFlow) || (!isOrgAdmin && isSellFlow)) && wlLabel) {
      setValue("BuyerEmail", isWhiteLabel());
    }

    if (((!isOrgAdmin && isBuyFlow) || (isOrgAdmin && isSellFlow)) && wlLabel) {
      setValue("SellerEmail", isWhiteLabel());
    }
  }, [whiteLabelSupportEmail, createDealFlow, isWhiteLabel, setValue]);

  return (
    <div>
      <div className={styles.stepOneForm}>
        {(isAdmin ||
          isOrgAdmin ||
          isTrader ||
          (createDealFlow === "WantToSell" &&
            !(isAdmin || isOrgAdmin || isTrader))) && (
          <Controller
            name={"BuyerEmail"}
            defaultValue={""}
            rules={{
              required: {
                value: true,
                message: t("Field is required"),
              },
              validate: validateEmail,
            }}
            render={({ field }) => (
              <MuiOutlinedInput
                {...field}
                label={"Buyer Email"}
                disabled={
                  (createDealFlow === "WantToSell" &&
                    !!isWhiteLabel() &&
                    !isOrgAdmin) ||
                  (isOrgAdmin && createDealFlow === "WantToBuy")
                }
                required={true}
                placeholder={t("Type")}
                error={errors.BuyerEmail}
              />
            )}
          />
        )}
        {(isAdmin ||
          isOrgAdmin ||
          isTrader ||
          (createDealFlow === "WantToBuy" &&
            !(isAdmin || isOrgAdmin || isTrader))) && (
          <Controller
            name={"SellerEmail"}
            defaultValue={""}
            rules={{
              required: {
                value: true,
                message: t("Field is required"),
              },
              validate: validateEmail,
            }}
            render={({ field }) => (
              <MuiOutlinedInput
                {...field}
                label={"Seller Email"}
                disabled={
                  (createDealFlow === "WantToBuy" &&
                    !!isWhiteLabel() &&
                    !isOrgAdmin) ||
                  (isOrgAdmin && createDealFlow === "WantToSell")
                }
                required={true}
                placeholder={t("Type")}
                error={errors.SellerEmail}
              />
            )}
          />
        )}
      </div>

      <div className={styles.muteEmailWrap}>
        <div className={`${styles.muteSwitcher} create__switcher`}>
          <Controller
            name={"BlockchainVerification"}
            render={({ field }) => (
              <SwitchComponent
                values={[t("BlockchainVerification"), ""]}
                onChange={(event) => {
                  field.onChange(event);
                }}
                checked={
                  watchBlockchainVerification === undefined
                    ? false
                    : watchBlockchainVerification
                }
                offColor={variables.switcherOffColor}
                height={20}
                width={48}
                handleDiameter={30}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.muteEmailWrap}>
        <div className={`${styles.muteSwitcher} create__switcher`}>
          <Controller
            name={"ShowContactInfo"}
            render={({ field }) => (
              <SwitchComponent
                values={[t("Show Contact Info"), ""]}
                onChange={(event) => {
                  field.onChange(event);
                }}
                checked={
                  watchShowContactInfo === undefined
                    ? false
                    : watchShowContactInfo
                }
                offColor={variables.switcherOffColor}
                height={20}
                width={48}
                handleDiameter={30}
              />
            )}
          />
        </div>
      </div>
      {(isAdmin || isOrgAdmin) && (
        <div className={styles.muteEmailWrap}>
          <div className={`${styles.muteSwitcher} create__switcher`}>
            <Controller
              name={"MuteEmails"}
              render={({ field }) => (
                <SwitchComponent
                  values={[t("Mute Email messages"), ""]}
                  onChange={(event) => {
                    field.onChange(event);
                  }}
                  checked={
                    watchEmailMuted === undefined ? true : watchEmailMuted
                  }
                  offColor={variables.switcherOffColor}
                  height={20}
                  width={48}
                  handleDiameter={30}
                />
              )}
            />
          </div>
        </div>
      )}
      <div className={styles.btnWrap}>
        <Button onClick={() => handleSubmit(submit)()} size={"large"}>
          {t("Next")}
        </Button>
      </div>
    </div>
  );
};

export default CreateStepOne;
