/**
 * Contact Support Component
 * description: Information about the support
 * @props contact: object with keys Name, Country, Email, Phone
 * @props title: title of the Support
 */

import React from 'react';

import VerticalSeparation from '@atoms/SeparationLine/VerticalSeparation/vertical-separation.component';

import styles from './contact-support.module.scss';

const ContactSupport = ({ contact, title }) => {
  return (
    <>
      {contact && (
        <div className={`${styles.container} contact-container`}>
          <strong>{title}:</strong>
          <div className={`${styles.contactWrap} contact-info--wrap`}>
            {contact.Name && (
              <span>{contact.Name} </span>
            )}
            <div>
              {contact.Email && (
                <span>
                  <a href={`mailto:${contact.Email}`}>{contact.Email}</a>
                </span>
              )}
              {contact.Phone && (
                <span>
                  <VerticalSeparation />
                  <a href={`tel:${contact.Phone}`}>{contact.Phone}</a>
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactSupport;
