/**
 * Deal Modification Messaged component
 * @description: Used to show message about the process which will occur after submit/confirm

 */

import React, { Fragment, memo, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useUserRole } from "@lib/hooks/useUserRole";
import { APRAbbr } from "@base/globalVariables/global-variables";
import Heading from "@atoms/Headings/heading.component";
import NotificationIcon from "@molecules/NotificationIcon/notification-icon.component";

import styles from "./deal.modification-message.module.scss";

const DealModificationMessages = ({
  step,
  stepAbbreviation,
  settings,
  saveDraft,
  validationFields,
  userReceiver,
  userParties,
  stepDocument,
  onBehalf,
  buttonType,
  dealInfo,
  approvalNeeded,
  executionType,
  hideTitle,
  createDealFlow,
}) => {
  const { isAdmin, isOrgAdmin, isTrader, isSeller, isBuyer } = useUserRole();
  const { t } = useTranslation();

  const approved = "approved";
  const force = "saveForceApprove";
  const rejected = "rejected";
  const requestChange = "requestChange";
  const change = "change";

  // add ", " or " and " based on the length of the array.
  const joinSymbol = (itemsLength, index) => {
    if (itemsLength - 1 === index) {
      return <b> {t("and")} </b>;
    } else if (itemsLength - 1 > index) {
      return <b>, </b>;
    }
  };

  const parties = useCallback(() => {
    return (
      <div className={styles.topMargin}>
        {userParties.map((user, i) => {
          if (i === 0) {
            return <strong key={i}>{user.Name}</strong>;
          } else {
            return (
              <Fragment key={i}>
                {joinSymbol(userParties.length, i)}
                <strong>{user?.Name}</strong>
              </Fragment>
            );
          }
        })}
        <>
          {" "}
          {!!userParties.length &&
            t("will receive copies of the email at")}{" "}
        </>
        {userParties.map((user, i) => {
          if (i === 0) {
            return <strong key={i}>{user?.Email}</strong>;
          } else {
            return (
              <Fragment key={i}>
                {joinSymbol(userParties.length, i)}
                <strong>{user?.Email}</strong>
              </Fragment>
            );
          }
        })}
      </div>
    );
  }, [userParties]);

  const title = () => {
    if (saveDraft) {
      if (step === "create") {
        return <>{t("You are about to create the deal as Draft")}</>;
      }

      return <Trans i18nKey="SaveAsDraft" values={{ step: t(step) }} />;
    } else {
      if (step === "create") {
        // create && !isAdmin
        if (isAdmin || isOrgAdmin) {
          // create && !isAdmin && !EmailsMuted
          return (
            <>
              <Trans
                i18nKey="CreateDealTitleAdmin"
                values={{
                  step: t(dealInfo.Step?.Current?.Name),
                  name: onBehalf?.Name,
                  company: onBehalf?.Organization,
                }}
              />
            </>
          );
        } else if (isTrader) {
          if (!settings?.EmailsMuted) {
            return (
              <>
                <Trans
                  i18nKey="CreateDealTitleSupport"
                  values={{
                    step: t(dealInfo.Step?.Current?.Name),
                    name: onBehalf?.Name,
                    company: onBehalf?.Organization,
                  }}
                />
              </>
            );
          }
        } else if (isSeller) {
          if (!settings?.EmailsMuted) {
            return (
              <>
                <Trans
                  i18nKey="CreateDealTitleSeller"
                  values={{
                    step: t(dealInfo.Step?.Current?.Name),
                    name: userReceiver?.Name,
                    company: userReceiver?.Organization,
                  }}
                />
              </>
            );
          }
        }
      }
      if (approvalNeeded === false) {
        if (buttonType === requestChange || buttonType === change) {
          return (
            <>
              <Trans i18nKey="ReopenStep" values={{ step: t(step) }} />
            </>
          );
        }
        return (
          <>
            {t("You are about to complete step")} {t(step)}
          </>
        );
      } else {
        //force button
        if (buttonType === force) {
          if (isAdmin || isOrgAdmin) {
            return (
              <>
                <Trans
                  i18nKey="ApproveAsAdmin"
                  values={{
                    step: t(step),
                    name: onBehalf?.Name,
                    company: onBehalf?.Organization,
                  }}
                />
              </>
            );
          }
        } else if (buttonType === requestChange) {
          // request change button
          return (
            <>
              <Trans i18nKey="ReopenStep" values={{ step: t(step) }} />
            </>
          );
        } else if (buttonType === change) {
          // change button
          return <Trans i18nKey="ReopenTitle" values={{ step: step }} />;
        } else if (buttonType === approved) {
          // approved button
          return (
            <>
              {t("You are about to approve step")} {t(step)}
            </>
          );
        } else if (buttonType === rejected) {
          // rejected button
          return (
            <>
              {t("You are about to decline step")} {t(step)}
            </>
          );
        } else if (stepAbbreviation === APRAbbr && isBuyer) {
          if (!settings?.EmailsMuted) {
            return (
              <Trans
                i18nKey="RequestSampleTitle"
                values={{
                  seller: userReceiver?.Name,
                  organization: userReceiver?.Organization,
                }}
              />
            );
          }
        } else {
          return (
            <Trans
              i18nKey="SendStepForApproval"
              values={{
                step: t(step),
                name: userReceiver?.Name,
                company: userReceiver?.Organization,
              }}
            />
          );
        }
      }
    }
  };

  const body = () => {
    if (saveDraft) {
      if (step === "create") {
        return (
          <>
            <div>
              {t("You haven't filled in")}
              {Object.values(validationFields).map((value, i) => {
                if (i > 0) {
                  return (
                    <Fragment key={i}>
                      , <b>{value}</b>
                    </Fragment>
                  );
                } else {
                  return (
                    <Fragment key={i}>
                      {" "}
                      <b>{value}</b>
                    </Fragment>
                  );
                }
              })}
              .
            </div>
            <Trans
              i18nKey="UserWontBeNotified"
              values={{ name: userReceiver?.Name || userReceiver?.Email }}
            />
            .{" "}
            <Trans
              i18nKey="CreateNoDocumentWillBeGenerated"
              values={{ stepDocument: stepDocument }}
            />
          </>
        );
      }

      return (
        <>
          <div>
            {t("You haven't filled in")}
            {Object.values(validationFields).map((value, i) => {
              if (i > 0) {
                return (
                  <Fragment key={i}>
                    , <b>{value}</b>
                  </Fragment>
                );
              } else {
                return (
                  <Fragment key={i}>
                    {" "}
                    <b>{value}</b>
                  </Fragment>
                );
              }
            })}
            .
          </div>
          <Trans
            i18nKey="UserWontBeNotified"
            values={{ name: userReceiver?.Name }}
          />
          .{" "}
          <Trans
            i18nKey="NoDocumentWillBeGenerated"
            values={{ stepDocument: stepDocument }}
          />
        </>
      );
    } else {
      if (step === "create") {
        // + all deal data filled + !settings.EmailsMuted)
        // CreateMessageEmailWillBeSend
        if (!settings?.EmailsMuted) {
          if (createDealFlow === "WantToBuy") {
            return (
              <>
                <div className={styles.bottomMargin}>
                  <Trans
                    i18nKey="CreateMessageEmailWillBeSend"
                    values={{
                      user: t("Seller"),
                      email: userReceiver?.Email,
                      document: t("RequestQuotationDetails"),
                    }}
                  />
                  .
                </div>
                {parties()}
              </>
            );
          } else if (createDealFlow === "WantToSell") {
            return (
              <>
                <div className={styles.bottomMargin}>
                  <Trans
                    i18nKey="CreateMessageEmailWillBeSend"
                    values={{
                      user: t("Buyer"),
                      email: userReceiver?.Email,
                      document: t("Price Quotation"),
                    }}
                  />
                  .
                </div>
                {parties()}
              </>
            );
          }
        } else {
          return (
            <>
              <div className={styles.bottomMargin}>
                {t("SinceNotificationsAreMuted")}
              </div>
            </>
          );
        }

        // if (isAdmin) {
        //   if (!settings?.EmailsMuted) {
        //     return (
        //       <>
        //         <div className={styles.bottomMargin}>
        //           <Trans
        //             i18nKey='CreateAdminNotMutedDesc'
        //             values={{ email: userReceiver?.Email }}
        //           />
        //         </div>
        //         {parties()}
        //       </>
        //     );
        //   } else if (settings.EmailsMuted) {
        //     return (
        //       <>
        //         <div className={styles.bottomMargin}>
        //           {t(
        //             'A file with the deal details will be generated, but will not be attached and sent, since email notifications are muted.'
        //           )}
        //         </div>
        //         <div className={styles.bottomMargin}>
        //           {t('No one will be notified about this action.')}
        //         </div>
        //       </>
        //     );
        //   }
        // } else if (isTrader) {
        //   return (
        //     <>
        //       <div className={styles.bottomMargin}>
        //         <Trans
        //           i18nKey='CreateAdminNotMutedDesc'
        //           values={{ email: userReceiver?.Email }}
        //         />
        //       </div>
        //       {parties()}
        //     </>
        //   );
        // } else if (isSeller) {
        //   return (
        //     <>
        //       <div className={styles.bottomMargin}>
        //         <Trans
        //           i18nKey='CreateAdminNotMutedDesc'
        //           values={{ email: userReceiver?.Email }}
        //         />
        //       </div>
        //       {parties()}
        //     </>
        //   );
        // }
      }

      if (approvalNeeded === false) {
        if (!settings?.EmailsMuted) {
          return (
            <>
              <div>
                {t("Confirmation email will be send to")}{" "}
                <b>{userReceiver?.Email}</b>
              </div>
              {parties()}
            </>
          );
        } else {
          return (
            <>
              <div>
                {t(
                  "Step will be completed, but no email messages will be send because this Deal is in muted state."
                )}
              </div>
              <div>
                {t(
                  "No one will be notified about this action. Please, make sure that your actions are communicated with all parties."
                )}
              </div>
            </>
          );
        }
      } else {
        if (buttonType === force) {
          if (isAdmin || isOrgAdmin) {
            if (!settings?.EmailsMuted) {
              //Force button & !saveDraft & approvalNeeded & approved & isAdmin & !EmailsMuted & Generate
              if (executionType === "Generate") {
                return (
                  <>
                    <div className={styles.bottomMargin}>
                      {t(
                        "The step will be completed and an email will be sent to"
                      )}{" "}
                      <b>{userReceiver?.Email}</b>
                    </div>
                    {parties()}
                  </>
                );
              }
            } else {
              //Force button & !saveDraft & approved & isAdmin & EmailsMuted & Generate
              if (executionType === "Generate") {
                //!saveDraft & approved & isAdmin & EmailsMuted & Generate && stepDocument
                if (stepDocument) {
                  return (
                    <>
                      <div className={styles.bottomMargin}>
                        {t("The step will be completed.")}
                      </div>
                      <div className={styles.bottomMargin}>
                        {t("No one will be notified about this action.")}
                      </div>
                    </>
                  );
                } else {
                  //Force button & !saveDraft & approved & isAdmin & EmailsMuted & Generate & !stepDocument
                  return (
                    <>
                      <div>
                        {t("No one will be notified about this action.")}
                      </div>
                    </>
                  );
                }
              }
            }
          }
        } else if (buttonType === requestChange) {
          if (!settings?.EmailsMuted) {
            return (
              <>
                <div className={styles.bottomMargin}>
                  {t("Email with the change request reason will be sent to")}{" "}
                  <b>{userReceiver?.Email}</b>
                </div>
                {parties()}
              </>
            );
          } else {
            return (
              <>
                <div>
                  {t(
                    "Change request action and reason will be logged into the system and will be visible to all Participants, but no email messages will be send because this Deal is in muted state."
                  )}
                </div>
                <div>
                  {t(
                    "No one will be notified about this action. Please, make sure that your actions are communicated with all parties."
                  )}
                </div>
              </>
            );
          }
        } else if (buttonType === change) {
          if (!settings?.EmailsMuted) {
            return (
              <>
                <div>
                  {t("Email with the applied action will be send to")}{" "}
                  <strong>{userReceiver?.Email}</strong>
                </div>
                {parties()}
              </>
            );
          } else {
            return (
              <>
                <div>
                  {t(
                    "Change action will be logged into the system and will be visible to all Participants, but no email messages will be send because this Deal is in muted state."
                  )}
                </div>
                <div>
                  {t(
                    "No one will be notified about this action. Please, make sure that your actions are communicated with all parties."
                  )}
                </div>
              </>
            );
          }
        } else if (buttonType === approved) {
          //!saveDraft & approved button && !EmailsMuted
          if (!settings?.EmailsMuted) {
            return (
              <>
                <Trans
                  i18nKey="ApprovalConfirmationEmailTo"
                  values={{ email: userReceiver?.Email }}
                />
                {parties()}
              </>
            );
          } else {
            return (
              <>
                <div>
                  {t(
                    "Approval action will be logged into the system and will be visible to all Participants, but no email messages will be send because this Deal is in muted state."
                  )}
                </div>
                <div>
                  {t(
                    "No one will be notified about this action. Please, make sure that your actions are communicated with all parties."
                  )}
                </div>
              </>
            );
          }
        } else if (buttonType === rejected) {
          //!saveDraft & rejected Button && !EmailsMuted
          if (!settings?.EmailsMuted) {
            return (
              <>
                <div className={styles.bottomMargin}>
                  <Trans
                    i18nKey="EmailWillBeDeclined"
                    values={{
                      email: userReceiver?.Email,
                      step: t(step),
                      user: userReceiver?.Name,
                    }}
                  />
                </div>
                {parties()}
              </>
            );
          } else {
            return (
              <>
                <div>
                  <Trans
                    i18nKey="DeclineMessage"
                    values={{ step: t(step), user: userReceiver?.Name }}
                  />
                </div>
              </>
            );
          }
        } else if (stepAbbreviation === APRAbbr) {
          if (!settings?.EmailsMuted) {
            if (isBuyer) {
              return (
                <>
                  <Trans
                    i18nKey="SampleRequestEmail"
                    values={{ mail: userReceiver?.Email }}
                  />
                  {parties()}
                </>
              );
            } else {
              return (
                <>
                  <Trans
                    i18nKey="ApprovalEmailTo"
                    values={{ email: userReceiver?.Email }}
                  />
                  {parties()}
                </>
              );
            }
          }
        }

        if (!settings?.EmailsMuted) {
          //!SaveDraft & !EmailsMuted & Generate
          if (executionType === "Generate") {
            //!SaveDraft & !EmailsMuted & Generate & stepDocument
            if (stepDocument) {
              return (
                <>
                  <Trans
                    i18nKey="SendEmailWithDocumentGenerate"
                    values={{
                      email: userReceiver?.Email,
                      document: stepDocument,
                    }}
                  />
                  {parties()}
                </>
              );
            } else {
              //!SaveDraft & !EmailsMuted & Generate & !stepDocument
              return (
                <>
                  <Trans
                    i18nKey="ApprovalEmailTo"
                    values={{ email: userReceiver?.Email }}
                  />
                  {parties()}
                </>
              );
            }
          }
          //!SaveDraft & !EmailsMuted & Attach
          else if (stepDocument) {
            return (
              <>
                {/*<div>*/}
                {/*  <Trans*/}
                {/*    i18nKey='TemplateAlreadyUploaded'*/}
                {/*    values={{*/}
                {/*      document: stepDocument,*/}
                {/*      date: uploadedDocuments.Date,*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</div>*/}
                <div>
                  <Trans
                    i18nKey="SendEmailWithDocumentAttach"
                    values={{
                      email: userReceiver?.Email,
                      document: stepDocument,
                    }}
                  />
                </div>
                {parties()}
              </>
            );
          } else {
            return (
              <>
                <Trans
                  i18nKey="ApprovalEmailTo"
                  values={{ email: userReceiver?.Email }}
                />
                {parties()}
              </>
            );
          }
        }
        //!SaveDraft & EmailsMuted
        else {
          //!SaveDraft & EmailsMuted & Generate
          if (executionType === "Generate") {
            //!SaveDraft & EmailsMuted Generate & stepDocument
            if (stepDocument) {
              return (
                <>
                  <div>
                    <Trans
                      i18nKey="ApproveDialogMutedGenerate"
                      values={{ document: stepDocument }}
                    />
                  </div>
                  <div>
                    {t(
                      "No one will be notified about this action. Please, make sure that your actions are communicated with all parties."
                    )}
                  </div>
                </>
              );
            } else {
              //!SaveDraft & EmailsMuted Generate & !stepDocument
              return (
                <>
                  <div>
                    {t(
                      "No email messages will be send because this Deal is in muted state."
                    )}
                  </div>
                  <div>
                    {t(
                      "No one will be notified about this action. Please, make sure that your actions are communicated with all parties."
                    )}
                  </div>
                </>
              );
            }
          } else {
            //!SaveDraft & EmailsMuted & Attach
            return (
              <>
                <div>
                  <Trans
                    i18nKey="ApproveDialogMutedAttach"
                    values={{
                      document: stepDocument,
                    }}
                  />
                </div>
                <div>
                  {t(
                    "No one will be notified about this action. Please, make sure that your actions are communicated with all parties."
                  )}
                </div>
              </>
            );
          }
        }
      }
    }
  };

  const underBody = () => {
    if (!saveDraft) {
      if (step === "create") {
        // create && EmailsMuted
        if (settings?.EmailsMuted) {
          return (
            <div className={styles.bottomMargin}>
              {t(
                "This action will be logged into the system and will be visible to all Participants. Please, make sure that your actions are communicated with all parties."
              )}
            </div>
          );
        }
      }
      if (buttonType === force) {
        if (isAdmin || isOrgAdmin) {
          //!saveDraft && force && isAdmin && EmailsMuted
          if (settings?.EmailsMuted) {
            return (
              <>
                {t(
                  "This action will be logged into the system and will be visible to all Participants. Please, make sure that your actions are communicated with all parties."
                )}
              </>
            );
          }
        }
      }
    }
  };

  return (
    <>
      {!hideTitle && (
        <Heading type={"h3"}>
          {step !== "create" && (
            <NotificationIcon emailsMuted={settings?.EmailsMuted} />
          )}
          {title()}
        </Heading>
      )}
      <section className={styles.description}>{body()}</section>
      <div className={styles.underBody}>{underBody()}</div>
    </>
  );
};

export default memo(DealModificationMessages);
