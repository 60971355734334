import { Fragment, useMemo, memo } from 'react';

import ProductAccordion from '@organisms/Products/ProductAccordion/product-accordion.component';
import RfqProductAccordion from '@organisms/Products/ProductAccordion/RfqProductAccordion/rfq-product-accordion.component';

const ProductsList = ({ products, onDelete, prefix = '', properties, validation, errors }) => {
  const mappedProducts = useMemo(() => {
    return products.map((product) => {
      return product?.Fields?.reduce(
        (acc, currentField) => {
          acc[currentField.Key] = { ...currentField };
          return acc;
        },
        { Calculator: product.Calculator }
      );
    });
  }, [products]);

  const renderAccordion = (product, index) => {
    switch (prefix) {
      case 'Rfq':
        return (
          <RfqProductAccordion
            product={product}
            index={index}
            onDelete={onDelete}
            properties={properties}
          />
        );
      default:
        return (
          <ProductAccordion
            product={product}
            index={index}
            onDelete={onDelete}
            properties={properties}
            validation={validation}
            error={errors && errors[index]}
          />
        );
    }
  }

  return (
    <div>
      {mappedProducts?.map((product, index) => (
        <Fragment key={index}>
          {renderAccordion(product, index)}
        </Fragment>
      ))}
    </div>
  );
};

export default memo(ProductsList);
