/**
 * Quote Step Component
 * description: Form used inside Create Deal and Quick Step Inside Manage Deal
 * @props buttons: show or hide the default quickStep buttons
 * @props onBack: if we have default buttons activated decide what the back button will do from the parent component
 * @props submit: if we have default buttons activated decide what the submit button will do from the parent component
 * @props loader: the parent component will tell us if we need to show the loader instead of the buttons for 1s
 * @props errors: bring the input errors
 * @props quoteFields: the main fields which are outside the accordions
 * @props quoteProduct: the products fields which are inside the accordions
 * @props setFields: set the products fields needed to the api on submit
 * @props setMainFields: set the main fields needed to the api on submit
 * @props step: on which step are you at the moment : 'create' | any
 * @props setChildProducts: send the current products to the parent in order to use them later again, because we change them inside the component
 * @props childProducts: use the products sent before.
 * @TODO: buttons Interaction
 */

import React, {
  memo,
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  useForm,
  FormProvider,
  useFieldArray,
} from 'react-hook-form';
import dayjs from 'dayjs';

import {
  QuoteStepProvider,
  useQuoteStepContext,
} from '@lib/Context/QuoteStep/quote-step.context';
import { usePopup } from '@lib/hooks/usePopup';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import PageLoader from '@templates/PageLoader/page-loader.template';
import Button from '@atoms/Button/button.component';
import Grid from '@atoms/Grid/DynamicGrid/grid.component';
import MuiOutlinedInputComponent from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import FormInputFields from '@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component';
import ProductButtons from '@molecules/ButtonsVariation/ProductButtons/product-buttons.component';
import ProductsListComponent from '@organisms/Products/ProductsList/products-list.component';
import DealTotal from '@organisms/Products/DealTotal/deal-total.component';
import { calculateFutureContract } from '@lib/helpers/calculate-future-contracts';

import styles from './quote-step.module.scss';
import './quote-step.styles.scss';

const QuoteStep = ({
  stepActions,
  onSubmit,
  quoteFields,
  quoteProduct,
  keepFormValues,
  defaultFormValues,
}) => {
  const { t } = useTranslation();
  const { showPopup } = usePopup();
  const { setContracts, setIncoterms, minContainers } = useQuoteStepContext();
  const { selectedStep } = useManageDealContext();
  const initialRender = useRef(true);

  const [quoteMainFields, setQuoteMainFields] = useState({});
  const [products, setProducts] = useState([]); // for the inputs value changes
  const [loading, setLoading] = useState(false);

  const speciesArabica = 'Arabica';
  const speciesRobusta = 'Robusta';

  const productTemplate = {
    Calculator: quoteProduct?.Template?.Calculator,
    Fields: quoteProduct?.Template?.Fields,
    Label: null,
    OriginPrice: null,
    ProductId: null,
    ProductName: '',
    Species: '',
  };
  const defaultProducts = !quoteProduct?.Items
    ? [productTemplate]
    : quoteProduct.Items.map((product) => {
        const UnitPrice = product.Fields.find(
          (field) => field.Key === 'UnitPrice'
        );
        const ProductPlatformId = product.Fields.find(
          (field) => field.Key === 'ProductPlatformId'
        );
        const NetWeight = product.Fields.find(
          (field) => field.Key === 'NetWeight'
        );

        return {
          ...product,
          UnitPrice: UnitPrice?.Value || '',
          ProductPlatformId: ProductPlatformId?.Value || '',
          NetWeight: NetWeight?.Value || '',
        };
      });

  const numContainers = quoteFields?.find(
    (field) => field.Key === 'NumContainers'
  );
  const netWeightUnitId = quoteFields?.find(
    (field) => field.Key === 'NetWeightUnitId'
  );
  const unitPriceCurrencyId = quoteFields?.find(
    (field) => field.Key === 'UnitPriceCurrencyId'
  );

  const defaultFieldsValue = quoteFields?.reduce((acc, curr) => {
    acc[curr.Key] = curr.Value;
    return acc;
  }, {});

  const form = useForm({
    defaultValues: {
      Products: [...defaultProducts],
      NumContainers: numContainers?.Value || '',
      UnitPriceCurrencyId: unitPriceCurrencyId?.Value || '',
      NetWeightUnitId: netWeightUnitId?.Value || '',
      ...defaultFieldsValue,
      ...defaultFormValues,
    },
    shouldUnregister: false,
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Products',
  });
  const watchNumContainers = watch('NumContainers');
  const watchPriceType = watch('PriceType');
  const watchSpecies = watch('Species');

  const onSubmitHandler = useCallback(
    (actionType, formData) => {
      typeof keepFormValues === 'function' && keepFormValues(formData);
      const { Products } = formData;
      const products = Products.map((product) => {
        // Remove no needed properties from payload
        const { Fields, Species, Label, ...rest } = product;
        return { ...rest };
      });
      const payload = {
        ...formData,
        Products: products,
        ButtonType: actionType,
      };

      onSubmit(payload);
    },
    [keepFormValues, onSubmit]
  );

  const addProducts = useCallback(() => {
    append(productTemplate);
  }, [append, productTemplate]);

  const containersValidation = useCallback(
    (value) => {
      // if (!!value && Number(value) < Number(minContainers)) {
      //   return t(
      //     `Min containers count must be greater or equal to {{containers}}`,
      //     { containers: minContainers }
      //   );
      // }
      if (!!value && Number(value) < 1) {
        return t(
          `Min containers count must be greater or equal to {{containers}}`,
          { containers: 1 }
        );
      } else if (!Number.isInteger(Number(value))) {
        return t(`WholeNumberValidation`);
      }
    },
    [minContainers, t]
  );

  const futureContractsValidation = useCallback((value) => {
    if (Number(value) <= 0) {
      return t('Value can not be a 0 or a negative number');
    } else if (!Number.isInteger(Number(value))) {
      return t(`WholeNumberValidation`);
    }
  }, []);

  const onChangeNumContainersHandler = useCallback(
    (onChange, event) => {
      // if (
      //   !!event.target?.value &&
      //   Number(event.target?.value) < Number(minContainers)
      // ) {
      //   setError('NumContainers', {
      //     type: 'error',
      //     message: `Min containers count must be greater or equal to ${minContainers}`,
      //   });

      //   onChange(event.target?.value);
      // }
      if (!!event.target?.value && Number(event.target?.value) < 1) {
        setError('NumContainers', {
          type: 'error',
          message: `Min containers count must be greater or equal to 1`,
        });

        onChange(event.target?.value);
      } else if (!Number.isInteger(Number(event.target?.value))) {
        setError('NumContainers', {
          type: 'error',
          message: t(`WholeNumberValidation`),
        });

        onChange(event.target?.value);
      } else {
        clearErrors('NumContainers');
        onChange(event.target?.value);

        setValue(
          'FutureContracts',
          calculateFutureContract({
            containers: event.target?.value,
            species: watchSpecies,
          })
        );
      }
    },
    [clearErrors, minContainers, setError, setValue, t, watchSpecies]
  );

  const onChangeFutureContractsHandler = useCallback(
    (onChange, event) => {
      if (!!event.target?.value && Number(event.target?.value) <= 0) {
        setError('FutureContracts', {
          type: 'error',
          message: `Value can not be a 0 or a negative number`,
        });

        onChange(event.target?.value);
      } else if (!Number.isInteger(Number(event.target?.value))) {
        setError('FutureContracts', {
          type: 'error',
          message: t(`WholeNumberValidation`),
        });

        onChange(event.target?.value);
      } else {
        clearErrors('FutureContracts');
        onChange(event.target?.value);
      }
    },
    [clearErrors, setError]
  );

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const [species] = getValues(['Species']);
      if (!species) {
        return;
      }

      if (watchSpecies === speciesArabica) {
        // Arabica
        setValue(`NetWeightUnitId`, 'kg');
      } else if (watchSpecies === speciesRobusta) {
        // Robusta
        setValue(`NetWeightUnitId`, 'MT');
      }
    }
  }, [getValues, setValue, watchSpecies]);

  useEffect(() => {
    const contractsField = quoteFields.find(
      (field) => field.Key === 'Contract'
    );
    const incotermsField = quoteFields.find(
      (field) => field.Key === 'Incoterms'
    );
    setContracts(contractsField.Options);
    setIncoterms(incotermsField.Options);
  }, [quoteFields, setContracts, setIncoterms]);

  //INIT
  useEffect(() => {
    if (quoteProduct?.Items?.length > 0) {
      setProducts(JSON.parse(JSON.stringify(quoteProduct?.Items)));
      // setValue('Products', quoteProduct?.Items);
    }
  }, [quoteProduct]);

  useEffect(() => {
    if (Object.keys(quoteFields)?.length > 0) {
      setQuoteMainFields(JSON.parse(JSON.stringify(quoteFields)));
    }
  }, [quoteFields]);

  const assignInputGrid = (key) => {
    switch (key) {
      case 'NumContainers':
        return 3;
      default:
        return 4;
    }
  };

  const renderFutureContracts = useCallback(
    (item) => {
      if (watchPriceType === 'Differential') {
        return (
          <Controller
            name={item.Key}
            rules={{ validate: futureContractsValidation }}
            render={({ field }) => (
              <Grid columns={assignInputGrid(item.Key)}>
                <MuiOutlinedInputComponent
                  {...field}
                  onChange={(value) =>
                    onChangeFutureContractsHandler(field.onChange, value)
                  }
                  error={errors[item.Key]}
                  type={item.Type}
                  label={item.Label}
                  required={!!item.Required}
                  disabled={!!item.Readonly || !!selectedStep?.Done}
                  placeholder={t('Type')}
                />
              </Grid>
            )}
          />
        );
      } else {
        return null;
      }
    },
    [
      watchPriceType,
      errors,
      onChangeFutureContractsHandler,
      futureContractsValidation,
    ]
  );

  const renderMainFields = useCallback(() => {
    return (
      <>
        {quoteMainFields?.map((item, i) => {
          if (
            item.Key === 'UnitPriceCurrencyId'
            // item.Key === 'NetWeightUnitId'
          ) {
            return null;
          } else if (item.Key === 'NumContainers') {
            return (
              <Controller
                key={i}
                name={item.Key}
                // defaultValue={item.Value || ''}
                rules={{ validate: containersValidation }}
                render={({ field }) => (
                  <Grid columns={assignInputGrid(item.Key)}>
                    <MuiOutlinedInputComponent
                      {...field}
                      onChange={onChangeNumContainersHandler.bind(
                        this,
                        field.onChange
                      )}
                      error={errors[item.Key]}
                      type={item.Type}
                      label={item.Label}
                      required={!!item.Required}
                      disabled={!!selectedStep?.Done}
                      placeholder={t('Type')}
                      defaultValue={item.Value}
                    />
                  </Grid>
                )}
              />
            );
          } else if (item.Key === 'FutureContracts') {
            return <Fragment key={i}>{renderFutureContracts(item)}</Fragment>;
          } else if (item.Key === 'ServiceFee') {
            return null;
          }
          return (
            <Fragment key={i}>
              {item.Type === 'placeholder' ? (
                <>
                  {/* <Controller
                    name={'NetWeightUnitId'}
                    render={({ field }) => {
                      if (field.value === 'MT') {
                        return null;
                      } else {
                        if (
                          watch('PriceType') === 'Outright' &&
                          watch('Species') === speciesArabica
                        ) {
                          return (
                            <Grid columns={12}>
                              <div className={styles.switchWrap}>
                                <SwitchComponent
                                  {...field}
                                  values={['lbs', 'kg']}
                                  onChange={(value) =>
                                    setValue(
                                      'NetWeightUnitId',
                                      value ? 'kg' : 'lbs'
                                    )
                                  }
                                  required={!!netWeightUnitId.Required}
                                  disabled={
                                    !!netWeightUnitId.Readonly ||
                                    !!selectedStep?.Done
                                  }
                                  label={t(netWeightUnitId.Label)}
                                  checked={field.value === 'kg' ? true : false}
                                />
                              </div>
                            </Grid>
                          );
                        } else {
                          return null;
                        }
                      }
                    }}
                  /> */}
                  <ProductsListComponent
                    products={fields}
                    onDelete={(index) => remove(index)}
                    properties={quoteProduct.Properties}
                  />
                </>
              ) : (
                <Controller
                  name={item.Key}
                  render={({ field }) => {
                    return (
                      <Grid columns={assignInputGrid(item.Key)}>
                        <FormInputFields
                          info={{ ...field }}
                          fields={item}
                          volumeUnit={getValues('NetWeightUnitId')}
                          onChange={(value) => {
                            if (field.name === 'PriceType') {
                              const products = getValues('Products');
                              products.forEach((_, index) =>
                                setValue(`Products[${index}].UnitPrice`, '')
                              );
                              setValue('Contract', '');
                            }
                            field.onChange(value);
                          }}
                          disabled={!!item.Readonly || !!selectedStep?.Done}
                          minDate={false}
                        />
                      </Grid>
                    );
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </>
    );
  }, [
    quoteMainFields,
    fields,
    control,
    remove,
    getValues,
    minContainers,
    errors,
    watchPriceType,
  ]);

  return (
    <FormProvider {...form}>
      {Object.keys(quoteMainFields).length > 0 && (
        <>
          <PageLoader isLoading={loading}>
            <div className={styles.mainForm}>{renderMainFields()}</div>
            <DealTotal />
            <div className={styles.footer}>
              {quoteProduct?.Properties?.ReadOnly === 0 &&
                quoteProduct?.Properties?.NewButton === 1 && (
                  <div className={styles.newProductsWrap}>
                    <Button type={'empty'} size={'large'} onClick={addProducts}>
                      {t('New Product')}
                    </Button>
                  </div>
                )}
            </div>
            {!!stepActions && (
              <ProductButtons
                handleSubmit={(actionType) =>
                  handleSubmit(onSubmitHandler.bind(this, actionType))
                }
                stepActions={stepActions}
              />
            )}
          </PageLoader>
        </>
      )}
    </FormProvider>
  );
};

function Component(props) {
  return (
    <QuoteStepProvider>
      <QuoteStep {...props} />
    </QuoteStepProvider>
  );
}

export default memo(Component);
