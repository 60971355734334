import { memo, useMemo } from 'react';

const DealTotalVolume = ({ totals }) => {
  const volume = useMemo(() => {
    return totals?.Volume ? `${totals.Volume}${totals.VolumeUnit}` : '--';
  }, [totals.Volume, totals.VolumeUnit]);

  return <div>{volume}</div>;
};

export default memo(DealTotalVolume);
