const UploadIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1598_18759)">
                <path d="M13.3309 5.88133C12.5914 2.93547 9.60388 1.14686 6.65801 1.88634C4.35589 2.46424 2.68429 4.45393 2.51206 6.82119C0.877462 7.09076 -0.229135 8.63437 0.0404295 10.269C0.280059 11.7222 1.53923 12.7864 3.01201 12.7805H5.51173V11.7806H3.01201C1.90757 11.7806 1.01223 10.8853 1.01223 9.78087C1.01223 8.67643 1.90757 7.78109 3.01201 7.78109C3.28813 7.78109 3.51195 7.55727 3.51195 7.28114C3.50945 4.79614 5.52195 2.77961 8.00696 2.77714C10.1581 2.77499 12.0099 4.29576 12.426 6.40624C12.4671 6.61697 12.6381 6.77795 12.8509 6.8062C14.2177 7.00083 15.1679 8.2666 14.9733 9.63335C14.7985 10.8607 13.7506 11.7745 12.511 11.7806H10.5112V12.7805H12.511C14.4438 12.7747 16.0058 11.2031 16 9.27033C15.9951 7.66145 14.8938 6.26313 13.3309 5.88133Z"/>
                <path d="M7.65651 7.92615L5.65673 9.92593L6.36165 10.6309L7.51153 9.48598V14.2805H8.51142V9.48598L9.65629 10.6309L10.3612 9.92593L8.36143 7.92615C8.16642 7.7323 7.85152 7.7323 7.65651 7.92615Z" />
            </g>
            <defs>
                <clipPath id="clip0_1598_18759">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default UploadIcon;