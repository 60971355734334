/**
 * PCE Step Component
 * description: Form used inside Manage Deal
 * @props buttons: show or hide the default quickStep buttons
 * @TODO: buttons interaction
 */

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  useForm,
  FormProvider,
  useFieldArray,
} from 'react-hook-form';

import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import Grid from '@atoms/Grid/DynamicGrid/grid.component';
import MuiOutlinedInputComponent from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import FormInputFields from '@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component';
import ProductButtons from '@molecules/ButtonsVariation/ProductButtons/product-buttons.component';
import styles from '@organisms/Steps/QuoteStep/quote-step.module.scss';
import DealTotal from '@organisms/Products/DealTotal/deal-total.component';
import PCEProductAccordion from '@organisms/Products/ProductAccordion/PCEProductAccordion/pce-product-accordion.component';

const PCEStep = ({ quoteFields, quoteProduct, stepActions, onSubmit }) => {
  const { t } = useTranslation();
  const { selectedStep, deal } = useManageDealContext();

  const [quoteMainFields, setQuoteMainFields] = useState({});
  const PriceType = quoteProduct?.Properties?.PriceType;

  const defaultProducts = quoteProduct.Items.map((product) => {
    return {
      ...product,
      InventoryLotId: product?.Fields[0]?.Value,
    };
  });

  const defaultValues = quoteFields.reduce((acc, curr) => {
    acc[curr.Key] = curr.Value || '';
    return acc;
  }, []);

  const form = useForm({
    defaultValues: {
      ...defaultValues,
      Products: [...defaultProducts],
    },
    shouldUnregister: false,
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = form;

  const { fields, remove } = useFieldArray({
    control,
    name: 'Products',
  });

  useEffect(() => {
    if (Object.keys(quoteFields)?.length > 0) {
      setQuoteMainFields(JSON.parse(JSON.stringify(quoteFields)));
    }
  }, [quoteFields]);

  const onSubmitHandler = useCallback((actionType, formData) => {
    const { Products } = formData;
    const products = Products.map((product) => {
      // Remove no needed properties from payload
      const {
        Calculator,
        Containers,
        Contract,
        Fields,
        Label,
        NetWeight,
        NetWeightUnitId,
        ProductInventoryInfo,
        ProductName,
        Species,
        UnitPrice,
        UnitPriceCurrencyId,
        ...rest
      } = product;

      return { ...rest };
    });
    const payload = {
      ...formData,
      Products: products,
      ButtonType: actionType,
    };

    onSubmit(payload);
  }, []);

  const onChangeFutureContractsHandler = useCallback(
    (onChange, event) => {
      if (!!event.target?.value && Number(event.target?.value) <= 0) {
        setError('FutureContracts', {
          type: 'error',
          message: `Value can not be a 0 or a negative number`,
        });

        onChange(event.target?.value);
      } else if (!Number.isInteger(Number(event.target?.value))) {
        setError('FutureContracts', {
          type: 'error',
          message: `WholeNumberValidation`,
        });

        onChange(event.target?.value);
      } else {
        clearErrors('FutureContracts');
        onChange(event.target?.value);
      }
    },
    [clearErrors, setError]
  );

  const futureContractsValidation = useCallback((value) => {
    if (Number(value) <= 0) {
      return t('Value can not be a 0 or a negative number');
    } else if (!Number.isInteger(Number(value))) {
      return t(`WholeNumberValidation`);
    }
  }, []);

  const renderFutureContracts = useCallback(
    (item) => {
      if (PriceType === 'Differential') {
        return (
          <Controller
            name={item.Key}
            rules={{ validate: futureContractsValidation }}
            render={({ field }) => (
              <Grid columns={assignInputGrid(item.Key)}>
                <MuiOutlinedInputComponent
                  {...field}
                  onChange={(value) =>
                    onChangeFutureContractsHandler(field.onChange, value)
                  }
                  error={errors[item.Key]}
                  type={item.Type}
                  label={item.Label}
                  required={!!item.Required}
                  disabled={!!item.Readonly || !!selectedStep?.Done}
                  placeholder={t('Type')}
                />
              </Grid>
            )}
          />
        );
      } else {
        return null;
      }
    },
    [PriceType]
  );

  const assignInputGrid = (key) => {
    switch (key) {
      case 'NumContainers':
        return 3;
      case 'FutureContracts':
        return 4;
      case 'NetWeightUnitId':
      case 'UnitPriceCurrencyId':
        return 6;
      default:
        return 12;
    }
  };

  const renderMainFields = useCallback(() => {
    return (
      <>
        {quoteMainFields?.map((item, i) => {
          if (item.Key === 'FutureContracts') {
            return (
              <Fragment key={i}>
                {renderFutureContracts(item)}
                <DealTotal priceType={PriceType} />
              </Fragment>
            );
          }

          return (
            <Fragment key={i}>
              {item.Type === 'placeholder' ? (
                fields.map((field, i) => (
                  <Fragment key={i}>
                    <PCEProductAccordion
                      productFields={field.Fields}
                      productId={field.ProductId}
                      index={i}
                      onDelete={(index) => remove(index)}
                      properties={quoteProduct.Properties}
                      label={field.Label}
                    />
                  </Fragment>
                ))
              ) : item.Key === 'ServiceFee' ? (
                <></>
              ) : (
                <Controller
                  name={item.Key}
                  
                  render={({ field }) => {
                    return (
                      <Grid columns={assignInputGrid(item.Key)}>
                        <FormInputFields
                          info={{ ...field }}
                          fields={item}
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                          disabled={!!item.Readonly || !!selectedStep?.Done}
                        />
                      </Grid>
                    );
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </>
    );
  }, [quoteMainFields, fields, control, errors]);

  return (
    <FormProvider {...form}>
      {Object.keys(quoteMainFields).length > 0 && (
        <>
          <div className={styles.mainForm}>{renderMainFields()}</div>
          {!!stepActions && (
            <ProductButtons
              handleSubmit={(actionType) =>
                handleSubmit((formData) =>
                  onSubmitHandler(actionType, formData)
                )
              }
              stepActions={stepActions}
            />
          )}
        </>
      )}
    </FormProvider>
  );
};

export default PCEStep;
