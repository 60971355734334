/**
 * Stepper V1 component
 * @description: default MUI stepper
 * @param steps: info with the steps
 * @param selectStep: parent function that is triggered when you choose a step
 * @param selectedStepperStep: parent state for the step ID of the stepper
 * @param setSelectedStepperStep: parent setState for the step ID of the stepper
 * Custom stepper: https://mui.com/material-ui/react-stepper/
 */


import { useCallback, useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';

import './stepper-v1.styles.scss';

const StepperV1 = ({
  steps,
  selectStep,
  selectedStepperStep,
  setSelectedStepperStep,
  disabledStepSelector,
}) => {
  const { t } = useTranslation();
  const { deal } = useManageDealContext();
  const [localSteps, setLocalSteps] = useState([]);

  // Remove the APR step from the stepper.
  useEffect(() => {
    const tempSteps = steps.filter((filteredStep) => {
      if (filteredStep.Abbreviation !== 'APR') {
        return filteredStep;
      }
    });
    setLocalSteps(tempSteps);
  }, [steps]);

  const renderStepLabel = useCallback((step, index) => {
    if (step.Id === selectedStepperStep) {
      return t(step.Name);
    } else {
      return t(step.Abbreviation);
    }
  }, [selectedStepperStep]);

  const handleStepperClick = (e, step, index) => {
    if (!disabledStepSelector) {
      setSelectedStepperStep(step.Id); // Use step.Id instead of index
      selectStep(step);
    } else {
      e.preventDefault();
    }
  };

  return (
    <div className={'stepper-wrap--v1'}>
      <Stepper activeStep={selectedStepperStep} alternativeLabel>
        {localSteps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (step.Id === deal.Step.Current.Id && step.Status === 'active') {
            labelProps.icon = ' ';
            stepProps.className = 'active-step';
          } else {
            stepProps.className = `${step.Done ? '' : 'default-label'}`;

            switch (step.Status) {
              case 'pendingApproval':
                labelProps.icon = <QuestionMarkIcon />;
                stepProps.className = 'status--pending-approval';
                break;
              case 'approved':
                labelProps.icon = <CheckIcon />;
                stepProps.className = 'status--approved';
                break;
              case 'rejected':
                labelProps.icon = <CloseIcon />;
                stepProps.className = 'status--declined';
                break;
              case 'active':
                labelProps.icon = ' ';
                stepProps.className = 'status--active';
                break;
              default:
                labelProps.icon = '';
                labelProps.className = `${step.Done ? '' : 'default-label'}`;
                break;
            }
          }

          if (step.Id === selectedStepperStep) {
            stepProps.className = `${stepProps.className} ${'selected-step'}`;
          }

          return (
            <Step
              {...stepProps}
              key={step.StepId || step.Id}
              completed={!!step.Done}
              onClick={(e) => handleStepperClick(e, step, index)}
            >
              <StepLabel {...labelProps}>{renderStepLabel(step, index)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default StepperV1;
