/**
 * Deal Card v3 component
 * description: Information about the deal. Used as Confirmation pop up right before you finish creating a deal.
 * @props
 */

import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import dealsService from '@services/deals-service';
import { useNavigation } from '@lib/hooks/useNavigation';
import { formatPrice, formatVolume } from '@lib/helpers/helpers';
import { convertCurrencyHelper } from '@lib/helpers/convert-currency.helper';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import { usePopup } from '@lib/hooks/usePopup';
import TitleDescVertical from '@atoms/TitleDescVertical/title-desc-vertical.component';
import Button from '@atoms/Button/button.component';
import DealModificationMessages from '@molecules/DealModificationMessages/deal-modification-messages.component';
import EditTitle from '@molecules/EditTitle/edit-title.component';
import NotificationIcon from '@molecules/NotificationIcon/notification-icon.component';
import CreateDealModificationTitle from '@molecules/CreateDealModificationMessages/create-deal-modification-title.component';
import StepperV1 from '@molecules/Steppers/StepperV1/stepper-v1.component';
import PageButtonInteraction from '@templates/PageButtonInteraction/page-button-interaction.template';
import PageLoader from '@templates/PageLoader/page-loader.template';

import styles from './create-step-four.module.scss';

const CreateStepFour = ({ stepFormValues, createDealFlow }) => {
  const { t } = useTranslation();
  const { redirect } = useNavigation();
  const { showPopup } = usePopup();
  const { setDeal } = useManageDealContext();
  const { watch } = useFormContext();
  const { state: relatedDealId } = useLocation();

  const [disableButton, setDisableButton] = useState(false);
  const [title, setTitle] = useState('');
  const [keyPressed, setKeyPressed] = useState();
  const [dealInfo, setDealInfo] = useState();
  const [loader, setLoader] = useState(true);
  const [stepList, setStepList] = useState([]);

  const watchAllFields = watch();
  const watchProducts = watch('Products');

  const productSubTitle = 'md';
  const infoLineTextSize = 'normal';

  useEffect(() => {
    if (Object.keys(stepFormValues).length > 0) {
      dealsService
        .createDeal(stepFormValues)
        .then((res) => {
          setDealInfo(res.data.Deal);
          setDeal(res.data.Deal);
          setLoader(false);
          setStepList(res.data.Deal.Steps);
        })
        .catch((e) => {
          console.log('error: ', e);
          setLoader(false);
        });
    }
  }, [stepFormValues]);

  useEffect(() => {
    if (dealInfo?.Products) {
      setTitle(dealInfo?.Products[0]?.Name);
    } else if (watchProducts) {
      setTitle(watchProducts[0]?.CreateProductName);
    }
  }, [dealInfo, watchProducts]);

  // const sectionVisualization = (name, organization) => {
  //   const user = name ? name : '';
  //   const company = organization
  //     ? name
  //       ? `(${organization})`
  //       : organization
  //     : '';
  //
  //   if (user || company) {
  //     return `${user} ${company}`;
  //   } else {
  //     return ' -- ';
  //   }
  // };

  const submitForm = () => {
    const data = {
      ...stepFormValues,
      Action: 'stepFourthSend',
      Flow: createDealFlow,
      Title: title,
      RelatedDealId: relatedDealId?.relatedDeal,
    };

    dealsService
      .createDeal(data)
      .then(() => {
        redirect('/', { state: { dealCreated: true } });
      })
      .catch((e) => {
        console.log('error: ', e);

        showPopup({
          state: 'error',
          message: t('SomethingWentWrongWeApologize'),
          timer: 4000,
        });
      });
  };

  const initSubmitButtons = () => {
    if (
      dealInfo?.Settings?.EmailsMuted &&
      (!dealInfo?.Settings?.SaveDraft || createDealFlow === 'WantToBuy')
    ) {
      return (
        <div className={styles.buttonsInnerWrapFlex}>
          <Button
            size={'large'}
            btnColor={'default'}
            disabled={disableButton}
            onClick={() => submitForm()}>
            {t('Create deal')}
          </Button>
        </div>
      );
    } else if (
      !dealInfo?.Settings?.EmailsMuted &&
      (!dealInfo?.Settings?.SaveDraft || createDealFlow === 'WantToBuy')
    ) {
      return (
        <div className={styles.buttonsInnerWrapFlex}>
          <Button
            size={'large'}
            disabled={disableButton}
            onClick={() => submitForm()}>
            {t('Submit')}
          </Button>
        </div>
      );
    } else {
      return (
        <div className={styles.buttonsInnerWrapFlex}>
          <Button
            size={'large'}
            disabled={disableButton}
            btnColor={'quaternary'}
            onClick={() => submitForm()}>
            {t('Save as Draft')}
          </Button>
        </div>
      );
    }
  };

  // on Edit Title submit focus the page again in order the keys to work on the Save and Back
  // useEffect(() => {
  //   const component = document.getElementById('page-interaction');
  //   component.focus();
  // }, [title]);

  // const titleDesc = useCallback((emailsMuted, email, user, type) => {
  //   return user ? (
  //     sectionVisualization(user, type)
  //   ) : emailsMuted ? (
  //     email
  //   ) : (
  //     <Trans i18nKey='userWillBeInvited' email={email}>
  //       User <b>{{ email }}</b> will be invited in Trade Dashboard
  //     </Trans>
  //   );
  // }, []);

  const renderTitle = useCallback(() => {
    if (dealInfo?.Products) {
      return dealInfo.Products[0]?.Name;
    } else if (watchProducts) {
      return watchProducts[0]?.CreateProductName;
    } else {
      return '';
    }
  }, [dealInfo]);

  const renderPositiveNumberMark = (number) => {
    if (number > 0) {
      return '+';
    } else if (number < 0) {
      return '-';
    } else {
      return '';
    }
  };

  const renderSubTitle = useCallback(() => {
    if (stepFormValues.PriceType === 'Outright') {
      // IF PriceType is Outright
      return (
        <>
          {dealInfo.Products[0]?.Price
            ? formatPrice(
                dealInfo.Products[0]?.Price,
                dealInfo.Products[0]?.PriceCurrency
              ) + ' | '
            : ''}
          {dealInfo.Products[0]?.Volume
            ? formatVolume(
                dealInfo.Products[0]?.Volume,
                dealInfo.Products[0]?.VolumeUnit
              ) + ' | '
            : ''}
          {dealInfo.Seller}
        </>
      );
    } else if (stepFormValues.PriceType === 'Differential') {
      // IF PriceType is Differential
      const productsTotalPrice = stepFormValues.Products.reduce(
        (acc, curr) => acc + Number(curr?.UnitPrice || 0),
        0
      );

      const productsTotalVolume = stepFormValues.Products.reduce(
        (acc, curr) => acc + Number(curr?.NetWeight || 0),
        0
      );

      return (
        <>
          {stepFormValues.Contract
            ? stepFormValues.Contract +
              ' ' +
              renderPositiveNumberMark(productsTotalPrice) +
              convertCurrencyHelper(stepFormValues.UnitPriceCurrencyId) +
              formatPrice(Math.abs(Number(productsTotalPrice)))
            : '--'}
          {` | ${formatVolume(productsTotalVolume)}${
            stepFormValues.NetWeightUnitId
          }`}
          {` | ${dealInfo.Seller}`}
        </>
      );
    }
  }, [stepFormValues, dealInfo]);

  const renderProduct = useCallback(
    (product) => {
      if (stepFormValues.PriceType === 'Outright') {
        return (
          <>
            {product.Name ? product.Name : ''}
            {product.Volume
              ? ' | ' + formatVolume(product.Volume, product.VolumeUnit)
              : ''}
            {product.Price
              ? ' | ' + formatPrice(product.Price, product.PriceCurrency)
              : ''}
            {product.Price && product.VolumeUnit
              ? `/` + product.VolumeUnit
              : ''}
          </>
        );
      } else if (stepFormValues.PriceType === 'Differential') {
        return (
          <>
            {product.Name ? product.Name : ''}
            {product.Volume
              ? ` | ${formatVolume(product.Volume, product.VolumeUnit)}`
              : ''}
            {' | ' +
              stepFormValues.Contract +
              ' ' +
              renderPositiveNumberMark(product.UnitPrice) +
              convertCurrencyHelper(stepFormValues.UnitPriceCurrencyId) +
              formatPrice(Math.abs(Number(product.UnitPrice)))}
            {product.VolumeUnit ? `/` + product.VolumeUnit : ''}
          </>
        );
      }
    },
    [stepFormValues]
  );

  const renderPrice = useCallback(
    (product) => {
      if (stepFormValues.PriceType === 'Outright') {
        return (
          <TitleDescVertical
            title={'Coffee Price'}
            desc={formatPrice(product.Total || 0, product.PriceCurrency)}
            textSize={productSubTitle}
          />
        );
      } else if (stepFormValues.PriceType === 'Differential') {
        return (
          <TitleDescVertical
            title={'Price'}
            desc={`
            ${stepFormValues.Contract} 
            ${renderPositiveNumberMark(
              product.UnitPrice
            )}${convertCurrencyHelper(
              stepFormValues.UnitPriceCurrencyId
            )}${formatPrice(Math.abs(Number(product.UnitPrice)))}
          `}
            textSize={productSubTitle}
          />
        );
      }
    },
    [stepFormValues]
  );

  return (
    <PageLoader isLoading={loader}>
      {dealInfo && (
        <div className={styles.page}>
          <PageButtonInteraction
            setKeyPressed={(value) => setKeyPressed(value)}>
            <div className={styles.container}>
              <div className={styles.topHeader}>
                <div className={styles.muteIcon}>
                  <NotificationIcon
                    emailsMuted={dealInfo?.Settings?.EmailsMuted}
                  />
                </div>
                <div className={styles.draft}>
                  <span>
                    {t(dealInfo.Code)} | {dealInfo.CreateDate}
                  </span>
                </div>
              </div>
              <div className={styles.titleWrap}>
                <EditTitle
                  title={renderTitle()}
                  headingType={'h2'}
                  setTitle={setTitle}
                  setErrors={(value) => setDisableButton(value)}
                />
                {Array.isArray(dealInfo.Products) && (
                  <>
                    <div className={styles.subTitle}>{renderSubTitle()}</div>
                    <div className={styles.productsContainer}>
                      {dealInfo.Products.map((product, i) => (
                        <div className={styles.productWrap} key={i}>
                          <div className={styles.productTitle}>
                            {renderProduct(product)}
                          </div>
                          <div className={styles.productSubTitle}>
                            <TitleDescVertical
                              title={'Origin'}
                              desc={product.Origin}
                              textSize={productSubTitle}
                            />
                            <TitleDescVertical
                              title={'Species'}
                              desc={product.Species}
                              textSize={productSubTitle}
                            />
                            {renderPrice(product)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div className={styles.messageModification}>
                <CreateDealModificationTitle
                  createDealFlow={createDealFlow}
                  saveDraft={dealInfo?.Settings?.SaveDraft}
                  formValues={stepFormValues}
                />

                <DealModificationMessages
                  settings={dealInfo?.Settings}
                  step={'create'}
                  saveDraft={dealInfo?.Settings.SaveDraft}
                  userReceiver={dealInfo?.Settings.UserReceiver}
                  userParties={dealInfo?.Settings?.UserParties || []}
                  stepDocument={dealInfo?.Settings.StepDocument}
                  validationFields={dealInfo?.Settings.ValidationFields}
                  dealInfo={dealInfo}
                  onBehalf={dealInfo?.Settings.UserOnBehalf}
                  createDealFlow={createDealFlow}
                  additionalInformation={watchAllFields}
                  hideTitle={true}
                />
              </div>

              <div className={styles.stepperWrap}>
                <div className={styles.stepperLabel}>{t('NextSteps')}: </div>
                <StepperV1
                  steps={stepList}
                  selectedStepperStep={dealInfo?.Step.Current.Position - 1}
                  customDeal={dealInfo?.Step}
                  disabledStepSelector={true}
                />
              </div>

              <div className={styles.heading}>
                <h3>{t('Deal Participants')}:</h3>
              </div>

              <div className={styles.infoLine}>
                <TitleDescVertical
                  title={'Seller'}
                  desc={dealInfo.SellerEmail}
                  textSize={infoLineTextSize}
                />
              </div>
              <div className={styles.infoLine}>
                <TitleDescVertical
                  title={'Buyer'}
                  desc={dealInfo.BuyerEmail}
                  textSize={infoLineTextSize}
                />
              </div>
              <div className={styles.infoLine}>
                <div className={styles.subInfo}>
                  <b>{t('Support')}:</b>
                </div>
                <div>
                  {dealInfo.SupportAgent?.map((agent, i) => (
                    <Fragment key={i}>
                      <div className={styles.subInfo}>
                        <b>{agent.Name}</b>, {t('email')}: <b>{agent.Email}</b>
                      </div>
                      <div className={styles.subInfo}>
                        {agent.Phone ? (
                          <>
                            {t('phone')}: <b>{agent.Phone}</b>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>

              <div className={styles.infoLine}>
                <TitleDescVertical
                  title={'Incoterms'}
                  desc={dealInfo.PriceTerms || ' -- '}
                  textSize={infoLineTextSize}
                />
              </div>
              <div className={styles.infoLine}>
                <TitleDescVertical
                  title={'Expire'}
                  desc={dealInfo.ExpireDate || ' -- '}
                  textSize={infoLineTextSize}
                />
              </div>
            </div>
            <div className={styles.buttonsWrap}>{initSubmitButtons()}</div>
          </PageButtonInteraction>
        </div>
      )}
    </PageLoader>
  );
};

export default memo(CreateStepFour);
