/**
 * Outright Total
 * @description:Product total when the Price Type is Outright
 * @param index: the index of the current product
 */

import { memo, useMemo } from 'react';
import { convertCurrencyHelper } from '@lib/helpers/convert-currency.helper';
import { formatPrice } from '@lib/helpers/helpers';
import { useFormContext } from 'react-hook-form';

const OutrightTotal = ({ index, prefix = '' }) => {
  const { watch } = useFormContext();

  const watchNetWeightUnit = watch(`${prefix}NetWeightUnitId`);
  const watchProductNetWeight = watch(`Products.${index}.NetWeight`);
  const watchProductUnitPrice = watch(`Products.${index}.UnitPrice`);
  const watchUnitPriceCurrency = watch(`${prefix}UnitPriceCurrencyId`);

  const offerPrice = useMemo(() => {
    const currency = convertCurrencyHelper(watchUnitPriceCurrency);
    const productPrice = watchProductUnitPrice || 0;
    const weight = watchProductNetWeight || '';
    const total = Number(weight) * Math.abs(Number(productPrice)).toFixed(4);
    const sign = total < 0 ? '-' : '';

    const price = watchProductUnitPrice
      ? formatPrice(Number(total), currency, sign)
      : '--';

    return watchProductUnitPrice ? <>{price}</> : '--';
  }, [
    watchNetWeightUnit,
    watchProductNetWeight,
    watchProductUnitPrice,
    watchUnitPriceCurrency,
  ]);

  return <>{offerPrice}</>;
};

export default memo(OutrightTotal);
