import {useTranslation} from "react-i18next";

import "./title-desc-vertical.styles.scss";

const TitleDescVertical = ({title, desc, textSize='normal'}) => {
  const { t } = useTranslation();

  return (
    <div className={`text-size--${textSize} title-desc-wrap`}>
      <b>{t(title)}: </b>
      <b>{desc || " -- "}</b>
    </div>
  )
}

export default TitleDescVertical;
