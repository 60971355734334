import {Trans, useTranslation} from "react-i18next";
import React, {useCallback} from "react";

import styles from "./create-deal-modification-title.module.scss";

const CreateDealModificationTitle = (
  {
    saveDraft,
    createDealFlow,
    formValues
  }) => {
  const { t } = useTranslation();

  const renderMessages = useCallback(() => {
    if (saveDraft) {
      if (createDealFlow === "WantToSell") {
        return <>{t('You are about to create the deal as Draft')}</>;
      } else if (createDealFlow === "WantToBuy") {
        return (
          <>
            <Trans
              i18nKey='CreateDealMainTitle'
              values={{
                step: t('SubmitQuotationRequest'),
                user: t("Seller"),
              }}
            />
          </>
        )
      }
    }
    else {
      if (createDealFlow === "WantToSell") {
        if(formValues.AlreadyDiscussedDetails === "No" ||
          (formValues.AlreadyDiscussedDetails === "Yes" &&
          (!formValues.QuotationDocumentId && !formValues.PurchaseDocumentId))
        ) {
          if(formValues.NextStep === "QuotationStep") {
            return (
              <>
                <Trans
                  i18nKey='CreateDealMainTitle'
                  values={{
                    step: t('SubmitQuoteApproval'),
                    user: t("Buyer"),
                  }}
                />
              </>
            )
          } else if(formValues.NextStep === "ProceedWithPCE") {
            return (
              <>
                <Trans
                  i18nKey='CreateDealPCETitle'
                  values={{
                    submit: t('QuoteAsCompleted'),
                    proceed: t('PCEProceed')
                  }}
                />
              </>
            )
          }
        }
        else if(formValues.AlreadyDiscussedDetails === "Yes") {
          if (formValues.QuotationDocumentId) {
            return (
              <>
                <Trans
                  i18nKey='CreateDealMainTitle'
                  values={{
                    step: t('SubmitQuoteApproval'),
                    user: t("Buyer"),
                  }}
                />
              </>
            )
          }
          else if(formValues.PurchaseDocumentId) {
            return (
              <>
                <Trans
                  i18nKey='CreateDealPCETitle'
                  values={{
                    submit: t('QuoteAsCompleted'),
                    proceed: t('PCESubmit')
                  }}
                /> {t('by')} {t('Buyer')}
              </>
            )
          }
        }
      } else if(createDealFlow === "WantToBuy") {
        if(formValues.AlreadyDiscussedDetails === "No" ||
          (formValues.AlreadyDiscussedDetails === "Yes" &&
          (!formValues.QuotationDocumentId && !formValues.PurchaseDocumentId))
        ) {
          if(formValues.NextStep === "QuotationStep") {
            return (
              <>
                <Trans
                  i18nKey='CreateDealMainTitle'
                  values={{
                    step: t('SubmitQuotationRequest'),
                    user: t("Seller"),
                  }}
                />
              </>
            )
          }
          else if(formValues.NextStep === "ProceedWithPCE") {
            return (
              <>
                <Trans
                  i18nKey='CreateDealPCETitle'
                  values={{
                    submit: '',
                    proceed: t('PCEProceed')
                  }}
                />
              </>
            )
          }
        }
        else if(formValues.AlreadyDiscussedDetails === "Yes") {
          if(formValues.QuotationDocumentId) {
            return (
              <>
                <Trans
                  i18nKey='CreateDealPCETitle'
                  values={{
                    submit: '',
                    proceed: t('PCEProceed')
                  }}
                />
              </>
            )
          }
          else if(formValues.PurchaseDocumentId) {
            return (
              <>
                <Trans
                  i18nKey='CreateDealPCETitle'
                  values={{
                    submit: t('QuoteAsCompleted'),
                    proceed: t('PCESubmit')
                  }}
                /> {t('by')} {t('Seller')}
              </>
            )
          }
        }
      }
    }
  }, [formValues, saveDraft, createDealFlow])

  return (
    <div className={styles.container}>
      <b>{renderMessages()}</b>
    </div>
  )
}

export default CreateDealModificationTitle;