import { forwardRef } from 'react';
import { FormHelperText, TextareaAutosize } from '@mui/material';

import InputLabel from '@atoms/Fields/InputLabel/input-label.components';

import styles from './mui-textarea.module.scss';

const MuiTextarea = forwardRef(
  ({ label, required, helperText, error, ...props }, ref) => {
    return (
      <div className={'outlined-input-component'}>
        <InputLabel label={label ? label : ''} required={required} />
        <TextareaAutosize
          className={styles.textarea}
          {...props}
          minRows={3}
          value={props.value === null ? '' : props.value}
        />
        {!!error && (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        )}
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </div>
    );
  }
);

MuiTextarea.displayName = 'MuiTextarea';

export default MuiTextarea;
