import {httpClient} from "@services/httpClient";

const RESOURCE_PATH = 'api-common';

export const fetchCommonData = {
  getUserDetails: function () {
    return httpClient.get(`${RESOURCE_PATH}/userDetails`);
  },
};

export default fetchCommonData;
