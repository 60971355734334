import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import DealCardV2 from '@organisms/DealCardv2/deal-card-v2.component';
import NoItems from '../../../Shared/NoItems/NoItems.component';

import styles from './card-view.module.scss';
import Grid from "@atoms/Grid/StaticGrid/static-grid.component";

function CardView({ loading, deals }) {
  const { t } = useTranslation();

  if (!loading && !deals.length) {
    return (
      <NoItems
        message={t('There are no deals created yet. Please create a new deal.')}
      />
    );
  }

  return (
    <div className={styles.dealsList}>
      <Grid columns={4}>
        {deals?.map((deal) => {
          return (
            <div className={styles.deal} key={deal.Deal.Id}>
              <DealCardV2 deal={deal.Deal} />
            </div>
          );
        })}
      </Grid>
    </div>
  );
}

export default memo(CardView);
