import React, { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import { FormHelperText } from '@mui/material';
import { Cookies } from 'react-cookie';
import { es, fr } from 'date-fns/locale';

import InputLabel from '@atoms/Fields/InputLabel/input-label.components';

import './form-date.styles.scss';

const cookies = new Cookies();

const FormDate = forwardRef(
  (
    {
      dateKey,
      label,
      view = 'date',
      isStepActive,
      readOnly,
      required = false,
      defaultValue,
      minDate = true,
      minDateValue,
      error,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { setValue, watch, getValues } = useFormContext();
    const formOptions = props;

    const [open, setOpen] = useState(false);
    const [dateValue, setDateValue] = useState('');
    const [daysLeft, setDaysLeft] = useState(0); //calculate days until the chosen date for the "date" type
    const today = dayjs();

    // On Init
    useEffect(() => {
      if (defaultValue && !getValues(formOptions.name)) {
        convertDate(defaultValue, false);
      }
    }, [defaultValue]);

    useEffect(() => {
      if (!dateValue) {
        convertDate(watch(formOptions.name), false);
      }
    }, [watch(formOptions.name)]);

    const datePlaceholder = () => {
      switch (dateKey) {
        case 'ExpirationDate':
          return `${t('Choose expiration date')}...`;
        case 'DeliveryDate':
          return `${t('Choose delivery date')}...`;
        default:
          return `${t('Choose date')}...`;
      }
    };

    const calculateRemainingDays = (date) => {
      const futureDate = dayjs(date);
      const daysDiff = futureDate.diff(today, 'day');
      setDaysLeft(daysDiff);
    };

    const convertDate = (date, shouldDirty) => {
      if (date) {
        const apiDateFormat = dayjs(date).format('YYYY-MM-DD');

        if (view === 'year') {
          const year = dayjs(date).format('YYY');
          setValue(formOptions.name, apiDateFormat, {
            shouldDirty: shouldDirty,
            shouldValidate: true,
          });
          setDateValue(year);
        } else if (view === 'month') {
          const month = dayjs(date).format('MMM YYYY');
          setValue(formOptions.name, apiDateFormat, {
            shouldDirty: shouldDirty,
            shouldValidate: true,
          });
          setDateValue(month || '');
        } else {
          const tempDate = dayjs(date).format('DD.MM.YYYY');
          setValue(formOptions.name, apiDateFormat, {
            shouldDirty: shouldDirty,
            shouldValidate: true,
          });
          setDateValue(tempDate || '');
          if (view === 'dateUntil') {
            calculateRemainingDays(date);
          }
        }
      }
    };

    // on change handle the end result
    const handleChange = (newValue) => {
      convertDate(newValue, true);
    };

    const assignLanguage = () => {
      const language = cookies.get('i18next'); // get the current language

      switch (language) {
        case 'fr': {
          return fr;
        }
        case 'es': {
          return es;
        }
      }
    };

    return (
      <div onKeyDown={(e) => e.stopPropagation()}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={assignLanguage()}>
          <InputLabel label={label} required={required} />
          <DatePicker
            {...(minDate ? { minDate: dayjs(minDateValue) || today } : {})}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            views={
              view === 'month'
                ? ['month', 'year']
                : view === 'year'
                ? ['year']
                : ['year', 'month', 'day']
            }
            // disableMaskedInput={true}
            // inputFormat={
            //   view === 'month'
            //     ? 'MMM yyyy'
            //     : view === 'year'
            //     ? 'YYYY'
            //     : 'dd/MM/yyyy'
            // }
            mask={
              view === 'month'
                ? '___ ____'
                : view === 'year'
                ? '____'
                : '__/__/____'
            }
            value={watch(formOptions.name) || ''}
            onChange={(newValue) => handleChange(newValue)}
            renderInput={({ inputRef, inputProps, InputProps }) =>
              !readOnly && !isStepActive ? (
                <Box
                  className={`date-input-box ${!!error ? 'date-error' : ''}`}
                  sx={{ display: 'flex', alignItems: 'center' }}
                  onClick={(e) => setOpen(true)}>
                  {view === 'dateUntil' && (
                    <span className={'days-left--wrap'}>
                      {daysLeft} {t('days left until')}
                    </span>
                  )}
                  <input
                    className={'date-field'}
                    {...inputProps}
                    ref={inputRef}
                    disabled={true}
                    placeholder={datePlaceholder()}
                    onKeyDown={(e) => e.stopPropagation()}
                    // onChange={(e) => { setDateValue(e.target.value) }}
                    value={dateValue || watch(formOptions.name) || ''}
                  />
                  {InputProps?.endAdornment}
                </Box>
              ) : (
                <input
                  className={'date-field'}
                  {...inputProps}
                  ref={inputRef}
                  placeholder={formOptions.value || ''}
                  disabled={true}
                  onKeyDown={(e) => e.stopPropagation()}
                  value={dateValue || ''}
                />
              )
            }
          />
          {!!error && (
            <FormHelperText error={!!error}>{error.message}</FormHelperText>
          )}
        </LocalizationProvider>
      </div>
    );
  }
);

FormDate.displayName = 'FormDate';

export default FormDate;
