/**
 * Provide a "Services orders with fees", incl. tracking
 */

import React, {
  forwardRef,
  useState,
  Fragment,
  useCallback,
  useEffect,
} from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { Box } from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import dealsService from '@lib/services/deals-service';
import FormInputFields from '@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component';
import Grid from '@atoms/Grid/grid.component';
import Button from '@atoms/Button/button.component';
import SwitchComponent from '@molecules/Switch/switch.component';
import ProductButtons from '@molecules/ButtonsVariation/ProductButtons/product-buttons.component';
import handleErrorsHelper from '@molecules/HandleErrors/handle-errors.helper';
import Portal from '@molecules/Portal/portal.component';
import PopupLayout from '@organisms/Popup/PopupLayout/popup-layout.component';
import PageLoader from '@templates/PageLoader/page-loader.template';
import Heading from '@atoms/Headings/heading.component';
import { MuiAutocomplete } from '@atoms/Fields/ReactFormFields/MuiAutocomplete/mui-autocomplete.component';
import variables from '@base/styles/variables.scss';
import './services-management.styles.scss';
import styles from './services-management.module.scss';
import { useUserRole } from '@lib/hooks/useUserRole';
import StepperV3 from '@molecules/Steppers/StepperV3/stepper-v3-component';
import StepOtherDocuments from '@organisms/Documents/StepOtherDocuments/step-other-documents.component';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import CheckmarkIcon from '@assets/icons/Checkmark';
import Sandglass from '@assets/images/sandglass.png';

const ServicesManagement = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { isAdmin, isTrader, isBuyer, isSeller } = useUserRole();

  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState({});
  const [formModifiedData, setFormModifiedData] = useState();
  const [originalMessageDialog, setOriginalMessageDialog] = useState(false);
  const [selectedFeeValue, setSelectedFeeValue] = useState(null);
  const [openRequestServiceDialog, setOpenRequestServiceDialog] =
    useState(false);
  const [haveServices, setHaveServices] = useState(true);

  const {
    serviceFields,
    dealId,
    dealDate,
    onChange,
    onBack,
    setActiveService,
  } = props;
  const steps = Object.keys(serviceFields.DealServices).map(
    (key) => serviceFields.DealServices[key]
  );

  //const modalServicesFields = serviceFields.DealServicesFields;
  const [selectedStepperStep, setSelectedStepperStep] = useState(0);
  const [selectedStep, selectStep] = useState(steps[0]);
  //const [documents, setDocuments] = useState(selectedStep.Documents);
  const [requestSideOptions, setRequestSideOptions] = useState(
    serviceFields.RequestSide
  );

  const { canWrite, channel, setChannel, updateDocuments, updatedDocuments } =
    useManageDealContext();

  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      ServiceFee: `${serviceFields?.Fee?.Fields[0]?.Value}` || '',
    },
  });

  useEffect(() => {
    updateDocuments(selectedStep.Documents);
    if (serviceFields.DealServicesFields.length === 0) {
      setHaveServices(false);
    }
    serviceFields.DealServicesFields.map((field) => {
      form.setValue(`${field.Key}`, !!field.Value);
      form.setValue(
        `${field.Key}For`,
        field.RequestedSide === null ? 'buyer' : field.RequestedSide
      );
      form.setValue(`${field.Key}IsPrivate`, field.IsPrivate);
    });
  }, []);

  useEffect(() => {
    updateDocuments(selectedStep.Documents);
  }, [selectedStep]);

  const {
    handleSubmit,
    formState: { isDirty, errors },
    reset,
    watch,
    setValue,
  } = form;

  const watchPriceType = watch('PriceType');
  const watchSpecies = watch('Species');
  const watchProducts = watch('Products');
  const watchNumContainers = watch('NumContainers');
  const watchFinance = watch('RequestFinance');
  const watchFinanceFor = watch('RequestFinanceFor');
  const watchFinanceIsPrivate = watch('RequestFinanceIsPrivate');
  const watchInsurance = watch('RequestInsurance');
  const watchInsuranceFor = watch('RequestInsuranceFor');
  const watchInsuranceIsPrivate = watch('RequestInsuranceIsPrivate');
  const watchBuyerInsurance = watch('BuyerInsurance');
  const watchBuyerInsuranceFor = watch('BuyerInsuranceFor');
  const watchBuyerInsuranceIsPrivate = watch('BuyerInsuranceIsPrivate');
  const watchFxHedging = watch('FxHedging');
  const watchFxHedgingFor = watch('FxHedgingFor');
  const watchFxHedgingIsPrivate = watch('FxHedgingIsPrivate');
  const watchSeaFreight = watch('SeaFreight');
  const watchSeaFreightFor = watch('SeaFreightFor');
  const watchSeaFreightIsPrivate = watch('SeaFreightIsPrivate');
  const watchLandTransport = watch('LandTransport');
  const watchLandTransportFor = watch('LandTransportFor');
  const watchLandTransportIsPrivate = watch('LandTransportIsPrivate');
  const watchLoadingSupervision = watch('LoadingSupervision');
  const watchLoadingSupervisionFor = watch('LoadingSupervisionFor');
  const watchLoadingSupervisionIsPrivate = watch('LoadingSupervisionIsPrivate');
  const watchQualityChecks = watch('QualityChecks');
  const watchQualityChecksFor = watch('QualityChecksFor');
  const watchQualityChecksIsPrivate = watch('QualityChecksIsPrivate');

  const getSelectedFeeName = (value) => {
    const feeField = serviceFields.Fee.Fields[0];
    const currentValue = serviceFields.Fee.Fields[0].Value;
    if (feeField && feeField.Options && feeField.Options[value]) {
      return feeField.Options[value].Name;
    }

    return serviceFields.Fee.Fields[0].Options[currentValue].Name;
  };

  const modifyServices = (data, dealId) => {
    setLoader(true);

    const responseData = {
      ServiceType: 'Fee',
      ServiceFee: data.ServiceFee,
      DealServices: [
        {
          RequestFinance: watchFinance,
          RequestFinanceFor: watchFinanceFor,
          RequestFinanceIsPrivate:
            (isBuyer || isSeller) && watchFinance !== undefined
              ? form.getFieldState('RequestFinance').isDirty
                ? true
                : watchFinanceIsPrivate
              : watchFinanceIsPrivate,
          RequestInsurance: watchInsurance,
          RequestInsuranceFor: watchInsuranceFor,
          RequestInsuranceIsPrivate:
            (isBuyer || isSeller) && watchInsurance !== undefined
              ? form.getFieldState('RequestInsurance').isDirty
                ? true
                : watchInsuranceIsPrivate
              : watchInsuranceIsPrivate,
          BuyerInsurance: watchBuyerInsurance,
          BuyerInsuranceFor: watchBuyerInsuranceFor,
          BuyerInsuranceIsPrivate:
            (isBuyer || isSeller) && watchBuyerInsurance !== undefined
              ? form.getFieldState('BuyerInsurance').isDirty
                ? true
                : watchBuyerInsuranceIsPrivate
              : watchBuyerInsuranceIsPrivate,
          FxHedging: watchFxHedging,
          FxHedgingFor: watchFxHedgingFor,
          FxHedgingIsPrivate:
            (isBuyer || isSeller) && watchFxHedging !== undefined
              ? form.getFieldState('FxHedging').isDirty
                ? true
                : watchFxHedgingIsPrivate
              : watchFxHedgingIsPrivate,
          SeaFreight: watchSeaFreight,
          SeaFreightFor: watchSeaFreightFor,
          SeaFreightIsPrivate:
            (isBuyer || isSeller) && watchSeaFreight !== undefined
              ? form.getFieldState('SeaFreight').isDirty
                ? true
                : watchSeaFreightIsPrivate
              : watchSeaFreightIsPrivate,
          LandTransport: watchLandTransport,
          LandTransportFor: watchLandTransportFor,
          LandTransportIsPrivate:
            (isBuyer || isSeller) && watchLandTransport !== undefined
              ? form.getFieldState('LandTransport').isDirty
                ? true
                : watchLandTransportIsPrivate
              : watchLandTransportIsPrivate,
          LoadingSupervision: watchLoadingSupervision,
          LoadingSupervisionFor: watchLoadingSupervisionFor,
          LoadingSupervisionIsPrivate:
            (isBuyer || isSeller) && watchLoadingSupervision !== undefined
              ? form.getFieldState('LoadingSupervision').isDirty
                ? true
                : watchLoadingSupervisionIsPrivate
              : watchLoadingSupervisionIsPrivate,
          QualityChecks: watchQualityChecks,
          QualityChecksFor: watchQualityChecksFor,
          QualityChecksIsPrivate:
            (isBuyer || isSeller) && watchQualityChecks !== undefined
              ? form.getFieldState('QualityChecks').isDirty
                ? true
                : watchQualityChecksIsPrivate
              : watchQualityChecksIsPrivate,
        },
      ],
    };

    dealsService
      .updateServicesData(dealId, responseData)
      .then((r) => {
        onChange();
        //window.location.reload();
        reset({
          ...form.getValues(),
        });
      })
      .catch((e) => {
        handleErrorsHelper(e.response.data, setAlert);
      })
      .finally(() => {
        setLoader(false);
        setOriginalMessageDialog(false);
      });
  };

  const renderFields = useCallback(
    (fieldsArray) => {
      return (
        <Fragment key={fieldsArray.Name}>
          {fieldsArray?.Field?.length > 0 &&
            fieldsArray?.Field?.map((element) => {
              return (
                <Controller
                  name={element.Key}
                  key={element.Key}
                  render={({ field }) => {
                    return (
                      <Grid columns={12} offsetPosition={'32'}>
                        {element.Type === 'dropdown' ? (
                          <FormInputFields
                            info={{ ...field }}
                            fields={element}
                            onChange={(value) => {
                              field.onChange(value);
                              setSelectedFeeValue(value.target.value);
                            }}
                            disabled={!!element.Readonly}
                          />
                        ) : null}
                      </Grid>
                    );
                  }}
                />
              );
            })}
          {updatedDocuments && (
            <StepOtherDocuments
              documents={updatedDocuments}
              updateDocuments={updateDocuments}
              step={selectedStep}
              canWrite={canWrite}
              activePage={'ServiceOrders'}
            />
          )}
        </Fragment>
      );
    },
    [canWrite, updatedDocuments, selectedStep]
  );

  const renderMainFields = useCallback(() => {
    return (
      <div className={styles.servicesMainFields}>
        {Object.keys(serviceFields.DealServices).map((objKey) => {
          const fields = serviceFields.DealServices[objKey];
          if (fields.Name === selectedStep.Name) {
            setActiveService(objKey);
            return (
              <div className={styles.services} key={objKey}>
                {selectedStep.HideContent ? (
                  <>
                    <h2 style={{ textAlign: 'center' }}>
                      {t('ContactAlmacena')}
                    </h2>
                    <div className={styles.imageWrap}>
                      <img src={Sandglass} title={'sandglass'} />
                    </div>
                  </>
                ) : (
                  renderFields(fields)
                )}
              </div>
            );
          }

          return null;
        })}
      </div>
    );
  }, [
    renderFields,
    selectedStep,
    serviceFields,
    setActiveService,
    updatedDocuments,
  ]);

  return (
    <>
      {originalMessageDialog && (
        <Portal overlay={'overlay'}>
          <PopupLayout
            closePopUp={() => setOriginalMessageDialog(false)}
            onSubmit={modifyServices}
            message={alert.message}
            state={alert.state}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box
                  typography={'h5'}
                  fontWeight={'bold'}
                  className={styles.title}>
                  <div>
                    <Tooltip
                      title={t('ServicesManagement')}
                      placement='top-start'>
                      <MarkEmailReadIcon className={styles.notMutedEmailIcon} />
                    </Tooltip>
                  </div>
                  {t('AboutToChangePlatformFee')}
                </Box>
              </Grid>

              <Grid item md={12}>
                <Box
                  typography={'p'}
                  fontWeight={'normal'}
                  className={styles.title}>
                  <Trans
                    i18nKey='ChangeDealPlatformFee'
                    values={{
                      oldvalue: getSelectedFeeName(
                        serviceFields.Fee.Fields[0].Value
                      ),
                      newvalue: getSelectedFeeName(selectedFeeValue),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                size={'large'}
                onClick={() => modifyServices(formModifiedData, dealId)}>
                {t('Confirm')}
              </Button>
            </Grid>
          </PopupLayout>
        </Portal>
      )}
      {openRequestServiceDialog && (
        <Portal overlay={'overlay'}>
          <PopupLayout
            //onConfirmClick={(action) => {setFormModifiedData()}}
            closePopUp={() => setOpenRequestServiceDialog(false)}>
            <Heading type={'h3'}>{t('RequestServices')}</Heading>
            {Object.keys(serviceFields).map((objKey) => {
              if (objKey === 'DealServicesFields') {
                const fieldsArray = serviceFields.DealServicesFields;
                return (
                  <FormProvider {...form} key={objKey}>
                    <div className={styles.services} key={objKey}>
                      {fieldsArray.map((item, i) => {
                        return (
                          <Fragment key={i}>
                            <Grid columns={isTrader || isAdmin ? 12 : 6}>
                              <div className={styles.requestServicesWrap}>
                                <Controller
                                  name={item.Key}
                                  defaultValue={false}
                                  render={({ field }) => {
                                    return (
                                      <div className={styles.financingSwitcher}>
                                        <SwitchComponent
                                          values={['', t(item.Label)]}
                                          onChange={(event) => {
                                            field.onChange(event);
                                          }}
                                          checked={field.value}
                                          onColor={variables.primaryBtnBgLight}
                                          onHandleColor={
                                            variables.primaryBtnBgLight
                                          }
                                          offColor='#999999'
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          height={20}
                                          width={48}
                                          handleDiameter={30}
                                          className={'financing-switcher'}
                                        />
                                      </div>
                                    );
                                  }}
                                />
                                <Controller
                                  name={`${item.Key}For`}
                                  defaultValue={[]}
                                  render={({ field }) => {
                                    return (
                                      <>
                                        {(isTrader || isAdmin) && (
                                          <div className={styles.selectInput}>
                                            <MuiAutocomplete
                                              disableClearable
                                              freeSolo={false}
                                              onChange={(value) => {
                                                setValue(
                                                  `${item.Key}For`,
                                                  value
                                                );
                                              }}
                                              value={
                                                field.value ||
                                                requestSideOptions.buyer
                                              }
                                              options={requestSideOptions}
                                              placeholder='Select'
                                              error={errors[`${item.Key}For`]}
                                              disabled={!watch(`${item.Key}`)}
                                            />
                                          </div>
                                        )}
                                      </>
                                    );
                                  }}
                                />
                                <Controller
                                  name={`${item.Key}IsPrivate`}
                                  defaultValue={null}
                                  render={({ field }) => {
                                    return (
                                      <>
                                        {(isTrader || isAdmin) && (
                                          <label
                                            key={item.StepId}
                                            className={`create-step`}
                                            onClick={(e) => {
                                              setValue(
                                                `${item.Key}IsPrivate`,
                                                !watch(`${item.Key}IsPrivate`)
                                              );
                                            }}>
                                            <span
                                              className={`checkmark ${
                                                watch(`${item.Key}IsPrivate`)
                                                  ? 'checked'
                                                  : ''
                                              } `}>
                                              {watch(
                                                `${item.Key}IsPrivate`
                                              ) && (
                                                <div className='check'>
                                                  <CheckmarkIcon />
                                                </div>
                                              )}
                                            </span>
                                            <p>{t('Private')}</p>
                                          </label>
                                        )}
                                      </>
                                    );
                                  }}
                                />
                              </div>
                            </Grid>
                          </Fragment>
                        );
                      })}
                      <Grid item xs={12}>
                        <div style={{ marginTop: 25, marginBottom: -10 }}>
                          <Button
                            fullWidth
                            size={'large'}
                            onClick={() => {
                              let haveChanges = false;
                              serviceFields.DealServicesFields.map(
                                (service) => {
                                  if (
                                    service.IsPrivate !==
                                    watch(`${service.Key}IsPrivate`)
                                  ) {
                                    haveChanges = true;
                                  }
                                }
                              );

                              if ((isAdmin || isTrader) && haveChanges) {
                                if (
                                  confirm(
                                    "Please confirm 'Private' fields change"
                                  )
                                ) {
                                  modifyServices(form.getValues(), dealId);
                                  setOpenRequestServiceDialog(false);
                                }
                              } else {
                                modifyServices(form.getValues(), dealId);
                                setOpenRequestServiceDialog(false);
                              }
                            }}>
                            {t('Confirm')}
                          </Button>
                        </div>
                      </Grid>
                    </div>
                  </FormProvider>
                );
              }

              return null;
            })}
          </PopupLayout>
        </Portal>
      )}

      <div className={styles.servicesStepper}>
        <div className={'step-group-container'}>
          <p className={'step-group'}>{t('ServicesOrders')}</p>
        </div>

        <div className={'stepper-wrap--v1 stepper-wrap--alternative'}>
          <div className={'step-title__container'}>
            <StepperV3
              steps={steps}
              selectStep={selectStep}
              selectedStepperStep={selectedStepperStep}
              setSelectedStepperStep={setSelectedStepperStep}
              setOpenRequestServiceDialog={setOpenRequestServiceDialog}
              disabledStepSelector={false}
              dealDate={dealDate}
              setActiveService={setActiveService}
              canWrite={canWrite}
              haveServices={haveServices}
            />
          </div>

          <FormProvider {...form}>
            <PageLoader isLoading={loader}>
              <div className={styles.mainForm}>{renderMainFields()}</div>
              {selectedStep.Buttons && (
                <div className={styles.buttonsWrap}>
                  <ProductButtons
                    handleSubmit={(actionType) =>
                      handleSubmit((formData) => {
                        setFormModifiedData(formData);
                        setOriginalMessageDialog(true);
                      })
                    }
                    stepActions={[
                      {
                        Name: 'Next',
                        Type: 'saveNext',
                        Disable: !isDirty,
                        size: 'large',
                      },
                    ]}
                  />
                </div>
              )}
            </PageLoader>
          </FormProvider>
        </div>
      </div>
    </>
  );
});

ServicesManagement.displayName = 'ServicesManagement';

export default ServicesManagement;
