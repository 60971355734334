import axios from 'axios';
import { Cookies } from 'react-cookie';

import { API_ROOT, AUTH_ROOT, nonce, AUTH_LOGOUT_ROOT } from '@config/config';

const cookies = new Cookies();
const API_URL = `${API_ROOT}`;

export const userAuthorize = () => {
  cookies.set('requestedURL', window.location.pathname, { path: '/' });
  cookies.set('nonce', nonce, { maxAge: 3600, path: '/' });
  window.location.replace(AUTH_ROOT);
};

export const httpClient = axios.create({
  baseURL: API_URL,
});

const checkCookies = () => {
  if (
    !cookies.get('id_token') ||
    !cookies.get('nonce') ||
    !cookies.get('access_token')
  ) {
    userAuthorize();
  }
};

httpClient.interceptors.request.use((req) => {
  checkCookies();
  req.headers.Authorization = `Bearer ${cookies.get('id_token')}`;
  req.headers['Custom-Nonce'] = cookies.get('nonce');
  req.headers['Accept-Language'] = localStorage.getItem('i18nextLng') || 'en';
  if (
    cookies.get('impersonateEmail') &&
    cookies.get('impersonateEmail') != 'undefined'
  ) {
    req.headers['Impersonate-Email'] = cookies.get('impersonateEmail');
  }
  return req;
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 403) {
      window.location.href = '/error/403';
    } else if (error?.response?.status === 404) {
      window.location.href = '/error/404';
    } else if (error?.response?.status === 401) {
      cookies.remove('id_token');
      cookies.remove('access_token');
      cookies.remove('nonce');
      cookies.remove('user');
      cookies.remove('impersonateEmail');
      userAuthorize();
    }
    return Promise.reject(error);
  }
);
