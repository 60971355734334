/**
 * Contacts Form
 * @description: inside settings page
 * @param fields
 * @param isFormDirty: the parent can reset the dirty state. IF needed
 * @param refreshFields: on submit send to the parent in order to send the new updated fields. IF needed.
 * @param ref: coming from the parent in order the parent to trigger the submit button. IF needed.
 */

import {useTranslation} from "react-i18next";
import React, {forwardRef, useCallback, useEffect} from "react";
import {Controller, useForm} from "react-hook-form";

import {usePopup} from "@lib/hooks/usePopup";
import commonService from "@services/common-service";
import FormMultiSelect from "@atoms/Fields/ReactFormFields/FormMultiSelect/form-multi-select.component";
import FormInput from "@atoms/Fields/ReactFormFields/FormInput/form-input.component";
import Button from "@atoms/Buttons/Button";
import styles from "@pages/DealSettings/deal-settings.module.scss";

const ContactsForm = forwardRef(({fields, isFormDirty, refreshFields, submitRef}, ref) => {
  const { t } = useTranslation();
  const { showPopup } = usePopup();

  const requiredField = t('Field is required');

  const defaultValues = {
    emailContact: fields?.emailContact?.Value,
    emailFinance: fields?.emailFinance?.Value,
    emailWarehouse: fields?.emailWarehouse?.Value,
    emailSupportUganda: fields?.emailSupportUganda?.Value,
    emailSupportRwanda: fields?.emailSupportRwanda?.Value,
    emailSupportDRC: fields?.emailSupportDRC?.Value,
    emailSupportBurundi: fields?.emailSupportBurundi?.Value,
    emailSupportEthiopia: fields?.emailSupportEthiopia?.Value,
    emailSupportDefault: fields?.emailSupportDefault?.Value,
    emailContactSupportDefault: fields?.emailContactSupportDefault?.Value,
  };

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm({defaultValues});

  const handleSubmitButton =  useCallback((data) => {
    commonService
      .submitSettings(data)
      .then(() => {
        showPopup({
          state: 'success',
          message: t('Saved successfully'),
          timer: 4000,
        });
      })
      .finally(() => {
        reset(data)
        refreshFields()
      })
  }, [])

  const onChangeMultiSelectHandler = (formName, value) => {
    setValue(formName, value, {shouldDirty: true});
  }

  useEffect(() => {
    isFormDirty(isDirty)
  }, [isDirty])

  return (
    <>
      <form>
        <div>
          <Controller
            control={control}
            name='emailContact'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                label={fields?.emailContact?.Label}
                required={true}
                placeholder={'Enter an email'}
                error={errors.emailContact?.message}
              />
            }
          />
        </div>
        <div>
          <Controller
            control={control}
            name='emailFinance'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                label={fields?.emailFinance?.Label}
                required={true}
                placeholder={'Enter an email'}
                error={errors.emailFinance?.message}
              />
            }
          />
        </div>
        <div>
          <Controller
            control={control}
            name='emailWarehouse'
            render={({ field }) =>
              <FormMultiSelect
                {...field}
                options={[]}
                required={false}
                onChange={onChangeMultiSelectHandler}
                canClearValue
                label={fields?.emailWarehouse?.Label}
                noOptionsText='Not found agent'
                error={errors.emailWarehouse?.message}
                freeSolo
              />
            }
          />
        </div>
        <div>
          <Controller
            control={control}
            name='emailSupportUganda'
            render={({ field }) =>
              <FormMultiSelect
                {...field}
                options={[]}
                required={false}
                onChange={onChangeMultiSelectHandler}
                canClearValue
                label={fields?.emailSupportUganda?.Label}
                noOptionsText='Not found agent'
                error={errors.emailSupportUganda?.message}
                freeSolo
              />
            }
          />
        </div>
        <div>
          <Controller
            control={control}
            name='emailSupportRwanda'
            render={({ field }) =>
              <FormMultiSelect
                {...field}
                options={[]}
                required={false}
                onChange={onChangeMultiSelectHandler}
                canClearValue
                label={fields?.emailSupportRwanda?.Label}
                noOptionsText='Not found agent'
                error={errors.emailSupportRwanda?.message}
                freeSolo
              />
            }
          />
        </div>
        <div>
          <Controller
            control={control}
            name='emailSupportDRC'
            render={({ field }) =>
              <FormMultiSelect
                {...field}
                options={[]}
                required={false}
                onChange={onChangeMultiSelectHandler}
                canClearValue
                label={fields?.emailSupportDRC?.Label}
                noOptionsText='Not found agent'
                error={errors.emailSupportDRC?.message}
                freeSolo
              />
            }
          />
        </div>
        <div>
          <Controller
            control={control}
            name='emailSupportBurundi'
            render={({ field }) =>
              <FormMultiSelect
                {...field}
                options={[]}
                required={false}
                onChange={onChangeMultiSelectHandler}
                canClearValue
                label={fields?.emailSupportBurundi?.Label}
                noOptionsText='Not found agent'
                error={errors.emailSupportBurundi?.message}
                freeSolo
              />
            }
          />
        </div>
        <div>
          <Controller
            control={control}
            name='emailSupportEthiopia'
            render={({ field }) =>
              <FormMultiSelect
                {...field}
                options={[]}
                required={false}
                onChange={onChangeMultiSelectHandler}
                canClearValue
                label={fields?.emailSupportEthiopia?.Label}
                noOptionsText='Not found agent'
                error={errors.emailSupportEthiopia?.message}
                freeSolo
              />
            }
          />
        </div>
        <div>
          <Controller
            control={control}
            name='emailSupportDefault'
            render={({ field }) =>
              <FormMultiSelect
                {...field}
                options={[]}
                required={false}
                onChange={onChangeMultiSelectHandler}
                canClearValue
                label={fields?.emailSupportDefault?.Label}
                noOptionsText='Not found agent'
                error={errors.emailSupportDefault?.message}
                freeSolo
              />
            }
          />
        </div>
        <div>
          <Controller
            control={control}
            name='emailContactSupportDefault'
            rules={{
              required: {
                value: true,
                message: requiredField
              }
            }}
            render={({ field }) =>
              <FormInput
                {...field}
                type={"text"}
                label={fields?.emailContactSupportDefault?.Label}
                required={true}
                placeholder={'Enter an email'}
                error={errors.emailContactSupportDefault?.message}
              />
            }
          />
        </div>
      </form>
      <div className={styles.saveButtonWrap}>
        <Button
          type={"fill"}
          size={"large"}
          onClick={handleSubmit(handleSubmitButton)}
          disabled={!isDirty}
          ref={submitRef}
        >{t("Save")}</Button>
      </div>
    </>
  )
})

ContactsForm.displayName = "ContactsForm";

export default ContactsForm;
