/**
 * Full Screen Layout component
 * @description: the semi transparent dark background on the full screen. Used for pop up sections
 * @props children
 * @props toggle: send to the parent when we close the layout
 * @props isMenuOpened: send if the Layout is Opened or not
 */
import {useEffect, useState} from "react";

import Portal from "@molecules/Portal/portal.component";

import styles from "./full-screen-layout.module.scss";
import {useWindowSize} from "@lib/hooks/useWindowSize";
import {smallMobileMediaQuery, tabletMediaQuery} from "@base/globalVariables/global-variables";

const FullScreenLayout = ({toggle, isMenuOpened, children}) => {
  const { width } = useWindowSize();

  const [menuOpened, setIsMenuOpened] = useState(false);

  // On Init
  useEffect(() => {
    setIsMenuOpened(isMenuOpened);
  }, [isMenuOpened])

  // disable body scrolling while the Layout is opened
  // and enabled it back when it is closed
  useEffect(() => {
    if(menuOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [menuOpened])

  const handleClick = () => {
    setIsMenuOpened(false);
    document.body.style.overflow = 'unset';
    toggle();
  }

  return (
    <>
      {menuOpened ?
        <Portal overlay={"overlay"}>
          <div className={styles.fullScreenLayout} onClick={handleClick}>
            <section onClick={(e) => {e.stopPropagation()}}>
              {children}
            </section>
          </div>
        </Portal>
        : <></>
      }
    </>
  )
}

export default FullScreenLayout;
