import './static-grid.styles.scss';

const StaticGrid = ({children, columns}) => {
  return (
    <div className={`grid grid-${columns}`}>
      {children}
    </div>
  )
}

export default StaticGrid;
