import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './deal-progress.module.scss';

const DealProgress = ({ dealStep, navigateToDeal }) => {
  const { t } = useTranslation();

  const progress = useMemo(() => {
    const current = Number(dealStep?.Current?.Position);
    const allSteps = Number(dealStep?.AllSteps);
    if (allSteps === current) {
      return 99;
    } else if (current === 1) {
      return 0;
    } else {
      return Math.round((100 / allSteps) * current);
    }
  }, []);

  return (
    <div className={styles.progress}>
      <div className={styles.status}>
        <p>{dealStep?.Current ? t(dealStep?.Current.Group) : ' -- '}</p>
        <p
          className={dealStep?.Current ? styles.stepName : ''}
          onClick={dealStep?.Current ? navigateToDeal : () => {}}>
          {dealStep?.Current ? t(dealStep?.Current.Name) : ' -- '}
        </p>
      </div>
      <div className={styles.progressBar}>
        <div
          className={styles.progressDot}
          style={{
            left: dealStep && dealStep?.Current && `${progress}%`,
          }}></div>
      </div>
    </div>
  );
};

export default memo(DealProgress);
